import styles from "./staffOneStatusesGraphTodayBtn.module.scss";
import { useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";

const StaffOneStatusesGraphTodaybtn = () => {
  const { staffOneStatusesStore, menuStore } = useStores();
  const [todayBtnPosition, setTodayBtnPosition] = useState<"left" | "right">(
    "left"
  );

  const scrollTableBy = (value: number) => {
    menuStore.scrollbarRef.current.view.scroll({
      left: value,
      behavior: "smooth"
    });
  };
  console.log(menuStore.scroll.left);
  console.log(todayBtnPosition);

  return (
    <div className={styles.wrapper}>
      <button
        className={classNames(styles.button, {
          [styles.hoveredContainer]: menuStore.scroll.left,
          [styles.containerToRight]: todayBtnPosition === "right"
        })}
        onClick={() => {
          if (!staffOneStatusesStore.isReversedTable) {
            scrollTableBy(
              menuStore.scroll?.scrollWidth - menuStore.scroll?.clientWidth >
                200
                ? menuStore.scroll?.scrollLeft -
                    (menuStore.scroll?.scrollWidth -
                      menuStore.scroll?.clientWidth)
                : 0
            );
            setTodayBtnPosition("left");
          }
          if (staffOneStatusesStore.isReversedTable) {
            scrollTableBy(
              menuStore.scroll?.scrollWidth - menuStore.scroll?.clientWidth >
                200
                ? menuStore.scroll?.scrollLeft +
                    (menuStore.scroll?.scrollWidth +
                      menuStore.scroll?.clientWidth)
                : menuStore.scroll?.scrollLeft + menuStore.scroll?.clientWidth
            );
            setTodayBtnPosition("right");
          }
        }}
        id="StaffOneStatusesGraphTodaybtn_goToPrevious"
      >
        <div
          className={classNames(styles.contentBlock, {
            [styles.contentBlockRight]:
              staffOneStatusesStore.isReversedTable &&
              todayBtnPosition !== "right"
          })}
        >
          {(!staffOneStatusesStore.isReversedTable && menuStore.scroll.left) ||
          (!staffOneStatusesStore.isReversedTable &&
            todayBtnPosition !== "right" &&
            menuStore.scroll.left) ? (
            <StatusIcon icon="iconarrowleft" color="blue-lazure" />
          ) : (
            ""
          )}
          <p
            className={classNames(styles.buttonText, {
              [styles.buttonTextActive]:
                (staffOneStatusesStore.isReversedTable &&
                  (todayBtnPosition !== "right" ||
                    menuStore.scroll.left < 1)) ||
                (!staffOneStatusesStore.isReversedTable &&
                  menuStore.scroll.left) ||
                (!staffOneStatusesStore.isReversedTable &&
                  todayBtnPosition !== "right" &&
                  menuStore.scroll.left)
            })}
          >
            Сегодня
          </p>
          {staffOneStatusesStore.isReversedTable &&
          (todayBtnPosition !== "right" || menuStore.scroll.left < 1) ? (
            <StatusIcon
              icon="iconarrowleft"
              color="blue-lazure"
              className={styles.icon}
            />
          ) : (
            ""
          )}
        </div>
      </button>
    </div>
  );
};

export default observer(StaffOneStatusesGraphTodaybtn);
