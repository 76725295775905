import React from "react";
import {
  ReactComponent as IconBack,
  ReactComponent as IconNext
} from "shared/assets/images/mainIcons/iconBack/iconBackWithoutStroke.svg";
import { classNames } from "shared/utils/helpers/classNames";
import StaffOneEditPhotoAltCropper from "../../StaffOneEditPhotoAltCropper";
import styles from "./photoAltGalleryViewSection.module.scss";
import { observer } from "mobx-react-lite";

type CropperRef = {
  getCropImage: () => string;
};

type ViewSectionProps = {
  imageIndex: number;
  setImageIndex: (index: number) => void;
  loadedImages: { hash: string; isMain: number; src: string }[];
  galleryState: string;
  cropperRef: React.RefObject<CropperRef>;
};

const PhotoAltGalleryViewSection = ({
  imageIndex,
  setImageIndex,
  loadedImages,
  galleryState,
  cropperRef
}: ViewSectionProps) => (
  <div className={styles.viewingSection}>
    <div className={styles.prevNextBtnBox}>
      <button
        type="button"
        className={classNames(styles.prevBtn, {
          [styles.prevBtnDisabled]: imageIndex <= 0
        })}
        onClick={() => setImageIndex(imageIndex - 1)}
        disabled={imageIndex <= 0}
        id="PhotoAltGalleryViewSection_previosPhoto"
      >
        <IconBack alt="Back" />
      </button>
      <div className={styles.galleryPhotoBackground}>
        <div className={styles.iconsGalleryBox}>
          <StaffOneEditPhotoAltCropper
            file={loadedImages[imageIndex]?.src}
            galleryState={galleryState}
            imageIndex={imageIndex}
            ref={cropperRef}
          />
        </div>
      </div>
      <button
        type="button"
        className={classNames(styles.nextBtn, {
          [styles.nextBtnDisabled]: imageIndex >= loadedImages.length - 1
        })}
        onClick={() => setImageIndex(imageIndex + 1)}
        disabled={imageIndex >= loadedImages.length - 1}
        id="PhotoAltGalleryViewSection_nextPhoto"
      >
        <IconNext className={styles.nextBtnImg} alt="Next" />
      </button>
    </div>
  </div>
);

export default observer(PhotoAltGalleryViewSection);
