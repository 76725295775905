import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import List from "shared/ui/List";

type FriendInviteOneStaffListProps = {
  handleOpenedList: (val: boolean) => void;
  isOpenedList: boolean;
  referenseRef: React.RefObject<HTMLDivElement>;
  id: string;
  title: string;
};

const FriendInviteOneStaffList = ({
  handleOpenedList,
  isOpenedList,
  referenseRef,
  id,
  title
}: FriendInviteOneStaffListProps) => {
  const { friendInviteStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const getList = () => friendInviteStore.getStaffList(id);
  const invite =
    friendInviteStore.updatedInvites[id] ||
    friendInviteStore.invitesList[id] ||
    {};
  const value = invite[title];

  useEffect(
    () => setIsLoading(friendInviteStore.isLoadingForStaffList),
    [friendInviteStore.isLoadingForStaffList]
  );

  useEffect(() => {
    if (
      friendInviteStore.pageStaff !== 1 &&
      friendInviteStore.pageStaff <= friendInviteStore.maxPageStaff &&
      friendInviteStore.pageStaff !== friendInviteStore.prevPageStaff
    ) {
      friendInviteStore.getStaffList(id, true);
    }
  }, [friendInviteStore.pageStaff, friendInviteStore.maxPageStaff]);

  return (
    <List
      options={friendInviteStore.staffList || []}
      value={value?.id || ""}
      setIsOpenedMenu={handleOpenedList}
      isOpenedMenu={isOpenedList}
      referenceRef={referenseRef}
      setSelectedItem={(item) => {
        friendInviteStore.editCol(id, {
          [`update[${title}]`]: (item?.tn as string) || ""
        });
      }}
      valueName="id"
      isLoading={isLoading}
      isSearchable
      isSearchWithPagination
      page={friendInviteStore.pageStaff}
      prevPage={friendInviteStore.prevPageStaff}
      maxPage={friendInviteStore.maxPageStaff}
      setPage={friendInviteStore.setPageStaff}
      getList={getList}
      setSearchValue={friendInviteStore.setSearchValueStaff}
      searchValue={friendInviteStore.searchValueStaff}
    />
  );
};

export default observer(FriendInviteOneStaffList);
