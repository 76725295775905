import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { Col } from "../StaffModule/types/Col";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Selects } from "stores/StaffModule/types/Selects";
import { TableParams } from "stores/StaffModule/types/TableParams";
import { BuildingList } from "./types/BuildingList";

import data from "./buildingFilters.json";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";

type Tab = {
  title: string;
  id: string;
  comment: null | string;
  ordered: null | string;
  custom: {
    order: string;
    choice: string;
  };
};

type FilterParams = {
  [key: string]: string | number;
};

export default class BuildingListStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;

  openedFilter = false;

  buildingListOrder = "";
  buildingListOrdered = "";
  searchValue = "";

  currentTitles: string[] = [];

  buildingTableParams: { [key: string]: Col } = {};
  buildingColsAll: { [key: string]: Col } = {};

  buildingTableCols: { [key: string]: Col } = {};

  buildingList: { [key: string]: string | number }[] = [];
  selectsCols = {} as Selects & { code: number };
  tableParams: { [key: string]: TableParams } = {};

  filters: { [key: string]: FilterType } = {};
  filtersChanged: { [key: string]: FilterType } = {};
  filterParams: FilterParams | { [key: string]: string[] } = {};
  queryParams: { [key: string]: string } = {};
  selectedFilter = "";
  downloadedFilter = "";

  onPage = 50;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  actionButton = {
    "0": {
      id: 1,
      title: "OK",
      action: () => {
        this.setErrorMessage("");
      }
    }
  };

  activeTab = "";

  tabs: {
    [key: string]: Tab;
  } = {};

  selectedStatus = "all";
  selectedType = "all";

  resetAll = () => {
    this.buildingListOrder = "";
    this.buildingListOrdered = "";
    this.page = 1;
    this.searchValue = "";
    this.filters = {};
    this.filterParams = {};
    this.buildingTableParams = {};
    this.buildingColsAll = {};
    this.buildingTableCols = {};
    this.buildingList = [];
  };

  getNewFilterParams = () => {
    if (this.selectedType !== "all") {
      this.filterParams["filter[type][]"] = this.selectedType;
    }

    if (this.selectedStatus !== "all") {
      this.filterParams["filter[status][]"] = this.selectedStatus;
    }

    this.filters?.filter_default?.cols?.forEach((item) => {
      this.filterParams[`show[${item}]`] = "on";
    });
  };

  getBuildingListWithFilter = (filter: FilterType) => {
    this.filterParams = {};
    this.setPage(1);
    if (!filter.default) {
      this.setBuildingListOrder(filter.order);
      this.setBuildingListOrdered(filter.ordered);
      this.getNewFilterParams();
    } else {
      this.setBuildingListOrder("");
      this.setBuildingListOrdered("");
      this.filterParams = {};
    }
    this.setDownloadedFilter(filter["id"]);
    this.getBuildingsList();
  };

  dataPreparation = (data: { [key: string]: string | number }[]) => {
    return data.map((building) => {
      const tempObjBuilding = { ...building };

      Object.entries(building).forEach(([key, value]) => {
        switch (value) {
          case null:
          case undefined:
          case -1:
            tempObjBuilding[key] = "";
            break;
          default:
            if (typeof value === "string")
              tempObjBuilding[key] = getFormattedDate(value);
            else tempObjBuilding[key] = value;
            break;
        }
      });
      return tempObjBuilding;
    });
  };

  getSelects = async () => {
    try {
      const data: Selects & { code: number } =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selectsCols = data;
        } else {
          this.selectsCols = {} as Selects & { code: number };
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<{ [key: string]: TableParams } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          currentClass: "building",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.tableParams = data["result"];
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getBuildingsList = async (action?: string, id?: string) => {
    runInAction(() => (this.isLoading = true));

    this.errorMessage = "";
    this.page = 1;
    this.getTableParams();
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.buildingList = [];
    try {
      const data: BuildingList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.buildingList = this.dataPreparation(
            Object.values(data["records"])
          );
        } else {
          this.buildingList = [];
          this.errorMessage = "Ничего не найдено";
        }

        this.maxPage = data["nav"]["max_page"];

        if (
          Object.values(data["filters"]).length &&
          (!Object.keys(this.filters).length || action)
        ) {
          Object.entries(data["cols"]).forEach(([key, col]) => {
            if (col["showAsColumn"]) this.buildingTableCols[key] = col;
            if (col["useAsFilter"]) this.buildingTableParams[key] = col;
          });

          Object.keys(this.buildingTableCols).forEach((col) => {
            if (this.tableParams[col]?.["show_default"] === "on") {
              this.currentTitles.push(col);
            }
          });
          this.buildingColsAll = data["cols"];

          this.filters = {};
          Object.entries(data["filters"]).forEach(([key, filter], index) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["cols"] = filter["filterdata"]["cols"]
              ? Object.values(filter["filterdata"]["cols"])
              : [];
            this.filters[key]["id"] = key;
            if (key === this.downloadedFilter) {
              this.buildingListOrdered = this.filters[key]["ordered"];
            }

            if (
              this.filters[key]["default"] &&
              !Object.values(this.filterParams).length
            ) {
              this.setDownloadedFilter(key);
            }

            if (action) {
              switch (action) {
                case "add":
                  if (Object.entries(data["filters"]).length - 2 === index) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "edit":
                  if (id === key) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "delete":
                  if (this.filters[key]["default"]) {
                    this.setSelectedFilter(key);
                  }
                  break;
              }
            } else {
              if (this.filters[key]["default"]) {
                this.setSelectedFilter(key);
              }
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  getMoreBuildingsList = async () => {
    this.errorMessage = "";

    runInAction(() => (this.isLoading = true));
    try {
      const data: BuildingList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          only_records: 1,
          ...this.filterParams
        }
      });
      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.buildingList.push(
            ...this.dataPreparation(Object.values(data["records"]))
          );
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.buildingList = [];
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};

    //РАССКОММЕНТИРОВАТЬ ПРИ ПОДКЛЮЧЕНИИ ФИЛЬТРОВ
    // if (this.buildingListOrder) {
    //   params["order"] = this.buildingListOrder;
    // }
    // if (this.buildingListOrdered) {
    //   params["ordered"] = this.buildingListOrdered;
    // }
    if (this.searchValue) {
      params["fast_search"] = this.searchValue;
    }

    return params;
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const newParams: { [key: string]: string | number } | { cols?: string[] } =
      {};

    runInAction(() => {
      this.isLoadingForFilters = true;
    });
    getEntries(values).forEach(([key, value]) => {
      switch (key) {
        case "cols":
          newParams[key] = values[key];
          break;
        case "filter":
          value &&
            getEntries(value).forEach(([keyField, valueField]) => {
              if (Array.isArray(valueField)) {
                valueField.forEach((item, index) => {
                  newParams[`filter[${keyField}][${index}]`] = item;
                });
              } else if (typeof valueField === "object") {
                getValues(valueField).forEach((item, index) => {
                  newParams[`filter[${keyField}][${index}]`] = item;
                });
              } else if (valueField.toString().length) {
                newParams[`filter[${keyField}]`] = valueField;
              }
            });
          break;
        case "id":
          break;
        default:
          newParams[key] = values[key];
          break;
      }
    });

    switch (action) {
      case "add":
        newParams["saveFilter"] = values.title;
        break;
      case "edit":
        newParams["updateFilter"] = id;
        break;
      case "delete":
        newParams["removeFilter"] = id;
        this.setSelectedFilter(
          Object.values(this.filters)[0]["id"] !== id
            ? Object.values(this.filters)[0]["id"]
            : "new"
        );
        break;
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        body: { module: "building", ...newParams }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getBuildingsList(action, id);
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      this.filtersChanged[currentFilter][key] = value;
    } else {
      const defaultArray = Object.keys(this.buildingColsAll).filter(
        (col) => !Object.keys(this.buildingColsAll).includes(col)
      );
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: this.currentTitles.filter((col) => !defaultArray.includes(col)),
        order: "title",
        ordered: "ASC"
      };
      this.filtersChanged[currentFilter][key] = value;
    }
  };

  setSelectedStatus = (status: string) => {
    this.selectedStatus = status;
    this.getNewFilterParams();

    this.getBuildingListWithFilter({
      id: "filter",
      title: "",
      default: 0,
      general: 0,
      filter: this.filterParams,
      cols: this.filters?.filter_default?.cols || [],
      order: "DESC",
      ordered: ""
    });
  };

  setSelectedType = (type: string) => {
    this.selectedType = type;
    this.getNewFilterParams();

    this.getBuildingListWithFilter({
      id: "filter",
      title: "",
      default: 0,
      general: 0,
      filter: this.filterParams,
      cols: this.filters?.filter_default?.cols || [],
      order: "DESC",
      ordered: ""
    });
  };

  getTitle = (key: string) => {
    if (
      Object.keys(this.buildingTableCols).length &&
      this.buildingTableCols[key]
    ) {
      return this.buildingTableCols[key]["title"];
    } else return key;
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setOpenedFilter = (value: boolean) => {
    this.openedFilter = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setBuildingListOrder = (value: string) => {
    this.buildingListOrder = value;
  };

  setBuildingListOrdered = (value: string) => {
    this.buildingListOrdered = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setFilterParamsFromQuery = (
    value: FilterParams | { [key: string]: string[] }
  ) => {
    this.filterParams = value;

    if (value["filter[type][]"]) {
      this.selectedType = value["filter[type][]"] as string;
    }

    if (value["filter[status][]"]) {
      this.selectedStatus = value["filter[status][]"] as string;
    }
  };

  setData = () => {
    runInAction(() => {
      this.isLoading = true;
    });

    try {
      runInAction(() => {
        Object.values(data.filters).map((el) => {
          if (el["custom"]["choice"]) this.setActiveTab(el["id"]);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setActiveTab = (id: string) => {
    this.activeTab = id;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
