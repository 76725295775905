import { RouteProps } from "react-router-dom";

import Main from "widgets/Main/index";

import {
  FriendInviteAll,
  FriendInviteOneInvite,
  FriendInviteOneEdit
} from "modules/FriendInviteModule/index";

import {
  StaffList,
  StaffAddNew,
  StaffImport,
  StaffRating,
  StaffAddAward
  // StaffVacation,
  // StaffCities,
  // StaffStatusSettings,
  // StaffCardSettings,
  // StaffTypeAddress,
} from "modules/StaffModule/StaffAll/index";

import {
  StaffOneInformation,
  StaffOneEdit,
  StaffOneStatuses,
  StaffOneComments,
  StaffOneConviction,
  StaffOneHistory,
  StaffOneAwards,
  StaffOneWorkshift,
  StaffOneSafetyWork,
  StaffOneSalary,
  StaffOnePremiumLevel
} from "modules/StaffModule/StaffOne/index";

import { PolatiAppVacantRequest } from "modules/PolatiAppModule/index";

import { BuildingList } from "modules/BuildingModule/BuildingAll";

import {
  BuildingOneInfotmation,
  BuildingOneTimesheet,
  BuildingOneStaff,
  BuildingOneStaffMovements,
  BuildingOneWorkshift,
  BuildingOneWorkshiftStaff,
  BuildingOneWorkshiftHistory
} from "modules/BuildingModule/BuildingOne/index";

import { ProjectsList } from "modules/ProjectsModule/ProjectsAll";

import { ProjectsOneInfo } from "modules/ProjectsModule/ProjectsOne/index";

import {
  AregisterAllReport,
  AregisterAllAppcreate,
  AregisterAllList,
  AregisterAllReference
} from "modules/AregisterModule/AregisterAll";

import {
  AregisterOneInfo,
  AregisterOneEdit,
  AregisterOneStaff,
  AregisterOnePresave,
  AregisterOneMasscopy
} from "modules/AregisterModule/AregisterOne";

import {
  SalaryOperations,
  SalaryOperationsOne,
  SalaryOperationsOneEdit,
  SalaryOperationsOneHistory,
  SalaryOperationsOneComments,
  SalaryPaymentlistAll,
  SalaryPaymentlistOneInfo,
  SalaryPaymentlistOneHistory,
  SalaryPremiums,
  SalaryPremiumsOneInfo,
  SalaryPremiumsOneHistory,
  SalaryAddOperation,
  SalaryAddPremium,
  SalaryAddPayment
} from "modules/SalaryModule/index";

import {
  SafetyWorkList,
  SafetyWorkAdd,
  SafetyWorkTermsControl
} from "modules/SafetyWorkModule";

import Error from "widgets/LoadedComponent/Error";

export enum AppRoutes {
  MAIN = "main",
  MAIN_WITH_EMPTY_STRING = "main_empty",

  FRIENDINVITE = "friendinvite",
  FRIENDINVITE_ONE = "friendinvite_one",
  FRIENDINVITE_ONE_EDIT = "friendinvite_one_edit",

  STAFF = "staff",
  STAFF_ADD = "staff_add",
  STAFF_ADD_AWARD = "staff_add_award",
  STAFF_IMPORT = "staff_import",
  STAFF_VACATION = "staff_vacation",
  STAFF_STATUS_SETTINGS = "staff_status_settings",
  STAFF_CARD_SETTINGS = "staff_card_settings",
  STAFF_TYPEADDRESS = "staff_typeaddress",
  STAFF_CITIES = "staff_cities",
  STAFF_RATING = "staff_rating",

  STAFF_ONE = "staff_one",
  STAFF_ONE_EDIT = "staff_one_edit",
  STAFF_ONE_EDIT_ID = "staff_one_edit_id",
  STAFF_ONE_CONVICTION = "staff_one_conviction",
  STAFF_ONE_HISTORY = "staff_one_history",
  STAFF_ONE_STATUSES = "staff_one_statuses",
  STAFF_ONE_COMMENTS = "staff_one_comments",
  STAFF_ONE_AWARDS = "staff_one_awards",
  STAFF_ONE_WORKSHIFT = "staff_one_workshift",
  STAFF_ONE_SAFETYWORK = "staff_one_safetywork",
  STAFF_ONE_SALARY = "staff_one_salary",
  STAFF_ONE_PREMIUMLEVEL = "staff_one_premiumlevel",

  POLATIAPP = "polatiapp",

  BUILDING = "building",
  BUILDING_ONE = "building_one",
  BUILDING_ONE_TIMESHEET = "building_one_timesheet",
  BUILDING_ONE_STAFF = "building_one_staff",
  BUILDING_ONE_STAFFMOVEMENTS = "building_one_staffmovements",

  WORKSHIFT_ONE = "workshift_one",
  WORKSHIFT_ONE_STAFF = "workshift_one_staff",
  WORKSHIFT_ONE_HISTORY = "workshift_one_history",

  PROJECTS = "projects",
  PROJECT_ONE = "project_one",

  AREGISTER = "aregister",
  AREGISTER_REPORT = "aregister_report",
  AREGISTER_REFERENCE = "aregister_reference",
  AREGISTER_APPCREATE = "aregister_appcreate",

  AREGISTER_ONE = "aregister_one",
  AREGISTER_ONE_EDIT = "aregister_one_edit",
  AREGISTER_ONE_STAFF = "aregister_one_staff",
  AREGISTER_ONE_PRESAVES = "aregister_one_presaves",
  AREGISTER_ONE_MASSCOPY = "aregister_one_masscopy",

  SALARY = "salary",
  SALARY_ONE = "salary_one",
  SALARY_ONE_EDIT = "salary_one_edit",
  SALARY_ONE_COMMENTS = "salary_one_comments",
  SALARY_ONE_HISTORY = "salary_one_history",

  PAYMENTLIST = "paymentlist",
  PAYMENTLIST_ONE = "paymentlist_one",
  PAYMENTLIST_ONE_HISTORY = "paymentlist_one_history",

  PREMIUM = "premium",
  PREMIUM_ONE = "premium_one",
  PREMIUM_ONE_HISTORY = "premium_one_history",

  PREMIUM_ADD = "premium_add",
  PAYMENTLIST_ADD = "payment_add",
  SALARY_ADD = "salary_add",
  SALARY_ADD_ID = "salary_add_id",

  SAFETYWORK = "safetywork",
  SAFETYWORK_ADD = "safetywork_add",
  SAFETYWORK_TERMSCONTROL = "safetywork_termscontrol",

  NOT_FOUND = "not_found"
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/main",
  [AppRoutes.MAIN_WITH_EMPTY_STRING]: "/",

  [AppRoutes.FRIENDINVITE]: "/friendinvite",
  [AppRoutes.FRIENDINVITE_ONE]: "/friendinvite/id=:id",
  [AppRoutes.FRIENDINVITE_ONE_EDIT]: "/friendinvite/id=:id/edit",

  [AppRoutes.STAFF]: "/staff",
  [AppRoutes.STAFF_ADD]: "/staff/add",
  [AppRoutes.STAFF_ADD_AWARD]: "/staff/addAward",
  [AppRoutes.STAFF_IMPORT]: "/staff/import",
  [AppRoutes.STAFF_VACATION]: "/staff/staffVacation",
  [AppRoutes.STAFF_STATUS_SETTINGS]: "/staff/statusSettings",
  [AppRoutes.STAFF_CARD_SETTINGS]: "/staff/staffVacation",
  [AppRoutes.STAFF_TYPEADDRESS]: "/staff/typeAddress",
  [AppRoutes.STAFF_CITIES]: "/staff/cities",
  [AppRoutes.STAFF_RATING]: "/staff/staffRating",

  [AppRoutes.STAFF_ONE]: "/staff/id=:id",
  [AppRoutes.STAFF_ONE_EDIT]: "/staff/id=:id/edit",
  [AppRoutes.STAFF_ONE_EDIT_ID]: "/staff/id=:id/edit=:edit",
  [AppRoutes.STAFF_ONE_CONVICTION]: "/staff/id=:id/conviction",
  [AppRoutes.STAFF_ONE_HISTORY]: "/staff/id=:id/history",
  [AppRoutes.STAFF_ONE_STATUSES]: "/staff/id=:id/statuses",
  [AppRoutes.STAFF_ONE_COMMENTS]: "/staff/id=:id/comments",
  [AppRoutes.STAFF_ONE_AWARDS]: "/staff/id=:id/awards",
  [AppRoutes.STAFF_ONE_WORKSHIFT]: "/staff/id=:id/workshift",
  [AppRoutes.STAFF_ONE_SAFETYWORK]: "/staff/id=:id/safety_work",
  [AppRoutes.STAFF_ONE_SALARY]: "/staff/id=:id/salary",
  [AppRoutes.STAFF_ONE_PREMIUMLEVEL]: "/staff/id=:id/premium_level",

  [AppRoutes.POLATIAPP]: "/polatiapp",

  [AppRoutes.BUILDING]: "/building",
  [AppRoutes.BUILDING_ONE]: "/building/id=:id",
  [AppRoutes.BUILDING_ONE_TIMESHEET]: "/building/id=:id/timesheet",
  [AppRoutes.BUILDING_ONE_STAFF]: "/building/id=:id/staff",
  [AppRoutes.BUILDING_ONE_STAFFMOVEMENTS]: "/building/id=:id/staffmovements",

  [AppRoutes.WORKSHIFT_ONE]: "/workshift/id=:id",
  [AppRoutes.WORKSHIFT_ONE_STAFF]: "/workshift/id=:id/staff",
  [AppRoutes.WORKSHIFT_ONE_HISTORY]: "/workshift/id=:id/history",

  [AppRoutes.PROJECTS]: "/projects",

  [AppRoutes.PROJECT_ONE]: "/projects/id=:id",

  [AppRoutes.AREGISTER]: "/aregister",
  [AppRoutes.AREGISTER_REPORT]: "/aregister/report",
  [AppRoutes.AREGISTER_REFERENCE]: "/aregister/reference",
  [AppRoutes.AREGISTER_APPCREATE]: "/aregister/appcreate",

  [AppRoutes.AREGISTER_ONE]: "/aregister/id=:id",
  [AppRoutes.AREGISTER_ONE_EDIT]: "/aregister/id=:id/edit",
  [AppRoutes.AREGISTER_ONE_STAFF]: "/aregister/id=:id/staff",
  [AppRoutes.AREGISTER_ONE_PRESAVES]: "/aregister/id=:id/presaves",
  [AppRoutes.AREGISTER_ONE_MASSCOPY]: "/aregister/id=:id/masscopy",

  [AppRoutes.SALARY]: "/salary",
  [AppRoutes.SALARY_ONE]: "/salary/id=:id",
  [AppRoutes.SALARY_ONE_EDIT]: "/salary/id=:id/edit",
  [AppRoutes.SALARY_ONE_COMMENTS]: "/salary/id=:id/comments",
  [AppRoutes.SALARY_ONE_HISTORY]: "/salary/id=:id/history",

  [AppRoutes.PAYMENTLIST]: "/salary/paymentlist",
  [AppRoutes.PAYMENTLIST_ONE]: "/salary/paymentlist/id=:id",
  [AppRoutes.PAYMENTLIST_ONE_HISTORY]: "/salary/paymentlist/id=:id/history",

  [AppRoutes.PREMIUM]: "/salary/premium",
  [AppRoutes.PREMIUM_ONE]: "/salary/premium/id=:id",
  [AppRoutes.PREMIUM_ONE_HISTORY]: "/salary/premium/id=:id/history",

  [AppRoutes.PREMIUM_ADD]: "/salary/add_premium",
  [AppRoutes.PAYMENTLIST_ADD]: "/salary/add_payment",
  [AppRoutes.SALARY_ADD]: "/salary/add_operation",
  [AppRoutes.SALARY_ADD_ID]: "/salary/add_operation/staff_id=:staff_id",

  [AppRoutes.SAFETYWORK]: "/safetywork",
  [AppRoutes.SAFETYWORK_ADD]: "/safetywork/add",
  [AppRoutes.SAFETYWORK_TERMSCONTROL]: "/safetywork/termsControl",

  [AppRoutes.NOT_FOUND]: "*"
};

export const routerConfig: RouteProps[] = [
  { path: RoutePath.main, element: <Main /> },
  { path: RoutePath.main_empty, element: <Main /> },

  { path: RoutePath.friendinvite, element: <FriendInviteAll /> },
  { path: RoutePath.friendinvite_one, element: <FriendInviteOneInvite /> },
  { path: RoutePath.friendinvite_one_edit, element: <FriendInviteOneEdit /> },

  { path: RoutePath.staff, element: <StaffList /> },
  { path: RoutePath.staff_add, element: <StaffAddNew /> },
  { path: RoutePath.staff_add_award, element: <StaffAddAward /> },

  { path: RoutePath.staff_import, element: <StaffImport /> },
  { path: RoutePath.staff_rating, element: <StaffRating /> },
  // { path: RoutePath.staff_vacation, element: <StaffVacation /> },
  // {
  //   path: RoutePath.staff_card_settings,
  //   element: <StaffCardSettings />
  // },
  // {
  //   path: RoutePath.staff_cities,
  //   element: <StaffCities />
  // },
  // {
  //   path: RoutePath.staff_typeaddress,
  //   element: <StaffTypeAddress />
  // },
  // {
  //   path: RoutePath.staff_status_settings,
  //   element: <StaffStatusSettings />
  // }

  { path: RoutePath.staff_one, element: <StaffOneInformation /> },
  { path: RoutePath.staff_one_statuses, element: <StaffOneStatuses /> },
  { path: RoutePath.staff_one_salary, element: <StaffOneSalary /> },
  { path: RoutePath.staff_one_workshift, element: <StaffOneWorkshift /> },
  { path: RoutePath.staff_one_safetywork, element: <StaffOneSafetyWork /> },
  { path: RoutePath.staff_one_conviction, element: <StaffOneConviction /> },
  { path: RoutePath.staff_one_awards, element: <StaffOneAwards /> },
  { path: RoutePath.staff_one_premiumlevel, element: <StaffOnePremiumLevel /> },
  { path: RoutePath.staff_one_history, element: <StaffOneHistory /> },
  { path: RoutePath.staff_one_comments, element: <StaffOneComments /> },
  { path: RoutePath.staff_one_edit_id, element: <StaffOneEdit /> },
  { path: RoutePath.staff_one_edit, element: <StaffOneEdit /> },

  { path: RoutePath.polatiapp, element: <PolatiAppVacantRequest /> },

  { path: RoutePath.building, element: <BuildingList /> },
  { path: RoutePath.building_one, element: <BuildingOneInfotmation /> },
  { path: RoutePath.building_one_staff, element: <BuildingOneStaff /> },
  {
    path: RoutePath.building_one_staffmovements,
    element: <BuildingOneStaffMovements />
  },
  { path: RoutePath.building_one_timesheet, element: <BuildingOneTimesheet /> },

  { path: RoutePath.workshift_one, element: <BuildingOneWorkshift /> },
  {
    path: RoutePath.workshift_one_staff,
    element: <BuildingOneWorkshiftStaff />
  },
  {
    path: RoutePath.workshift_one_history,
    element: <BuildingOneWorkshiftHistory />
  },

  { path: RoutePath.projects, element: <ProjectsList /> },

  { path: RoutePath.project_one, element: <ProjectsOneInfo /> },

  { path: RoutePath.aregister, element: <AregisterAllList /> },
  { path: RoutePath.aregister_report, element: <AregisterAllReport /> },
  { path: RoutePath.aregister_appcreate, element: <AregisterAllAppcreate /> },
  { path: RoutePath.aregister_reference, element: <AregisterAllReference /> },

  { path: RoutePath.aregister_one, element: <AregisterOneInfo /> },
  { path: RoutePath.aregister_one_edit, element: <AregisterOneEdit /> },
  { path: RoutePath.aregister_one_masscopy, element: <AregisterOneMasscopy /> },
  { path: RoutePath.aregister_one_presaves, element: <AregisterOnePresave /> },
  { path: RoutePath.aregister_one_staff, element: <AregisterOneStaff /> },

  { path: RoutePath.salary, element: <SalaryOperations /> },
  { path: RoutePath.salary_one, element: <SalaryOperationsOne /> },
  {
    path: RoutePath.salary_one_comments,
    element: <SalaryOperationsOneComments />
  },
  { path: RoutePath.salary_one_edit, element: <SalaryOperationsOneEdit /> },
  {
    path: RoutePath.salary_one_history,
    element: <SalaryOperationsOneHistory />
  },

  { path: RoutePath.paymentlist, element: <SalaryPaymentlistAll /> },
  { path: RoutePath.paymentlist_one, element: <SalaryPaymentlistOneInfo /> },
  {
    path: RoutePath.paymentlist_one_history,
    element: <SalaryPaymentlistOneHistory />
  },

  { path: RoutePath.premium, element: <SalaryPremiums /> },
  { path: RoutePath.premium_one, element: <SalaryPremiumsOneInfo /> },
  {
    path: RoutePath.premium_one_history,
    element: <SalaryPremiumsOneHistory />
  },

  { path: RoutePath.premium_add, element: <SalaryAddPremium /> },
  { path: RoutePath.payment_add, element: <SalaryAddPayment /> },
  { path: RoutePath.salary_add, element: <SalaryAddOperation /> },
  { path: RoutePath.salary_add_id, element: <SalaryAddOperation /> },

  { path: RoutePath.safetywork, element: <SafetyWorkList /> },
  { path: RoutePath.safetywork_add, element: <SafetyWorkAdd /> },
  {
    path: RoutePath.safetywork_termscontrol,
    element: <SafetyWorkTermsControl />
  },

  { path: RoutePath.not_found, element: <Error error /> }
];
