import styles from "./convictionForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useFormikContext } from "formik";
import { Conviction } from "stores/StaffModule/types/Conviction";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Select from "shared/ui/Inputs/Select";
import Textarea from "shared/ui/Inputs/Textarea";
import { ViewField } from "shared/ui/ViewField";
import { classNames } from "shared/utils/helpers/classNames";
import { Col } from "stores/utils/types/Col";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { format, getYear, parse } from "date-fns";
import { Input } from "shared/ui/Inputs/Input";
import { Fragment } from "react";

type ConvictionFormProps = {
  nameCalendarOpened: string;
  changeOpenedName: (name: string) => void;
  type: string;
  fields: string[];
  cols: {
    [key: string]: Col;
  };
  requiredFields: string[];
  values?: Record<string, string | number>;
  activeRow?: Partial<Conviction>;
  index?: number;
};
const ConvictionForm = ({
  nameCalendarOpened,
  changeOpenedName,
  index,
  type,
  fields,
  cols,
  requiredFields,
  values,
  activeRow
}: ConvictionFormProps) => {
  const { userStore } = useStores();
  const { handleBlur, handleChange } = useFormikContext();

  return (
    <div className={styles.form}>
      {fields.map((field) => {
        const colInfo = cols[field];
        const colTitle = colInfo?.title;
        const fieldName =
          type === "newStaffAdd" ? `conviction.${index}.${field}` : field;

        switch (field) {
          case "uid":
          case "id":
            return;

          case "author":
            return (
              <div
                key={`${field}_${index}`}
                className={classNames("", {
                  [styles.disabledField]: type === "detail"
                })}
              >
                <ViewField
                  title={colTitle}
                  value={userStore.user_id}
                  disabled={type !== "detail"}
                />
              </div>
            );

          case "time_create":
            return (
              <div
                key={`${field}_${index}`}
                className={classNames("", {
                  [styles.disabledField]: type === "detail"
                })}
              >
                <ViewField
                  title={colTitle}
                  value={
                    values?.[field]
                      ? getFormattedDate(String(values[field]))
                      : format(new Date(), "dd.MM.yyyy HH:mm:ss")
                  }
                  disabled={type !== "detail"}
                />
              </div>
            );

          case "article":
          case "punishment":
          case "term_type":
            return (
              <Fragment key={`${field}_${index}`}>
                <div
                  className={classNames("", {
                    [styles.disabledField]: type === "detail"
                  })}
                >
                  {type === "detail" ? (
                    <ViewField
                      title={colTitle}
                      value={
                        field === "article"
                          ? cols[field]["directory"][values[field]]?.[
                              "title"
                            ] ||
                            cols[field]["directory"][`${values[field]}`]?.[
                              "title"
                            ] ||
                            `ст.${activeRow[field]?.["number"]} ${
                              activeRow[field]?.["part"]
                                ? `ч.${activeRow[field]?.["part"]}`
                                : ""
                            } ${activeRow[field]?.["title"]}` ||
                            ""
                          : String(values[field])
                      }
                    />
                  ) : (
                    <Select
                      name={fieldName}
                      title={colTitle}
                      isFloating
                      options={cols[field]["directory"]}
                      onBlur={handleBlur}
                      required={requiredFields.includes(field)}
                    />
                  )}
                </div>
                {field === "article" && type === "detail" && (
                  <div className={styles.disabledField}>
                    <ViewField
                      title="Категория"
                      value={
                        cols[field]["directory"][values[field]]?.["category"]
                      }
                    />
                  </div>
                )}
              </Fragment>
            );

          case "date_start":
            return (
              <div
                key={`${field}_${index}`}
                className={classNames("", {
                  [styles.disabledField]: type === "detail"
                })}
              >
                {type === "detail" ? (
                  <ViewField
                    title={colTitle}
                    value={getFormattedDate(String(values[field]))}
                  />
                ) : (
                  <DatePickerField
                    name={fieldName}
                    dateFormat={"yyyy"}
                    title={colTitle}
                    isCalendarOpened={nameCalendarOpened === fieldName}
                    setIsCalendarOpened={() => {
                      changeOpenedName(fieldName);
                    }}
                    minDate={parse(
                      String(getYear(new Date()) - 70 + 14),
                      "yyyy",
                      new Date()
                    )}
                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    required={requiredFields.includes(field)}
                  />
                )}
              </div>
            );

          case "comment":
            return (
              <div
                key={`${field}_${index}`}
                className={classNames("", {
                  [styles.disabledField]: type === "detail"
                })}
              >
                <Textarea
                  name={fieldName}
                  value={
                    (values?.[field]?.["title"]
                      ? values?.[field]["title"]
                      : values?.[field]) || ""
                  }
                  placeholder={colTitle}
                  label={colTitle}
                  disabled={type === "detail"}
                  commentCol={field === "comment" ? cols[field] : ""}
                  required={requiredFields.includes(field)}
                />
              </div>
            );

          default:
            if (cols[field]) {
              return (
                <div
                  key={field}
                  className={classNames("", {
                    [styles.disabledField]: type === "detail"
                  })}
                >
                  <Input
                    disabled={type === "detail" || field === "category"}
                    name={fieldName}
                    label={colTitle}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              );
            } else return;
        }
      })}
    </div>
  );
};
export default observer(ConvictionForm);
