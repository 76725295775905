import styles from "./aregisterOneStaffTableTotal.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";

import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";

import { PresaveStaff } from "stores/AregisterModule/types/PresavesStaff";

type StaffTableTotalProps = {
  company_id?: string;
  company_idx: number;
  staff: PresaveStaff;
};

const StaffTableTotal = ({
  company_id,
  company_idx,
  staff
}: StaffTableTotalProps) => {
  const { aregisterOneStore, menuStore } = useStores();

  const companiesCount = getKeys(staff.main.staff_info.used_company).length;
  const isWorkHoursTotal = (field: string) =>
    field === "work_hours_total" && company_idx === 0 && companiesCount > 1;
  const getValue = (field: keyof PresaveStaff["total"]) => {
    if (field === "work_hours_total" && company_idx !== 0) return;
    else if (
      field === "contribution_by_company" ||
      field === "work_hours_by_company"
    )
      return staff.total[field][company_id];
    else return staff.total[field];
  };

  return (
    <>
      {getKeys(aregisterOneStore.presavesCols?.total).map((field) => {
        if (field === "work_hours_total" && company_idx !== 0) return;

        return (
          <td
            key={field}
            className={classNames("", {
              [styles.rowspan]: isWorkHoursTotal(field),
              // при наличии горизонтального скролла (таблица широкая) и небольшом скролле вправо нужно отобразить тень стобца Итого
              [styles.shadowLeft]:
                aregisterOneStore.hasHorizontalScroll() &&
                menuStore.scroll?.left < 0.9
            })}
            rowSpan={isWorkHoursTotal(field) ? companiesCount : undefined}
          >
            <div>{getValue(field)}</div>
          </td>
        );
      })}
    </>
  );
};

export default observer(StaffTableTotal);
