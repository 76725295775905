import styles from "./staffOneInfoMain.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams, useNavigate } from "react-router";

import LoadedComponent from "widgets/LoadedComponent";
// import { Link } from "react-router-dom";

import Avatar from "shared/assets/images/mainIcons/iconAvatar/AvatarWithoutFill.svg";

import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";

type StaffOneInfoMainProps = {
  scrollTop: boolean;
  maxHeight: string;
};

const StaffOneInfoMain = ({ scrollTop, maxHeight }: StaffOneInfoMainProps) => {
  const { staffOneStore } = useStores();

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className={styles.mainInfoContainer}>
      <div
        className={!scrollTop ? styles.mainInfo : styles.mainInfo_mini}
        style={{
          height: !scrollTop ? maxHeight + "5px" : ""
        }}
      >
        <div>
          <div
            className={`${styles.photo} ${scrollTop ? styles.photo_mini : ""} ${
              !staffOneStore.info["photo"]["src"] ? styles.photo_avatar : ""
            }`}
          >
            <img
              src={
                staffOneStore.info["photo"]["src"]
                  ? `${fileUrl}${
                      staffOneStore.info["photo"]["src"]
                    }?${Math.floor(Math.random() * 100000)}`
                  : Avatar
              }
            />
          </div>
          <div className={styles.mainInfo__info}>
            <div>
              <div className={styles.mainInfo__info__fio}>
                <div className={styles.TNBlock}>
                  <p className={styles.TNBlock__title}>ТН</p>
                  <p className={styles.TNBlock__value}>
                    {staffOneStore.info["uid"]}
                  </p>
                </div>
                <div className={styles.fioBlock}>
                  <p className={styles.fioBlock__fio}>
                    {staffOneStore.info["surname"]} {staffOneStore.info["name"]}{" "}
                    {staffOneStore.info["patronymic"]}
                  </p>

                  {(staffOneStore.info["position"] as string) &&
                  !staffOneStore.isLoadingForWidgetMetrics[id] ? (
                    <p className={styles.fioBlock__position}>
                      {staffOneStore.info["position"] as string}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {!scrollTop ? (
                <div className={styles.widgetBlock}>
                  <LoadedComponent
                    isLoading={staffOneStore.isLoadingForWidgetMetrics[id]}
                    withoutText
                  >
                    <div className={styles.buildingMetrics}>
                      {staffOneStore.widgetMetrics[id]?.building_metrics &&
                      Object.values(
                        staffOneStore.widgetMetrics[id]?.building_metrics
                      ).length
                        ? Object.values(
                            staffOneStore.widgetMetrics[id]?.building_metrics
                          )
                            .sort((a, b) => a.order - b.order)
                            .map((building) => {
                              return (
                                <div
                                  key={`mainInfo_${building.company_title}`}
                                  className={styles.status}
                                >
                                  <div
                                    className={
                                      styles[`status_${building.color}`]
                                    }
                                  >
                                    <div
                                      className={
                                        building.metrics?.current_tour
                                          ? styles.status__leftCol
                                          : ""
                                      }
                                    >
                                      <div className={styles.status__heading}>
                                        <Badge
                                          colorName={
                                            !isEmpty(
                                              staffOneStore.companiesList
                                            ) && building.company_title
                                              ? getValues(
                                                  staffOneStore.companiesList
                                                ).find(
                                                  (company) =>
                                                    company.title ===
                                                    building.company_title
                                                )?.color
                                              : "primary"
                                          }
                                          text={building.company_title}
                                        />
                                        <Badge
                                          icon={building.icon}
                                          iconColor={building.color}
                                          text={building.status}
                                          textColorName="bw-black"
                                          colorName="none"
                                        />
                                      </div>
                                      <a
                                        className={styles.status__building}
                                        href={`${fileUrl}/crm/building/?id=${building.building_id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {building.building_title}
                                      </a>
                                      {/* <Link
                                        className={styles.status__building}
                                        to={`../../building/id=${building.building_id}/timesheet`}
                                        target="_blank"
                                      >
                                        {building.building_title}
                                      </Link> */}
                                    </div>
                                    {building.metrics?.current_tour ? (
                                      <div className={styles.status__rightCol}>
                                        <div
                                          className={
                                            styles.status__rightCol__title
                                          }
                                        >
                                          {building.metrics.current_tour.title}
                                        </div>
                                        <div
                                          className={
                                            styles.status__rightCol__value
                                          }
                                        >
                                          {building.metrics.current_tour.value}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              );
                            })
                        : null}
                    </div>
                  </LoadedComponent>
                </div>
              ) : (
                ""
              )}
            </div>

            {!scrollTop && staffOneStore.widgetMetrics[id]?.classic_metrics ? (
              <div className={styles.widgetBlock__classicMetrics}>
                <LoadedComponent
                  isLoading={staffOneStore.isLoadingForWidgetMetrics[id]}
                  withoutText
                >
                  <>
                    {staffOneStore.widgetMetrics[id]?.classic_metrics &&
                    Object.values(
                      staffOneStore.widgetMetrics[id].classic_metrics
                    ).length ? (
                      <div className={styles.classicMetrics}>
                        {(() => {
                          let miniBlock = false;
                          return Object.entries(
                            staffOneStore.widgetMetrics[id].classic_metrics
                          ).map(([name, oneOfMetric], i) => {
                            if (
                              oneOfMetric.show &&
                              oneOfMetric.metrics &&
                              Object.values(oneOfMetric.metrics).length
                            ) {
                              if (oneOfMetric.colspan === 1) {
                                if (
                                  miniBlock &&
                                  i !== 0 &&
                                  Object.values(
                                    staffOneStore.widgetMetrics[id]
                                      .classic_metrics
                                  )[i - 1].show
                                ) {
                                  miniBlock = false;
                                  return;
                                }
                                !miniBlock ? (miniBlock = true) : "";
                              }
                              return (
                                <div
                                  key={name}
                                  className={
                                    oneOfMetric.colspan === 1
                                      ? "d-flex align-items-center justify-content-between gap-2"
                                      : ""
                                  }
                                >
                                  <div
                                    className={`${
                                      oneOfMetric.colspan === 1
                                        ? styles.block_mini
                                        : styles.block
                                    } ${
                                      [
                                        "ws_count_total",
                                        "balance_total",
                                      ].includes(name)
                                        ? styles.block_link
                                        : ""
                                    } ${
                                      name === "balance_total" &&
                                      oneOfMetric.metrics.balance_total_value
                                        ?.value &&
                                      Math.sign(
                                        oneOfMetric.metrics.balance_total_value
                                          .value as number
                                      ) === -1
                                        ? styles.block_red
                                        : name === "balance_total"
                                        ? styles.block_green
                                        : ""
                                    }`}
                                    onClick={() => {
                                      switch (name) {
                                        case "ws_count_total":
                                          navigate(`./workshift`);
                                          break;
                                        case "balance_total":
                                          navigate(`./salary`);
                                          break;
                                      }
                                    }}
                                  >
                                    {Object.entries(oneOfMetric.metrics).map(
                                      ([metricName, block], blockIdx) => {
                                        return (
                                          <div
                                            key={block.title}
                                            className={`${
                                              blockIdx > 0
                                                ? styles.block__divider
                                                : ""
                                            } ${styles.block__data}`}
                                          >
                                            <div
                                              className={styles.block__title}
                                            >
                                              {block.title}
                                            </div>
                                            <div
                                              className={styles.block__value}
                                            >
                                              {[
                                                "remainder",
                                                "balance_total_value"
                                              ].includes(metricName) &&
                                              typeof block.value === "number"
                                                ? numberWithSpaces(
                                                    block.value,
                                                    true
                                                  )
                                                : block.value ?? 0}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                  {(() => {
                                    if (
                                      oneOfMetric.colspan === 1 &&
                                      Object.values(
                                        staffOneStore.widgetMetrics[id]
                                          .classic_metrics
                                      )[i + 1]?.show &&
                                      miniBlock
                                    ) {
                                      return (
                                        <div className={styles.block_mini}>
                                          {Object.values(
                                            Object.values(
                                              staffOneStore.widgetMetrics[id]
                                                .classic_metrics
                                            )[i + 1].metrics
                                          ).map((block) => {
                                            return (
                                              <div
                                                key={block.title}
                                                className={styles.block__data}
                                              >
                                                <div
                                                  className={
                                                    styles.block__title
                                                  }
                                                >
                                                  {block.title}
                                                </div>
                                                <div
                                                  className={
                                                    styles.block__value
                                                  }
                                                >
                                                  {[
                                                    "remainder",
                                                    "balance_total_value"
                                                  ].includes(
                                                    Object.keys(
                                                      staffOneStore
                                                        .widgetMetrics[id]
                                                        .classic_metrics
                                                    )[i + 1]
                                                  ) &&
                                                  typeof block.value ===
                                                    "number"
                                                    ? numberWithSpaces(
                                                        block.value,
                                                        true
                                                      )
                                                    : block.value ?? 0}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              );
                            }
                          });
                        })()}
                      </div>
                    ) : null}
                  </>
                </LoadedComponent>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(StaffOneInfoMain);
