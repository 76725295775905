import { classNames } from "shared/utils/helpers/classNames";
import styles from "./button.module.scss";
import { ButtonHTMLAttributes, ReactNode } from "react";
import StatusIcon from "shared/ui/StatusIcon";

export enum ButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  CANCELLED = "cancelled",
  FLOATING = "floating",
  CLEAR = "clear",
  ROUND = "round",
  TAB = "tab",
  TAB_WITH_TEXT = "tab_with_text",
  ICON = "icon"
}

export enum ButtonSize {
  S = "size_s",
  M = "size_m",
  L = "size_l"
}

interface ButtonPropsBase extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Стили, заданные в родителе для кнопки
   */
  className?: string;
  /**
   * Вид кнопки, передаем из enum ButtonTheme
   */
  theme?: ButtonTheme;
  /**
   * Размер кнопки, передаем из enum ButtonSize
   */
  size?: ButtonSize;
  selected?: boolean;
  label?: string;
}

// Для кнопки с темой ICON: обязательный icon, children запрещён
interface ButtonPropsIcon extends ButtonPropsBase {
  theme: ButtonTheme.ICON;
  icon: string;
  iconColor?: string;
  children?: never;
}

// Для остальных тем: обязательный children, icon/color не передаются
interface ButtonPropsRegular extends ButtonPropsBase {
  theme?: Exclude<ButtonTheme, ButtonTheme.ICON>;
  children: ReactNode;
  icon?: never;
  iconColor?: never;
}

type ButtonProps = ButtonPropsRegular | ButtonPropsIcon;

export const Button = ({
  className,
  children,
  icon,
  iconColor,
  theme = ButtonTheme.PRIMARY,
  size,
  label,
  selected,
  ...otherProps
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles[theme]]: theme,
          [styles[size]]: size,
          [styles[`${theme}Active`]]: selected,
          [styles.withLabel]: label !== undefined
        },
        [className ? className : ""]
      )}
      type="button"
      {...otherProps}
    >
      {label !== undefined && <p className={styles.label}>{label}</p>}
      {theme === ButtonTheme.ICON ? (
        <StatusIcon icon={icon} color={iconColor || "blue-lazure"} />
      ) : (
        children
      )}
    </button>
  );
};
