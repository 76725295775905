import React from "react";
import { ReactComponent as UploadIconDefault } from "shared/assets/images/menuIcons/iconSelected/ImportEmployee.svg";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styles from "./photoAltGalleryUploadSection.module.scss";
import { Button, ButtonSize } from "shared/ui/Button";

type UploadSectionProps = {
  getRootProps: (
    props?: DropzoneRootProps
  ) => DropzoneRootProps & React.HTMLAttributes<HTMLDivElement>;
  getInputProps: (
    props?: DropzoneInputProps
  ) => DropzoneInputProps & React.InputHTMLAttributes<HTMLInputElement>;
  open: () => void;
  uploadErrors: string[];
};

const PhotoAltGalleryUploadSection = ({
  getRootProps,
  getInputProps,
  open,
  uploadErrors
}: UploadSectionProps) => {
  return (
    <>
      <div className={styles.addPlaceBorder}>
        <div {...getRootProps({ className: styles.dropzone })}>
          <UploadIconDefault className={styles.iconAddFotoDefault} />
          <input {...getInputProps()} />
          <p className={styles.addPlaceText}>
            Чтобы загрузить файл, перетащите изображение сюда или нажмите кнопку
            &quot;Загрузить файл&quot;
          </p>
          <Button
            id="PhotoAltGalleryUploadSection_uploadPhoto"
            type="button"
            onClick={open}
            size={ButtonSize.L}
          >
            Загрузить файл
          </Button>
          <div className={styles.flexColumn}>
            <em>Только файлы с расширениями .jpg, .jpeg, .png</em>
            <em>Объём файла не должен превышать 20МБ</em>
          </div>
        </div>
      </div>
      {uploadErrors.length > 0 && (
        <div className={styles.errorContainer}>
          {uploadErrors.map((error, index) => (
            <p key={index} className={styles.errorMessage}>
              {error}
            </p>
          ))}
        </div>
      )}
    </>
  );
};

export default PhotoAltGalleryUploadSection;
