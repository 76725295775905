import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import Select from "shared/ui/Inputs/Select";
import { useStores } from "stores/index";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

type FriendInviteOneEditStaffProps = {
  name: string;
  title: string;
};

const FriendInviteOneEditStaff = ({
  title,
  name
}: FriendInviteOneEditStaffProps) => {
  const { friendInviteOneStore } = useStores();
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { id } = useParams();

  useEffect(() => {
    if (
      friendInviteOneStore.pageStaff !== 1 &&
      friendInviteOneStore.pageStaff <= friendInviteOneStore.maxPageStaff &&
      friendInviteOneStore.pageStaff !== friendInviteOneStore.prevPageStaff
    ) {
      friendInviteOneStore.getStaffList(title, true);
    }
  }, [friendInviteOneStore.pageStaff, friendInviteOneStore.maxPageStaff]);

  const handleOnClickField = () => friendInviteOneStore.getStaffList(name);
  const getStaffList = () => friendInviteOneStore.getStaffList(title);
  const handleOnClickOption = (option: OptionWithTitle) => {
    setFieldValue(`${name}_full`, option);
    friendInviteOneStore.setChangedStaff(id, option);

    if (option === null) {
      setFieldValue(`${name}_full`, initialValues[`${name}_full`]);
      friendInviteOneStore.setChangedStaff(id, {});
    } else {
      setFieldValue(`${name}_full`, option);
      friendInviteOneStore.setChangedStaff(id, option);
    }
  };
  const handleCloseList = () => friendInviteOneStore.setSearchValueStaff("");
  const handleChangeOption = (option) =>
    option === null && setFieldValue(`${name}_full`, null);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div onClick={handleOnClickField}>
        <Select
          name={name}
          options={friendInviteOneStore.staffList}
          label={
            values[`${name}_full`]?.title
              ? values[`${name}_full`]?.title
              : values[`${name}_full`]?.tn
              ? `ТН${values[`${name}_full`]?.tn} ${
                  values[`${name}_full`]?.name
                }`
              : ""
          }
          title={title}
          required={friendInviteOneStore.tableParams[name]?.required === "on"}
          isFloating
          isSearchable
          isSearchWithPagination
          page={friendInviteOneStore.pageStaff}
          prevPage={friendInviteOneStore.prevPageStaff}
          maxPage={friendInviteOneStore.maxPageStaff}
          setPage={friendInviteOneStore.setPageStaff}
          getList={getStaffList}
          setSearchValue={friendInviteOneStore.setSearchValueStaff}
          searchValue={friendInviteOneStore.searchValueStaff}
          isLoading={friendInviteOneStore.isLoadingForStaffList}
          onClick={handleOnClickOption}
          onCloseList={handleCloseList}
          onChange={handleChangeOption}
          valueName="newname"
        />
      </div>
    </ErrorBoundary>
  );
};

export default observer(FriendInviteOneEditStaff);
