import styles from "./staffRating.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useEffect, useRef, useState } from "react";

import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import SearchInput from "shared/ui/Inputs/SearchInput";
import StaffRatingTable from "./StaffRatingTable/index";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";
import { Formik } from "formik";
import Alert from "shared/ui/Alert";

const StaffRating = () => {
  const { menuStore, modalWindowsStore, staffRatingStore } = useStores();
  const [activeRatingTypeTab, setActiveRatingTypeTab] = useState("");

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    staffRatingStore.getRatingList();
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("staffRating");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/staffRating",
      title: "Рейтинги"
    });
    activeRatingTypeTab !== "itr" && setActiveRatingTypeTab("itr");
  }, []);

  return (
    <>
      <div
        className={`${styles.headWrapper} ${
          !menuStore.isMenuShown ? styles.headWrapper__collapsed : ""
        }`}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className={styles.tabs}>
            <ButtonsTabs
              selectedTab={"itr"}
              tabs={[
                {
                  title: "Рейтинг ИТР",
                  id: "itr"
                }
              ]}
              changeActiveTab={() => {
                return;
              }}
            />
          </div>
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <div className={styles.tabs}>
            <ButtonsTabs
              selectedTab={staffRatingStore.activeRatingYearTab}
              tabs={[...staffRatingStore.ratingYearsTabs]}
              changeActiveTab={staffRatingStore.setActiveRatingYearTab}
            />
          </div>
        </ErrorBoundary>

        {staffRatingStore.activeRatingYearTab ? (
          <>
            <div className={styles.search}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Formik
                  initialValues={{
                    searchStaffRatingValue: staffRatingStore.searchValue || ""
                  }}
                  onSubmit={() => null}
                >
                  {({ values, setFieldValue }) => {
                    const handleOnChange = (e) => {
                      const newValue = e.target.value;
                      setFieldValue("searchStaffRatingValue", newValue);
                      staffRatingStore.setSearchValue(newValue);
                    };

                    const handleFindData = () => {
                      // делаем все вкладки неактивными для общего поиска
                      staffRatingStore.setActiveSwitchedTab("");
                      staffRatingStore.setSearchValue(
                        values.searchStaffRatingValue
                      );
                      staffRatingStore.getRatingStaff(
                        staffRatingStore.activeRatingYearTab,
                        staffRatingStore.activeSwitchedTab
                      );
                      inputRef.current.blur();
                    };

                    const handleClearSearch = () => {
                      setFieldValue("searchStaffRatingValue", "");
                      // Выбираем вкладку "Топ-100" при очистке поиска
                      staffRatingStore.setActiveSwitchedTab("inTop");
                      staffRatingStore.setSearchValue("");
                      staffRatingStore.getRatingStaff(
                        staffRatingStore.activeRatingYearTab,
                        staffRatingStore.activeSwitchedTab
                      );
                    };

                    return (
                      <SearchInput
                        name="searchStaffRatingValue"
                        inputRef={inputRef}
                        onChange={handleOnChange}
                        handleFindData={handleFindData}
                        blurCondition={
                          staffRatingStore.isLoading ||
                          modalWindowsStore.isErrorWindow
                        }
                        clearSearch={handleClearSearch}
                        fixWidth
                      />
                    );
                  }}
                </Formik>
              </ErrorBoundary>
            </div>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              resetKeys={[staffRatingStore.activeSwitchedTab]}
            >
              <div className={styles.tabsBlock}>
                <SwitchedTabs
                  tabs={
                    staffRatingStore.topTabs[
                      staffRatingStore.activeRatingYearTab
                    ] || []
                  }
                  changeActiveTab={(id) => {
                    staffRatingStore.setActiveSwitchedTab(id);
                    // при клике на неактивную вкладку очищается поле поиска и загружается список сотрудников
                    if (staffRatingStore.searchValue.length) {
                      staffRatingStore.setSearchValue("");
                      staffRatingStore.getRatingStaff(
                        staffRatingStore.activeRatingYearTab,
                        staffRatingStore.activeSwitchedTab
                      );
                    }
                  }}
                  selectedTab={staffRatingStore.activeSwitchedTab}
                />
                <div className={styles.linksBlock}>
                  <Button
                    onClick={() =>
                      window.open(
                        "https://drive.google.com/file/d/1zl8l6uuEwTbZpGvBS37wckMUXN2upjUY/view?usp=sharing",
                        "_blank"
                      )
                    }
                    id="StaffRating_video_first"
                    theme={ButtonTheme.CLEAR}
                    className={styles.link}
                  >
                    Что такое Рейтинг ИТР
                  </Button>
                  <Button
                    onClick={() =>
                      window.open(
                        "https://drive.google.com/file/d/1kCPKEYK0Bdumw6-FBdJ5lffgA5gVGDzk/view",
                        "_blank"
                      )
                    }
                    id="StaffRating_video_second"
                    theme={ButtonTheme.CLEAR}
                    className={styles.link}
                  >
                    Как устроен Рейтинг ИТР
                  </Button>
                </div>
              </div>
            </ErrorBoundary>
          </>
        ) : (
          <Alert errors={{ head: "Не выбран год", color: "empty" }} />
        )}
      </div>
      {staffRatingStore.activeRatingYearTab && (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          resetKeys={[
            staffRatingStore.activeSwitchedTab,
            staffRatingStore.activeSwitchedTab
          ]}
        >
          <StaffRatingTable
            selectedTab={staffRatingStore.activeRatingYearTab}
            switchedTab={staffRatingStore.activeSwitchedTab}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default observer(StaffRating);
