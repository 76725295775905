import Select from "shared/ui/Inputs/Select";
import styles from "./staffImportSelect.module.scss";
import { useStores } from "stores";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { useRef, useState } from "react";
import { classNames } from "shared/utils/helpers/classNames";

type StaffImportSelectProps = {
  name: string;
  title: string;
};

const StaffImportSelect = ({ name, title }: StaffImportSelectProps) => {
  const { staffImportStore } = useStores();
  const [isMenuOpened, setIsMenuOpened] = useState("");

  const ref = useRef<HTMLTableDataCellElement>();

  useOnClickOutside({
    ref,
    handler: (event) => {
      // условие ниже не позволяет закрывать окно при клике на выпадающий список
      if (
        event.target?.["dataset"]["list"] === "true" ||
        event.target?.["dataset"]["type"] === "list-search" ||
        (typeof event.target?.["childNodes"]?.[0] === "string" &&
          event.target?.["childNodes"]?.[0]?.includes("itemsScrollBoard")) ||
        event.target?.["parentNode"]?.["dataset"]["list"] === "true"
      )
        return;

      setIsMenuOpened("");
    }
  });

  return (
    <td
      className={classNames(styles.field, {
        [styles.selected]: isMenuOpened === name
      })}
      ref={ref}
    >
      <div onClick={() => setIsMenuOpened(name)}>
        <Select
          name={name}
          options={staffImportStore.selects?.[title] || {}}
          valueName="id"
          onChange={() => setIsMenuOpened("")}
        />
      </div>
    </td>
  );
};

export default StaffImportSelect;
