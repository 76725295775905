import iconLeft from "shared/assets/images/mainIcons/iconLeft.svg";
import styles from "./customHeaderButton.module.scss";
import { ReactNode } from "react";
import { classNames } from "shared/utils/helpers/classNames";

type ClickEvent = React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

type CustomHeaderButtonProps = {
  onClickDecrease: (event: ClickEvent) => void;
  onClickIncrease: (event: ClickEvent) => void;
  children: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
};

const CustomHeaderButton = ({
  onClickDecrease,
  onClickIncrease,
  children,
  disabled,
  fullWidth
}: CustomHeaderButtonProps) => {
  return (
    <div
      className={classNames(styles.wrapper, { [styles.fullWidth]: fullWidth })}
      data-type="datepicker"
    >
      <button
        type="button"
        onClick={onClickDecrease}
        className={styles.btn}
        disabled={disabled}
        data-type="datepicker"
      >
        <img src={iconLeft} data-type="datepicker" />
      </button>
      <p className={styles.date} data-type="datepicker">
        {children}
      </p>
      <button
        type="button"
        onClick={onClickIncrease}
        className={styles.btn}
        data-type="datepicker"
      >
        <img
          src={iconLeft}
          className={styles.imgRight}
          data-type="datepicker"
        />
      </button>
    </div>
  );
};

export default CustomHeaderButton;
