import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStores } from "stores/index";
import { ErrorBoundary } from "react-error-boundary";
import { Formik } from "formik";
import PageTitle from "shared/ui/PageTitle";
import LoadedComponent from "widgets/LoadedComponent";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { staffAddAwardSchema } from "./validation";
import StaffAddAwardFormContent from "./StaffAddAwardFormContent";

const StaffAddAward = () => {
  const { menuStore, staffAddAwardStore } = useStores();
  const [selectedSnapshot, setSelectedSnapshot] = useState<
    Array<{ id: string; title: string }>
  >([]);
  const [lastPayload, setLastPayload] = useState<{
    award_type: string;
    award_date_start: string;
    staff_id: string[];
  } | null>(null);

  useEffect(() => {
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("addAward");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/addAward",
      title: "Добавить награду"
    });
    staffAddAwardStore.fetchAwardAndGroups();
  }, []);

  const handleSubmit = async (values, { setFieldValue }) => {
    const snapshot = [...selectedSnapshot];
    const result = await staffAddAwardStore.addAward(
      values.staff_id,
      values.award_type,
      values.award_date_start,
      snapshot
    );

    if (result) {
      const { errorIds } = result;
      if (errorIds.length !== 0) {
        setLastPayload({
          award_type: values.award_type,
          award_date_start: values.award_date_start,
          staff_id: values.staff_id
        });
        const newStaffIds = values.staff_id.filter((id) =>
          errorIds.includes(id)
        );
        setFieldValue("staff_id", newStaffIds);
      } else {
        setLastPayload(null);
      }
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <PageTitle leftCol title="Выберите награду" isStickyTitle />
      <LoadedComponent
        isLoading={staffAddAwardStore.isLoading}
        error={staffAddAwardStore.error}
      >
        <Formik
          initialValues={{
            award_type: "",
            building: "",
            award_date_start: "",
            staff_id: [] as string[]
          }}
          validationSchema={staffAddAwardSchema}
          validateOnChange
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <StaffAddAwardFormContent
                setSelectedSnapshot={setSelectedSnapshot}
                lastPayload={lastPayload}
              />
            );
          }}
        </Formik>
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(StaffAddAward);
