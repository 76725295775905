import styles from "./friendInviteStaff.module.scss";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef } from "react";
import FriendInviteStaffForm from "./FriendInviteStaffForm";
import { classNames } from "shared/utils/helpers/classNames";
import { InvitesListItem } from "stores/FriendInviteModule/types/InvitesListItem";
import FriendInviteOneStaffList from "./FriendInviteStaffList";

type FriendInviteStaffProps = {
  id: string;
  title: string;
};

const FriendInviteStaff = ({ id, title }: FriendInviteStaffProps) => {
  const { friendInviteStore } = useStores();
  const [invite, setInvite] = useState<Partial<InvitesListItem>>(
    friendInviteStore.updatedInvites[id] ||
      friendInviteStore.invitesList[id] ||
      {}
  );

  const referenseRef = useRef<HTMLTableDataCellElement>();

  const isOpenedList = friendInviteStore.openedList === `${id}_${title}`;
  const isEditableCandidate =
    title === "candidate_tn" &&
    friendInviteStore.tableParams[title]?.access_edit;
  const handleOpenedList = (value: boolean) => {
    friendInviteStore.setOpenedList(value ? `${id}_${title}` : "");
    !value && friendInviteStore.setSearchValueStaff("");
  };

  const handleClickOnCell = () => {
    if (isEditableCandidate) {
      if (
        friendInviteStore.isLoadingCol !== `${title}_${id}` &&
        friendInviteStore?.openedList !== `${id}_${title}`
      ) {
        handleOpenedList(true);
        isEditableCandidate && friendInviteStore.getStaffList(id);
      }
      if (
        event.target["dataset"]["type"] !== "list" &&
        event.target["dataset"]["list"] !== "true" &&
        event.target["dataset"]["type"] !== "list-search"
      ) {
        handleOpenedList(true);
        friendInviteStore.getStaffList(id);
      }
    }
  };

  useEffect(
    () =>
      setInvite(
        friendInviteStore.updatedInvites[id] ||
          friendInviteStore.invitesList[id] ||
          {}
      ),
    [friendInviteStore.updatedInvites[id] || friendInviteStore.invitesList[id]]
  );

  return invite?.[title] !== null || isEditableCandidate ? (
    <td
      className={classNames(styles.td, {
        [styles.tdActive]: isOpenedList
      })}
      data-type="list"
      onClick={handleClickOnCell}
      ref={referenseRef}
    >
      <FriendInviteStaffForm id={id} title={title} />
      {isOpenedList && (
        <FriendInviteOneStaffList
          id={id}
          title={title}
          handleOpenedList={handleOpenedList}
          isOpenedList={isOpenedList}
          referenseRef={referenseRef}
        />
      )}
    </td>
  ) : (
    <td className={styles.td}></td>
  );
};

export default observer(FriendInviteStaff);
