import styles from "./safetyWorkTermsControl.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import TermsControlTableWithFilters from "./TermsControlTableWithFilters";
import { ReactComponent as IconSearch } from "shared/assets/images/mainIcons/iconSearch.svg";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import Select from "shared/ui/Inputs/Select";
import TermsControlKindsTypesForm from "./TermsControlKindsTypesForm";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";
import LoadedComponent from "widgets/LoadedComponent";
import { useSearchParams } from "react-router-dom";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { isEmpty } from "lodash";
import Alert from "shared/ui/Alert";

const SafetyWorkTermsControl = () => {
  const { safetyWorkTermsControlStore, menuStore } = useStores();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | string[] } = {};

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      safetyWorkTermsControlStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      getEntries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "searchValue":
            safetyWorkTermsControlStore.setSearchValue(value);
            break;

          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      safetyWorkTermsControlStore.setFilterParamsFromQuery(queryParams);
    }
    safetyWorkTermsControlStore.getData();

    menuStore.setOpenedModule("safetywork");
    menuStore.setOpenedSubmodule("termsControl");
    menuStore.updateWindow({
      mainPath: "/safetywork",
      path: "/safetywork/termsControl",
      title: "Контроль сроков"
    });
  }, []);

  // обновляет параметры query запроса в поисковой строке при изменении переменных из массива зависимостей
  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    getEntries(safetyWorkTermsControlStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );
    if (!isEmpty(safetyWorkTermsControlStore.filterParams)) {
      if (safetyWorkTermsControlStore.searchValue) {
        filterParams["searchValue"] = safetyWorkTermsControlStore.searchValue;
      }
    }
    safetyWorkTermsControlStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [safetyWorkTermsControlStore.filterParams]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (getKeys(safetyWorkTermsControlStore.queryParams).length) {
      setSearchParams(safetyWorkTermsControlStore.queryParams);
    }
  }, [window.location.href]);

  const filterList = () => {
    safetyWorkTermsControlStore.setOrder("name");
    safetyWorkTermsControlStore.setOrdered(
      safetyWorkTermsControlStore.ordered === "DESC" ? "ASC" : "DESC"
    );

    //обновляет параметры query запроса в поисковой строке при применении сортировки
    safetyWorkTermsControlStore.setQueryParams({
      ...safetyWorkTermsControlStore.queryParams,
      order: safetyWorkTermsControlStore.order,
      ordered: safetyWorkTermsControlStore.ordered
    });
    setSearchParams({
      ...safetyWorkTermsControlStore.queryParams,
      order: safetyWorkTermsControlStore.order,
      ordered: safetyWorkTermsControlStore.ordered
    });
    safetyWorkTermsControlStore.getCertsTermsControl();
  };

  useEffect(() => {
    if (
      safetyWorkTermsControlStore.pageStaff !== 1 &&
      safetyWorkTermsControlStore.pageStaff <=
        safetyWorkTermsControlStore.maxPageStaff &&
      safetyWorkTermsControlStore.pageStaff !==
        safetyWorkTermsControlStore.prevPageStaff
    ) {
      safetyWorkTermsControlStore.getMoreStaff();
    }
  }, [
    safetyWorkTermsControlStore.pageStaff,
    safetyWorkTermsControlStore.maxPageStaff,
    safetyWorkTermsControlStore.prevPageStaff
  ]);

  useEffect(() => {
    if (
      safetyWorkTermsControlStore.pageBuilding !== 1 &&
      safetyWorkTermsControlStore.pageBuilding <=
        safetyWorkTermsControlStore.maxPageBuilding &&
      safetyWorkTermsControlStore.pageBuilding !==
        safetyWorkTermsControlStore.prevPageBuilding
    ) {
      safetyWorkTermsControlStore.getMoreBuildingsList();
    }
  }, [
    safetyWorkTermsControlStore.pageBuilding,
    safetyWorkTermsControlStore.maxPageBuilding,
    safetyWorkTermsControlStore.prevPageBuilding
  ]);

  return (
    <LoadedComponent
      isLoading={safetyWorkTermsControlStore.isLoading}
      error={safetyWorkTermsControlStore.error}
    >
      <Formik
        initialValues={safetyWorkTermsControlStore.initialValues}
        onSubmit={() => null}
        validateOnBlur
        enableReinitialize
      >
        {({ values }) => {
          const handleClick = () => {
            safetyWorkTermsControlStore.generalFilters.new.filter = values;
            safetyWorkTermsControlStore.getSafetyWorkListWithFilter(
              safetyWorkTermsControlStore.generalFilters.new
            );
          };
          const handleMenuOpen = () => {
            safetyWorkTermsControlStore.resetStaffListState();
            safetyWorkTermsControlStore.getStaffList();
          };
          return safetyWorkTermsControlStore.isTableOpened ? (
            <TermsControlTableWithFilters filterList={filterList} />
          ) : (
            <>
              <Form className={styles.form}>
                <div className={styles.fields}>
                  {safetyWorkTermsControlStore.currentTitles.map((title) => {
                    switch (title) {
                      case "building":
                        return (
                          <Select
                            key={title}
                            name={title}
                            options={
                              safetyWorkTermsControlStore
                                .termsControlColsParam?.[title]?.directory
                            }
                            title={
                              safetyWorkTermsControlStore.termsControlColsParam[
                                title
                              ]?.title
                            }
                            isFloating
                            withClearBtn
                            isSearchable
                            isSearchWithPagination
                            page={safetyWorkTermsControlStore.pageBuilding}
                            prevPage={
                              safetyWorkTermsControlStore.prevPageBuilding
                            }
                            maxPage={
                              safetyWorkTermsControlStore.maxPageBuilding
                            }
                            setPage={
                              safetyWorkTermsControlStore.setPageBuilding
                            }
                            getList={
                              safetyWorkTermsControlStore.getBuildingsList
                            }
                            setSearchValue={
                              safetyWorkTermsControlStore.setSearchValueBuilding
                            }
                            searchValue={
                              safetyWorkTermsControlStore.searchValueBuilding
                            }
                            isLoading={
                              safetyWorkTermsControlStore.isLoadingForBuildingList
                            }
                            required={safetyWorkTermsControlStore.requiredFields.includes(
                              title
                            )}
                            valueName="newname"
                            onCloseList={() => {
                              safetyWorkTermsControlStore.setSearchValueBuilding(
                                ""
                              );
                              safetyWorkTermsControlStore.getBuildingsList();
                            }}
                          />
                        );
                      case "uid":
                        return (
                          <SelectMulti
                            title={
                              safetyWorkTermsControlStore.termsControlColsParam[
                                title
                              ]?.title
                            }
                            key={title}
                            name={title}
                            options={
                              safetyWorkTermsControlStore
                                .termsControlColsParam?.[title]?.directory
                            }
                            label={
                              safetyWorkTermsControlStore.termsControlColsParam[
                                title
                              ]?.title
                            }
                            isSearchWithPagination
                            page={safetyWorkTermsControlStore.pageStaff}
                            prevPage={safetyWorkTermsControlStore.prevPageStaff}
                            maxPage={safetyWorkTermsControlStore.maxPageStaff}
                            setPage={safetyWorkTermsControlStore.setPageStaff}
                            getList={safetyWorkTermsControlStore.getStaffList}
                            setSearchValue={
                              safetyWorkTermsControlStore.setSearchValueStaff
                            }
                            searchValue={
                              safetyWorkTermsControlStore.searchValueStaff
                            }
                            isLoading={
                              safetyWorkTermsControlStore.isLoadingForStaffList
                            }
                            required={safetyWorkTermsControlStore.requiredFields.includes(
                              title
                            )}
                            onMenuOpen={handleMenuOpen}
                            valueName="newname"
                            onClick={() => {
                              safetyWorkTermsControlStore.setSearchValueStaff(
                                ""
                              );
                              safetyWorkTermsControlStore.getStaffList();
                            }}
                            dictForArray={
                              safetyWorkTermsControlStore.dictForFilter
                            }
                            setDictForArray={
                              safetyWorkTermsControlStore.setDictForFilter
                            }
                          />
                        );
                      case "kind":
                        return (
                          <TermsControlKindsTypesForm
                            values={values}
                            key={title}
                          />
                        );
                      default: {
                        return;
                      }
                    }
                  })}

                  <Button
                    theme={ButtonTheme.SECONDARY}
                    id="SafetyWorkTermsControl_showBtn"
                    onClick={handleClick}
                    disabled={!values.uid?.length || !values.kind.length}
                  >
                    <IconSearch
                      className={classNames(styles.iconSearch, {
                        [styles.iconSearchActive]:
                          values.uid?.length && values.kind.length
                      })}
                    />
                    <span
                      className={classNames(styles.showBtnText, {
                        [styles.showBtnTextActive]:
                          values.uid?.length && values.kind.length
                      })}
                    >
                      Показать
                    </span>
                  </Button>
                </div>
              </Form>
              {isEmpty(safetyWorkTermsControlStore.errorsMessage) ? (
                <div className={styles.text}>
                  Для просмотра удостоверений выберите Объект, Сотрудников, Вид
                  и Тип
                </div>
              ) : (
                <Alert
                  errors={{
                    head: safetyWorkTermsControlStore.errorsMessage.head,
                    color: safetyWorkTermsControlStore.errorsMessage.color
                  }}
                  className={{ container: styles.alert }}
                />
              )}
            </>
          );
        }}
      </Formik>
    </LoadedComponent>
  );
};

export default observer(SafetyWorkTermsControl);
