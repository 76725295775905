import styles from "./buildingOneWorkshiftStafftableThead.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { Fragment } from "react";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";

const BuildingOneWorkshiftStaffTableThead = () => {
  const { menuStore, buildingOneWorkshiftStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);

  const { id } = useParams();
  const headerRef = useRef<HTMLTableSectionElement>();

  useEffect(() => {
    if (
      headerRef["current"]?.getBoundingClientRect()?.top -
        (buildingOneWorkshiftStore.blocksHeight[id].header + 40) <=
        50 &&
      !isScroll
    ) {
      setTimeout(() => {
        setIsScroll(true);
      }, 100);
    } else if (
      headerRef["current"]?.getBoundingClientRect()?.top -
        (buildingOneWorkshiftStore.blocksHeight[id].header + 40) >
        50 &&
      isScroll
    ) {
      setTimeout(() => {
        setIsScroll(false);
      }, 100);
    }
  }, [menuStore.scroll?.scrollTop]);

  const handleClickToSort = (col_name: string) => () => {
    buildingOneWorkshiftStore.setStaffListOrder(col_name);
  };

  return (
    <thead
      className={classNames(styles.thead, {
        [styles.thead_sticky]: isScroll,
        [styles.thead_normal]: !isScroll
      })}
      style={{
        top: buildingOneWorkshiftStore.blocksHeight[id]?.header
          ? buildingOneWorkshiftStore.blocksHeight[id]?.header + 33
          : 100
      }}
      ref={headerRef}
    >
      <tr>
        {getEntries(buildingOneWorkshiftStore.workshiftTableCols).map(
          ([name, col]) => {
            if (buildingOneWorkshiftStore.isShowCol(name)) {
              if (name === "uid") {
                return (
                  <Fragment key={name}>
                    <th
                      id={`BuildingOneWorkshiftStaffTableThead_tn_sort`}
                      onClick={handleClickToSort("tn")}
                    >
                      <span>
                        ТН
                        <StatusIcon
                          icon="iconarrowdouble"
                          color={
                            buildingOneWorkshiftStore.staffListOrder[
                              id
                            ]?.[0] === "tn"
                              ? "bw-gray6"
                              : "bw-gray3"
                          }
                        />
                      </span>
                    </th>
                    <th
                      id={`BuildingOneWorkshiftStaffTableThead_fio_sort`}
                      onClick={handleClickToSort("fio")}
                    >
                      <span>
                        ФИО
                        <StatusIcon
                          icon="iconarrowdouble"
                          color={
                            buildingOneWorkshiftStore.staffListOrder[
                              id
                            ]?.[0] === "fio"
                              ? "bw-gray6"
                              : "bw-gray3"
                          }
                        />
                      </span>
                    </th>
                  </Fragment>
                );
              } else
                return (
                  <th
                    key={name}
                    id={`BuildingOneWorkshiftStaffTableThead_${name}_sort`}
                    onClick={handleClickToSort(name)}
                  >
                    <span>
                      {col.title}
                      <StatusIcon
                        icon="iconarrowdouble"
                        color={
                          buildingOneWorkshiftStore.staffListOrder[id]?.[0] ===
                          name
                            ? "bw-gray6"
                            : "bw-gray3"
                        }
                      />
                    </span>
                  </th>
                );
            }
          }
        )}
      </tr>
    </thead>
  );
};

export default observer(BuildingOneWorkshiftStaffTableThead);
