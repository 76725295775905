import RootStore from "../index";
import { makeAutoObservable, runInAction } from "mobx";
import { Errors } from "stores/utils/types/ErrorsType";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { Select } from "stores/utils/types/Select";
import { getEntries } from "shared/utils/helpers/getEntries";
import { StaffList } from "./types/StaffList";
import { isEmpty } from "lodash";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { getValues } from "shared/utils/helpers/getValues";

type GroupItem = {
  id: string;
  title: string;
};

type AwardItem = {
  id: string;
  title: string;
  custom?: {
    group?: string;
  };
  img?: string;
};

type StaffListItem = {
  id: string;
  uid: number;
  surname: string;
  name: string;
  patronymic?: string;
};

export default class StaffAddAward {
  isLoading = false;
  isLoadingForStaffList = false;
  isLoadingForModal = false;
  success = false;
  error = false;
  errorsMessage: Partial<Errors["message"]> = {};
  allAwards: Record<string, OptionWithTitle> = {};
  awardGroups: { [key: string]: Partial<Select> } = {};
  employeesList: OptionWithTitle[] = [];
  awardResult: { successIds: string[]; errorIds: string[] } | null = null;

  searchValue = "";
  onPage = 30;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  clearErrorsMessage = () => {
    this.errorsMessage = {};
  };

  resetStaffListState = () => {
    this.searchValue = "";
    this.onPage = 30;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.employeesList = [];
  };

  setSuccess = (value: boolean) => {
    this.success = value;
  };

  dataPreparation(data: StaffListItem[]) {
    return data.map((oneOfStaff) => {
      return {
        title: `TH${oneOfStaff.uid} ${oneOfStaff.surname || ""} ${
          oneOfStaff.name
        } ${oneOfStaff.patronymic || ""}`.trim(),
        id: oneOfStaff.id
      };
    });
  }

  rootStore: RootStore;

  fetchAllAwardTypes = async () => {
    this.isLoading = true;
    this.error = false;
    this.clearErrorsMessage();

    try {
      const data = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        currentClass: "staff_award_types",
        method: "getList",
        params: {
          on_page: 10000
        }
      });

      runInAction(() => {
        if (data.code === 200 && data.result) {
          this.allAwards = Object.fromEntries(
            getEntries(data.result as Record<string, AwardItem>).map(
              ([key, awardItem]) => [
                key,
                {
                  id: awardItem.id,
                  title: awardItem.title,
                  group: awardItem.custom?.group,
                  img: awardItem.img
                }
              ]
            )
          );
        } else {
          this.error = true;
          this.errorsMessage = data.message || {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  fetchAllAwardGroups = async () => {
    this.isLoading = true;
    this.clearErrorsMessage();

    try {
      const data = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        currentClass: "staff_award_group",
        method: "getList",
        params: {
          on_page: 100
        }
      });

      runInAction(() => {
        if (data.code === 200 && data.result) {
          this.awardGroups = Object.fromEntries(
            getEntries(data.result as Record<string, GroupItem>).map(
              ([key, groupItem]) => [
                key,
                { id: groupItem.id, title: groupItem.title }
              ]
            )
          );
        } else {
          runInAction(() => {
            this.error = true;
            this.errorsMessage = data.message || {};
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;
    this.employeesList = [];

    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          fast_search: this.searchValue
        }
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          this.employeesList = this.dataPreparation(getValues(data.records));
        } else {
          this.employeesList = [];
        }

        this.maxPage = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          fast_search: this.searchValue
        }
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          this.employeesList = [
            ...this.employeesList,
            ...this.dataPreparation(getValues(data.records))
          ];
        } else {
          this.employeesList = [];
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.prevPage = 1;
    this.searchValue = value;
  };

  setPage = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.page = value;
    }
  };

  fetchAwardAndGroups = async () => {
    await Promise.all([
      this.fetchAllAwardTypes(),
      this.fetchAllAwardGroups(),
      this.getStaffList()
    ]);
  };

  addAward = async (
    staff_id: string[],
    award_type: string,
    award_date_start: string,
    snapshot: Array<{ id: string; title: string }>
  ) => {
    this.errorsMessage = {};
    this.isLoadingForModal = true;
    this.success = false;
    this.awardResult = null;

    const staffIdsObj = staff_id.reduce((acc, current, index) => {
      acc[`staff_id[${index}]`] = current;
      return acc;
    }, {} as Record<string, string>);

    const body = {
      ...staffIdsObj,
      award_type,
      award_date_start
    };

    try {
      const data: ApiResponse<{ message?: Omit<Errors["message"], "body"> }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          currentClass: "staff_award",
          method: "addAward",
          body: body
        });

      const successIds: string[] = [];
      const errorIds: string[] = [];

      runInAction(() => {
        if (data.code === 200) {
          const resultObj = (data.result || {}) as Record<string, boolean>;

          getEntries(resultObj).forEach(([id, status]) => {
            if (status === true) {
              successIds.push(id);
            } else {
              errorIds.push(id);
            }
          });

          if (
            errorIds.length > 0 &&
            data.message &&
            data.message.body &&
            !isEmpty(data.message.body)
          ) {
            const errorTitlesObj = errorIds.reduce((acc, staffId) => {
              const staff = snapshot.find((item) => item.id === staffId);
              acc[staffId] = staff ? staff.title : staffId;
              return acc;
            }, {} as Record<string, string>);

            this.errorsMessage = {
              ...data.message,
              body: {
                ...data.message.body,
                "0": {
                  ...data.message.body["0"],
                  list: {
                    ...data.message.body["0"].list,
                    type: data.message?.body?.["0"]?.list?.type ?? "text",
                    body: errorTitlesObj
                  }
                }
              }
            };
          } else {
            this.errorsMessage = data.message || {};
          }
          this.success = successIds.length > 0;
          this.awardResult = { successIds, errorIds };
        } else {
          this.errorsMessage = data.message || {};
          this.success = false;
        }
      });
      return { errorIds };
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForModal = false;
      });
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
