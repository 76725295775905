import { isEmpty } from "lodash";
import { stringSchema, dateSchema } from "shared/utils/validation/validation";

import {
  phoneSchema,
  pastDate,
  validationStaffForm,
  birthdaySchema
} from "shared/utils/validation/validationStaffForm";
import { array, object, string } from "yup";

const validationAddNewStaffSchema = {
  ...validationStaffForm,
  company: array().test("isEmpty", "Обязательное поле", (val) => {
    return !isEmpty(val);
  }),
  surname: stringSchema,
  name: stringSchema,
  patronymic: stringSchema,
  phone: phoneSchema,
  pasp_place: stringSchema,
  birth_place: stringSchema,
  propisk: stringSchema,
  living_adress: stringSchema,
  pasp_date: pastDate("pasp_date"),
  birthday: birthdaySchema(),
  training_date_start: dateSchema.test(
    "training_date_start",
    "Дата начала не может быть позже даты окончания",
    function (val) {
      if (this.parent.training_date_end && val) {
        return Boolean(this.parent.training_date_end >= val);
      } else return true;
    }
  ),
  training_date_finish: dateSchema.test(
    "training_date_finish",
    "Дата окончания не может быть ранее даты начала",
    function (val) {
      if (this.parent.training_date_start && val) {
        return Boolean(this.parent.training_date_start <= val);
      } else return true;
    }
  )
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {
    companyAll: array().of(stringSchema)
  };
  const tempValidationSchema = {};
  cols.map((col) => {
    tempValidationSchema[col] =
      col in validationAddNewStaffSchema
        ? validationAddNewStaffSchema[col]
        : string().nullable();

    if (requiredCols.includes(col)) {
      tempValidationSchema[col] =
        col in validationAddNewStaffSchema
          ? validationAddNewStaffSchema[col]?.required("Обязательное поле")
          : string()?.nullable()?.required("Обязательное поле");
    }

    if (col.includes("phone")) {
      tempValidationSchema[col] = validationAddNewStaffSchema["phone"];
    }
  });
  validationSchema["staff"] = array().of(object(tempValidationSchema));

  return object(validationSchema);
};
