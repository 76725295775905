export const specialChars = [
  "!",
  "@",
  "#",
  "$",
  "%",
  "&",
  "*",
  "(",
  ")",
  "_",
  "+",
  "=",
  "-",
  "{",
  "}",
  "[",
  "]",
  ":",
  ";",
  '"',
  "'",
  "<",
  ">",
  ",",
  ".",
  "/",
  "?",
  "~",
  "^",
  "|",
  "\\",
  "`"
];
