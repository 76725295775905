import { string, array, object, number } from "yup";
import { getYear, intervalToDuration } from "date-fns";

import {
  stringSchema,
  dateSchema,
  numberRegEx
} from "shared/utils/validation/validation";

export const calculateFullAge = (dob: number | Date) => {
  const { years } = intervalToDuration({ start: dob, end: new Date() });
  return { years };
};

const nowStart = getYear(new Date()) - 70;
const nowEnd = getYear(new Date()) - 16;
export const phoneSchema = string().nullable();

export const numberSchema = stringSchema.matches(numberRegEx, "Только число");

export const pastDate = (name: string) => {
  return dateSchema.test(name, `Дата не может быть больше текущей`, (val) => {
    if (val) {
      return !(new Date() < val);
    } else return true;
  });
};

export const birthdaySchema = (isRequired?: boolean) => {
  const scheme = dateSchema.test(
    "birthday",
    `Дата рождения не входит в диапазон от ${nowStart} до ${nowEnd}`,
    (val) => {
      if (val) {
        const years = calculateFullAge(Date.parse(String(val)))["years"];
        return !(years < 16 || years > 69);
      } else return true;
    }
  );
  return isRequired
    ? scheme.required("Поле обязательно для заполнения")
    : scheme;
};

export const snilsSchema = stringSchema
  .test("snils", "СНИЛС может состоять только из 11 цифр", (value) => {
    if (value) {
      const clearLength = value.replace(/[^0-9]+/g, "").length;
      return clearLength === 11;
    } else return !value;
  })
  .test("snils", "Неверный СНИЛС", (value) => {
    if (value) {
      const snils = value.replace(/[^0-9]+/g, "");
      const checkSnilsSum = (sum: number) => {
        sum === 100 ? (sum = 0) : sum < 100 ? sum : "";
        return sum === +snils.slice(-2);
      };
      let snilsSum = 0;
      for (let i = 0; i < 9; i++) {
        snilsSum += +snils[i] * (9 - i);
      }
      return snilsSum >= 101
        ? checkSnilsSum(snilsSum % 101)
        : checkSnilsSum(snilsSum);
    } else return !value;
  });

export const innSchema = stringSchema
  .test("inn", "ИНН может состоять только из 10 или 12 цифр", (value) => {
    if (value) {
      const clearLength = value.replace(/[^0-9]+/g, "").length;
      return clearLength === 10 || clearLength === 12;
    } else return !value;
  })
  .test("inn", "Неверный ИНН", (value) => {
    const checkDigit = (inn: string, coef: number[]) => {
      let num = 0;
      for (const i in coef) {
        num += coef[i] * Number(inn[i]);
      }
      return +((num % 11) % 10);
    };

    if (value && value.length === 10) {
      const inn = value.replace(/[^0-9]+/g, "");
      const num_10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
      return num_10 === +inn[9];
    } else if (value && value.length === 12) {
      const inn = value.replace(/[^0-9]+/g, "");
      const num_11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
      const num_12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
      return num_11 === +inn[10] && num_12 === +inn[11];
    } else return !value;
  });

export const pasp_codeSchema = stringSchema.test("len", "6 цифр", (val) => {
  if (val) {
    const clearLength = val.replace(/-|_/g, "").length;
    return clearLength === 6;
  } else return !val;
});

export const validationStaffForm = {
  id: stringSchema,
  uid: stringSchema,
  company: array().of(stringSchema),
  time_create: stringSchema,
  surname: stringSchema,
  name: stringSchema,
  patronymic: stringSchema,
  birthday: birthdaySchema(),
  phone_old: stringSchema,
  phone: array()
    .of(
      object({
        number: number().typeError("Только число"),
        comment: string()
          .typeError("Должно быть строкой")
          .max(50, "Не более 50 символов")
      })
    )
    .nullable(),
  sud: stringSchema,
  grazd: stringSchema,
  vakans: stringSchema,
  ist_inf: stringSchema,
  city: stringSchema,
  region: stringSchema,
  reason_off: stringSchema,
  type: stringSchema,
  status: stringSchema,
  email: string().email("Введите верный email").nullable(),
  size: stringSchema,
  height: numberSchema,
  shoe_size: numberSchema,
  education: stringSchema,
  pasp_n: stringSchema,
  pasp_date: dateSchema,
  pasp_place: stringSchema,
  pasp_code: pasp_codeSchema,
  birth_place: stringSchema,
  propisk: stringSchema,
  dismiss_date: dateSchema,
  dismiss_type: stringSchema,
  dismiss_reason: stringSchema,
  dismiss_comment: string().min(15, "Минимум 15 символов"),
  dismiss_position: stringSchema,
  dismiss_object: object({
    "": stringSchema
  }).nullable(),
  black_list: numberSchema,
  grey_list: numberSchema,
  black_reason: stringSchema,
  grey_reason: stringSchema,
  soisk_status: stringSchema,
  manager_mass: stringSchema,
  comment: stringSchema,
  medkom: stringSchema,
  ino: numberSchema,
  of: numberSchema,
  over_price: stringSchema,
  position: stringSchema,
  date_of_employment_of: dateSchema,
  date_of_employment_unof: dateSchema,
  place_of_study: stringSchema,
  training_date_start: dateSchema
    .default(null)
    .test(
      "training_date_start",
      "Дата начала не может быть позднее даты окончания",
      function (val) {
        if (val && this.parent.training_date_finish) {
          return this.parent.training_date_finish >= val ? true : false;
        } else return true;
      }
    ),
  training_date_finish: dateSchema
    .default(null)
    .test(
      "training_date_finish",
      "Дата окончания не может быть ранее даты начала",
      function (val) {
        if (val && this.parent.training_date_start) {
          return this.parent.training_date_start <= val ? true : false;
        } else return true;
      }
    ),
  skills_and_achievements: stringSchema,
  salary: stringSchema,
  over_price_hh: stringSchema,
  premium_price: stringSchema,
  money_2002: stringSchema,
  object_of_employment: stringSchema,
  trud_dog: stringSchema,
  old_count_work: stringSchema,
  password: stringSchema,
  leadership: numberSchema,
  modified: numberSchema,
  date_chg: stringSchema,
  snils: snilsSchema,
  hasSnils: number().nullable(),
  inn: innSchema,
  email_confirm: numberSchema,
  guid_1c: stringSchema,
  propisk_1c: stringSchema,
  birth_place_1c: stringSchema,
  region_1c: stringSchema,
  living_adress: stringSchema,
  document_type: stringSchema,
  date_photo: dateSchema,
  trud_dog_date: dateSchema,
  employment_of_registrar: stringSchema,
  employment_of_order_number: stringSchema,
  employment_of_object: stringSchema,
  employment_of_position: stringSchema,
  current_data: stringSchema,
  photo: object({
    src: string(),
    hash: string()
  }),
  photo_alt: object(),
  labor_book_number: stringSchema,
  labor_book_track_number: stringSchema,
  duty_allowance: numberSchema,
  locked: numberSchema,
  // ниже поля для формы добавления статусов с удержаниями УЦ
  event_start: dateSchema.test(
    "event_start",
    "Дата начала не может быть позднее даты окончания",
    function (val) {
      if (val && this.parent.event_end) {
        return this.parent.event_end >= val;
      } else return true;
    }
  ),
  event_end: dateSchema
    .test(
      "event_end",
      "Нельзя установить дату окончания без даты начала",
      function (val) {
        if (val) {
          return this.parent.event_start;
        } else return !val;
      }
    )
    .test(
      "event_end",
      "Дата окончания не может быть ранее даты начала",
      function (val) {
        if (val) {
          return this.parent.event_start && this.parent.event_start <= val;
        } else return !val;
      }
    ),
  sub_start_date: dateSchema
    .test(
      "sub_start_date",
      "Нельзя установить дату ежегодного отпуска без даты межвахтогово отпуска",
      function (val) {
        if (val) {
          return this.parent.event_start || this.parent.event_end;
        } else return !val;
      }
    )
    .test(
      "sub_start_date",
      "Нельзя установить дату начала без даты окончания",
      function (val) {
        if (val) {
          return this.parent.sub_end_date;
        } else return !val;
      }
    )
    .test(
      "sub_start_date",
      "Дата ежегодного отпуска должна быть в пределах дат межвахтового отпуска",
      function (val) {
        if (val) {
          return (
            this.parent.event_start <= val &&
            (this.parent.event_end !== null
              ? this.parent.event_end >= val
              : true)
          );
        } else return !val;
      }
    ),
  sub_end_date: dateSchema
    .test(
      "sub_end_date",
      "Нельзя установить дату окончания без даты начала",
      function (val) {
        if (val) {
          return this.parent.sub_start_date;
        } else return !val;
      }
    )
    .test(
      "sub_end_date",
      "Нельзя установить дату ежегодного отпуска без даты межвахтогово отпуска",
      function (val) {
        if (val) {
          return this.parent.event_start;
        } else return !val;
      }
    )
    .test(
      "sub_end_date",
      "Дата окончания не может быть ранее даты начала",
      function (val) {
        if (val) {
          return (
            this.parent.sub_start_date && this.parent.sub_start_date <= val
          );
        } else return !val;
      }
    )
    .test(
      "sub_end_date",
      "Дата ежегодного отпуска должна быть в пределах дат межвахтового отпуска",
      function (val) {
        if (val) {
          return this.parent.event_end
            ? this.parent.event_start <= val && this.parent.event_end >= val
            : this.parent.event_start && this.parent.event_start <= val;
        } else return !val;
      }
    )
};

export const getValidationSchema = (cols: string[], requiredCols: string[]) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = validationStaffForm[col]
      ? validationStaffForm[col]
      : string().nullable();

    if (requiredCols.includes(col)) {
      if (validationSchema[col]?.type == "array") {
        validationSchema[col] = validationSchema[col].min(
          1,
          "Поле обязательно для заполнения"
        );
      } else {
        validationSchema[col] = validationSchema[col]?.required(
          "Поле обязательно для заполнения"
        );
      }
    }
  });

  return object(validationSchema);
};
