import styles from "./staffOneEditAmnesty.module.scss";
import { useParams } from "react-router-dom";
import { Badge } from "shared/ui/Badge";
import { Button, ButtonTheme } from "shared/ui/Button";
import { useStores } from "stores";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { format } from "date-fns";
import { Formik } from "formik";
import FormWindow from "shared/ui/FormWindow";
import DatePickerField from "shared/ui/Inputs/DatePickerField";

const StaffOneEditAmnesty = () => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);
  const [isOpenedCalendar, setIsOpenedCalendar] = useState(false);

  const handleOnClick = () => {
    setIsOpenedDialog(true);
  };

  return (
    <div className={styles.container}>
      {staffOneEditStore.amnesty[id] && (
        <Badge
          text="Амнистия"
          textColorName="bw-white"
          colorName="accent-green"
        />
      )}
      {staffOneEditStore.amnesty[id] === false &&
        staffOneEditStore.amnesty[id] !== null && (
          <Button theme={ButtonTheme.CLEAR} onClick={handleOnClick}>
            Добавить амнистию <IconAdd />
          </Button>
        )}

      {isOpenedDialog && (
        <Formik
          initialValues={{ date: format(new Date(), "yyy-MM-dd") }}
          onSubmit={() => null}
        >
          {({ isValid, values }) => (
            <FormWindow
              title="Добавить амнистию"
              setOpenWindow={() => setIsOpenedDialog(!isOpenedDialog)}
              saveBtnTitle="Сохранить"
              saveBtnOnClickFunc={() =>
                staffOneEditStore.addAmnesty(values.date)
              }
              saveBtnDisabledValue={!isValid}
              addBtnTitle="Отмена"
              saveSuccessModal={staffOneEditStore.success}
              onSuccessModalClose={() => {
                staffOneEditStore.setSuccess(false);
              }}
              successMessageTitle={{
                save: "Амнистия добавлена"
              }}
              fromOverlayWindow
              isLoadingForModal={staffOneEditStore.isLoadingForForm}
            >
              <DatePickerField
                name="date"
                title="Дата амнистии"
                isCalendarOpened={isOpenedCalendar}
                setIsCalendarOpened={setIsOpenedCalendar}
                withClearBtn
                required
              />
            </FormWindow>
          )}
        </Formik>
      )}
    </div>
  );
};

export default observer(StaffOneEditAmnesty);
