import styles from "./staffListCustomFilter.module.scss";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import Select from "shared/ui/Inputs/Select";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { ViewField } from "shared/ui/ViewField";
import SelectMulti from "shared/ui/Inputs/SelectMulti";

type StaffListCustomFilterProps = Partial<{
  openedListName: string;
  valueField: string;
  values: FilterType;
  params: { [key: string]: Col };
  selectMulti: string[];
  dateRangeFields: Record<string, string[]>;
  changeValue: (
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => void;
  changeOpenedWindows: (arg: string) => void;
  dictForArray: Record<string, string>;
  setDictForArray: (value: Record<string, string>) => void;
}>;

const StaffListCustomFilter = ({
  openedListName,
  valueField,
  values,
  params,
  selectMulti,
  dateRangeFields,
  changeValue,
  changeOpenedWindows,
  dictForArray,
  setDictForArray
}: StaffListCustomFilterProps) => {
  const { staffListStore, filterStore } = useStores();
  const { handleChange } = useFormikContext();

  // Вспомогательная функция для проверки формата данных и преобразования строк в массивы
  const ensureArrayFormat = (value: unknown): string[] => {
    if (Array.isArray(value)) {
      return value;
    }

    if (typeof value === 'string') {
      return value.split(',').map(s => s.trim()).filter(Boolean);
    }

    return [];
  };

  // Хелпер для проверки, изменились ли массивы
  const arraysAreDifferent = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return true;
    return arr1.some(id => !arr2.includes(id));
  };

  // Кэширование предыдущих значений для предотвращения лишних запросов
  const prevValuesRef = useRef({
    building: [] as string[],
    position: [] as string[],
    company: [] as string[]
  });

  // Хелпер для обновления словаря с проверкой необходимости вызова
  const updateBuildingDict = (ids: string[]) => {
    if (ids.length === 0 || !arraysAreDifferent(ids, prevValuesRef.current.building)) return;

    prevValuesRef.current.building = [...ids];
    staffListStore.getBuildingDictForSavedFilter(ids);
    setDictForArray(staffListStore.dictForArray);
  };

  // Хелпер для обновления словаря должностей с проверкой необходимости вызова
  const updatePositionDict = (ids: string[]) => {
    if (ids.length === 0 || !arraysAreDifferent(ids, prevValuesRef.current.position)) return;

    prevValuesRef.current.position = [...ids];
    staffListStore.getPositionDictForSavedFilter(ids);
    setDictForArray(staffListStore.dictForArray);
  };

  // Хелпер для обновления списков на основе компаний с проверкой необходимости вызова
  const updateCompanyRelatedLists = (ids: string[]) => {
    if (arraysAreDifferent(ids, prevValuesRef.current.company)) {
      prevValuesRef.current.company = [...ids];
      if (ids.length > 0) {
        getBuildingListFromCompany(ids);
        getPositionListFromCompany(ids);
      }
    }
  };

  // Объединенный эффект, который обрабатывает все источники данных для фильтров
  useEffect(() => {
    // 1. Обработка данных из основных значений формы (values.filter)
    if (values?.filter) {
      const buildingArray = ensureArrayFormat(values.filter["building"]);
      const positionArray = ensureArrayFormat(values.filter["position"]);
      const companyArray = ensureArrayFormat(values.filter["company"]);

      updateBuildingDict(buildingArray);
      updatePositionDict(positionArray);
      updateCompanyRelatedLists(companyArray);
    }

    // 2. Обработка данных из сохраненных фильтров или URL-параметров
    const fromSavedFilter = filterStore.isOpenedSavedFilterOptions;

    // Получаем значения из параметров фильтра
    const buildingIds = ensureArrayFormat(staffListStore.filterParams["filter[building]"]);
    const positionIds = ensureArrayFormat(staffListStore.filterParams["filter[position]"]);
    const companyIds = ensureArrayFormat(staffListStore.filterParams["filter[company]"]);

    // Обновляем данные только если мы в режиме сохраненного фильтра или если нет values.filter
    if (fromSavedFilter || !values?.filter) {
      updateBuildingDict(buildingIds);
      updatePositionDict(positionIds);
      updateCompanyRelatedLists(companyIds);
    }
  }, [
    values?.filter?.["building"],
    values?.filter?.["position"],
    values?.filter?.["company"],
    filterStore.isOpenedSavedFilterOptions,
    staffListStore.downloadedFilter,
    staffListStore.filterParams
  ]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      staffListStore.pageBuilding !== 1 &&
      staffListStore.pageBuilding <= staffListStore.maxPageBuilding &&
      staffListStore.pageBuilding !== staffListStore.prevPageBuilding
    ) {
      staffListStore.getMoreBuildingList();
    }
  }, [staffListStore.pageBuilding, staffListStore.maxPageBuilding]);

  // пагинация для выпадающего списка должностей
  useEffect(() => {
    if (
      staffListStore.pagePosition !== 1 &&
      staffListStore.pagePosition <= staffListStore.maxPagePosition &&
      staffListStore.pagePosition !== staffListStore.prevPagePosition
    ) {
      staffListStore.getMorePositionList();
    }
  }, [staffListStore.pagePosition, staffListStore.maxPagePosition]);

  // при обнулении поля Компания при нажатии на кнопку "Сохранить" обновляются выпадающие списки объектов и должностей
  useEffect(() => {
    if (
      Array.isArray(values.filter["company"]) &&
      !values.filter["company"].length
    ) {
      getBuildingListFromCompany([]);
      getPositionListFromCompany([]);
    }
  }, [values.filter?.["company"]]);
  // Функция для изменения списка компаний в зависимости от выбранного объекта
  // При выборе объекта сразу проставляется значение поля company выбранного объекта в поле "Компания"
  const changeCompanyFromBuilding = (value: string[]) => {
    const dict = params["building"]?.directory;
    if (Array.isArray(dict)) {
      // получение списка компаний из выбранных объектов, где каждое значение является уникальным
      const companyValue = Array.from(
        new Set(
          value
            .map((buildingID) => {
              const buildingItem = dict.filter(
                (item) => buildingID === item["newname"]
              )[0];
              return buildingItem ? buildingItem["company"] : null;
            })
            .filter(Boolean) // Отфильтровываем null значения
        )
      );

      if (companyValue.length > 0) {
        changeValue("filter.company", companyValue);
        getPositionListFromCompany(companyValue);
        getNewValueFromCompany(companyValue, "position");
      }
    }
  };

  // функция для подтягивания нового списка объектов в том случае, если первым заполняется поле Компания
  const getBuildingListFromCompany = (value: string[]) => {
    staffListStore.setSearchValueBuilding("");
    staffListStore.setCompanyListForBuilding(value);
    staffListStore.getBuildingList();
  };

  // функция для подтягивания нового списка должностей в зависимости от выбранных компаний
  const getPositionListFromCompany = (value: string[]) => {
    staffListStore.setSearchValuePosition("");
    staffListStore.setCompanyListForPosition(value);
    staffListStore.getPositionList();
  };

  //функции для изменения значений полей Должность или Объект
  const getNewValueFromCompany = (
    companyList: string[],
    field: "position" | "building"
  ) => {
    // Сначала проверяем, что values.filter[field] существует и является массивом
    if (!values.filter[field] || !Array.isArray(values.filter[field])) {
      return; // Если не массив или undefined, просто выходим из функции
    }

    // Проверяем, есть ли в поле "Должность"/"Объект" выбранные значения
    if ((values.filter[field] as string[]).length) {
      const fieldValue = (values.filter[field] as string[]).filter(
        (fieldID) => {
          const foundItem = (
            params[field].directory as {
              [key: string]: string;
              newname: string;
              title: string;
            }[]
          ).filter((item) => fieldID === item["newname"])[0];

          // Проверяем, что элемент найден перед обращением к его свойству
          if (!foundItem) return false;

          return companyList.filter(Boolean).includes(foundItem["company"]);
        }
      );
      // если есть, то обновляем значения поля согласно полученнуму списку компаний companyList
      changeValue(`filter.${field}`, fieldValue);
    }
  };
  return (
    <>
      {Object.keys(values.filter).map((key) => {
        const field = params[key];

        if (field?.directory) {
          const dict = field.directory;

          if (selectMulti && selectMulti.includes(key)) {
            switch (key) {
              case "building":
                return (
                  <SelectMulti
                    key={key}
                    name={`filter.${key}`}
                    label={field.title}
                    title={field.title}
                    options={dict}
                    valueName="newname"
                    isSearchWithPagination
                    page={staffListStore.pageBuilding}
                    prevPage={staffListStore.prevPageBuilding}
                    maxPage={staffListStore.maxPageBuilding}
                    setPage={staffListStore.setPageBuilding}
                    getList={staffListStore.getBuildingList}
                    setSearchValue={staffListStore.setSearchValueBuilding}
                    searchValue={staffListStore.searchValueBuilding}
                    isLoading={staffListStore.isLoadingForBuildingList}
                    onSelectedItemsChange={(items) => {
                      const newArray = items.map((item) => item.id);
                      changeValue(`filter.${key}`, newArray);

                      if (!newArray.length) {
                        changeValue("filter.company", []);
                        changeValue("filter.position", []);
                        getPositionListFromCompany([]);
                      } else {
                        changeCompanyFromBuilding(newArray);
                      }
                    }}
                    onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                    onMenuClose={() => changeOpenedWindows("")}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                    className={styles.container}
                  />
                );

              case "company":
                return (
                  <SelectMulti
                    key={key}
                    name={`filter.${key}`}
                    label={field.title}
                    title={field.title}
                    options={dict}
                    valueName="newname"
                    onSelectedItemsChange={(items) => {
                      const newArray = items.map((item) => item.id);
                      changeValue(`filter.${key}`, newArray);

                      if (!newArray.length) {
                        // При удалении всех компаний очищаем все
                        changeValue("filter.building", []);
                        changeValue("filter.position", []);
                        getBuildingListFromCompany([]);
                        getPositionListFromCompany([]);
                      } else {
                        // При частичном удалении компаний
                        getBuildingListFromCompany(newArray);
                        getPositionListFromCompany(newArray);
                        // Проверяем и обновляем список объектов, оставляя только те, что принадлежат оставшимся компаниям
                        getNewValueFromCompany(newArray, "building");
                        // Проверяем и обновляем список должностей
                        getNewValueFromCompany(newArray, "position");
                      }
                    }}
                    onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                    onMenuClose={() => changeOpenedWindows("")}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                    className={styles.container}
                  />
                );

              case "position":
                if (
                  !values.filter["company"] ||
                  (Array.isArray(values.filter["company"]) &&
                    !values.filter["company"].length)
                ) {
                  return (
                    <div
                      className={styles.tooltip}
                      data-tooltip="Сперва необходимо выбрать Компанию"
                      key={key}
                    >
                      <ViewField
                        title={field?.title}
                        value={undefined}
                        disabled
                      />
                    </div>
                  );
                } else {
                  return (
                    <SelectMulti
                      key={key}
                      name={`filter.${key}`}
                      label={field.title}
                      title={field.title}
                      options={dict}
                      valueName="newname"
                      isSearchWithPagination
                      page={staffListStore.pagePosition}
                      prevPage={staffListStore.prevPagePosition}
                      maxPage={staffListStore.maxPagePosition}
                      setPage={staffListStore.setPagePosition}
                      getList={staffListStore.getPositionList}
                      setSearchValue={staffListStore.setSearchValuePosition}
                      searchValue={staffListStore.searchValuePosition}
                      isLoading={staffListStore.isLoadingForPositionList}
                      onSelectedItemsChange={(items) => {
                        const newArray = items.map((item) => item.id);
                        changeValue(`filter.${key}`, newArray);
                      }}
                      onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                      onMenuClose={() => changeOpenedWindows("")}
                      dictForArray={dictForArray}
                      setDictForArray={setDictForArray}
                      className={styles.container}
                    />
                  );
                }

              default:
                return (
                  <SelectMulti
                    key={key}
                    name={`filter.${key}`}
                    label={field.title}
                    title={field.title}
                    options={dict}
                    valueName="newname"
                    onSelectedItemsChange={(items) => {
                      const newArray = items.map((item) => item.id);
                      changeValue(`filter.${key}`, newArray);
                    }}
                    onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                    onMenuClose={() => changeOpenedWindows("")}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                    className={styles.container}
                  />
                );
            }
          } else {
            return (
              <Select
                key={key}
                name={`filter.${key}`}
                title={field.title}
                label={
                  values.filter[key] && values.filter[key] !== -1
                    ? dict[values.filter[key] as string]
                      ? dict[values.filter[key] as string].title
                      : (values.filter[key] as string)
                    : null
                }
                options={dict}
                onClick={(value) => {
                  changeValue(`filter.${key}`, value[valueField] as string);
                }}
                isFloating
                valueName={valueField}
              />
            );
          }
        }

        if (field?.type === "date") {
          if (dateRangeFields && key in dateRangeFields) {
            return (
              <DateRangePicker
                key={key}
                monthsShown={2}
                name={`filter.${dateRangeFields[key][0]}`}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                placeholderVisible
                siblingDateName={`filter.${dateRangeFields[key][1]}`}
              />
            );
          } else {
            return (
              <DatePickerField
                key={key}
                name={`filter.${key}`}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                onBlur={() => {
                  changeOpenedWindows(null);
                }}
              />
            );
          }
        }

        if (
          dateRangeFields &&
          Object.values(dateRangeFields).flat().includes(key)
        ) {
          return;
        }

        if (field?.type === "bool") {
          return (
            <div className={styles.input} key={key}>
              <Select
                name={`filter.${key}`}
                title={field.title}
                isFloating
                options={{
                  1: { newname: "1", title: "Да" },
                  0: { newname: "0", title: "Нет" }
                }}
                onClick={(option) => {
                  changeValue(`filter.${key}`, option.newname as string);
                }}
              />
            </div>
          );
        }

        if (key === "count_work_all") {
          return (
            <div
              key={key}
              className={styles.tooltip}
              data-tooltip="Сперва необходимо выбрать Компанию, Должность, Объект и Статус"
            >
              <ViewField title={field?.title} value={undefined} disabled />
            </div>
          );
        }

        return (
          <div className={styles.input} key={key}>
            <Input
              name={`filter.${key}`}
              onChange={(e) => {
                handleChange(e);
                changeValue(`filter.${key}`, e.target.value);
              }}
              label={field?.title}
            />
          </div>
        );
      })}
    </>
  );
};

export default observer(StaffListCustomFilter);
