import React, { useEffect } from "react";
import styles from "./employeesBlock.module.scss";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

type StaffEmployeesBlockProps = {
  staffIdCount: number;
  setSelectedSnapshot: (items: Array<{ id: string; title: string }>) => void;
};

const EmployeesBlock = ({
  staffIdCount,
  setSelectedSnapshot
}: StaffEmployeesBlockProps) => {
  const { staffAddAwardStore } = useStores();

  const handleMenuOpen = () => {
    staffAddAwardStore.resetStaffListState();
    staffAddAwardStore.getStaffList();
  };

  useEffect(() => {
    if (
      staffAddAwardStore.page !== 1 &&
      staffAddAwardStore.page <= staffAddAwardStore.maxPage &&
      staffAddAwardStore.page !== staffAddAwardStore.prevPage
    ) {
      staffAddAwardStore.getMoreStaff();
    }
  }, [staffAddAwardStore.page, staffAddAwardStore.maxPage]);

  return (
    <div className={styles.employeeSelect}>
      <p className={styles.subtitle}>
        Выберите одного или нескольких сотрудников
      </p>

      <SelectMulti
        title="Сотрудники"
        name="staff_id"
        label="Сотрудники"
        options={staffAddAwardStore.employeesList}
        valueName="id"
        isSearchWithPagination
        page={staffAddAwardStore.page}
        prevPage={staffAddAwardStore.prevPage}
        maxPage={staffAddAwardStore.maxPage}
        setPage={staffAddAwardStore.setPage}
        getList={staffAddAwardStore.getStaffList}
        setSearchValue={staffAddAwardStore.setSearchValue}
        searchValue={staffAddAwardStore.searchValue}
        isLoading={staffAddAwardStore.isLoadingForStaffList}
        onSelectedItemsChange={setSelectedSnapshot}
        onMenuClose={handleMenuOpen}
        required
      />

      <p className={styles.subtitle}>Выбрано сотрудников: {staffIdCount}</p>
    </div>
  );
};

export default observer(EmployeesBlock);
