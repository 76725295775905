import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { SelectModule } from "stores/StaffModule/types/Selects";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";
import { Param } from "stores/utils/types/Param";
import { Col } from "stores/utils/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { isEmpty, isEqual } from "lodash";
import { StaffListItem } from "stores/StaffModule/types/StaffListItem";
import { getKeys } from "shared/utils/helpers/getKeys";
import { FilterType } from "stores/utils/types/FilterType";
import { OneOfStaff } from "./types/TermsControl";

type Select = { kind: string; types: Record<string, string> };

export type InitialValues = {
  building?: string;
  kind: string[];
  type?: string[][];
  uid: string[];
};

export default class SafetyWorkTermsControlStore {
  ordered = "";
  order = "";
  onPage = 100;
  page = 1;
  prevPage = 1;
  isScrolling = true;
  searchValue = "";
  error = false;
  errorsMessage: Partial<Errors["message"]> = {};
  errorMessage = "";
  success = false;
  isLoading = false;
  isLoadingForBuildingList = false;
  isLoadingForFilters = false;

  termsControlCols: Record<string, Col> = {};
  termsControlColsParam: Record<string, Col> = {};
  termsControlParams: Record<string, Param> = {};
  currentTitles: string[] = [];

  initialValues: InitialValues = {
    building: "",
    kind: [""],
    type: [[]],
    uid: []
  };
  // для начальной страницы фильтрации
  requiredFields = ["uid", "kind"];

  fieldsWithoutFilter = [
    "time_create",
    "company",
    "date_start",
    "date_end",
    "id",
    "comment",
    "user",
    "tn",
    "events_type",
    "cert_expires"
  ];
  // найденные сотрудники
  foundedStaff = 0;

  // поля для работы со выпадающим списком Сотрудник
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  // поля для работы со выпадающим списком Объект
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";

  // данные для таблицы
  termsControlSelects: Record<string, SelectModule> = {};
  oneOfStaff: Record<string, OneOfStaff> = {};
  termsControlTypes: Select[] = [];
  isTableOpened = false;

  filterParams: { [key: string]: string[] | string | number } = {};
  queryParams: { [key: string]: string } = {};
  // список типов для выбора в виде пары id - title
  listOfTypes = {};
  // список в виде пары id - title
  // нужен для вывода title в компоненте SelectMulti и FilterMainWindow
  dictForFilter: Record<string, string> = {};

  generalFilters: { [key: string]: FilterType } = {
    new: {
      cols: ["building", "uid", "kind"],
      default: 0,
      filter: {},
      general: 0,
      id: "new",
      order: "name",
      ordered: "DESC",
      title: null
    }
  };

  getData = async () => {
    this.isLoading = true;
    try {
      isEmpty(this.termsControlCols) && (await this.getTableCols()),
        isEmpty(this.termsControlParams) && (await this.getTableParams()),
        isEmpty(this.termsControlSelects) && (await this.getSelects()),
        isEmpty(this.termsControlColsParam.building.directory) &&
          (await this.getBuildingsList()),
        isEmpty(this.termsControlColsParam.uid.directory) &&
          (await this.getStaffList());
      !isEmpty(this.filterParams) && (await this.getCertsTermsControl());
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  // функция для получения списка типов для выбора в виде пары id - title
  getTypesForKind = (kindId: string) => {
    const listOfTypes = {};
    getValues(
      this.termsControlSelects?.kind?.[kindId]?.custom?.type as Record<
        string,
        string
      >
    ).map((item) => {
      listOfTypes[this.termsControlSelects.type[item].id] = {
        newname: this.termsControlSelects.type[item].id,
        title: this.termsControlSelects.type[item].title
      };
    });
    this.listOfTypes[kindId] = listOfTypes;
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};
    if (this.ordered) {
      params["ordered"] = this.ordered;
      params["order"] = "name";
    }
    if (this.searchValue) {
      params["searchInFound"] = this.searchValue;
    }
    return params;
  };

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    getEntries(filter.filter).forEach(([key, value]) => {
      if ((key === "uid" && !isEmpty(value)) || (key === "building" && value)) {
        this.filterParams[`filter[${key}]`] = value as string;
      }
      if (key === "kind" && !isEqual(value, [""])) {
        (value as string[]).map((item, index) => {
          if (item !== null) {
            this.filterParams[`filter[kind][${index}]`] = item;
            if (filter.filter?.type && filter.filter.type[index]?.length) {
              this.filterParams[`filter[type][${index}]`] =
                filter.filter.type[index];
            }
          }
        });
      }
    });
  };

  getSafetyWorkListWithFilter = (filter: FilterType) => {
    this.errorsMessage = {};
    this.setInitialValues(filter.filter as InitialValues);
    this.generalFilters.new.filter = filter.filter;
    this.filterParams = {};
    if (!filter.default) {
      this.getNewFilterParams(filter);
    } else {
      this.filterParams = {};
    }
    !isEmpty(this.filterParams) ? this.getCertsTermsControl() : null;
  };

  getCertsTermsControl = async () => {
    this.isLoading = true;
    this.errorsMessage = {};
    this.setPage(1);
    let params = {};
    params = { ...this.filterParams, ...this.newParams() };
    params["uid_select"] = 1;
    if (this.ordered) {
      params["ordered"] = this.ordered;
      params["order"] = "name";
    }

    try {
      const data: ApiResponse<OneOfStaff> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          method: "getDeadlineControl",
          page: !this.searchValue && this.page,
          on_page: !this.searchValue && this.onPage * this.prevPage,
          params: params
        });
      runInAction(() => {
        if (data.code === 200) {
          const oneStaff = {};
          getEntries(data.result).map(([staffId, value]) => {
            oneStaff[staffId] = {
              ...value
            };
          });
          this.oneOfStaff = oneStaff;
          this.foundedStaff = getKeys(oneStaff).length;
          this.setIsTableOpened(true);

          this.getStaffList();
          this.setSearchValueBuilding("");
          this.getBuildingsList();
          this.getSelects();
        } else if (data.message) {
          this.oneOfStaff = {};
          this.foundedStaff = 0;
          this.isLoading = false;
          this.errorsMessage = data.message;
        } else {
          this.oneOfStaff = {};
          this.foundedStaff = 0;
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.page = this.prevPage;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getMoreCertsTermsControl = async () => {
    this.errorsMessage = {};
    let params = {};
    params = { ...this.filterParams, ...this.newParams() };
    params["uid_select"] = 1;
    if (this.ordered) {
      params["ordered"] = this.ordered;
      params["order"] = "name";
    }
    if (this.searchValue) {
      params["searchInFound"] = this.searchValue;
    }

    try {
      const data: ApiResponse<OneOfStaff> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          method: "getDeadlineControl",
          page: this.page,
          on_page: this.onPage,
          params: params
        });

      runInAction(() => {
        if (data.result) {
          const oneStaff = {};
          getEntries(data.result).map(([staffId, value]) => {
            oneStaff[staffId] = {};
            oneStaff[staffId] = {
              ...value
            };
          });
          this.oneOfStaff = { ...this.oneOfStaff, ...oneStaff };
        } else {
          this.isScrolling = false;
          data.message
            ? (this.errorsMessage = data.message)
            : (this.errorMessage = "Ничего не найдено");
        }
        this.prevPage = this.page;
        this.foundedStaff = getKeys(this.oneOfStaff).length;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  massCopyTn = async () => {
    this.errorsMessage = {};
    this.success = false;

    try {
      const data: ApiResponse<{ [key: string]: number }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          method: "masCopyTn",
          params: { ...this.filterParams, ...this.newParams() }
        });

      runInAction(() => {
        if (data.code === 200) {
          const staffTNumbers = getValues(data.result);
          const staffTNumbersString = staffTNumbers.join(", ");
          navigator.clipboard.writeText(staffTNumbersString).then(() => {
            this.success = true;
          });
        } else {
          this.errorsMessage = data.message;
          this.error = true;
          this.success = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: {
        select: Record<string, SelectModule>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSelects"
      });

      runInAction(() => {
        if (getValues(data.select).length) {
          this.termsControlSelects = data.select;
          let arr: Select = {
            kind: "",
            types: {}
          };
          getValues(this.termsControlSelects.kind).map((item) => {
            arr = {
              kind: item.id,
              types: item.custom.type as Record<string, string>
            };
            this.termsControlTypes.push(arr);
          });

          getEntries(this.termsControlSelects).forEach(([key, value]) => {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            if (
              key in this.termsControlColsParam &&
              this.termsControlParams[key]["isvariable"] === "on"
            ) {
              getValues(value).forEach((item) => {
                directory[item.id ? item.id : item.title] = {
                  newname: item.id ? item.id : item.title,
                  title: item.title
                };
              });
              this.termsControlColsParam[key].directory = directory;
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          // хардкодные поля, которых пока нет в getTableCols
          this.termsControlCols = {
            building: {
              newname: "building",
              title: "Объект",
              default: "",
              type: "varchar",
              length: 100
            },
            tn: {
              newname: "tn",
              title: "ТН",
              default: "",
              type: "int",
              length: ""
            },
            ...data.result
          };
          this.currentTitles = getKeys(this.termsControlCols);
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.termsControlParams = {
            building: {
              access_edit: 1,
              description: "",
              variable: {},
              isvariable: "on",
              nobreaking_dash: "",
              show_default: "on",
              des_color: "info",
              nobreaking_space: "",
              required: 1,
              useAsFilter: "on",
              dadata: "",
              show_length: 100,
              nospace: "",
              editable: "on",
              add_show: "on",
              nobr: "",
              access_show: 1
            },
            ...data.result
          };
          getEntries(this.termsControlCols).map(([key, value]) => {
            if (
              !this.fieldsWithoutFilter.includes(key) &&
              this.termsControlParams[key]?.useAsFilter
            ) {
              this.termsControlColsParam[key] = value;
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  formatStaffList = (
    staffList: StaffListItem[]
  ): { newname: string; title: string }[] =>
    staffList.map((staff) => {
      return {
        newname: staff.id,
        title: `ТН${staff.uid} ${staff.surname}${
          staff.surname ? " " + staff.name : staff.name
        }${staff.patronymic ? " " + staff.patronymic : ""}`
      };
    });

  getStaffList = async () => {
    this.errorMessage = "";
    this.isLoadingForStaffList = true;
    this.termsControlColsParam.uid.directory = [];
    const params = {};
    if (this.searchValueStaff) {
      params["fast_search"] = this.searchValueStaff;
    }

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPageStaff,
        page: this.pageStaff,
        params: params
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((value) => {
            this.dictForFilter[value.id] = `${value.surname}${
              value.surname ? " " + value.name : value.name
            }${value.patronymic ? " " + value.patronymic : ""}`;
          });
          if (
            "uid" in this.termsControlColsParam &&
            this.termsControlParams.uid.isvariable === "on"
          ) {
            this.termsControlColsParam.uid = {
              ...this.termsControlColsParam.uid,
              directory: this.formatStaffList(getValues(data.records))
            };
          }
        } else {
          this.errorMessage = "Ничего не найдено";
          this.termsControlColsParam.uid.directory = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data.nav.max_page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.errorMessage = "";
    this.isLoadingForStaffList = true;
    const params = {};
    if (this.searchValueStaff) {
      params["fast_search"] = this.searchValueStaff;
    }

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPageStaff,
        page: this.pageStaff,
        params: params
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          if (
            "uid" in this.termsControlParams &&
            this.termsControlParams.uid.isvariable === "on"
          ) {
            if (Array.isArray(this.termsControlColsParam.uid.directory)) {
              const directory = this.termsControlColsParam.uid.directory.concat(
                this.formatStaffList(getValues(data.records))
              );
              this.termsControlColsParam.uid = {
                ...this.termsControlColsParam.uid,
                directory: directory
              };
            }
          }
        } else {
          this.termsControlColsParam.uid.directory = [];
          this.isLoadingForStaffList = false;
        }
        this.errorMessage = "Ничего не найдено";
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  formatBuilding = (data: { id: string; title: string }[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfStaffObj["newname"] = value;
            break;

          default:
            tempOneOfStaffObj[key] = value;
        }
      });
      return tempOneOfStaffObj;
    });
  };

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingsList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data.result !== -1) {
          if ("building" in this.termsControlColsParam) {
            this.termsControlColsParam["building"] = {
              ...this.termsControlColsParam["building"],
              directory: this.formatBuilding(getValues(data.result))
            };
          }
        } else {
          this.termsControlColsParam.building.directory = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data.nav.max_page;
      });
    } catch (error) {
      runInAction(() => {
        this.termsControlColsParam.building.directory = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingsList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data.result !== -1) {
          if ("building" in this.termsControlColsParam) {
            const directory = (
              this.termsControlColsParam.building.directory as {
                newname: string;
                title: string;
              }[]
            ).concat(this.formatBuilding(getValues(data.result)));

            this.termsControlColsParam["building"] = {
              ...this.termsControlColsParam["building"],
              directory: directory
            };
          }
        } else {
          this.termsControlColsParam.building.directory = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.termsControlColsParam.building.directory = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  setPage = (value: number) => {
    this.page = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setFilterParamsFromQuery = (value: { [key: string]: string[] | string }) => {
    this.filterParams = value;
  };

  setIsTableOpened = (value: boolean) => {
    this.isTableOpened = value;
  };

  setSuccess = (value: boolean) => {
    this.success = value;
  };

  setInitialValues = (values: InitialValues) => {
    this.initialValues = values;
  };

  setOrdered = (ordered: string) => {
    this.ordered = ordered;
  };

  setOrder = (order: string) => {
    this.order = order;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  resetAll = () => {
    this.searchValueStaff = "";
    this.searchValueBuilding = "";
    this.filterParams = {};
    this.termsControlCols = {};
    this.termsControlColsParam = {};
    this.termsControlParams = {};
    this.termsControlTypes = [];
    this.termsControlSelects = {};
    this.currentTitles = [];
    this.oneOfStaff = {};
    this.foundedStaff = 0;
    this.queryParams = {};
    this.isTableOpened = false;
    this.initialValues = {
      building: "",
      kind: [""],
      type: [[]],
      uid: []
    };
    this.order = "";
    this.ordered = "";
    this.error = false;
    this.success = false;
    this.errorsMessage = {};
    this.errorMessage = "";
  };

  resetStaffListState = () => {
    this.searchValueStaff = "";
    this.onPageStaff = 30;
    this.pageStaff = 1;
    this.maxPageStaff = 0;
    this.prevPageStaff = 1;
  };

  setDictForFilter = (value: Record<string, string>) => {
    this.dictForFilter = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
