import styles from "./awardsFields.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { fileUrl } from "stores/utils/consts";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import AwardsWindow from "../AwardsWindow";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getEntries } from "shared/utils/helpers/getEntries";
import Collapsible from "shared/ui/Collapsible";
import { classNames } from "shared/utils/helpers/classNames";
import Alert from "shared/ui/Alert";
import { getValues } from "shared/utils/helpers/getValues";

const AwardsFields = () => {
  const { staffOneAwardsStore, menuStore } = useStores();

  return (
    <>
      {staffOneAwardsStore.openedWindowType !== "add" && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AwardsWindow />
        </ErrorBoundary>
      )}
      {getValues(staffOneAwardsStore.awardGroups).map((field) => {
        const filteredAwards = Object.fromEntries(
          getEntries(staffOneAwardsStore.awards).filter(([, value]) =>
            staffOneAwardsStore.searchValue
              ? String(value.type_title.toLowerCase()).includes(
                  staffOneAwardsStore.searchValue.toLowerCase()
                ) &&
                staffOneAwardsStore.awardGroups[value.custom.group].title ===
                  field.title
              : staffOneAwardsStore.awardGroups[value.custom.group].title ===
                field.title
          )
        );

        const isOpenedField =
          (!staffOneAwardsStore.awardsArray?.includes(field.title) &&
            !staffOneAwardsStore.isFocusSearch) ||
          (staffOneAwardsStore.isFocusSearch &&
            staffOneAwardsStore.awardsFieldsFoundDuringSearch.includes(
              field.title
            ));

        const toggleOneFieldShowing = () => {
          if (
            !staffOneAwardsStore.isFocusSearch &&
            !staffOneAwardsStore.searchValue
          ) {
            staffOneAwardsStore.changeOpenedAwards(
              !isOpenedField ? "delete" : "add",
              [field.title]
            );
          }
          return;
        };

        return (
          <Collapsible
            key={field.id}
            onClick={toggleOneFieldShowing}
            title={field.title}
            isOpenedField={isOpenedField}
            className={{
              headerContainer: classNames(styles.collapsibleContainer, {
                [styles.widgetHeader]: menuStore.scroll?.scrollTop
              }),
              dashedLineWrapper: styles.marginBottom
            }}
            withHeaderShadow
          >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {isOpenedField ? (
                <div className={styles.field}>
                  {getKeys(filteredAwards).length ? (
                    getEntries(filteredAwards).map(([id, award]) => {
                      const handleAwardWindowOpen = () => {
                        staffOneAwardsStore.setOpenedAward(id);
                        staffOneAwardsStore.setOpenedWindowType("view");
                        staffOneAwardsStore.setIsActiveButton(
                          staffOneAwardsStore.awardGroups[
                            staffOneAwardsStore.awards[
                              staffOneAwardsStore.openedAward
                            ].custom?.group
                          ]
                        );
                      };
                      return (
                        <div
                          key={award.id}
                          className={styles.award}
                          onClick={handleAwardWindowOpen}
                        >
                          <div className={styles.dataText}>
                            {getFormattedDate(award.award_start)}
                          </div>
                          {award.img_src !== -1 ? (
                            <img
                              src={fileUrl + award.img_src}
                              alt={award.comment}
                              className={styles.img}
                            />
                          ) : (
                            ""
                          )}
                          <h3 className={styles.title}>{award.type_title}</h3>
                        </div>
                      );
                    })
                  ) : (
                    <Alert
                      errors={{
                        head: `У сотрудника нет наград за ${field.title}`,
                        color: "empty"
                      }}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
            </ErrorBoundary>
          </Collapsible>
        );
      })}
    </>
  );
};

export default observer(AwardsFields);
