import styles from "./scrollButton.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores/index";
import iconBack from "shared/assets/images/mainIcons/iconBack.svg";
import iconNext from "shared/assets/images/mainIcons/iconNext.svg";
import { MutableRefObject } from "react";

type ScrollButtonProps = {
  tableRef: MutableRefObject<HTMLTableElement>;
  leftButtonClass?: string;
  rightButtonClass?: string;
  children?: JSX.Element;
};

const ScrollButton = ({
  tableRef,
  leftButtonClass,
  rightButtonClass,
  children
}: ScrollButtonProps) => {
  const { menuStore } = useStores();

  const scrollTableBy = (value?: number) => {
    menuStore.scrollbarRef.current.view.scroll({
      left: value,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    menuStore.setScroll(menuStore.scrollbarRef?.current?.getValues());
  }, []);

  return (
    <div>
      <button
        className={`${styles.leftBtn} ${leftButtonClass}`}
        id="ScrollButton_leftScroll"
        onClick={() =>
          scrollTableBy(
            menuStore.scroll?.scrollWidth - menuStore.scroll?.clientWidth > 200
              ? menuStore.scroll?.scrollLeft -
                  (menuStore.scroll?.scrollWidth -
                    menuStore.scroll?.clientWidth) /
                    2
              : 0
          )
        }
        disabled={menuStore.scroll?.scrollLeft <= 1}
        style={{
          top:
            menuStore.scroll?.scrollTop > tableRef.current?.offsetTop + 70
              ? 0
              : (tableRef.current?.offsetTop
                  ? tableRef.current?.offsetTop
                  : 118) +
                50 -
                (menuStore.scroll?.scrollTop ? menuStore.scroll?.scrollTop : 0)
        }}
      >
        <img src={iconBack} />
      </button>
      {children && children}
      <button
        className={`${styles.rightBtn} ${rightButtonClass}`}
        id="ScrollButton_rightScroll"
        onClick={() => {
          scrollTableBy(
            menuStore.scroll?.scrollWidth - menuStore.scroll?.clientWidth > 200
              ? menuStore.scroll?.scrollLeft +
                  (menuStore.scroll?.scrollWidth -
                    menuStore.scroll?.clientWidth) /
                    2
              : menuStore.scroll?.scrollLeft + menuStore.scroll?.clientWidth
          );
        }}
        disabled={
          menuStore.scroll?.clientWidth === menuStore.scroll?.scrollWidth ||
          menuStore.scroll?.clientWidth +
            Math.ceil(menuStore.scroll?.scrollLeft) >=
            menuStore.scroll?.scrollWidth
        }
        style={{
          top:
            menuStore.scroll?.scrollTop > tableRef.current?.offsetTop + 70
              ? 0
              : (tableRef.current?.offsetTop
                  ? tableRef.current?.offsetTop
                  : 118) +
                50 -
                (menuStore.scroll?.scrollTop ? menuStore.scroll?.scrollTop : 0)
        }}
      >
        <img src={iconNext} />
      </button>
    </div>
  );
};

export default observer(ScrollButton);
