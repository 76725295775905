import styles from "./staffOneStatusesTabs.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonTheme } from "shared/ui/Button";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";
import { StatusesList } from "stores/BuildingModule/types/StatusesList";
import { useParams } from "react-router-dom";

type StaffOneStatusesTabsProps = {
  changeSelectedStatus: (arg: string) => void;
};

const StaffOneStatusesTabs = ({
  changeSelectedStatus
}: StaffOneStatusesTabsProps) => {
  const { staffOneStatusesStore } = useStores();
  const { id } = useParams();

  return (
    <div className={styles.tabs}>
      {staffOneStatusesStore.isViolator[id] ? (
        <Button theme={ButtonTheme.TAB} selected>
          <div className={styles.button}>
            <StatusIcon
              icon={
                staffOneStatusesStore.statusesParams[StatusesList.DISMISS]
                  ?.custom?.icon
              }
              color={
                staffOneStatusesStore.selectedStatus !== StatusesList.DISMISS
                  ? staffOneStatusesStore.statusesParams[StatusesList.DISMISS]
                      ?.custom.color_api
                  : "default"
              }
            />
            {staffOneStatusesStore.statusesParams[StatusesList.DISMISS]?.title}
          </div>
        </Button>
      ) : !isEmpty(staffOneStatusesStore.statusesParams) ? (
        getEntries(staffOneStatusesStore.statusesParams).map(
          ([key, status]) => {
            const handleSetSelectedStatus = () => changeSelectedStatus(key);

            if (
              !staffOneStatusesStore.statusesParams[
                staffOneStatusesStore.lastStatus["type"]
              ]?.custom?.ignore_transit_cols_bool
                ? status.custom.choice &&
                  staffOneStatusesStore.showStatuses.includes(key)
                : true
            ) {
              return (
                <Button
                  key={key}
                  theme={ButtonTheme.TAB}
                  selected={staffOneStatusesStore.selectedStatus === key}
                  onClick={handleSetSelectedStatus}
                >
                  <div className={styles.button}>
                    <StatusIcon
                      icon={status.custom?.icon}
                      color={
                        staffOneStatusesStore.selectedStatus !== status.id
                          ? status.custom.color_api
                          : "default"
                      }
                    />
                    {status.title}
                  </div>
                </Button>
              );
            }
          }
        )
      ) : (
        ""
      )}
    </div>
  );
};
export default observer(StaffOneStatusesTabs);
