import { makeAutoObservable, runInAction } from "mobx";
import { InvitesListItem } from "../types/InvitesListItem";
import RootStore from "stores";
import { Company } from "stores/StaffModule/types/Company";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { cloneDeep, isEmpty } from "lodash";
import { FriendInviteSelects } from "../types/FriendInviteSelects";
import { Param } from "stores/utils/types/Param";
import { Col } from "stores/utils/types/Col";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

export type FieldsGroups = Readonly<
  ["status", "time_create", "candidate", "uc", "pay"]
>;
export const groups: Readonly<Record<FieldsGroups[number], string[]>> = {
  status: ["status", "pay"],
  time_create: ["time_create", "author"],
  candidate: ["candidate_surname", "candidate_name", "candidate_birthday"],
  uc: ["uc", "uc_date"],
  pay: ["pay_sum", "pay_date"]
};
type StaffListItem = {
  uid: string;
  surname: string;
  name: string;
  patronymic: string;
  id: string;
};

export default class FriendInviteOneStore {
  initialState: typeof this;
  error = false;
  isLoading = false;
  errorMessage = "";

  //Статичные данные и настройки
  cols: Record<string, Col> = {};
  tableParams: Record<string, Param> = {};
  selects: Partial<FriendInviteSelects> = {};
  companies: { [company_id: string]: Company } = {};

  // состояние выпадающих списков для таблицы
  openedList = "";

  selectedOne: Partial<InvitesListItem> = {};
  openedAllInvites: Record<string, Partial<InvitesListItem>> = {};
  formikValues: Record<string, Record<string, string | number>> = {};
  currentTitles: string[] = [];
  requiredFields: string[] = [];

  // выпадающий список сотрудников
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;
  staffList: {
    title: string;
    newname: string;
    tn: string;
  }[] = [];
  selectedStaff: StaffListItem;
  changedStaff: Record<string, unknown> = {};
  isOpenedList = "";

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    this.initialState = cloneDeep(this);
    makeAutoObservable(this);
  }

  resetAll = () => {
    Object.assign(this, this.initialState);
  };

  setSelectedOneInvite = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (isEmpty(this.rootStore.menuStore.allWindows)) {
      this.rootStore.menuStore.addWindow("/friendinvite", "Приведи друга");
    }

    if (!this.rootStore.menuStore.allWindows[`/friendinvite/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/friendinvite/id=${id}`,
        "Загрузка..."
      );
      delete this.openedAllInvites[id];
    }

    if (!isEmpty(this.openedAllInvites[id])) {
      if (!isEmpty(this.openedAllInvites[id])) {
        this.selectedOne = this.openedAllInvites[id];
      } else {
        runInAction(() => (this.error = true));
      }
      runInAction(() => (this.isLoading = false));
    } else {
      Promise.all([
        isEmpty(this.companies) && this.getCompanies(),
        isEmpty(this.cols) && this.getCols(),
        isEmpty(this.tableParams) && this.getTableParams(),
        isEmpty(this.selects) && this.getSelects()
      ]).then(() => this.getOneInviteFriendData(id));
    }
  };

  getOneInviteFriendData = async (id: string) => {
    this.isLoading = true;
    this.error = false;

    try {
      const data: ApiResponse<InvitesListItem> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getOne",
          params: { id }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.selectedOne = data.result;

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: `${this.selectedOne["candidate_surname"] || ""} ${
              this.selectedOne["candidate_name"] || ""
            }`
          });

          this.openedAllInvites[id] = data.result;
          this.getFormikValues(id);
        } else {
          this.openedAllInvites[id] = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/friendinvite/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getCompanies = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach((company) => {
            this.companies[company.id] = company;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<FriendInviteSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCols = async () => {
    try {
      const data: ApiResponse<Record<string, Col>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cols = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.tableParams = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getFormikValues = (id: string) => {
    this.formikValues[id] = {};
    getEntries(this.cols).forEach(([title, col]) => {
      if (col.hidden !== "on" && this.tableParams[title]?.editable) {
        if (["uid", "candidate_tn"].includes(title)) {
          this.formikValues[id][title] = this.selectedOne[title]?.id || null;
          this.formikValues[id][`${title}_full`] = this.selectedOne[title];
        } else this.formikValues[id][title] = this.selectedOne[title];
      }
    });
  };

  getStaffList = async (title: string, isMore = false, isHot = false) => {
    this.isLoadingForStaffList = true;

    if (!isMore) {
      this.staffList = [];
      this.selectedStaff = undefined;
    }

    const params: Record<string, string> = {};

    if (this.searchValueStaff) {
      params.fast_search = this.searchValueStaff;
    }

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPageStaff,
        page: this.pageStaff,
        params
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          const allStaff = getValues(data.records);
          let staffList = this.formatStaffList(allStaff);
          if (!isMore && !params.fast_search && this.selectedOne?.[title]?.id) {
            Promise.all([
              (isHot || !this.changedStaff[this.rootStore.menuStore.tabId]) &&
                this.getOneOfStaff(this.selectedOne?.[title]?.id)
            ]).then(() => {
              runInAction(() => {
                if (
                  (!isHot &&
                    this.changedStaff[this.rootStore.menuStore.tabId]) ||
                  !isEmpty(this.selectedStaff)
                ) {
                  staffList.push(
                    this.formatStaffList([
                      this.changedStaff[this.rootStore.menuStore.tabId] ||
                        this.selectedStaff
                    ])[0]
                  );
                }
                staffList = this.getUniqueStaffList(staffList);
                this.staffList = staffList;

                this.maxPageStaff = data.nav.max_page;
                this.isLoadingForStaffList = false;
              });
            });
          } else {
            runInAction(() => {
              staffList = this.getUniqueStaffList(staffList);

              if (isMore) {
                this.staffList = this.staffList.concat(staffList);
              } else {
                this.staffList = staffList;
              }

              this.staffList = this.getUniqueStaffList(this.staffList);

              this.prevPageStaff = this.pageStaff;
              this.isLoadingForStaffList = false;
            });
          }
        } else {
          runInAction(() => {
            this.staffList = [];
            this.isLoadingForStaffList = false;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
        this.isLoadingForStaffList = false;
      });
    }
  };

  getOneOfStaff = async (id: string) => {
    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: 1,
        params: { "filter[id]": id }
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          const staff = getValues(data.records)[0];
          this.selectedStaff = {
            uid: staff.uid,
            surname: staff.surname,
            name: staff.name,
            patronymic: staff.patronymic,
            id: staff.id
          };
        } else this.selectedStaff = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  formatStaffList = (
    staffList:
      | Partial<StaffListItem>[]
      | {
          newname: string;
          title: string;
          tn: string;
        }[]
  ) =>
    staffList.map((staff) => {
      if (staff?.newname) return staff;

      if (staff?.id)
        return {
          newname: staff.id,
          title: `ТН${staff.uid} ${staff.surname}${
            staff.surname ? " " + staff.name : staff.name
          }${staff.patronymic ? " " + staff.patronymic : ""}`,
          tn: staff.uid
        };
    });

  getUniqueStaffList = (
    staffList: {
      newname: string;
      title: string;
      tn: string;
    }[]
  ) => {
    const uniqueStaffMap = new Map();
    staffList.forEach((staff) => {
      staff && uniqueStaffMap.set(staff.newname, staff);
    });
    return Array.from(uniqueStaffMap.values());
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setIsOpenedList = (value: string) => {
    this.isOpenedList = value !== this.isOpenedList ? value : "";
  };

  handleSubmitForm = async (values: Record<string, string | number>) => {
    const formData: Record<string, string | number> = {};
    getEntries(values).map(async ([key, value]) => {
      if (key.includes("full")) return;

      if (value !== this.formikValues[this.rootStore.menuStore.tabId]?.[key]) {
        if (key.includes("_full")) return;
        formData[`update[${key}]`] = ["uid", "candidate_tn"].includes(key)
          ? values[`${key}_full`]?.["tn"] || ""
          : value === null ? "" : value;
      }
    });

    await this.editCol(this.rootStore.menuStore.tabId, formData);
    await this.getOneInviteFriendData(this.rootStore.menuStore.tabId);
  };

  editCol = async (id: string, values: Record<string, string | number>) => {
    this.changedStaff[id] && delete this.changedStaff[id];
    try {
      const data: ApiResponse<never> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "friendinvite",
        method: "editCol",
        body: { id, ...(values || {}) }
      });
      if (data.result) {
        this.getOneInviteFriendData(id);
      } else {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setOpenedList = (value: string) => {
    if (value.includes("_tn") && this.openedList === value) {
      this.setSearchValueStaff("");
    }
    this.openedList = this.openedList !== value ? value : "";
  };

  setChangedStaff = (id: string, staff: unknown) => {
    isEmpty(staff)
      ? this.changedStaff[id] && delete this.changedStaff[id]
      : (this.changedStaff[id] = staff);
  };
}
