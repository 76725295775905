import styles from "./menuSection.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

type IMenuItem = {
  to: string;
  submoduleName: string;
  moduleName: string;
  text: string;
  defaultIcon: string;
  selectedIcon: string;
  label?: string;
  alt?: string;
};

type MenuSectionProps = {
  sectionTitle: string;
  children: React.ReactElement<IMenuItem>[];
};

const MenuSection = ({ sectionTitle, children }: MenuSectionProps) => {
  const { menuStore } = useStores();

  return (
    <div className={styles.section}>
      {sectionTitle ? (
        <h6
          className={`${styles.section__title} ${
            !menuStore.isMenuShown && styles.section__collapsedTitle
          }`}
        >
          {sectionTitle}
        </h6>
      ) : (
        ""
      )}

      <ul className={styles.list}>
        {children && children.length > 1 ? (
          children.map(
            (child: React.ReactElement<IMenuItem>, index: number) => {
              if (!child) return null;
              return (
                //Убрать index, когда будет убран тултип с раздела Отпуск
                <li key={child.props.to || index} className={styles.list__item}>
                  {child}
                </li>
              );
            }
          )
        ) : (
          <li className={styles.list__item}>{children && children}</li>
        )}
      </ul>
    </div>
  );
};

export default observer(MenuSection);
