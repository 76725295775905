import styles from "./friendInviteSelects.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { classNames } from "shared/utils/helpers/classNames";
import List from "shared/ui/List";
import { useRef } from "react";
import FriendInviteSelectsForm from "./FriendInviteSelectsForm";
import LoaderSpinner from "shared/ui/LoaderSpinner";

type FriendInviteSelectsProps = {
  id: string;
  title: string;
};
const FriendInviteSelects = ({ id, title }: FriendInviteSelectsProps) => {
  const { friendInviteStore } = useStores();

  const referenseRef = useRef<HTMLTableDataCellElement>();
  const isOpenedList = friendInviteStore.openedList === `${id}_${title}`;
  const handleOpenedList = (value: boolean) => {
    friendInviteStore.setOpenedList(value ? `${id}_${title}` : "");
  };
  const handleClickOnCell = () => {
    if (
      friendInviteStore.isLoadingCol !== `${title}_${id}` &&
      friendInviteStore.openedList !== `${id}_${title}`
    ) {
      handleOpenedList(true);
    }
    if (
      event.target["dataset"]["type"] !== "list" &&
      event.target["dataset"]["list"] !== "true" &&
      event.target["dataset"]["type"] !== "list-search"
    ) {
      handleOpenedList(true);
    }
  };

  return (
    <td
      className={classNames(styles.td, {
        [styles.tdActive]: isOpenedList
      })}
      data-type="list"
      onClick={handleClickOnCell}
      ref={referenseRef}
    >
      {friendInviteStore.isLoadingCol === `${title}_${id}` ? (
        <LoaderSpinner color="bw-gray5" size="small" />
      ) : (
        <FriendInviteSelectsForm id={id} title={title} />
      )}
      {isOpenedList && (
        <List
          options={friendInviteStore.selects[title] || {}}
          value={
            (friendInviteStore.updatedInvites[id] ||
              friendInviteStore.invitesList[id])?.[title] || ""
          }
          setIsOpenedMenu={handleOpenedList}
          isOpenedMenu={isOpenedList}
          referenceRef={referenseRef}
          setSelectedItem={(item) => {
            friendInviteStore.editCol(id, {
              [`update[${title}]`]: item?.id === null ? "" : item?.id
            });

            setTimeout(() => {
              handleOpenedList(false);
            }, 10);
          }}
          isSearchable
          valueName="id"
        />
      )}
    </td>
  );
};

export default observer(FriendInviteSelects);
