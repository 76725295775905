import * as yup from "yup";
import { object, string } from "yup";
import { getYear } from "date-fns";
import { stringSchema } from "shared/utils/validation/validation";
import { without } from "lodash";

export const validationConvictionForm = object({
  article: stringSchema,
  date_start: yup
    .date()
    .typeError("Некорректный формат даты")
    .max(new Date(), "Дата получения не может быть больше текущей даты")
    .min(
      getYear(new Date()) - 70 + 14,
      `Дата получения не может быть раньше ${
        getYear(new Date()) - 70 + 14
      } года`
    )
    .nullable(),
  punishment: stringSchema,
  term_type: stringSchema,
  comment: stringSchema
});

export const getValidationConvictionSchema = (
  cols: string[],
  requiredCols: string[]
) => {
  const filteredCols = without(requiredCols, "uid", "id", "author", "time_create")
  const validationSchema = {};
  
  cols.map((col) => {
    validationSchema[col] = validationConvictionForm[col]
      ? validationConvictionForm[col]
      : string().nullable();

    if (filteredCols.includes(col)) {
      validationSchema[col] = validationConvictionForm[col]
        ? validationConvictionForm[col]?.required(
            "Поле обязательно для заполнения"
          )
        : string().nullable()?.required("Поле обязательно для заполнения");
    }
  });
  return object(validationSchema);
};
