import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { cloneDeep } from "lodash";

import { Col } from "stores/StaffModule/types/Col";
import { FilterType } from "stores/utils/types/FilterType";
import { NavType } from "stores/utils/types/NavType";
import { Payment } from "../types/Payment";
import { PaymentStatus } from "../types/PaymentStatus";
import { PaymentType } from "../types/PaymentType";
import { ApiResponse } from "stores/utils/types/ApiResponse";

interface ICol extends Omit<Col, "directory"> {
  directory:
    | { [key: string]: { newname: string; title: string } }
    | { newname: string; title: string }[];
}

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

type PaymentList = {
  code: number;
  nav: {
    count: number;
    now_page: number;
    on_page: number;
    max_page: number;
  };
  result: { [key: string]: Payment };
  cols: { [key: string]: ICol };
  show_fields: { [key: string]: string };
};

export default class SalaryPaymentlistAllStore {
  error = false;
  isLoading = false;
  isLoadingForFilters = false;
  errorMessage = "";

  page = 1;
  onPage = 100;
  maxPage = 0;
  prevPage = 1;
  downloadedFilter = "";

  openedFilter = false;

  currentTitles: string[] = [];
  salaryPaymentTableCols: Record<string, ICol> = {};
  salaryPaymentParam: Record<string, ICol> = {};

  paymentList: Payment[] = [];

  searchValue = "";
  foundCounter = 0;
  selectedFilter = "";
  filterParams: { [key: string]: string[] | string | number } = {};
  queryParams: { [key: string]: string } = {};
  defaultFilters: { [key: string]: string[] } = {};

  // индикатор загрузки параметров из URL, для предотвращения лишнего перерендера
  isSettingParamsFromURL = false;

  // объект, в котором хранится не сохраненный фильтр
  filtersChanged: { [key: string]: Partial<FilterType> } = {};

  // фильтры-кнопки по типам и статусам
  buttonFilter: { [key: string]: string[] } = {};
  buttonFilterMode: ["type", "status"] = ["type", "status"];

  // справочники для таблицы ведомостей
  selects: Partial<{
    type: {
      [key: string]: PaymentType;
    };
    status: {
      [key: string]: PaymentStatus;
    };
    [select_name: string]: { [key: string]: Col };
  }> = {};

  // поля для работы со выпадающим списком Сотрудник в фильтре
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  // поля для работы со выпадающим списком Объект в фильтре
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  // поля для работы со выпадающим списком Автор в фильтре
  pageAuthor = 1;
  onPageAuthor = 30;
  maxPageAuthor = 0;
  prevPageAuthor = 1;
  searchValueAuthor = "";
  isLoadingForAuthorList = false;

  // список в виде пары id - title
  // нужен для вывода title в компоненте SelectMulti и FilterMainWindow
  dictForFilter: Record<string, string> = {};
  filters: Record<string, FilterType> = {};
  dateRangeFields: Record<string, string[]> = {};
  rootStore: RootStore;

  // предустановленные фильтры хардкодим на фронте
  generalFilters: Record<string, FilterType> = {
    filter_default: {
      cols: [],
      default: 1,
      filter: {},
      general: 1,
      id: "filter_default",
      order: "time_create",
      ordered: "DESC",
      title: "Весь список"
    }
  };

  resetAll = () => {
    this.paymentList = [];
    this.error = false;
    this.errorMessage = "";
    this.searchValue = "";
    this.onPage = 100;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.filters = {};
    this.filterParams = {};
    this.foundCounter = 0;
    this.selects = {};
    this.salaryPaymentTableCols = {};
    this.salaryPaymentParam = {};
    this.currentTitles = [];
  };

  getNewFilterParams = (filter?: FilterType) => {
    if (filter) {
      this.filterParams = {};
      Object.entries(filter.filter).forEach(([key, value]) => {
        // этот блок if при пустом массиве статуса или типа
        // устанавливает в filterParams значения по умолчанию
        // TODO: значения по умолчанию должны быть и в формике фильтра тоже
        if (Array.isArray(value) && !value?.length) {
          this.buttonFilterMode.map((name) => {
            if (name === key) {
              switch (name) {
                case "type":
                  Object.values(this.selects[key]).forEach((status) =>
                    status.custom?.pl_type === "object"
                      ? (this.filterParams[`filter[${key}]`] = status.id)
                      : ""
                  );
                  break;
                case "status":
                  this.filterParams[`filter[${key}]`] = Object.keys(
                    this.selects[key]
                  );
                  break;
              }
            }
          });
        }

        if (
          (typeof value === "string" && value) ||
          (Array.isArray(value) && value.length) ||
          typeof value === "number"
        ) {
          this.filterParams[`filter[${key}]`] = value as string;
        } else if (typeof value === "object" && !Array.isArray(value)) {
          this.filterParams[`filter[${key}]`] = Object.values(value);
        }
      });
    } else {
      Object.entries(this.buttonFilter).forEach(([filter_name, value]) => {
        this.filterParams[`filter[${filter_name}]`] = value;
      });

      this.filters?.filter_default?.cols?.forEach((item) => {
        this.filterParams[`show[${item}]`] = "on";
      });
    }
  };

  getPaymentListWithFilter = (filter?: FilterType) => {
    this.filterParams = {};
    this.setPage(1);

    if (!filter?.default) {
      this.getNewFilterParams(filter);
    } else {
      this.buttonFilterMode.forEach((filter_name) => {
        this.buttonFilter[filter_name] =
          this.defaultFilters[`filter[${filter_name}]`];

        this.filterParams[`filter[${filter_name}]`] =
          this.defaultFilters[`filter[${filter_name}]`];
      });
    }
    this.setDownloadedFilter(filter?.["id"] || "");
    this.getPaymentList();
  };

  getFilters = async (action?: string, id?: string) => {
    try {
      const data: ApiResponse<{
        savedFilter: { [key: string]: { filterdata: FilterType } };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "getFilter",
        params: { module: "salary", prefix: "paymentlist" }
      });
      runInAction(() => {
        // проверяем на наличие новых фильтров, если сохраненные в сторе фильтры равны количеству приходящих с бэка плюс 1
        // (один мы прописываем хардкодом на фронте) или же передан action на добавление/редактирование/удаление
        // и код двухсотый, то обновляем поле this.filters
        if (
          data["code"] === 200 &&
          (Object.keys(this.filters).length !==
            Object.keys(data["savedFilter"]).length + 1 ||
            action)
        ) {
          this.filters = {};
          this.filters = cloneDeep(this.generalFilters);
          Object.entries(data["savedFilter"]).forEach(([key, filter]) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["id"] = key;
            this.filters[key]["filter"] = filter["filterdata"]?.["filter"]
              ? filter["filterdata"]?.["filter"]
              : {};

            Object.entries(this.dateRangeFields).forEach(
              ([keyRange, valueRange]) => {
                if (
                  valueRange.every(
                    (field) => field in this.filters[key]["filter"]
                  )
                ) {
                  this.filters[key]["filter"][keyRange] = "";
                }
              }
            );
          });

          Object.values(this.filters).forEach((filter) => {
            if (action) {
              switch (action) {
                case "add":
                  this.setSelectedFilter("new");
                  break;
                case "delete":
                  if (filter.default) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
                case "edit":
                  if (id === filter.id) {
                    this.setSelectedFilter(filter.id);
                  }
                  break;
              }
            }
          });
          Object.values(this.filters).forEach((filter) => {
            if (
              !this.downloadedFilter &&
              filter.default &&
              !Object.keys(this.filterParams).length
            ) {
              this.setDownloadedFilter(filter.id);
            }
          });

          this.filtersChanged = cloneDeep(this.filters);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
      this.filtersChanged[currentFilter][key] = value;
    } else {
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: []
      };
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
    this.filtersChanged[currentFilter][key] = value;
  };

  getPaymentListData = (action?: string, id?: string) => {
    Promise.all([
      !Object.values(this.selects.type || {}).length && this.getPaymentTypes(),
      !Object.values(this.selects.status || {}).length &&
        this.getPaymentStatuses()
    ]).then(() => {
      this.defaultFilters["filter[status]"] = Object.keys(this.selects.status);
      this.defaultFilters["filter[type]"] = [
        Object.values(this.selects.type).find(
          (type) => type.custom?.pl_type === "object"
        )?.id || ""
      ];

      this.buttonFilterMode.forEach((filter_name) => {
        if (!this.buttonFilter[filter_name]?.length) {
          this.buttonFilter[filter_name] =
            this.defaultFilters[`filter[${filter_name}]`];
          this.filterParams[`filter[${filter_name}]`] =
            this.defaultFilters[`filter[${filter_name}]`];
        }
      });

      this.getPaymentList(action, id);
    });
  };

  getPaymentList = async (action?: string, id?: string) => {
    this.page = 1;
    this.errorMessage = "";
    this.isLoading = true;

    let params = {
      fast_search: this.searchValue,
      ...this.filterParams
    };

    // Если сброшены все фильтры
    if (
      !params.fast_search.length &&
      (!params["filter[type]"] || !params["filter[status]"])
    ) {
      // то, удаляем fast_search из параметров
      delete params.fast_search;
      // и устанавливаем фильтры по умолчанию
      params = {
        ...params,
        ...this.defaultFilters
      };
    }
    // если у нас есть быстрый поиск
    else if (params.fast_search.length) {
      // то удаляем всё, кроме быстрого поиска (согласовано с Игорем только для ведомостей)
      params = { fast_search: this.searchValue };
    }

    try {
      const data: PaymentList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getPaymentList",
        on_page: this.onPage,
        page: this.page,
        params
      });

      runInAction(() => {
        if (Object.values(data["cols"]).length) {
          this.salaryPaymentTableCols = data["cols"];
          Object.entries(data["cols"]).forEach(([key, value]) => {
            if (value.useAsFilter === "on") {
              this.salaryPaymentParam[key] = value;
            }
            if ("range" in value) {
              this.dateRangeFields[key] = Object.keys(value["range"]);
            }
          });

          this.setTypeAndStatusDirectory();
          Promise.all([
            this.getBuildingList(),
            this.getStaffList(),
            this.getAuthorList()
          ]);
        }

        this.getFilters(action, id);

        if (Object.values(data["result"]).length) {
          this.paymentList = Object.values(data["result"]);
        } else {
          this.paymentList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitles = Object.values(data["show_fields"]);
        } else {
          this.currentTitles = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.page = this.prevPage;
        this.maxPage = data["nav"]["max_page"];
        this.foundCounter = data["nav"]["count"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  getMorePremiumList = async () => {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const data: PaymentList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getPaymentList",
        on_page: this.onPage,
        page: this.page,
        params: {
          fast_search: this.searchValue,
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data.result).length) {
          this.paymentList.push(...Object.values(data.result));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.paymentList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  setTypeAndStatusDirectory = () => {
    this.buttonFilterMode.forEach((mode) => {
      if (mode in this.salaryPaymentParam) {
        const directory: {
          [key: string]: { newname: string; title: string };
        } = {};
        Object.values(this.selects[mode] || {}).forEach((value) => {
          directory[value.id] = {
            newname: value.id,
            title: value.title
          };
        });
        this.salaryPaymentParam[mode] = {
          ...this.salaryPaymentParam[mode],
          directory: directory
        };
      }
    });
  };

  getPaymentTypes = async () => {
    try {
      const data: {
        code: number;
        result: { [key: string]: PaymentType };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        currentClass: "paymentlist_types",
        method: "getList"
      });

      runInAction(() => {
        if (data.code === 200) {
          this.selects.type = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getPaymentStatuses = async () => {
    try {
      const data: ApiResponse<{ [key: string]: PaymentStatus }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "paymentlist_status",
          method: "getList"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects.status = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // * STAFF LIST

  // метод для создания списка Сотрудников в выпадающем окне фильтров
  dataPreparationStaff = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj["newname"] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: {
        result: Record<string, StaffListItem>;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "fastSearch",
        on_page: this.onPageStaff,
        page: this.pageStaff,
        params: {
          fast_search: this.searchValueStaff
        }
      });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.salaryPaymentParam["staff"] = {
            ...this.salaryPaymentParam["staff"],
            directory: this.dataPreparationStaff(Object.values(data["result"]))
          };
        } else {
          this.error = true;
          this.salaryPaymentParam["staff"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: {
        result: { [key: string]: StaffListItem };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "fastSearch",
        on_page: this.onPageStaff,
        page: this.pageStaff,
        params: {
          fast_search: this.searchValueStaff
        }
      });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          const directory = (
            this.salaryPaymentParam["staff"]["directory"] as {
              newname: string;
              title: string;
            }[]
          ).concat(this.dataPreparationStaff(Object.values(data["result"])));
          this.salaryPaymentParam["staff"] = {
            ...this.salaryPaymentParam["staff"],
            directory: directory
          };
        } else {
          this.salaryPaymentParam["staff"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  // метод для создания списков в выпадающем окне фильтров
  dataPreparation = (data: { id: string; title: string }[]) => {
    return data.map((oneOfObject) => {
      const tempOneOfObject = {} as { title: string; newname: string };
      Object.entries(oneOfObject).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfObject["newname"] = value;
            break;

          default:
            tempOneOfObject[key] = value;
        }
      });
      return tempOneOfObject;
    });
  };

  // * BUILDING LIST

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryPaymentTableCols) {
            this.salaryPaymentParam["object"] = {
              ...this.salaryPaymentParam["object"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.salaryPaymentParam["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.salaryPaymentParam["object"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  //метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryPaymentParam) {
            const directory = (
              this.salaryPaymentParam["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (object) => !(object["id"] in this.dictForFilter)
                )
              )
            );

            this.salaryPaymentParam["object"] = {
              ...this.salaryPaymentParam["object"],
              directory: directory
            };
          }
        } else {
          this.salaryPaymentParam["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов по их id из сохраненных фильтров и последующее их добавление в справочник
  getBuildingDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        params: formDataFromSavedFilter,
        select: ["id", "title"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["title"];
          });
          if ("object" in this.salaryPaymentParam) {
            const directory = (
              this.salaryPaymentParam["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));
            this.salaryPaymentParam["object"] = {
              ...this.salaryPaymentParam["object"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  // * AUTHOR LIST

  // метод для получения авторов в выпадающем окне фильтра
  getAuthorList = async () => {
    this.isLoadingForAuthorList = true;

    const formData: Record<string, string> = {
      "filter[where][name][value]": this.searchValueAuthor,
      "filter[where][name][comparison]": "LIKE",
      "filter[where][name][logic]": "AND"
    };

    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formData,
        select: ["id", "name"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("author" in this.salaryPaymentParam) {
            this.salaryPaymentParam["author"] = {
              ...this.salaryPaymentParam["author"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.salaryPaymentParam["author"]["directory"] = [];
          this.isLoadingForAuthorList = false;
        }
        this.maxPageAuthor = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.salaryPaymentParam["author"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForAuthorList = false;
      });
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreAuthorList = async () => {
    this.isLoadingForAuthorList = true;

    const formData: Record<string, string> = {
      "filter[where][name][value]": this.searchValueAuthor,
      "filter[where][name][comparison]": "LIKE",
      "filter[where][name][logic]": "AND"
    };

    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formData,
        select: ["id", "name"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("author" in this.salaryPaymentParam) {
            const directory = (
              this.salaryPaymentParam["author"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (author) => !(author["id"] in this.dictForFilter)
                )
              )
            );

            this.salaryPaymentParam["author"] = {
              ...this.salaryPaymentParam["author"],
              directory: directory
            };
          }
        } else {
          this.salaryPaymentParam["author"]["directory"] = [];
          this.isLoadingForAuthorList = false;
        }
        this.prevPageAuthor = this.pageAuthor;
      });
    } catch (error) {
      runInAction(() => {
        this.salaryPaymentTableCols["author"]["directory"] = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForAuthorList = false));
    }
  };

  // метод для получения авторов по их id из сохраненных фильтров и последующее их добавление в справочник
  getAuthorDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: {
        result: Record<string, { id: string; title: string }> | -1;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formDataFromSavedFilter,
        select: ["id", "name"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["name"];
          });
          if ("author" in this.salaryPaymentTableCols) {
            const directory = (
              this.salaryPaymentTableCols["author"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));

            this.salaryPaymentTableCols["author"] = {
              ...this.salaryPaymentTableCols["author"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  setSearchValueAuthor = (value: string) => {
    this.setPageAuthor(1);
    this.prevPageAuthor = 1;
    this.searchValueAuthor = value;
  };

  setPageAuthor = (value: number) => {
    if (!this.isLoadingForAuthorList) {
      this.pageAuthor = value;
    }
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;

    if (value?.length) {
      this.buttonFilterMode.forEach(
        (filter_name) =>
          (this.buttonFilter[filter_name] = Object.keys(
            this.selects[filter_name] || {}
          ))
      );
    } else {
      this.buttonFilterMode.forEach((filter_name) => {
        this.buttonFilter[filter_name] =
          this.defaultFilters[`filter[${filter_name}]`];

        this.filterParams[`filter[${filter_name}]`] =
          this.defaultFilters[`filter[${filter_name}]`];
      });
    }
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setFilterParamsFromQuery = (value: { [key: string]: string }) => {
    if (value) {
      this.filterParams = value;

      setTimeout(() => this.setIsSettingParamsFromURL(false), 200);
    }
  };

  setQueryParams = (value: { [key: string]: string }) => {
    const params: { [key: string]: string[] } = {};

    Object.entries(value || {}).forEach(([key, value]) => {
      this.buttonFilterMode.forEach((filter_name) => {
        if (key.includes(`[${filter_name}]`)) {
          params[filter_name] ?? (params[filter_name] = []);

          params[filter_name].push(value);
        }
      });
    });

    Object.entries(params).forEach(([filter_name, value]) => {
      this.buttonFilter[filter_name] = value;
    });

    this.queryParams = value.fast_search?.length
      ? { fast_search: value.fast_search }
      : value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setDictForArray = (value: Record<string, string>) => {
    this.dictForFilter = value;
  };

  setSelectedButtonFilter = (filter: string, value: string[]) => {
    if (this.searchValue?.length) {
      this.setSearchValue("");
      this.rootStore.filterStore.setSearchInputValue("");
    }
    this.buttonFilter[filter] = value;
    this.getPaymentListWithFilter();
  };

  setIsSettingParamsFromURL = (value: boolean) => {
    this.isSettingParamsFromURL = value;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
