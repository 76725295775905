import { makeAutoObservable, runInAction } from "mobx";

import { StaffListItem } from "./types/StaffListItem";
import { Col } from "stores/utils/types/Col";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import RootStore from "stores";
import { StaffList } from "./types/StaffList";
import { cloneDeep, isEmpty } from "lodash";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";

type FilterParams = {
  [key: string]: string | number;
};

type CountWorkshiftResponse = {
  [staffId: string]: number;
};

export default class StaffListStore {
  error = false;
  errorMessage = "";
  isLoading = false;
  isLoadingForFilters = false;

  loadingCountWorkAll: { [staffId: string]: boolean } = {};

  openedFilter = false;

  currentTitles: string[] = [];

  staffTableCols: { [key: string]: Col } = {};
  staffTableParams: { [key: string]: Col } = {};
  staffColsAll: { [key: string]: Col } = {};

  staffList: StaffListItem[] = [];

  staffListOrder = "";
  staffListOrdered = "";
  searchValue = "";
  filterParams: FilterParams | { [key: string]: string[] } = {};
  queryParams: { [key: string]: string } = {};

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;
  filters: { [key: string]: FilterType } = {};
  filtersChanged: { [key: string]: FilterType } = {};
  selectedFilter = "";
  downloadedFilter = "";
  staffFoundCounter = 0;

  // поля для работы со выпадающим списком Объект в фильтре
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  // поля для работы со выпадающим списком Должность в фильтре
  pagePosition = 1;
  onPagePosition = 30;
  maxPagePosition = 0;
  prevPagePosition = 1;
  searchValuePosition = "";
  isLoadingForPositionList = false;

  // Список компаний для передачи в метод по получению Объектов или Должностей
  // будут искаться Объекты/Должности, компании которых находятся в этом массиве
  companyListForBuilding: string[] = [];
  companyListForPosition: string[] = [];

  // список в виде пары id - title
  // нужен для вывода title в компоненте SelectMulti и FilterMainWindow
  dictForArray: Record<string, string> = {};

  // массивы, которые показывают какие поля не являются колонками и не явлюятся фильтрами
  // пока хардкод, так как у бэка пока нет настроек для этого
  notShowAsColumnList = [
    "company",
    "position",
    "event_type",
    "building",
    "count_work_all"
  ];

  actionButton = {
    0: {
      id: 1,
      title: "OK"
    }
  };

  resetAll = () => {
    this.staffListOrder = "";
    this.staffListOrdered = "";
    this.onPage = 100;
    this.page = 1;
    this.maxPage = 0;
    this.prevPage = 1;
    this.searchValue = "";
    this.filters = {};
    this.filterParams = {};
    this.staffTableCols = {};
    this.staffTableParams = {};
    this.staffColsAll = {};
    this.currentTitles = [];
    this.staffList = [];
  };

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    Object.entries(filter.filter).length &&
      Object.entries(filter.filter).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          switch (key) {
            case "phone": {
              if (typeof value === "string") {
                const newPhone = value.replace(/[\D]+/g, "");
                this.filterParams[`filter[${key}]`] = newPhone;
              }
              break;
            }
            case "company":
            case "position":
            case "building": {
              if (Array.isArray(value) && value.length > 0) {
                this.filterParams[`filter[${key}]`] = value as string[];
              } else if (
                typeof value === "object" &&
                getKeys(value).length > 0
              ) {
                this.filterParams[`filter[${key}]`] = getValues(
                  value
                ) as string[];
              } else if (value !== -1 && value !== "") {
                this.filterParams[`filter[${key}]`] = value as string | number;
              }
              break;
            }
            default:
              if (
                value !== -1 &&
                typeof value === "object" &&
                Object.keys(value).length > 0
              ) {
                this.filterParams[`filter[${key}]`] = Object.values(
                  value
                ) as string[];
              } else if (value !== -1 && value !== "") {
                this.filterParams[`filter[${key}]`] = value as string | number;
              }
              break;
          }
        }
      });

    filter.cols.forEach((item) => {
      this.filterParams[`show[${item}]`] = "on";
    });
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};
    if (this.staffListOrder) {
      params["order"] = this.staffListOrder;
    }
    if (this.staffListOrdered) {
      params["ordered"] = this.staffListOrdered;
    }
    if (this.searchValue) {
      params["fast_search"] = this.searchValue;
    }

    return params;
  };

  getStaffListWithFilter = (filter: FilterType) => {
    this.filterParams = {};
    this.setPage(1);
    if (!filter.default) {
      this.setStaffListOrder(filter.order);
      this.setStaffListOrdered(filter.ordered);
      this.getNewFilterParams(filter);
    } else {
      this.setStaffListOrder("");
      this.setStaffListOrdered("");
      this.filterParams = {};
    }

    this.setDownloadedFilter(filter["id"]);
    this.getStaffData();
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setFilterParamsFromQuery = (
    value: FilterParams | { [key: string]: string[] }
  ) => {
    this.filterParams = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setStaffListOrder = (value: string) => {
    this.staffListOrder = value;
  };

  setStaffListOrdered = (value: string) => {
    this.staffListOrdered = value;
  };

  setOpenedFilter = (value: boolean) => {
    this.openedFilter = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setErrorMessage = (value: string) => {
    this.errorMessage = value;
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter")) {
        const filterKey = key.slice(7);
        this.filtersChanged[currentFilter]["filter"][filterKey] =
          value as FilterType["filter"][string];
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    } else {
      const defaultArray = Object.keys(this.staffColsAll).filter(
        (col) => !Object.keys(this.staffTableCols).includes(col)
      );
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: this.currentTitles.filter((col) => !defaultArray.includes(col)),
        order: "time_create",
        ordered: "ASC"
      };
      if (key.includes("filter")) {
        const filterKey = key.slice(7);
        this.filtersChanged[currentFilter]["filter"][filterKey] =
          value as FilterType["filter"][string];
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
  };

  getMoreStaff = async () => {
    this.errorMessage = "";

    runInAction(() => (this.isLoading = true));
    try {
      const data: {
        records: { [key: string]: StaffListItem };
        show_fields: { [key: string]: string };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          only_records: 1,
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (!isEmpty(data["records"])) {
          const newStaffList = this.dataPreparation(getValues(data["records"]));
          this.staffList.push(...newStaffList);
          this.rootStore.menuStore.isScrollBottom = false;
          this.prevPage = this.page;

          // Получаем все ID сотрудников
          const staffIds = newStaffList.map((staff) => staff.id);

          // Запрашиваем количество смен
          this.getCountWorkshift(staffIds).then((counts) => {
            runInAction(() => {
              this.staffList = this.staffList.map((staff) => ({
                ...staff,
                count_work_all: counts[staff.id] || 0
              }));
            });
          });
        } else {
          this.staffList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  getCountWorkshift = async (
    staffIds: string[]
  ): Promise<CountWorkshiftResponse> => {
    if (staffIds.length === 0) return {};

    const params: { [key: string]: string } = {};
    staffIds.forEach((id, index) => {
      params[`staff[${index}]`] = id;
    });

    // Устанавливаем состояние загрузки для каждого staff_id
    runInAction(() => {
      staffIds.forEach((id) => {
        this.loadingCountWorkAll[id] = true;
      });
    });

    try {
      const data: ApiResponse<CountWorkshiftResponse> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "workshift",
          method: "getCountWorkshift",
          params: params
        });
      if (data.result) {
        return data.result;
      } else {
        this.errorMessage = "Ошибка при получении количества смен";
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.errorMessage = "Не удалось загрузить количество смен";
      });
      return {};
    } finally {
      // Сбрасываем состояние загрузки после завершения запроса
      runInAction(() => {
        staffIds.forEach((id) => {
          this.loadingCountWorkAll[id] = false;
        });
      });
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const obj: StaffListItem = { ...oneOfStaff };

      delete obj.count_work_all;

      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (value) {
          case null:
            obj[key] = "";
            break;
          case -1:
            obj[key] = 0;
            break;
          default:
            if (typeof value === "string") obj[key] = getFormattedDate(value);
            else obj[key] = value;
            break;
        }
      });
      return obj;
    });
  };

  getStaffData = async (action?: string, id?: string) => {
    runInAction(() => (this.isLoading = true));
    this.errorMessage = "";
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.staffList = [];
    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          ...this.newParams(),
          ...this.filterParams
        }
      });

      runInAction(() => {
        if (Object.values(data["cols"]).length) {
          Object.entries(data["cols"]).forEach(([key, col]) => {
            if (!this.notShowAsColumnList.includes(key))
              this.staffTableCols[key] = col;
            if (col.useAsFilter === "on") this.staffTableParams[key] = col;
          });
          this.getCompaniesList();
          this.getEventTypeList();
          this.getBuildingList();
          this.getPositionList();

          this.staffColsAll = data["cols"];

          if (Object.keys(data["selects"]).length) {
            Object.entries(data["selects"]).forEach(([key, value]) => {
              if (key in this.staffTableParams) {
                const directory: {
                  [key: string]: { newname: string; title: string };
                } = {};

                Object.values(value).forEach((item) => {
                  directory[item.id ? item.id : item.title] = {
                    newname: item.id ? item.id : item.title,
                    title: item.title
                  };
                });

                this.staffTableParams[key] = {
                  ...this.staffTableParams[key],
                  directory: directory
                };
              }
            });
          }
        }
        if (
          Object.values(data["filters"]).length &&
          (!Object.keys(this.filters).length || action)
        ) {
          this.filters = {};
          Object.entries(data["filters"]).forEach(([key, filter], index) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["cols"] = filter["filterdata"]["cols"]
              ? Object.values(filter["filterdata"]["cols"])
              : [];
            this.filters[key]["id"] = key;
            this.filters[key]["filter"] = this.filters[key]["filter"]
              ? this.filters[key]["filter"]
              : {};

            if (key === this.downloadedFilter) {
              this.staffListOrdered = this.filters[key]["ordered"];
            }

            if (
              this.filters[key]["default"] &&
              !Object.values(this.filterParams).length
            ) {
              this.setDownloadedFilter(key);
            }

            if (this.newParams().fast_search) {
              this.setSearchValue(this.newParams().fast_search);
            }

            if (action) {
              switch (action) {
                case "add":
                  if (Object.entries(data["filters"]).length - 2 === index) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "edit":
                  if (id === key) {
                    this.setSelectedFilter(key);
                  }
                  break;
                case "delete":
                  if (this.filters[key]["default"]) {
                    this.setSelectedFilter(key);
                  }
                  break;
              }
            } else {
              if (
                this.filters[key]["default"] &&
                this.selectedFilter !== "new"
              ) {
                this.setSelectedFilter(key);
              }
              if (this.filterParams.selectedFilter) {
                this.selectedFilter !== "new" &&
                  this.setSelectedFilter(
                    this.filterParams.selectedFilter as string
                  );

                this.setDownloadedFilter(
                  this.filterParams.selectedFilter as string
                );
              }
            }
          });
          this.filtersChanged = cloneDeep(this.filters);
        }

        if (Object.values(data["records"]).length) {
          if (
            !this.staffListOrder &&
            !this.filters[this.selectedFilter]?.["general"] &&
            this.filters[this.selectedFilter]
          ) {
            this.getStaffListWithFilter(this.filters[this.selectedFilter]);
          } else {
            const preparedStaffList = this.dataPreparation(
              getValues(data["records"])
            );
            this.staffList = preparedStaffList;
            this.staffFoundCounter = data["nav"]["count"];
            this.isLoading = false;

            const staffIds = preparedStaffList.map((staff) => staff.id);

            // Запрашиваем количество смен
            this.getCountWorkshift(staffIds).then((counts) => {
              runInAction(() => {
                this.staffList = this.staffList.map((staff) => ({
                  ...staff,
                  count_work_all: counts[staff.id] || 0
                }));
              });
            });
          }
        } else {
          this.staffList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
          this.staffFoundCounter = 0;
        }

        if (Object.values(data["show_fields"]).length) {
          this.currentTitles = getValues(data["show_fields"]);
        } else {
          this.currentTitles = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }

        this.maxPage = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
        this.isLoading = false;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoadingForFilters = false;
      });
    }
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "company",
        currentClass: "company",
        method: "getList",
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("company" in this.staffTableParams) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(data["result"]).forEach((value) => {
              directory[value.id] = {
                newname: value.id,
                title: value.title
              };
            });
            this.staffTableParams["company"] = {
              ...this.staffTableParams["company"],
              directory: directory
            };
          }
        } else {
          this.staffTableParams["company"]["directory"] = [];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getEventTypeList = async () => {
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building_events_type",
        method: "getList",
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("event_type" in this.staffTableParams) {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            Object.values(data["result"]).forEach((value) => {
              directory[value.id] = {
                newname: value.id,
                title: value.title
              };
            });
            this.staffTableParams["event_type"] = {
              ...this.staffTableParams["event_type"],
              directory: directory
            };
          }
        } else {
          this.staffTableParams["event_type"]["directory"] = [];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для создания списков в выпадающем окне фильтров
  getDirectoryArray = (
    data: { id: string; title: string }[],
    isPosition?: boolean
  ) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfStaffObj["newname"] = value;
            break;

          case "title": {
            const companyTitle = isPosition
              ? this.staffTableParams["company"]?.["directory"]?.[
                  oneOfStaff["company"]
                ]?.["title"]
              : "";
            tempOneOfStaffObj[key] = `${value}${
              companyTitle ? ` (${companyTitle})` : ""
            }`;
            break;
          }

          default:
            tempOneOfStaffObj[key] = value;
        }
      });
      return tempOneOfStaffObj;
    });
  };

  setCompanyListForBuilding = (value: string[]) => {
    this.companyListForBuilding = value;
  };

  setCompanyListForPosition = (value: string[]) => {
    this.companyListForPosition = value;
  };

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    let formData: Record<string, string | string[]> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };
    if (this.companyListForBuilding.length) {
      formData = {
        ...formData,
        "filter[where][company][value]": this.companyListForBuilding,
        "filter[where][company][logic]": "AND",
        "filter[where][company][comparison]": "IN"
      };
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("building" in this.staffTableParams) {
            this.staffTableParams["building"] = {
              ...this.staffTableParams["building"],
              directory: this.getDirectoryArray(Object.values(data["result"]))
            };
          }
        } else {
          this.staffTableParams["building"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    let formData: Record<string, string | string[]> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };
    if (this.companyListForBuilding.length) {
      formData = {
        ...formData,
        "filter[where][company][value]": this.companyListForBuilding,
        "filter[where][company][logic]": "AND",
        "filter[where][company][comparison]": "IN"
      };
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("building" in this.staffTableParams) {
            const directory = (
              this.staffTableParams["building"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.getDirectoryArray(
                Object.values(data["result"]).filter(
                  (building) => !(building["id"] in this.dictForArray)
                )
              )
            );

            this.staffTableParams["building"] = {
              ...this.staffTableParams["building"],
              directory: directory
            };
          }
        } else {
          this.staffTableParams["building"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов по их id из сохраненных фильтров и последующее их добавление в справочник
  getBuildingDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    // Проверяем, переданы ли значения
    if (!valueFromSavedFilter || !valueFromSavedFilter.length) {
      return;
    }

    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        params: formDataFromSavedFilter,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1 && Object.keys(data["result"]).length > 0) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForArray[value["id"]] = value["title"];
          });
          if ("building" in this.staffTableParams) {
            const directory = (
              this.staffTableParams["building"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.getDirectoryArray(Object.values(data["result"])));

            this.staffTableParams["building"] = {
              ...this.staffTableParams["building"],
              directory: directory
            };
          }
        } else {
          this.dictForArray = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для получения должностей по их id из сохраненных фильтров и последующее их добавление в справочник
  getPositionDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    // Проверяем, переданы ли значения
    if (!valueFromSavedFilter || !valueFromSavedFilter.length) {
      return;
    }

    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "position",
        currentClass: "position",
        method: "getList",
        on_page: this.onPagePosition,
        params: formDataFromSavedFilter,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1 && Object.keys(data["result"]).length > 0) {
          Object.values(data["result"]).forEach((value) => {
            const companyTitle =
              this.staffTableParams["company"]?.["directory"]?.[
                value["company"]
              ]?.["title"];
            this.dictForArray[value["id"]] = `${value["title"]}${
              companyTitle ? ` (${companyTitle})` : ""
            }`;
          });

          if ("position" in this.staffTableParams) {
            const directory = (
              this.staffTableParams["position"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.getDirectoryArray(Object.values(data["result"]), true)
            );

            this.staffTableParams["position"] = {
              ...this.staffTableParams["position"],
              directory: directory
            };
          }
        } else {
          this.dictForArray = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для получения должностей в выпадающем окне фильтра
  getPositionList = async () => {
    this.isLoadingForPositionList = true;

    let formData: Record<string, string | string[]> = {
      "filter[where][title][value]": this.searchValuePosition,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };
    if (this.companyListForPosition.length) {
      formData = {
        ...formData,
        "filter[where][company][value]": this.companyListForPosition,
        "filter[where][company][logic]": "AND",
        "filter[where][company][comparison]": "IN"
      };
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "position",
        currentClass: "position",
        method: "getList",
        on_page: this.onPagePosition,
        page: this.pagePosition,
        params: formData,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("position" in this.staffTableParams) {
            this.staffTableParams["position"] = {
              ...this.staffTableParams["position"],
              directory: this.getDirectoryArray(
                Object.values(data["result"]),
                true
              )
            };
          }
        } else {
          this.staffTableParams["position"]["directory"] = [];
          this.isLoadingForPositionList = false;
        }
        this.maxPagePosition = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForPositionList = false;
      });
    }
  };

  // метод для получения должностей в выпадающем окне фильтра при скролле списка (пагинация)
  getMorePositionList = async () => {
    this.isLoadingForPositionList = true;
    let formData: Record<string, string | string[]> = {
      "filter[where][title][value]": this.searchValuePosition,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };
    if (this.companyListForPosition.length) {
      formData = {
        ...formData,
        "filter[where][company][value]": this.companyListForPosition,
        "filter[where][company][logic]": "AND",
        "filter[where][company][comparison]": "IN"
      };
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "position",
        currentClass: "position",
        method: "getList",
        on_page: this.onPagePosition,
        page: this.pagePosition,
        params: formData,
        select: ["id", "title", "company"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("position" in this.staffTableParams) {
            const directory = (
              this.staffTableParams["position"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.getDirectoryArray(
                Object.values(data["result"]).filter(
                  (position) => !(position["id"] in this.dictForArray)
                ),
                true
              )
            );

            this.staffTableParams["position"] = {
              ...this.staffTableParams["position"],
              directory: directory
            };
          }
        } else {
          this.staffTableParams["position"]["directory"] = [];
          this.isLoadingForPositionList = false;
        }
        this.prevPagePosition = this.pagePosition;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForPositionList = false));
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  setSearchValuePosition = (value: string) => {
    this.setPagePosition(1);
    this.prevPagePosition = 1;
    this.searchValuePosition = value;
  };

  setPagePosition = (value: number) => {
    if (!this.isLoadingForPositionList) {
      this.pagePosition = value;
    }
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const newParams: { [key: string]: string | number | string[] } = {};
    runInAction(() => {
      this.isLoadingForFilters = true;
    });
    getEntries(values).forEach(([key, value]) => {
      switch (key) {
        case "cols":
          newParams[key] = values[key];
          break;
        case "filter":
          value &&
            getEntries(value).forEach(([keyField, valueField]) => {
              if (Array.isArray(valueField)) {
                valueField.forEach((item, index) => {
                  newParams[`filter[${keyField}][${index}]`] = item;
                });
              } else if (typeof valueField === "object") {
                getValues(valueField).forEach((item, index) => {
                  newParams[`filter[${keyField}][${index}]`] = item;
                });
              } else if (valueField.toString().length) {
                newParams[`filter[${keyField}]`] = valueField;
              }
            });
          break;
        case "id":
          break;
        default:
          newParams[key] = values[key];
          break;
      }
    });

    const newSelectedFilter = this.filters?.[id]?.default
      ? Object.values(this.filters).filter((filter) => filter.general)[0]["id"]
      : Object.values(this.filters).filter((filter) => filter.default)[0]?.[
          "id"
        ] || Object.keys(this.filters).filter((filter) => filter !== id)[0];

    switch (action) {
      case "add":
        newParams["saveFilter"] = values.title;
        break;
      case "edit":
        newParams["updateFilter"] = id;
        this.setStaffListOrder(values.order);
        this.setStaffListOrdered(values.ordered);
        this.setSelectedFilter(id);
        this.setDownloadedFilter(id);
        this.getNewFilterParams(values);
        break;
      case "delete":
        newParams["removeFilter"] = id;
        this.setStaffListOrder("time_create");
        this.setStaffListOrdered("DESC");
        this.setSearchValue("");

        this.setSelectedFilter(newSelectedFilter);
        this.setDownloadedFilter(newSelectedFilter);

        if (this.selectedFilter) {
          this.getNewFilterParams(this.filters[this.selectedFilter]);
        }

        break;
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        body: { module: "staff", ...newParams }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getStaffData(action, id);
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
