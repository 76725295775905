import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Widgets } from "../types/Widgets";
import { Company } from "../types/Company";
import { Col } from "../types/Col";
import { Selects } from "../types/Selects";
import { SafetyWork } from "../types/SafetyWork";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getEntries } from "shared/utils/helpers/getEntries";

export default class StaffOneSafetyWorkStore {
  error = false;
  isLoading = false;
  isLoadingForForm = false;
  success = false;

  certificates: SafetyWork["certificates"] = {};
  comments: SafetyWork["comments"] = {};
  certsColsTable: { [key: string]: Col } = {};
  certsColsEvents: { [key: string]: Col } = {};
  certsTypeList: Selects["sw_type_list"] = {};

  certsShowFields: Widgets["show_fields"]["safety_work_certs"] = {};

  companiesTabs: { [key: string]: Company } = {};
  openedAllMainCompanies: { [staff_id: string]: string } = {};
  selectedCompany = "";

  openedAllStaffForCertificates: { [key: string]: Partial<Widgets> } = {};
  selectedOneForCertificates: Partial<Widgets> = {};

  rootStore: RootStore;

  setSuccess(value: boolean) {
    this.success = value;
  }

  setUpdateCertificates = (data?: Partial<Widgets>) => {
    if (data) {
      runInAction(() => {
        this.certificates = data["widgets"]["safety_work"]["certificates"];
        this.comments = data["widgets"]["safety_work"]["comments"];
        this.companiesTabs = data["staff"]["company"];
        this.certsColsTable = data["cols"]["safety_work_certs"];
        this.certsColsEvents = data["cols"]["safety_work_events"];
        this.certsShowFields = data["show_fields"]["safety_work_certs"];
        this.certsTypeList = data["selects"]["sw_type_list"];
      });
    } else {
      this.certificates = {};
      this.comments = {};
      this.companiesTabs = {};
      this.certsColsTable = {};
      this.certsColsEvents = {};
      this.certsShowFields = {};
      this.certsTypeList = {};
    }
  };

  setSelectedOneForCertificates = (id: string) => {
    this.isLoading = true;
    this.error = false;

    this.setUpdateCertificates();
    if (!getValues(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForCertificates[id];
      delete this.openedAllMainCompanies[id];
    }

    if (
      getKeys(this.openedAllStaffForCertificates).length &&
      this.openedAllStaffForCertificates[id]
    ) {
      if (getValues(this.openedAllStaffForCertificates[id]).length) {
        this.selectedOneForCertificates =
          this.openedAllStaffForCertificates[id];
        this.setUpdateCertificates(this.selectedOneForCertificates);
        this.selectedCompany = this.openedAllMainCompanies[id];
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else this.getCertificatesForOneOfStaff(id);
  };

  getCertificatesForOneOfStaff = async (id: string) => {
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields: "surname, name, patronymic, uid, id, company",
          widgets: ["safety_work"],
          cols: "safety_work_events, safety_work_certs, comments",
          selects: "company, safety_work"
        }
      });

      if (!data["errors"]) {
        this.openedAllStaffForCertificates[id] = data;
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
        });
        if (this.rootStore.menuStore.tabId === id) {
          this.selectedOneForCertificates =
            this.openedAllStaffForCertificates[id];
          this.setUpdateCertificates(this.selectedOneForCertificates);

          const hasCertificates: { [key: string]: number } = {};

          this.selectedOneForCertificates.widgets.safety_work?.certificates &&
            getEntries(
              this.selectedOneForCertificates.widgets.safety_work.certificates
            ).forEach(([company, certificates]) => {
              !(company in hasCertificates)
                ? (hasCertificates[company] = 0)
                : "";

              if (certificates && getValues(certificates).length) {
                getValues(certificates).forEach((oneOfCertificate) => {
                  hasCertificates[company] +=
                    oneOfCertificate && getValues(oneOfCertificate).length
                      ? 1
                      : 0;
                });
              }
            });

          getEntries(hasCertificates).forEach(([company, num], i) => {
            if (num && !this.openedAllMainCompanies[id]) {
              this.openedAllMainCompanies[id] = company;
            } else if (
              // если у сотрудника нет сертификатов вообще, т.е. не записалась никакая компания в openedMainCompanies[id]
              // а мы уже на последнем элементе массива
              i === getEntries(hasCertificates).length - 1 &&
              !this.openedAllMainCompanies[id]
            ) {
              // то записываем в активную компанию самую первую из списка
              this.openedAllMainCompanies[id] = getKeys(hasCertificates)[0] as string;
            }
          });
        }
      } else {
        this.openedAllStaffForCertificates[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });

        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingForForm = false;
      });
    }
  };

  addHistoryComment = async (uid: string, comment: string, date: string) => {
    const formData = { uid, comment, date };
    this.isLoading = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "addHistoryComment",
        body: formData
      });

      if (data["code"] !== 500) {
        await this.getCertificatesForOneOfStaff(uid);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  addCertificate = async (
    uid: string,
    kind: string,
    type: string,
    date_start: string,
    date_end: string,
    comment: string,
    company: string,
    file: File
  ) => {
    const formData = {
      uid,
      kind,
      type,
      date_start,
      date_end,
      comment,
      company,
      file: file
    };
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });

    try {
      const data: ApiResponse<boolean | string> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "safetywork",
          method: "addСertificate",
          body: formData
        });

      if (data.result) {
        await this.getCertificatesForOneOfStaff(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
        this.success = true;
      } else {
        runInAction(() => {
          this.error = true;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  updateCertificate = async (
    id: string,
    uid: string,
    kind: string,
    type: string,
    date_start: string,
    date_end: string,
    comment: string,
    company: string,
    file: File
  ) => {
    const formData = {
      id,
      "update[uid]": uid,
      "update[kind]": kind,
      "update[type]": type,
      "update[date_start]": date_start,
      "update[date_end]": date_end,
      "update[comment]": comment,
      "update[company]": company,
      file
    };
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "updateСertificate",
        body: formData
      });

      if (data.result) {
        await this.getCertificatesForOneOfStaff(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
        this.success = true;
      } else {
        runInAction(() => {
          this.error = true;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  deleteCertificate = async (id: string, uid: string) => {
    const formData = {
      id
    };
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "deleteСertificate",
        body: formData
      });

      if (data.result) {
        await this.getCertificatesForOneOfStaff(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
        this.success = true;
      } else {
        runInAction(() => {
          this.error = true;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  deleteFile = async (id: string, uid: string) => {
    const formData = {
      id
    };

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "safetywork",
        method: "deleteFile",
        body: formData
      });

      if (data.result) {
        await this.getCertificatesForOneOfStaff(uid);
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setSelectedCompany = (company: string) => {
    this.selectedCompany = company;
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
