import styles from "./itemsScrollBoard.module.scss";
import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { positionValues, Scrollbars } from "react-custom-scrollbars-2";
import { IScrollBars } from "stores/utils/types/ScrollBars";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import ItemScrollBoardHeader from "./ItemScrollBoardHeader";
import { classNames } from "shared/utils/helpers/classNames";
import ItemsScrollBoardList from "./ItemsScrollBoardList";
import Alert from "shared/ui/Alert";
import { getKeys } from "shared/utils/helpers/getKeys";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { getValues } from "shared/utils/helpers/getValues";
import LoaderSpinner from "shared/ui/LoaderSpinner";

const sortList = (list: OptionWithTitle[]) => {
  list.sort((a, b) => {
    typeof a.title === "number" ? (a.title = `${a.title}`) : "";
    typeof b.title === "number" ? (b.title = `${b.title}`) : "";
    const nameA = a.title ? a.title.toLowerCase() : "",
      nameB = b.title ? b.title.toLowerCase() : "";
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return list;
};

type ItemsScrollBoardProps = {
  options:
    | {
        [key: string]: OptionWithTitle;
      }
    | OptionWithTitle[];
  values?: string | string[] | number;
  valueName?: string;
  searchPlaceholder?: string;
  notSearchable?: boolean;
  addItem?: (option: OptionWithTitle) => void;
  addAllItem?: (option: OptionWithTitle[]) => void;
  isItemBtnMode?: boolean;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  isLoading?: boolean;
  notSortable?: boolean;
  onDelete?: () => void;
  selectedItem?: OptionWithTitle;
  className?: string;
};

const ItemsScrollBoard = ({
  options,
  values,
  valueName = "newname",
  notSearchable,
  searchPlaceholder,
  addItem,
  addAllItem,
  isItemBtnMode,
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  searchValue,
  isLoading,
  notSortable,
  onDelete,
  selectedItem,
  className
}: ItemsScrollBoardProps) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [foundedItems, setFoundedItems] = useState<OptionWithTitle[]>([]);
  const [otherItems, setOtherItems] = useState<OptionWithTitle[]>([]);
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const scrollbar = useRef<IScrollBars>();

  const handleScroll = (e: positionValues) => {
    if (e.top > 0.87) {
      setIsScrollBottom(!isScrollBottom);
    } else {
      setIsScrollBottom(false);
    }
  };

  useEffect(() => {
    if (
      isSearchWithPagination &&
      isScrollBottom &&
      maxPage >= page &&
      page === prevPage
    ) {
      setPage(page + 1);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    if (!options) {
      setFoundedItems([]);
      setOtherItems([]);
      return;
    }

    if (isSearchWithPagination) {
      const arr = Array.isArray(options) ? options : getValues(options);
      setFoundedItems(arr);
      setOtherItems([]);
    } else {
      let modifiedOptions: { [key: string]: OptionWithTitle } = {};
      if (Array.isArray(options)) {
        options.forEach((opt) => {
          modifiedOptions[String(opt[valueName])] = opt;
        });
      } else {
        modifiedOptions = { ...options };
      }
      if (values !== undefined) {
        if (!Array.isArray(values)) {
          getKeys(modifiedOptions).forEach((k) => {
            if (String(modifiedOptions[k][valueName]) === String(values)) {
              delete modifiedOptions[k];
            }
          });
        } else {
          values.forEach((val) => {
            getKeys(modifiedOptions).forEach((k) => {
              if (String(modifiedOptions[k][valueName]) === String(val)) {
                delete modifiedOptions[k];
              }
            });
          });
        }
      }

      const newOptions = getValues(modifiedOptions);

      if (searchInputValue.trim()) {
        const founded: OptionWithTitle[] = [];
        const other: OptionWithTitle[] = [];

        newOptions.forEach((col) => {
          const colTitle = String(col.title).toLowerCase();
          if (colTitle.includes(searchInputValue.toLowerCase())) {
            founded.push(col);
          } else {
            other.push(col);
          }
        });

        setFoundedItems(notSortable ? founded : sortList(founded));
        setOtherItems(notSortable ? other : sortList(other));
      } else {
        setFoundedItems([]);
        setOtherItems(notSortable ? newOptions : sortList(newOptions));
      }
    }
  }, [
    options,
    values,
    valueName,
    isSearchWithPagination,
    searchInputValue,
    notSortable
  ]);

  const filteredOptions = isSearchWithPagination
    ? (Array.isArray(options) ? options : getValues(options))?.filter(
        (item) => !(values as string[])?.includes(item[valueName] as string)
      )
    : [];

  const shouldShowFoundBlock = isItemBtnMode && isSearchWithPagination;
  const foundCount = foundedItems.length;
  const totalCount = foundCount + otherItems.length;

  const parseThFromString = (str: string): string[] => {
    return str
      .split(/[\s,]+/)
      .map((t) => t.trim())
      .filter(Boolean)
      .filter((token) => /^\d+$/.test(token));
  };

  const currentSearch = isSearchWithPagination
    ? searchValue ?? ""
    : searchInputValue;
  const numericTokens = parseThFromString(currentSearch);

  const existingStrings = [...foundedItems, ...otherItems]
    .map((opt) => String(opt.title).toLowerCase())
    .filter((str) => /\d+/.test(str));

  const missingTokens = numericTokens.filter((tn) => {
    return !existingStrings.some((str) => str.includes(tn.toLowerCase()));
  });

  const missingTokensObj = missingTokens.reduce<Record<string, string>>(
    (acc, token) => {
      acc[token] = token;
      return acc;
    },
    {}
  );

  const handleAddAll = () => {
    addAllItem && addAllItem(filteredOptions);
  };

  const showAllAdded =
    (shouldShowFoundBlock &&
      !isLoading &&
      filteredOptions.length === 0 &&
      (options as OptionWithTitle[]).length > 0) ||
    (!isSearchWithPagination &&
      foundedItems.length === 0 &&
      otherItems.length === 0 &&
      (Array.isArray(options) ? options.length : getValues(options).length) >
        0);

  const showPartialTnWarning =
    shouldShowFoundBlock &&
    !isLoading &&
    totalCount > 0 &&
    getKeys(missingTokensObj).length > 0 &&
    (foundedItems.length > 0 || otherItems.length > 0);

  return (
    <div
      className={classNames(
        styles.scrollboard,
        {
          [styles.scrollboardWithoutPadding]: !notSearchable
        },
        [className]
      )}
      data-list="true"
    >
      <ItemScrollBoardHeader
        notSearchable={notSearchable}
        searchPlaceholder={searchPlaceholder}
        isSearchWithPagination={isSearchWithPagination}
        isItemBtnMode={isItemBtnMode}
        getList={getList}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        notSortable={notSortable}
        otherItems={otherItems}
        setOtherItems={setOtherItems}
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
      />
      {shouldShowFoundBlock && searchValue && (
        <div className={styles.foundBlock}>
          <div className={styles.textBlock}>
            <p className={styles.foundText}>Найдено:</p>
            {isLoading ? (
              <LoaderSpinner color="bw-gray4" size="small" />
            ) : (
              filteredOptions.length
            )}
          </div>
          {addAllItem && (
            <Button
              theme={ButtonTheme.CLEAR}
              disabled={!filteredOptions.length}
              id="ItemsScrollBoard_addAllBtn"
              onClick={handleAddAll}
            >
              Добавить все{" "}
              <IconAdd
                className={classNames(styles.addAllIcon, {
                  [styles.addAllIconDisabled]: !filteredOptions.length
                })}
              />
            </Button>
          )}
        </div>
      )}

      {showPartialTnWarning && (
        <Alert
          errors={{
            head: "Нет результата по некоторым значениям:",
            color: "warning",
            body: {
              missing: {
                head: missingTokens.join(", "),
                list: {
                  type: "text",
                  body: {}
                }
              }
            }
          }}
          className={{ container: styles.emptyAlert }}
        />
      )}

      {showAllAdded && (
        <Alert
          errors={{
            head: "Добавлены все параметры. Список пуст.",
            color: "empty"
          }}
          className={{ container: styles.emptyAlert }}
        />
      )}
      <Scrollbars
        ref={scrollbar}
        style={{ width: "100%" }}
        autoHeight
        autoHeightMax={"100%"}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        onScrollFrame={(e) => handleScroll(e)}
        data-list="true"
      >
        <ItemsScrollBoardList
          filteredOptions={filteredOptions}
          valueField={valueName}
          notSearchable={notSearchable}
          addItem={addItem}
          isItemBtnMode={isItemBtnMode}
          isSearchWithPagination={isSearchWithPagination}
          page={page}
          isLoading={isLoading}
          foundedItems={foundedItems}
          otherItems={otherItems}
          setFoundedItems={setFoundedItems}
          onDelete={onDelete}
          selectedItem={selectedItem}
        />
        {!isLoading && typeof values === "object" && !getKeys(options).length && (
          <Alert
            errors={{
              head: isSearchWithPagination
                ? "Ничего не найдено"
                : "Список пуст",
              color: "empty"
            }}
            className={{ container: styles.emptyAlert }}
          />
        )}
      </Scrollbars>
    </div>
  );
};

export default observer(ItemsScrollBoard);
