import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { validationSettingsFields } from "./validation";

import styles from "./staffStatusSettingsForm.module.scss";

import Main from "./Main";
import Settings from "./Settings";
import Additional from "./Additional";
import BuildingCondition from "./BuildingCondition";

import DefaultAndDelete from "./DefaultAndDelete";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { useState } from "react";
import CheckList from "./CheckList";
// import SelectMulti from "sharedComponents/Inputs/SelectMulti";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

const StaffStatusSettingsForm = () => {
  const { staffStatusStore } = useStores();

  const [openedSubMenus, setOpenedSubMenus] = useState({
    staffFields: false,
    states: false
  });

  return staffStatusStore.selectedStatusId ? (
    <div className={styles.formContainer}>
      <Formik
        enableReinitialize
        initialValues={
          staffStatusStore.selectedStatusId === "new"
            ? staffStatusStore.newStatus
            : staffStatusStore.statusTypes[staffStatusStore.selectedStatusId]
        }
        validationSchema={validationSettingsFields}
        validateOnBlur
        validateOnChange
        onSubmit={() => {
          return;
        }}
      >
        {({ values, initialValues, errors, handleChange, handleSubmit }) => {
          return (
            <Form className={styles.form}>
              <Main values={values} handleChange={handleChange} />
              <Settings values={values} handleChange={handleChange} />
              <Additional handleChange={handleChange} />

              {/* Раскомментировать, когда в staffStatusStore.statusFields будут приходить данные бех хардкода */}
              {/* <section>
                {values["custom"]["staff_cols"] ? (
                  <SelectMulti
                    name="custom.staff_cols"
                    label="Поля сотрудника"
                    searchPlaceholder="Поиск по параметрам"
                    options={staffStatusStore.statusFields}
                  />
                ) : null}
              </section> */}

              <CheckList
                handleChange={handleChange}
                title="Статусы, доступные для смены текущего"
                name="transit_cols"
                list={Object.values(staffStatusStore.statusTypes).filter(
                  (status) => status.id !== staffStatusStore.selectedStatusId
                )}
              />
              <BuildingCondition
                id={initialValues["id"]}
                values={values}
                initialValues={initialValues}
                errors={errors}
                openedSubMenus={openedSubMenus}
                setOpenedSubMenus={setOpenedSubMenus}
              />
              <CheckList
                handleChange={handleChange}
                name="notice_change"
                title="Выслать уведомления при смене на"
                list={Object.values(staffStatusStore.statusTypes)}
              />
              <DefaultAndDelete values={values} />

              <ButtonsGroupForSettings
                saveBtnTitle="Сохранить"
                saveBtnOnClick={handleSubmit}
                saveBtnDisabled
                cancelBtnTitle="Удалить"
                cancelBtnDisabled
                cancelBtnImg={IconClose}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  ) : null;
};

export default observer(StaffStatusSettingsForm);
