import { makeAutoObservable, runInAction } from "mobx";
import { InvitesListItem } from "./types/InvitesListItem";
import { Col } from "stores/utils/types/Col";
import { InviteFriendData } from "./types/InviteAllFriendData";
import RootStore from "stores";
import { Company } from "stores/StaffModule/types/Company";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { cloneDeep, isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";
import { Param } from "stores/utils/types/Param";
import { FriendInviteSelects } from "./types/FriendInviteSelects";
import { FilterParams } from "features/TableWithStickyFilter/FilterInfoBlock";
import { FilterType } from "stores/utils/types/FilterType";
import { getKeys } from "shared/utils/helpers/getKeys";
import { NavType } from "stores/utils/types/NavType";
import { StaffListItem } from "stores/StaffModule/types/StaffListItem";
import { getEntries } from "shared/utils/helpers/getEntries";
import { PromisesInOrder } from "shared/utils/helpers/promisesInOrder";
import { transformSelectsToDirectory } from "shared/utils/helpers/transformSelectsToDirectory";

type StaffListFormatted = { id: string; title: string; tn: number }[];

export default class FriendInviteStore {
  initialState: typeof this;
  error = false;
  isLoading = false;
  isLoadingForFilters = false;
  isLoadingCol = "";
  errorMessage = "";

  invitesList: Record<string, InvitesListItem> = {};
  updatedInvites: Record<string, InvitesListItem> = {};

  order = "time_create";
  ordered = "DESC";
  filterParams: FilterParams | { [key: string]: string[] } = {};
  queryParams: { [key: string]: string } = {};

  onPage = 100;
  page = 1;
  maxPage = 0;
  prevPage = 1;
  foundCounter = 0;
  filters: { [key: string]: FilterType } = {};
  filtersChanged: { [key: string]: FilterType } = {};
  selectedFilter = "";
  downloadedFilter = "";
  staffFoundCounter = 0;

  // предустановленные фильтры хардкодим на фронте
  generalFilters: Record<string, FilterType> = {
    filter_default: {
      cols: [],
      default: 1,
      filter: {},
      general: 1,
      id: "filter_default",
      ordered: "DESC",
      order: "time_create",
      title: "Весь список"
    }
  };

  searchValue = "";

  // состояние выпадающих списков для таблицы
  openedList = "";

  //Статичные данные и настройки
  cols: Record<string, Col> = {};
  filterCols: Record<string, Col> = {};
  currentTitles: string[] = [];
  tableParams: Record<string, Param> = {};
  selects: Partial<FriendInviteSelects> = {};
  companiesList: { [company_id: string]: Company } = {};

  // выпадающий список сотрудников
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;
  staffList: StaffListFormatted = [];
  selectedStaff: Partial<StaffListItem> = {};

  // Добавляем состояния для списков в фильтрах
  staffListForFilter: { newname: string; title: string }[] = [];
  authorListForFilter: { newname: string; title: string }[] = [];

  // Состояния для пагинации staff в фильтре
  pageStaffFilter = 1;
  onPageStaffFilter = 30;
  maxPageStaffFilter = 0;
  prevPageStaffFilter = 1;
  searchValueStaffFilter = "";
  isLoadingForStaffListFilter = false;

  // Состояния для пагинации author в фильтре
  pageAuthorFilter = 1;
  onPageAuthorFilter = 30;
  maxPageAuthorFilter = 0;
  prevPageAuthorFilter = 1;
  searchValueAuthorFilter = "";
  isLoadingForAuthorListFilter = false;

  // Справочник для фильтров
  dictForFilter: Record<string, string> = {};
  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    this.initialState = cloneDeep(this);
    makeAutoObservable(this);
  }

  resetAll = () => {
    Object.assign(this, this.initialState);
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.searchValue = value;
  };

  setOrdered = (ordered: string) => {
    this.ordered = ordered;
  };

  setOrder = (order: string) => {
    this.order = order;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setFilterParamsFromQuery = (
    value: FilterParams | { [key: string]: string[] }
  ) => {
    this.filterParams = value;

    // Получаем значения для справочников из параметров фильтра
    getEntries(value).forEach(([key, filterValue]) => {
      if (key.includes("filter[")) {
        const fieldName = key.replace("filter[", "").replace("]", "");

        if (Array.isArray(filterValue)) {
          switch (fieldName) {
            case "uid":
            case "candidate_tn":
              this.getStaffDictForSavedFilter(filterValue, fieldName);
              break;
            case "author":
              this.getAuthorDictForSavedFilter(filterValue);
              break;
          }
        }
      }
    });
  };

  newParams = (): { [key: string]: string } => {
    const params: { [key: string]: string } = {};
    this.order && (params["order"] = this.order);
    this.ordered && (params["ordered"] = this.ordered);
    this.searchValue && (params["fast_search"] = this.searchValue);
    return params;
  };

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    getEntries(filter?.filter).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value) ||
        (Array.isArray(value) && value.length) ||
        typeof value === "number"
      ) {
        this.filterParams[`filter[${key}]`] = value as string;
      } else if (typeof value === "object" && !Array.isArray(value)) {
        this.filterParams[`filter[${key}]`] = getValues(value);
      }
    });
  };

  getPage = async () => {
    this.isLoading = true;
    this.errorMessage = "";
    this.page = 1;
    this.rootStore.menuStore.setIsScrollBottom(true);
    this.invitesList = {};

    try {
      const data: InviteFriendData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "friendinvite",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: { ...this.newParams(), ...this.filterParams }
      });
      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((invite) => {
            !this.invitesList[invite.id] &&
              (this.invitesList[invite.id] = invite);
          });

          this.prevPage = this.page;
          this.maxPage = data.nav.max_page;
          this.foundCounter = data.nav.count;
        } else {
          this.invitesList = {};
          this.errorMessage = "Ничего не найдено";
        }

        this.getFilters();
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    }
  };

  getMorePage = async () => {
    this.errorMessage = "";
    this.isLoading = true;

    const params: { [key: string]: string } = this.newParams();

    try {
      const data: InviteFriendData = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "friendinvite",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: { ...params, ...this.filterParams }
      });
      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((invite) => {
            !this.invitesList[invite.id] &&
              (this.invitesList[invite.id] = invite);
          });

          this.rootStore.menuStore.isScrollBottom = false;
          this.prevPage = this.page;
          this.maxPage = data.nav.max_page;
        } else {
          this.invitesList = {};
          this.errorMessage = "Ничего не найдено";
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.rootStore.menuStore.setIsScrollBottom(false);
        this.isLoading = false;
      });
    }
  };

  getAllInviteFriendData = async () => {
    this.isLoading = true;
    this.invitesList = {};
    this.setPage(1);

    // Сначала получаем все необходимые справочники и параметры
    await PromisesInOrder([
      () => isEmpty(this.companiesList) && this.getCompanies(),
      () => isEmpty(this.selects) && this.getSelects(),
      () => isEmpty(this.cols) && this.getCols(),
      () => isEmpty(this.tableParams) && this.getTableParams(),
      () => this.getStaffListForFilter(),
      () => this.getAuthorListForFilter()
    ]);

    // Если есть параметры фильтрации в URL, загружаем справочники для них
    if (!isEmpty(this.filterParams)) {
      const promises = [];
      getEntries(this.filterParams).forEach(([key, value]) => {
        if (key.includes("filter[")) {
          const fieldName = key.replace("filter[", "").replace("]", "");
          if (Array.isArray(value)) {
            switch (fieldName) {
              case "uid":
              case "candidate_tn":
                promises.push(() =>
                  this.getStaffDictForSavedFilter(value, fieldName)
                );
                break;
              case "author":
                promises.push(() => this.getAuthorDictForSavedFilter(value));
                break;
            }
          }
        }
      });

      if (promises.length) {
        await Promise.all(promises.map((p) => p()));
      }
    }

    // Затем загружаем данные
    await this.getPage();

    runInAction(() => {
      this.isLoading = false;
      this.error = false;
    });
  };

  getCompanies = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList"
        });

      runInAction(() => {
        if (data.result !== -1) {
          getValues(data.result).forEach((company) => {
            this.companiesList[company.id] = company;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<FriendInviteSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getSelects"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCols = async () => {
    try {
      const data: ApiResponse<Record<string, Col>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.cols = data.result;

          getKeys(this.cols).forEach((key) => {
            if (this.selects[key]) {
              this.cols[key]["directory"] = transformSelectsToDirectory(
                this.selects
              )[key];
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.tableParams = data.result;

          getEntries(this.tableParams).forEach(([key, col]) => {
            if (col.show_default) {
              this.currentTitles.push(key);
            }

            if (col.useAsFilter === "on") {
              this.cols[key] && (this.filterCols[key] = this.cols[key]);
            }
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  updateInviteCol = async (id: string) => {
    try {
      const data: ApiResponse<InvitesListItem> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "friendinvite",
          method: "getOne",
          params: { id }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.updatedInvites[id] = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  editCol = async (id: string, value: Record<string, string | number>) => {
    this.isLoadingCol = `${getKeys(value)?.[0]}_${id}`;

    try {
      const data: ApiResponse<never> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "friendinvite",
        method: "editCol",
        body: { id, ...(value || {}) }
      });

      runInAction(() => {
        if (data.result) {
          Promise.all([this.updateInviteCol(id)]).then(() =>
            runInAction(() => (this.isLoadingCol = ""))
          );
        } else runInAction(() => (this.isLoadingCol = ""));
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  getStaffList = async (id: string, isMore = false) => {
    this.isLoadingForStaffList = true;

    if (!isMore) {
      this.staffList = [];
      this.selectedStaff = {};
    }

    const nav: NavType = {
      on_page: this.onPageStaff,
      now_page: this.pageStaff
    };
    const params: Record<string, string> = {};

    if (this.searchValueStaff) {
      params.fast_search = this.searchValueStaff;
    }

    try {
      const data = await this.rootStore.staffApiStore.getPage(nav, params);

      if (!isEmpty(data?.records)) {
        const allStaff = getValues(data.records);
        let staffList = this.formatStaffList(allStaff);

        if (!isMore && this.invitesList[id]?.candidate_tn?.id) {
          await this.getOneOfStaff(this.invitesList[id]?.candidate_tn?.id);
          if (!isEmpty(this.selectedStaff)) {
            staffList.push(this.formatStaffList([this.selectedStaff])[0]);
          }
        }

        staffList = this.getUniqueStaffList(staffList);

        runInAction(() => {
          if (isMore) {
            this.staffList = this.staffList.concat(staffList);
          } else {
            this.staffList = staffList;
          }

          this.maxPageStaff = data.nav.max_page;
          this.prevPageStaff = this.pageStaff;
        });
      } else {
        runInAction(() => (this.staffList = []));
      }
    } catch (error) {
      runInAction(() => (this.error = false));
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  getOneOfStaff = async (id: string) => {
    try {
      const nav = { on_page: 1, now_page: 1 };
      const params = { "filter[id]": id };
      const data = await this.rootStore.staffApiStore.getPage(nav, params);
      runInAction(() => {
        if (!isEmpty(data.records)) {
          const staff = getValues(data.records)[0];
          this.selectedStaff = staff;
        } else this.selectedStaff = undefined;
      });
    } catch (error) {
      runInAction(() => (this.error = false));
    }
  };

  formatStaffList = (staffList: Partial<StaffListItem>[]) =>
    staffList.map((staff) => {
      return {
        id: staff.id,
        title: `ТН${staff.uid} ${staff.surname}${
          staff.surname ? " " + staff.name : staff.name
        }${staff.patronymic ? " " + staff.patronymic : ""}`,
        tn: staff.uid
      };
    });

  getUniqueStaffList = (staffList: StaffListFormatted) => {
    const uniqueStaffMap = new Map();
    staffList.forEach((staff) => uniqueStaffMap.set(staff.id, staff));
    return Array.from(uniqueStaffMap.values());
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  getPageListWithFilter = (filter: FilterType) => {
    this.getNewFilterParams(filter);
    if (filter.default) {
      this.setOrdered("DESC");
      this.setOrder("");
    }

    this.setPage(1);
    this.setDownloadedFilter(filter["id"]);
    this.getPage();
  };

  getFilters = async () => {
    try {
      const data: ApiResponse<never> & {
        savedFilter: { [key: string]: { filterdata: FilterType } };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "filters",
        method: "getFilter",
        params: { module: "friendinvite" }
      });

      runInAction(() => {
        if (data["code"] === 200) {
          this.filters = {};
          this.filters = cloneDeep(this.generalFilters);
          getEntries(data["savedFilter"]).forEach(([key, filter]) => {
            this.filters[key] = filter["filterdata"];
            this.filters[key]["id"] = key;
            this.filters[key]["filter"] = filter["filterdata"]?.["filter"]
              ? filter["filterdata"]?.["filter"]
              : {};
          });

          getValues(this.filters).forEach((filter) => {
            if (
              !this.downloadedFilter &&
              filter.default &&
              isEmpty(this.filterParams)
            ) {
              this.setDownloadedFilter(filter.id);
            }
          });
          this.filtersChanged = cloneDeep(this.filters);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  setFilters = async (action: string, id: string, values: FilterType) => {
    const newParams: { [key: string]: string | number | string[] } = {};
    runInAction(() => {
      this.isLoadingForFilters = true;
    });

    getEntries(values).forEach(([key, value]) => {
      if (!key.includes("isIntervalField")) {
        switch (key) {
          case "filter":
            value &&
              getEntries(value).forEach(([keyField, valueField]) => {
                if (Array.isArray(valueField)) {
                  valueField.forEach((item, index) => {
                    newParams[`filter[${keyField}][${index}]`] = item;
                  });
                } else if (typeof valueField === "object") {
                  getValues(valueField).forEach((item, index) => {
                    newParams[`filter[${keyField}][${index}]`] = item;
                  });
                } else if (valueField.toString().length) {
                  newParams[`filter[${keyField}]`] = valueField;
                }
              });
            break;
          case "id":
          case "cols":
            break;
          default:
            newParams[key] = values[key];
            break;
        }
      }
    });

    const newSelectedFilter = this.filters?.[id]?.default
      ? getValues(this.filters).filter((filter) => filter.general)[0]["id"]
      : getValues(this.filters).filter((filter) => filter.default)[0]?.["id"] ||
        getKeys(this.filters).filter((filter) => filter !== id)[0];

    switch (action) {
      case "add":
        newParams["saveFilter"] = values.title;
        break;
      case "edit":
        newParams["updateFilter"] = id;
        this.setOrdered(values.ordered);
        this.setOrder(values.order);
        this.setSelectedFilter(id);
        this.setDownloadedFilter(id);
        this.getNewFilterParams(values);
        break;
      case "delete":
        newParams["removeFilter"] = id;
        this.setSelectedFilter(newSelectedFilter as string);
        this.setDownloadedFilter(newSelectedFilter as string);
        if (this.selectedFilter) {
          this.getNewFilterParams(this.filters[this.selectedFilter]);
        }
        break;
    }

    try {
      const data: ApiResponse<string> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "filters",
        method: "setFilter",
        body: { module: "friendinvite", ...newParams }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getPage().finally(() => {
            runInAction(() => {
              this.isLoadingForFilters = false;
            });
          });
        } else {
          this.isLoadingForFilters = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      getKeys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
      this.filtersChanged[currentFilter][key] = value;
    } else {
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: [],
        ordered: "DESC",
        order: "time_create"
      };
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
    this.filtersChanged[currentFilter][key] = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setOpenedList = (value: string) =>
    (this.openedList = this.openedList !== value ? value : "");

  // Методы для staff списка в фильтре

  setPageStaffFilter = (value: number) => {
    if (!this.isLoadingForStaffListFilter) {
      this.pageStaffFilter = value;
    }
  };

  setSearchValueStaffFilter = (value: string) => {
    this.setPageStaffFilter(1);
    this.prevPageStaffFilter = 1;
    this.searchValueStaffFilter = value;
    this.getStaffListForFilter();
  };

  formatStaffListForFilter = (staffList: StaffListItem[]) =>
    staffList.map((staff) => ({
      newname: staff.id,
      title: `ТН${staff.uid} ${staff.surname}${
        staff.surname ? " " + staff.name : staff.name
      }${staff.patronymic ? " " + staff.patronymic : ""}`
    }));

  getStaffListForFilter = async () => {
    this.isLoadingForStaffListFilter = true;

    const params: Record<string, string> = {};
    if (this.searchValueStaffFilter) {
      params.fast_search = this.searchValueStaffFilter;
    }

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPageStaffFilter,
        page: this.pageStaffFilter,
        params
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          getValues(data.records).forEach((value) => {
            this.dictForFilter[value.id] = `${value.surname}${
              value.surname ? " " + value.name : value.name
            }${value.patronymic ? " " + value.patronymic : ""}`;
          });

          if ("uid" in this.filterCols) {
            const staffDirectory = {};
            getValues(data.records).forEach((staff) => {
              staffDirectory[staff.id] = {
                newname: staff.id,
                title: `ТН${staff.uid} ${staff.surname}${
                  staff.surname ? " " + staff.name : staff.name
                }${staff.patronymic ? " " + staff.patronymic : ""}`
              };
            });

            this.filterCols.uid = {
              ...this.filterCols.uid,
              directory: staffDirectory
            };
          }

          if ("candidate_tn" in this.filterCols) {
            const staffDirectory = {};
            getValues(data.records).forEach((staff) => {
              staffDirectory[staff.id] = {
                newname: staff.uid.toString(),
                title: `ТН${staff.uid} ${staff.surname}${
                  staff.surname ? " " + staff.name : staff.name
                }${staff.patronymic ? " " + staff.patronymic : ""}`
              };
            });

            this.filterCols.candidate_tn = {
              ...this.filterCols.candidate_tn,
              directory: staffDirectory
            };
          }

          this.maxPageStaffFilter = data.nav.max_page;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffListFilter = false;
      });
    }
  };

  getMoreStaffForFilter = async () => {
    this.isLoadingForStaffListFilter = true;

    const params: Record<string, string> = {};
    if (this.searchValueStaffFilter) {
      params.fast_search = this.searchValueStaffFilter;
    }

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
        nav: NavType;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPageStaffFilter,
        page: this.pageStaffFilter,
        params
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          if ("uid" in this.filterCols) {
            const currentDirectory = this.filterCols.uid.directory || {};
            const newStaffDirectory = {};
            getValues(data.records).forEach((staff) => {
              if (!currentDirectory[staff.id]) {
                newStaffDirectory[staff.id] = {
                  newname: staff.id,
                  title: `ТН${staff.uid} ${staff.surname}${
                    staff.surname ? " " + staff.name : staff.name
                  }${staff.patronymic ? " " + staff.patronymic : ""}`
                };
              }
            });

            this.filterCols.uid = {
              ...this.filterCols.uid,
              directory: { ...currentDirectory, ...newStaffDirectory }
            };
          }

          if ("candidate_tn" in this.filterCols) {
            const currentDirectory =
              this.filterCols.candidate_tn.directory || {};
            const newStaffDirectory = {};
            getValues(data.records).forEach((staff) => {
              if (!currentDirectory[staff.id]) {
                newStaffDirectory[staff.id] = {
                  newname: staff.uid.toString(),
                  title: `ТН${staff.uid} ${staff.surname}${
                    staff.surname ? " " + staff.name : staff.name
                  }${staff.patronymic ? " " + staff.patronymic : ""}`
                };
              }
            });

            this.filterCols.candidate_tn = {
              ...this.filterCols.candidate_tn,
              directory: { ...currentDirectory, ...newStaffDirectory }
            };
          }
        }
        this.prevPageStaffFilter = this.pageStaffFilter;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffListFilter = false;
      });
    }
  };

  // Методы для author списка в фильтре

  setPageAuthorFilter = (value: number) => {
    if (!this.isLoadingForAuthorListFilter) {
      this.pageAuthorFilter = value;
    }
  };

  setSearchValueAuthorFilter = (value: string) => {
    this.setPageAuthorFilter(1);
    this.prevPageAuthorFilter = 1;
    this.searchValueAuthorFilter = value;
    this.getAuthorListForFilter();
  };

  getAuthorListForFilter = async () => {
    this.isLoadingForAuthorListFilter = true;

    const params: Record<string, string> = {};
    if (this.searchValueAuthorFilter) {
      params.fast_search = this.searchValueAuthorFilter;
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; showname: string }>
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthorFilter,
        page: this.pageAuthorFilter,
        params
      });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          if ("author" in this.filterCols) {
            const authorDirectory = {};
            getValues(data.result).forEach((author) => {
              authorDirectory[author.id] = {
                newname: author.id?.toString(),
                title: author.showname
              };
            });

            this.filterCols.author = {
              ...this.filterCols.author,
              directory: authorDirectory
            };
          }

          this.maxPageAuthorFilter = data.nav.max_page;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForAuthorListFilter = false;
      });
    }
  };

  getMoreAuthorForFilter = async () => {
    this.isLoadingForAuthorListFilter = true;

    const params: Record<string, string> = {};
    if (this.searchValueAuthorFilter) {
      params.fast_search = this.searchValueAuthorFilter;
    }

    try {
      const data: ApiResponse<
        Record<string, { id: string; showname: string }>
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthorFilter,
        page: this.pageAuthorFilter,
        params
      });

      runInAction(() => {
        if (!isEmpty(data.result)) {
          if ("author" in this.filterCols) {
            const currentDirectory = this.filterCols.author.directory || {};
            const newAuthorDirectory = {};
            getValues(data.result).forEach((author) => {
              newAuthorDirectory[author.id] = {
                newname: author.id?.toString(),
                title: author.showname
              };
            });

            this.filterCols.author = {
              ...this.filterCols.author,
              directory: { ...currentDirectory, ...newAuthorDirectory }
            };
          }
        }
        this.prevPageAuthorFilter = this.pageAuthorFilter;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForAuthorListFilter = false;
      });
    }
  };

  getStaffDictForSavedFilter = async (
    valueFromSavedFilter?: string[],
    fieldName?: string
  ) => {
    if (!valueFromSavedFilter?.length) return;

    try {
      const data: ApiResponse<never> & {
        records: Record<string, StaffListItem>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: valueFromSavedFilter.length,
        params:
          fieldName === "candidate_tn"
            ? { "filter[uid]": valueFromSavedFilter }
            : { "filter[id]": valueFromSavedFilter }
      });

      runInAction(() => {
        if (!isEmpty(data.records)) {
          const staffList = getValues(data.records);

          // Добавляем в справочник
          staffList.forEach((staff) => {
            this.dictForFilter[staff.id] = `ТН${staff.uid} ${staff.surname}${
              staff.surname ? " " + staff.name : staff.name
            }${staff.patronymic ? " " + staff.patronymic : ""}`;
          });

          // Добавляем в directory для фильтров
          if ("uid" in this.filterCols) {
            const currentDirectory = this.filterCols.uid.directory || {};
            const newStaffDirectory = {};
            staffList.forEach((staff) => {
              if (!currentDirectory[staff.id]) {
                newStaffDirectory[staff.id] = {
                  newname: staff.id,
                  title: `ТН${staff.uid} ${staff.surname}${
                    staff.surname ? " " + staff.name : staff.name
                  }${staff.patronymic ? " " + staff.patronymic : ""}`
                };
              }
            });

            this.filterCols.uid = {
              ...this.filterCols.uid,
              directory: { ...currentDirectory, ...newStaffDirectory }
            };
          }

          if ("candidate_tn" in this.filterCols) {
            const currentDirectory =
              this.filterCols.candidate_tn.directory || {};
            const newStaffDirectory = {};
            staffList.forEach((staff) => {
              if (!currentDirectory[staff.id]) {
                newStaffDirectory[staff.id] = {
                  newname: staff.uid.toString(),
                  title: `ТН${staff.uid} ${staff.surname}${
                    staff.surname ? " " + staff.name : staff.name
                  }${staff.patronymic ? " " + staff.patronymic : ""}`
                };
              }
            });

            this.filterCols.candidate_tn = {
              ...this.filterCols.candidate_tn,
              directory: { ...currentDirectory, ...newStaffDirectory }
            };
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };

  getAuthorDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    if (!valueFromSavedFilter?.length) return;

    try {
      const promises = valueFromSavedFilter.map(async (id) => {
        const response: ApiResponse<{
          id: string;
          name: string;
          showname: string;
        }> = await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "core",
          currentClass: "user",
          method: "getUser",
          params: { id }
        });
        return response;
      });

      const results = await Promise.all(promises);

      runInAction(() => {
        results.forEach((data) => {
          if (data.code === 200 && data.result) {
            const author = data.result;
            const title = author.showname || author.name;

            // Добавляем в справочник
            this.dictForFilter[author.id] = title;

            // Добавляем в directory для фильтров
            if ("author" in this.filterCols) {
              if (!this.filterCols.author.directory) {
                this.filterCols.author.directory = {};
              }

              this.filterCols.author.directory[author.id] = {
                newname: author.id?.toString(),
                title: title
              };
            }
          }
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    }
  };
}
