export const getTHeadTop = (
  savedFiltersHeight: number,
  hasSearch: boolean,
  isFirefox: boolean,
  hasInfoBlock = false,
  newTable = false
) => {
  const baseHeight = 59; // Высота шапки фильтра
  const searchHeight = hasSearch ? (newTable ? 37 : 40) : 0; // Высота строки поиска
  const infoBlockHeight = hasInfoBlock ? 38 : 0; // Высота инфоблока
  const firefoxOffset = isFirefox ? 2 : 0;

  return (
    savedFiltersHeight +
    baseHeight +
    searchHeight +
    infoBlockHeight +
    firefoxOffset
  );
};
