import * as yup from "yup";
import { dateSchema, stringSchema } from "shared/utils/validation/validation";

export const staffAddAwardSchema = yup.object({
  award_type: stringSchema.required("Поле обязательно для заполнения"),
  award_date_start: dateSchema.required("Поле обязательно для заполнения"),
  staff_id: yup
    .array()
    .of(yup.string().required())
    .min(1, "Поле обязательно для заполнения")
});
