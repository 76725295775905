import AllEmployees from "shared/assets/images/menuIcons/iconDefault/AllEmployees.svg";
import Vacation from "shared/assets/images/menuIcons/iconDefault/Vacation.svg";
import Rating from "shared/assets/images/menuIcons/iconDefault/Rating.svg";
import AddEmployee from "shared/assets/images/menuIcons/iconDefault/AddEmployee.svg";
import AddAward from "shared/assets/images/menuIcons/iconDefault/AddAward.svg";
import ImportEmployee from "shared/assets/images/menuIcons/iconDefault/ImportEmployee.svg";

import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
import EmployeeStatus from "shared/assets/images/menuIcons/iconDefault/EmployeeStatus.svg";
import WaitingWorker from "shared/assets/images/menuIcons/iconDefault/WaitingWorker.svg";
import PlannedBuildings from "shared/assets/images/menuIcons/iconDefault/PlannedBuildings.svg";

// в объектах ниже имя - название модуля + Module, ключ - название подмодуля, а значение иконка
// отображаемая в меню Все разделы (из menuIcons/iconsDefault)
const staffModule = {
  "": AllEmployees,
  staffRating: Rating,
  staffVacation: Vacation,
  add: AddEmployee,
  addAward: AddAward,
  import: ImportEmployee
};

const buildingModule = {
  "": Buildings,
  staffEvents: EmployeeStatus,
  watch: WaitingWorker,
  buildingReport: PlannedBuildings
};

// в экспортируемом объекте ключ - название модуля, значение - объект с иконками, соответствующий модулю
export const icons = {
  staff: staffModule,
  building: buildingModule
};
