import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import Select from "shared/ui/Inputs/Select";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import styles from "./termsControlCustomFilter.module.scss";
import TermsControlKindsTypesForm from "../../TermsControlKindsTypesForm";

type TermsControlCustomFilterProps = Partial<{
  valueField: string;
  values: FilterType;
  params: { [key: string]: Col };
  changeValue: (
    key: string,
    value:
      | string
      | number
      | string[]
      | {
          [key: string]: string | string[];
        }[]
      | FilterType["filter"]
  ) => void;
  changeOpenedWindows: (arg: string) => void;
  dictForArray: Record<string, string>;
  setDictForArray: (value: Record<string, string>) => void;
  selectsWithLoading: string[];
  pageSelectsInfo: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
}>;

const TermsControlCustomFilter = ({
  valueField,
  values,
  params,
  changeValue,
  changeOpenedWindows,
  dictForArray,
  setDictForArray,
  selectsWithLoading,
  pageSelectsInfo
}: TermsControlCustomFilterProps) => {
  const { safetyWorkTermsControlStore } = useStores();

  useEffect(() => {
    if (Array.isArray(values?.filter["uid"]) && values?.filter["uid"].length) {
      setDictForArray?.(safetyWorkTermsControlStore.dictForFilter);
    }
  }, [values?.filter["uid"]]);

  useEffect(() => {
    if (isEmpty(safetyWorkTermsControlStore.filterParams)) {
      safetyWorkTermsControlStore.setIsTableOpened(false);
    }
  }, [safetyWorkTermsControlStore.filterParams]);

  return (
    <>
      {["building", "uid", "kind"].map((key) => {
        const field = params[key];

        if (field?.directory) {
          const dict = field.directory;

          switch (key) {
            case "uid":
              return (
                <div key={key}>
                  <SelectMulti
                    key={key}
                    name={`filter.${key}`}
                    label={field.title}
                    title={field.title}
                    options={dict}
                    valueName={valueField || "newname"}
                    isSearchWithPagination={selectsWithLoading?.includes(key)}
                    page={pageSelectsInfo?.[key]?.page}
                    prevPage={pageSelectsInfo?.[key]?.prevPage}
                    maxPage={pageSelectsInfo?.[key]?.maxPage}
                    setPage={pageSelectsInfo?.[key]?.setPage}
                    getList={pageSelectsInfo?.[key]?.getList}
                    setSearchValue={pageSelectsInfo?.[key]?.setSearchValue}
                    searchValue={pageSelectsInfo?.[key]?.searchValue}
                    isLoading={pageSelectsInfo?.[key]?.isLoading}
                    searchPlaceholder={field.title}
                    onSelectedItemsChange={(items) => {
                      const newArray = items.map((item) => item.id);
                      changeValue?.(`filter.${key}`, newArray);
                    }}
                    onMenuOpen={() => changeOpenedWindows?.(`filter.${key}`)}
                    onMenuClose={() => changeOpenedWindows?.("")}
                    className={styles.container}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                  />
                </div>
              );
            case "building":
              return (
                <Select
                  key={key}
                  name={`filter.${key}`}
                  title={field.title}
                  label={
                    values.filter[key] && values.filter[key] !== -1
                      ? dict?.[values.filter[key] as string]?.title || ""
                      : null
                  }
                  options={dict}
                  isFloating
                  withClearBtn
                  isSearchable
                  isSearchWithPagination
                  placeholder={field.title}
                  onClick={(value) => {
                    changeValue?.(
                      `filter.${key}`,
                      value[valueField || "newname"] as string
                    );
                  }}
                  page={safetyWorkTermsControlStore.pageBuilding}
                  prevPage={safetyWorkTermsControlStore.prevPageBuilding}
                  maxPage={safetyWorkTermsControlStore.maxPageBuilding}
                  setPage={safetyWorkTermsControlStore.setPageBuilding}
                  getList={safetyWorkTermsControlStore.getBuildingsList}
                  setSearchValue={
                    safetyWorkTermsControlStore.setSearchValueBuilding
                  }
                  searchValue={safetyWorkTermsControlStore.searchValueBuilding}
                  isLoading={
                    safetyWorkTermsControlStore.isLoadingForBuildingList
                  }
                  valueName={valueField}
                />
              );
            case "kind":
              return (
                <TermsControlKindsTypesForm
                  key={key}
                  values={{
                    filter: {
                      kind: (values.filter.kind || []) as string[],
                      type: (values.filter.type || []) as string[][],
                      uid: (values.filter.uid || []) as string[]
                    }
                  }}
                  valueField={valueField}
                  prefix="filter"
                  onChangeValue={changeValue}
                />
              );
            default:
              return null;
          }
        }
        return null;
      })}
    </>
  );
};

export default observer(TermsControlCustomFilter);
