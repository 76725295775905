import { string, object } from "yup";
import { without } from "lodash";

import { dateSchema, stringSchema } from "shared/utils/validation/validation";

const validationForm = {
  kind: stringSchema,
  type: stringSchema,
  company: stringSchema,
  uid: stringSchema,
  comment: stringSchema,
  date_start: dateSchema.test(
    "date_start",
    "Дата начала действия не может быть позже даты окончания",
    function (val) {
      if (this.parent.date_end && val) {
        return Boolean(this.parent.date_end >= val);
      } else return true;
    }
  ),
  date_end: dateSchema.test(
    "date_end",
    "Дата окончания не может быть ранее даты начала действия",
    function (val) {
      if (this.parent.date_start && val) {
        return Boolean(this.parent.date_start <= val);
      } else return true;
    }
  )
};

export const getValidationSchema = (
  cols: string[],
  requiredCols: string[],
  isCertEndless: boolean | number
) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = validationForm[col]
      ? validationForm[col]
      : string().nullable();

    if (requiredCols.includes(col)) {
      isCertEndless && requiredCols.includes("date_end")
        ? (requiredCols = without(requiredCols, "date_end"))
        : (validationSchema[col] = validationSchema[col].required(
            "Поле обязательно для заполнения"
          ));
    }
  });

  return object(validationSchema);
};
