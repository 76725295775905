import styles from "./staffOneSalaryRetentionPPE.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { Table } from "react-bootstrap";
import {
  //Link,
  useParams
} from "react-router-dom";
import { useEffect, useState } from "react";
import OperationForm from "features/OperationForm";
import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { Button, ButtonTheme } from "shared/ui/Button";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

const currentTitles = ["date", "building", "sum"];

const cols = {
  date: { title: "Дата" },
  building: { title: "Объект" },
  sum: { title: "Сумма" }
};

const StaffOneSalaryRetentionPPE = () => {
  const { staffOneSalaryStore } = useStores();

  const { id } = useParams();
  const [isOpenModalLocal, setIsOpenModalLocal] = useState(false);
  const [isOpenWindowByCompensation, setIsOpenWindowByCompensation] =
    useState(false);

  useEffect(() => {
    if (!staffOneSalaryStore.isOpenModal) {
      setIsOpenWindowByCompensation(false);
      setIsOpenModalLocal(false);
    }
  }, [staffOneSalaryStore.isOpenModal]);

  return (
    <>
      <Button
        theme={ButtonTheme.CLEAR}
        type="button"
        onClick={() => {
          setIsOpenModalLocal(true);
          staffOneSalaryStore.setIsOpenModal(true);
        }}
        id="StaffOneSalaryRetentionPPE_addCompensation"
      >
        Добавить
        <IconAdd />
      </Button>
      {currentTitles.length && Object.values(cols).length ? (
        <Table className="w-50">
          <thead className={styles.thead}>
            <tr>
              {currentTitles.map((title) => {
                return (
                  <th key={title} className={styles.title}>
                    <div className="d-flex align-items-center">
                      <p className="m-0">{cols[title]["title"]}</p>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {staffOneSalaryStore.fields["retentionPPE"] &&
              Object.values(staffOneSalaryStore.fields["retentionPPE"]).map(
                (retention) => {
                  return (
                    <tr key={retention.id}>
                      {currentTitles.map((title) => {
                        switch (title) {
                          case "building":
                            return (
                              <td key={title}>
                                <a
                                  className={styles.link}
                                  href={`${fileUrl}/crm/building/?id=${retention.buildingID}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <p>{retention["buildingTitle"]}</p>
                                </a>
                                {/* <Link
                                  className={styles.link}
                                  id={`StaffOneRetentionPPE_redirectTo_${retention.buildingID}`}
                                  to={`/building/id=${retention.buildingID}`}
                                  target="_blank"
                                >
                                  <p>{retention["buildingTitle"]}</p>
                                </Link> */}
                              </td>
                            );
                          case "sum":
                            return (
                              <td key={title}>
                                <div className={styles.tdSum}>
                                  <p>
                                    {numberWithSpaces(
                                      retention[title] as number
                                    )}
                                  </p>
                                  <Button
                                    type="button"
                                    theme={ButtonTheme.SECONDARY}
                                    id={`StaffOneRetentionPPE_redirectTo_${retention.buildingID}`}
                                    className={styles.compensationBtn}
                                    onClick={() => {
                                      staffOneSalaryStore.setIsOpenModal(true);
                                      setIsOpenWindowByCompensation(true);
                                      setIsOpenModalLocal(true);
                                    }}
                                  >
                                    Добавить компенсацию
                                  </Button>
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td key={title}>
                                <p>{retention[title]}</p>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                }
              )}
          </tbody>
        </Table>
      ) : (
        ""
      )}
      <OperationForm
        title={
          isOpenWindowByCompensation
            ? "Добавление компенсации"
            : "Добавление удержания СИЗ"
        }
        isOpenModal={isOpenModalLocal}
        setIsOpenModal={() => {
          setIsOpenModalLocal(false);
        }}
        clearErrorsMessage={() => staffOneSalaryStore.clearErrorsMessage(id)}
        fields={["date", "building", "type", "money", "comment"]}
        nameOneOfStaff={staffOneSalaryStore.nameOneOfStaff[id]}
        type="add"
        errorsMessage={staffOneSalaryStore.errorsMessage[id]}
      />
    </>
  );
};

export default observer(StaffOneSalaryRetentionPPE);
