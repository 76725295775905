import Alert from "shared/ui/Alert";
import styles from "./table.module.scss";
import { Table } from "react-bootstrap";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { useStores } from "stores";
import { Conviction } from "stores/StaffModule/types/Conviction";
import { observer } from "mobx-react-lite";

type StaffOneConvictionTableProps = {
  setSelectedConviction: (el: Conviction) => void;
};

const StaffOneConvictionTable = ({
  setSelectedConviction
}: StaffOneConvictionTableProps) => {
  const { staffOneConvictionStore } = useStores();

  return staffOneConvictionStore.showFields.records &&
    Object.values(staffOneConvictionStore.convictions).length ? (
    <Table borderless className={styles.table} striped>
      <thead>
        <tr className={`${styles.tr} ${styles.thead}`}>
          {Object.values(staffOneConvictionStore.showFields.records).map(
            (title) => {
              return (
                <th key={title} className={styles.title}>
                  {staffOneConvictionStore.cols[title]?.title || ""}
                </th>
              );
            }
          )}
        </tr>
      </thead>

      <tbody>
        {Object.values(staffOneConvictionStore.convictions).map(
          (conviction) => {
            return (
              <tr
                key={conviction["id"]}
                className={styles.tr}
                onClick={() => setSelectedConviction(conviction)}
                id={`table_openRow_${conviction["id"]}`}
              >
                {Object.values(staffOneConvictionStore.showFields.records).map(
                  (field, idx) => {
                    switch (field) {
                      case "category":
                        return (
                          <td key={idx}>{conviction[field]?.["title"]}</td>
                        );
                      case "article":
                        return (
                          <td key={idx}>
                            {staffOneConvictionStore.cols["article"][
                              "directory"
                            ][conviction[field]["id"]]?.["title"] ||
                              staffOneConvictionStore.cols["article"][
                                "directory"
                              ][` ${conviction[field]["id"]}`]?.["title"] ||
                              `ст.${conviction[field]?.["number"]} ${
                                conviction[field]?.["part"]
                                  ? `ч.${conviction[field]?.["part"]}`
                                  : ""
                              } ${conviction[field]?.["title"]}` ||
                              ""}
                          </td>
                        );
                      case "uid":
                        return (
                          <td key={idx}>
                            {`ТН${staffOneConvictionStore.staffData[field]} ${
                              staffOneConvictionStore.staffData["surname"]
                            } ${staffOneConvictionStore.staffData["name"]} ${
                              staffOneConvictionStore.staffData["patronymic"] ||
                              ""
                            }` || ""}
                          </td>
                        );
                      case "author":
                        return (
                          <td key={idx}>
                            {conviction[field]?.["showname"] || ""}
                          </td>
                        );
                      default:
                        return (
                          <td key={idx}>
                            {typeof conviction[field] !== "object"
                              ? getFormattedDate(conviction[field])
                              : ""}
                          </td>
                        );
                    }
                  }
                )}
              </tr>
            );
          }
        )}
      </tbody>
    </Table>
  ) : (
    <Alert
      errors={{
        head: "Нет сведений о судимости",
        color: "empty"
      }}
    />
  );
};

export default observer(StaffOneConvictionTable);
