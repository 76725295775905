import styles from "./staffOneConvictionWindow.module.scss";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useStores } from "stores";

import { useEffect, useState } from "react";

import { Formik } from "formik";
import { format } from "date-fns";
import { getValidationConvictionSchema } from "shared/utils/validation/validationStaffConvictions";
import { Conviction } from "stores/StaffModule/types/Conviction";
import FormWindow from "shared/ui/FormWindow";
import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import ConvictionForm from "features/ConvictionForm";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

type StaffOneConvictionWindowProps = {
  type: string;
  id: string;
  activeRow: Partial<Conviction>;
  setActiveRow: (el: Partial<Conviction>) => void;
  setOpenWindow: (el: { type: string; open: boolean }) => void;
};

const StaffOneConvictionWindow = ({
  id,
  type,
  setOpenWindow,
  activeRow,
  setActiveRow
}: StaffOneConvictionWindowProps) => {
  const { staffOneConvictionStore } = useStores();
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | number;
  }>({});
  const [openedName, setOpenedName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    runInAction(() => {
      staffOneConvictionStore.errorsMessage = {};
      staffOneConvictionStore.error = false;
    });

    const newConvictionObject = {};
    getEntries(activeRow).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        switch (key) {
          case "category":
            newConvictionObject[key] = value["title"] || "";
            break;
          default:
            newConvictionObject[key] = value["showname"] || value["id"] || "";
        }
      } else {
        newConvictionObject[key] = value || "";
      }
    });

    switch (type) {
      case "detail":
      case "edit":
        setInitialValues(newConvictionObject);
        break;
      case "add":
        setInitialValues(getInitialValues());
        break;
      default:
        break;
    }
  }, [type, activeRow]);

  const getInitialValues = () => {
    const newInitialValues: { [key: string]: string | number } = {};

    getKeys(staffOneConvictionStore.cols).forEach((field) => {
      switch (field) {
        case "id":
          newInitialValues[field] = id;
          break;
        case "time_create":
          newInitialValues[field] = format(new Date(), "yyyy-MM-dd");
          break;
        case "comment":
          newInitialValues[field] = "";
          break;
        default:
          switch (staffOneConvictionStore.cols[field].type) {
            case "bool":
              newInitialValues[field] = 0;
              break;
            default:
              newInitialValues[field] = "";
              break;
          }
          break;
      }
    });

    return newInitialValues;
  };

  const changeOpened = (name: string) => {
    setOpenedName(openedName === name ? "" : name);
  };

  const handleDelete = async () => {
    await staffOneConvictionStore.deleteConviction(id, initialValues.id);
  };

  useEffect(() => {
    setIsLoading(staffOneConvictionStore.isLoadingForForm);
  }, [staffOneConvictionStore.isLoadingForForm]);

  return (
    <>
      {getValues(initialValues).length ? (
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationConvictionSchema(
            getValues(staffOneConvictionStore.showFields[type]),
            staffOneConvictionStore.requiredFields
          )}
          onSubmit={async (values) => {
            if (type === "add") {
              await staffOneConvictionStore.addConviction(
                id,
                values.article as string,
                values.date_start as string,
                values.punishment as string,
                values.term_type as string,
                values.comment as string
              );
            } else if (type === "edit") {
              await staffOneConvictionStore.editConviction(
                id,
                values.id as string,
                values.article as string,
                values.date_start as string,
                values.punishment as string,
                values.term_type as string,
                values.comment as string
              );
            }
          }}
          enableReinitialize
        >
          {({ values, handleSubmit, isValid, dirty, isSubmitting }) => {
            return (
              <FormWindow
                title={(() => {
                  switch (type) {
                    case "detail":
                      return "Просмотр записи";
                    case "edit":
                      return "Редактирование записи";
                    case "add":
                      return "Добавление записи";
                  }
                })()}
                setOpenWindow={() => setOpenWindow({ type: "", open: false })}
                saveBtnTitle={
                  type === "add" || type === "edit"
                    ? "Сохранить"
                    : "Редактировать"
                }
                saveBtnOnClickFunc={() => {
                  type === "detail"
                    ? setOpenWindow({ type: "edit", open: true })
                    : handleSubmit();
                }}
                saveBtnDisabledValue={
                  (type === "add" || type === "edit") &&
                  (!isValid || !dirty || isSubmitting)
                }
                saveSuccessModal={staffOneConvictionStore.success}
                onSuccessModalClose={() =>
                  staffOneConvictionStore.setSuccess(false)
                }
                deleteBtnTitle={type === "detail" && "Удалить запись"}
                deleteBtnImg={type === "detail" && <IconClose />}
                deleteBtnOnClick={() => {
                  type === "detail" && handleDelete();
                }}
                successMessageTitle={{
                  save: "Успешно сохранено",
                  delete: "Успешно удалено"
                }}
                addBtnTitle={
                  type === "add" ? "Отмена" : type === "edit" ? "Назад" : ""
                }
                addBtnOnClickFunc={() => {
                  type === "edit" &&
                    setOpenWindow({ type: "detail", open: true });
                }}
                addBtnImg={
                  type === "edit" ? (
                    <IconLeft className={styles.backIcon} />
                  ) : type === "add" ? (
                    <IconClose />
                  ) : null
                }
                hasBackBtn={type === "edit"}
                optionalCloseFunc={() => {
                  runInAction(() => {
                    staffOneConvictionStore.errorsMessage = {};
                    staffOneConvictionStore.error = false;
                  });
                  setActiveRow({});
                  setOpenWindow({ type: "", open: false });
                }}
                errors={getValues(staffOneConvictionStore.errorsMessage)}
                isLoadingForModal={
                  isLoading || staffOneConvictionStore.isLoading
                }
                containerClassName={styles.container}
                isScroll
                {...(type === "detail" && {
                  buttonsOrder: ["delete", "save"]
                })}
              >
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <ConvictionForm
                    nameCalendarOpened={openedName}
                    changeOpenedName={changeOpened}
                    fields={getValues(staffOneConvictionStore.showFields[type])}
                    requiredFields={staffOneConvictionStore.requiredFields}
                    type={type}
                    cols={staffOneConvictionStore.cols}
                    values={values}
                    activeRow={activeRow}
                  />
                </ErrorBoundary>
              </FormWindow>
            );
          }}
        </Formik>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(StaffOneConvictionWindow);
