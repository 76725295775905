import styles from "./staffOneSafetyWorkTable.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Table } from "react-bootstrap";

import { getTableArray } from "features/StaffOneSafetyWork";
import React, { useEffect, useState } from "react";
import StaffOneSafetyWorkTableWindow from "./StaffOneSafetyWorkTableWindow";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import Alert from "shared/ui/Alert";

type StaffOneSafetyWorkTableProps = {
  positions: Partial<{
    [key: string]: { [key: string]: string };
  }>;
  activePosition: string;
  activeRow: string;
  setActiveRow: (arg: string) => void;
};

const StaffOneSafetyWorkTable = ({
  positions,
  activePosition,
  activeRow,
  setActiveRow
}: StaffOneSafetyWorkTableProps) => {
  const { staffOneSafetyWorkStore } = useStores();

  const [openWindow, setOpenWindow] = useState(false);

  useEffect(() => {
    if (!openWindow) {
      setActiveRow("");
    }
  }, [openWindow]);

  const titles = [
    staffOneSafetyWorkStore.certsColsTable.kind.title,
    staffOneSafetyWorkStore.certsColsTable.type.title,
    staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")
      .length === 3
      ? `${
          staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")[0]
        } ${
          staffOneSafetyWorkStore.certsColsTable.date_start.title.split(" ")[1]
        }`
      : staffOneSafetyWorkStore.certsColsTable.date_start.title,
    staffOneSafetyWorkStore.certsColsTable.date_end.title
  ];

  return !staffOneSafetyWorkStore.certificates[
    staffOneSafetyWorkStore.selectedCompany
  ][activePosition] ? (
    <Alert
      errors={{
        head: `Не найдены сертификаты для должности ${
          positions[staffOneSafetyWorkStore.selectedCompany][activePosition]
        }`,
        color: "empty"
      }}
    />
  ) : staffOneSafetyWorkStore.selectedCompany !== "history" &&
    staffOneSafetyWorkStore.certificates[
      staffOneSafetyWorkStore.selectedCompany
    ][activePosition] ? (
    <>
      {getKeys(
        staffOneSafetyWorkStore.certificates[
          staffOneSafetyWorkStore.selectedCompany
        ]
      ).map((position) => {
        const table = getTableArray(
          position.toString(),
          activePosition,
          staffOneSafetyWorkStore.certificates[
            staffOneSafetyWorkStore.selectedCompany
          ][activePosition],
          staffOneSafetyWorkStore.certsTypeList
        );

        return position === activePosition && table.length ? (
          <React.Fragment key={activePosition}>
            <Table borderless className={styles.table}>
              <thead>
                <tr
                  className={`${styles.thead} ${
                    activeRow ===
                    `${table[0]?.id}-${activePosition}-${
                      staffOneSafetyWorkStore.selectedCompany
                    }-${0}`
                      ? styles.activeRow_sibling
                      : ""
                  }`}
                >
                  {titles.map((title) => {
                    return (
                      <th key={title} className={styles.title}>
                        {title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {table.map((row, idx) => {
                  return (
                    <tr
                      key={row.id}
                      className={`${styles.tr} ${
                        row.color ? styles[row.color] : ""
                      } ${
                        activeRow ===
                        `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}`
                          ? styles.activeRow
                          : ""
                      } ${
                        activeRow ===
                        `${table[idx + 1]?.id}-${activePosition}-${
                          staffOneSafetyWorkStore.selectedCompany
                        }-${idx + 1}`
                          ? styles.activeRow_sibling
                          : ""
                      }`}
                    >
                      {getKeys(row).map((cell) => {
                        if (cell === "color" || cell === "id") return;
                        return (
                          <td
                            className={styles.td}
                            key={`${cell}-${row.id}`}
                            id={`${cell}_${row.id}`}
                            onClick={() => {
                              activeRow !==
                                `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}` &&
                                setActiveRow(
                                  `${row.id}-${activePosition}-${staffOneSafetyWorkStore.selectedCompany}-${idx}`
                                );

                              setOpenWindow(true);
                            }}
                          >
                            {row[cell] ? row[cell] : "—"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {openWindow && (
              <StaffOneSafetyWorkTableWindow
                setOpenWindow={setOpenWindow}
                certificate={{
                  certificate: getValues(
                    staffOneSafetyWorkStore.certificates[
                      staffOneSafetyWorkStore.selectedCompany
                    ][activePosition]
                  ).find((id) => id.id === activeRow.slice(0, 40)),
                  company: getValues(
                    staffOneSafetyWorkStore.companiesTabs
                  ).find(
                    (id) => id.id === staffOneSafetyWorkStore.selectedCompany
                  )
                }}
              />
            )}
          </React.Fragment>
        ) : position === activePosition && !table.length ? (
          <Alert
            key={activePosition}
            errors={{
              head: `Не найдены сертификаты для должности ${
                positions[staffOneSafetyWorkStore.selectedCompany]?.[
                  activePosition
                ]
              }`,
              color: "empty"
            }}
          />
        ) : (
          ""
        );
      })}
    </>
  ) : null;
};

export default observer(StaffOneSafetyWorkTable);
