import { object, string } from "yup";
import { dateSchema, stringSchema } from "shared/utils/validation/validation";
import { without } from "lodash";

const validationForm = {
  kind: stringSchema,
  type: stringSchema,
  company: stringSchema,
  uid: stringSchema,
  comment: stringSchema,
  date_start: dateSchema
    .test("date_start", "Необходимо установить дату окончания", function (val) {
      if (val) {
        return this.parent.date_end;
      } else return !val;
    })
    .test(
      "date_start",
      "Дата начала не может быть позже даты окончания",
      function (val) {
        if (val) {
          return this.parent.date_end && this.parent.date_end >= val
            ? true
            : false;
        } else return false;
      }
    ),
  date_end: dateSchema
    .test(
      "date_end",
      "Нельзя установить дату окончания без даты начала",
      function (val) {
        if (val) {
          return this.parent.date_start;
        } else return !val;
      }
    )
    .test(
      "date_end",
      "Дата окончания не может быть ранее даты начала",
      function (val) {
        if (val) {
          return this.parent.date_start && this.parent.date_start <= val
            ? true
            : false;
        } else return false;
      }
    )
};

export const getValidationDate = (
  cols: string[],
  isCertEndless: boolean | number
) => {
  const validationSchema = {};

  cols.map((col) => {
    if (col === "date_end" && isCertEndless) {
      cols = without(cols, "date_end");
      validationSchema[col] = validationForm[col]
        ? validationForm[col]
        : string().nullable();
    }
  });

  return object(validationSchema);
};
