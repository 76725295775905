import classNames from "classnames";
import styles from "./photoAltGalleryThumbnails.module.scss";
import iconStar from "shared/assets/images/mainIcons/iconIndicatorStar.svg";
import { observer } from "mobx-react-lite";

type BottomPreviewGalleryProps = {
  loadedImages: {
    src: string | null;
    hash: string;
    isMain: number;
  }[];
  imageIndex: number;
  setImageIndex: (index: number) => void;
};

const PhotoAltGalleryThumbnails = ({
  loadedImages,
  imageIndex,
  setImageIndex
}: BottomPreviewGalleryProps) => {
  return (
    <div className={styles.bottomPreviewGallery}>
      {loadedImages.map((photo, index) => {
        if (photo.src !== null) {
          return (
            <div className={styles.photoWrapper} key={photo.hash}>
              <img
                className={classNames(styles.iconAddPhoto, {
                  [styles.iconAddPhotoDisabled]: index === imageIndex
                })}
                src={photo.src}
                onClick={() => setImageIndex(index)}
                alt="iconAddPhoto"
              />
              {photo.isMain > 0 && (
                <img src={iconStar} className={styles.iconStar} alt="Main" />
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default observer(PhotoAltGalleryThumbnails);
