import styles from "./textarea.module.scss";
import { useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import { Col } from "stores/utils/types/Col";
import { classNames } from "shared/utils/helpers/classNames";
import { ClearFieldButton } from "shared/ui/ClearFieldButton";
interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Для прокидывания стилей из родительского компонента
   */
  className?: string;
  /**
   * Отображается в заполненном поле
   */
  label?: string;
  /**
   * Разрешает перенос на новый абзац по клику на Enter
   */
  enableEnter?: boolean;
  /**
   * Добавляет кнопку очистки в поле ввода
   */
  withClearBtn?: boolean;
  /**
   * Применяет нужные стили если Textarea используется в sharedComponent CommentInputField
   */
  forCommentInputField?: boolean;
  /**
   * Максимальное количество строк
   */
  maxRows?: number;
  /**
   * Допустимое количество символов в поле комментария
   */
  commentCol?: Partial<Col>;
  /**
   * Обязательность поля
   */
  required?: boolean;
  /**
   * Заблокировано ли поле
   */
  disabled?: boolean;
  /**
   * Нужен ли счетчик символов
   */
  withoutCounter?: boolean;
  /**
   * Нужно ли скрывать ошибку и стили ошибки (для таблицы импорта, будет доработано для всех таблиц по умолчанию)
   */
  withoutErrorText?: boolean;
  textAreaRef?: React.RefObject<HTMLTextAreaElement>;
  /**
   * Значение текстового поля (для использования без Formik)
   */
  value?: string;
  /**
   * Обработчик изменения значения (для использования без Formik)
   */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * Обработчик потери фокуса (для использования без Formik)
   */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /**
   * Ошибка (для использования без Formik)
   */
  error?: string;
  /**
   * для использования без Formik
   */
  withoutFormik?: boolean;
}

const ALLOWED_NUM_OF_CHARS = 250;

const Textarea = ({
  className,
  label,
  enableEnter,
  withClearBtn,
  forCommentInputField,
  maxRows,
  commentCol,
  textAreaRef,
  withoutCounter,
  withoutErrorText,
  value: propValue,
  onChange: propOnChange,
  onBlur: propOnBlur,
  error: propError,
  withoutFormik = false,
  ...props
}: TextareaProps) => {
  const { name, required, placeholder, maxLength, disabled } = props;
  const [isTouched, setIsTouched] = useState(false); // Новое состояние

  // Логика для Formik
  const [field, meta] = !withoutFormik
    ? useField(name || "")
    : [{ onBlur: () => null }, {}];
  const { setFieldValue, setFieldTouched } = !withoutFormik
    ? useFormikContext()
    : { setFieldValue: () => null, setFieldTouched: () => null };

  // Логика для независимого использования
  const [localValue, setLocalValue] = useState(propValue || "");
  const [isFocus, setIsFocus] = useState(false);

  const initialCounterValue = useRef(
    commentCol?.length &&
      (commentCol.type === "text" || commentCol.type === "varchar")
      ? Number(commentCol.length)
      : ALLOWED_NUM_OF_CHARS
  );

  const [counter, setCounter] = useState(initialCounterValue.current);

  // Значение и обработчики в зависимости от режима
  const value = !withoutFormik ? field?.value : localValue;
  const error = !withoutFormik ? meta?.error : propError;
  const isError =
    !withoutErrorText && (!withoutFormik ? meta.touched : isTouched) && error;
  const isRequired = required && !isFocus && !value;

  const handleOnBlur = (
    event: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    setIsFocus(false);
    if (!withoutFormik) {
      field?.onBlur(event);
      setFieldTouched(field?.name);
    } else {
      propOnBlur && propOnBlur(event);
    }
    setIsTouched(true); // Устанавливаем touched при потере фокуса
  };

  const handleFocus = () => {
    setIsFocus(true);
    setIsTouched(true); // Устанавливаем touched при фокусе
  };

  const handleClick = () => {
    setIsFocus(true);
  };

  const handleResetTextarea = () => {
    if (!withoutFormik) {
      setFieldValue(field?.name, "");
    } else {
      setLocalValue("");
      propOnChange &&
        propOnChange({
          target: { value: "" }
        } as React.ChangeEvent<HTMLTextAreaElement>);
    }
    setCounter(initialCounterValue.current);
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (!withoutFormik) {
      setFieldValue(field?.name, newValue);
    } else {
      setLocalValue(newValue);
      propOnChange && propOnChange(event);
    }
    setCounter(initialCounterValue.current - newValue.length);
    setIsTouched(true); // Устанавливаем touched при изменении значения
  };

  const handleAddIndent = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    enableEnter ? "" : event.key === "Enter" && event.preventDefault();
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperRequired]: isRequired,
          [styles.wrapperInvalid]: isError && !isFocus && !value
        })}
      >
        {label && (isFocus || value) ? (
          <p
            className={classNames(styles.label, {
              [styles.labelRequired]: required,
              [styles.labelInvalid]: required && isError
            })}
          >
            {label}
          </p>
        ) : null}
        <TextareaAutosize
          ref={textAreaRef}
          name={name}
          value={value || ""}
          onBlur={handleOnBlur}
          placeholder={placeholder || " "}
          onChange={handleChange}
          onClick={handleClick}
          onKeyDown={handleAddIndent}
          onFocus={handleFocus}
          maxLength={
            !withoutCounter && initialCounterValue.current
              ? initialCounterValue.current
              : maxLength
          }
          maxRows={maxRows}
          disabled={disabled}
          className={classNames(
            styles.textarea,
            {
              [styles.textIndent]: withClearBtn,
              [styles.forCommentInputFieldTextarea]: forCommentInputField,
              [styles.withoutLabel]: !label,
              [styles.textareaError]: isError,
              [styles.required]: isRequired
            },
            [className]
          )}
        />
        {!disabled && !withoutCounter && (
          <div
            className={classNames(styles.initialCounterValue, {
              [styles.forCommentInputField]: forCommentInputField
            })}
          >
            Осталось символов:{" "}
            <span
              className={classNames(styles.green, {
                [styles.red]: counter <= 20
              })}
            >
              {!value ? initialCounterValue.current : counter} /{" "}
              {initialCounterValue.current}
            </span>
          </div>
        )}
        {withClearBtn ? (
          <ClearFieldButton
            name={name || ""}
            disabled={disabled}
            isFocused={isFocus}
            onClick={handleResetTextarea}
            className={value ? styles.clearBtnActive : styles.clearBtn}
          />
        ) : null}
        {!forCommentInputField && isError ? (
          <div
            className={classNames(styles.error, {
              [styles.errorWithoutCounter]: withoutCounter
            })}
          >
            {error}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Textarea;

export type TextareaAttrs = JSX.LibraryManagedAttributes<
  typeof Textarea,
  TextareaProps
>;
