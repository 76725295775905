import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import Handshake from "shared/assets/images/menuIcons/iconDefault/Handshake.svg";
import HandshakeSelected from "shared/assets/images/menuIcons/iconSelected/Handshake.svg";

import StatementSettings from "shared/assets/images/menuIcons/iconDefault/StatementSettings.svg";
import StatementSettingsSelected from "shared/assets/images/menuIcons/iconSelected/StatementSettings.svg";

const FriendInviteSectionOne = () => {
  const { menuStore } = useStores();
  const { tabId } = menuStore;

  const overview = [
    {
      text: "Приглашение",
      to: `/friendinvite/id=${tabId}`,
      moduleName: "friendInviteOne",
      submoduleName: "friendInviteOne",
      defaultIcon: Handshake,
      selectedIcon: HandshakeSelected
    }
  ];

  const action = [
    {
      text: "Редактировать",
      to: `/friendinvite/id=${tabId}/edit`,
      moduleName: "friendInviteOne",
      submoduleName: "friendInviteOneEdit",
      defaultIcon: StatementSettings,
      selectedIcon: StatementSettingsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {action.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default observer(FriendInviteSectionOne);
