import styles from "./alert.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores";

import { Link } from "react-router-dom";

import { ReactComponent as IconAttention } from "shared/assets/images/iconStatus/Attention.svg";
import { ReactComponent as IconCircleCheck } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconNotice } from "shared/assets/images/iconStatus/Notice.svg";
import { ReactComponent as IconCircleX } from "shared/assets/images/iconStatus/CircleX.svg";
import { ReactComponent as IconInfo } from "shared/assets/images/iconStatus/Info.svg";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconLeft/iconLeftWithoutStroke.svg";

import { Errors } from "stores/utils/types/ErrorsType";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";

import DOMPurify from "dompurify";

type AlertType = {
  /**
   * Ошибка, которую необходимо отобразить
   */
  errors: Partial<Errors["message"]>;
  /**
   * Иконка, если она отличается от стандартной
   */
  errorIcon?: JSX.Element;
  /**
   * Слово, по которому разбивается каждый элемент списка body
   */
  splitItemLi?: string;
  // TODO: расширить тип при рефакторинге компонента
  className?: Partial<{
    container: string;
  }>;
  /**
   * Если true, принудительно все ссылки открываются в новой вкладке
   */
  isLinkInNewTab?: boolean;
};

const errorIcons = {
  danger: <IconAttention className={styles.errorsIcon_danger} />,
  success: <IconCircleCheck />,
  warning: <IconNotice />,
  empty: <IconCircleX />,
  info: <IconInfo />
};

// в переменной задаем сколько элементов из поля list будут отображаться по умолчанию
const LIMITLIST = 3;

const Alert = ({
  errors,
  errorIcon,
  splitItemLi,
  className,
  isLinkInNewTab
}: AlertType) => {
  const { menuStore } = useStores();
  // стейт определяет раскрыты или скрыты поля из поля list ошибки
  const [isOpenedList, setIsOpenedList] = useState(false);
  // булевая переменная показывает наличие body у сообщения для отображения иконок в head/body
  const listBody =
    errors.body?.value?.list?.body ??
    (errors.body ? getEntries(errors.body)[0]?.[1]?.list?.body : {});
  const hasBody = getValues(listBody).length;

  const getErrorsList = (
    error: Errors["message"]["body"]["key"],
    id: string,
    item: string
  ) => {
    return (
      <li className={styles.errorItem} key={id}>
        {error.list.type === "link" ? (
          isLinkInNewTab ? (
            <Link
              className={styles.errorItemLink}
              id={id}
              to={`/${error.list.dir}/id=${id}`}
              target="_blank"
            >
              {item}
            </Link>
          ) : menuStore.openedModule === error.list.dir ||
            (menuStore.tabId.length &&
              menuStore.allWindows[`/${error.list.dir}`]) ? (
            <Link
              className={styles.errorItemLink}
              id={id}
              to={`/${error.list.dir}/id=${id}`}
            >
              {item}
            </Link>
          ) : (
            <Link
              className={styles.errorItemLink}
              id={id}
              to={`/${error.list.dir}/id=${id}`}
              target="_blank"
            >
              {item}
            </Link>
          )
        ) : !splitItemLi ? (
          <div
            className={styles.errorsTextWithLink}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item)
            }}
          />
        ) : (
          <div className={styles.itemList}>
            <div>{item.split(splitItemLi)[0]}</div>
            <div>{`${splitItemLi} ${item.split(splitItemLi)[1]}`}</div>
          </div>
        )}
      </li>
    );
  };

  return (
    <div
      className={classNames(
        styles.errors,
        { [styles.fitContent]: errors.color === "empty" },
        [className?.container]
      )}
      data-error={true}
    >
      <div
        className={
          styles[
            `errors_${errors.color !== "default" ? errors.color : "danger"}`
          ]
        }
      >
        {errors.head ? (
          <div
            className={classNames(styles.errorsHeading, {
              [styles.errorsHeadingHover]: hasBody > LIMITLIST
            })}
            id="AlertButton"
            onClick={() => hasBody > 1 && setIsOpenedList(!isOpenedList)}
          >
            <div className={styles.errorsHeadingCenter}>
              {errorIcon
                ? errorIcon
                : errors.color in errorIcons
                ? errorIcons[errors.color]
                : ""}

              <div
                className={styles.errorsHeadingText}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(errors.head || "")
                }}
              />
            </div>
            {hasBody > LIMITLIST ? (
              <IconExpand
                className={classNames(styles.bodyExpandIcon, {
                  [styles.bodyExpandIconOpened]: isOpenedList
                })}
                onClick={() => setIsOpenedList(!isOpenedList)}
              />
            ) : null}
          </div>
        ) : null}
        {errors.body
          ? getValues(errors.body).length > 1 ||
            getValues(errors.body).length === 1
            ? getEntries(errors.body).map(([key, error], i) => {
                return (
                  <div
                    className={classNames(styles.errorsBody, {
                      [styles.errorsBodyWithoutHead]: !errors.head
                    })}
                    key={`${errors.head}_${key}`}
                  >
                    <div
                      className={classNames("", {
                        [styles.errorsBodyIcon]: !errors.head
                      })}
                    >
                      {!errors.head
                        ? errorIcon
                          ? errorIcon
                          : errors.color in errorIcons
                          ? errorIcons[errors.color]
                          : ""
                        : ""}
                    </div>
                    <div
                      className={classNames(styles.error, {
                        [styles.errorWithBorder]:
                          !(i === 0 && !errors.head) &&
                          i < getEntries(errors.body).length
                      })}
                    >
                      <div className={styles.errorHeading}>
                        <div
                          className={styles.errorsHeadingText}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(error.head || "")
                          }}
                        />
                      </div>
                      {error.list ? (
                        <>
                          <ol className={styles.errorList}>
                            {error.list.body &&
                              getEntries(error.list.body).map(
                                ([id, item], index) => {
                                  if (index > LIMITLIST - 1) {
                                    return;
                                  }
                                  return getErrorsList(error, id, item);
                                }
                              )}
                          </ol>
                          {isOpenedList ? (
                            <ol
                              className={styles.errorList}
                              start={LIMITLIST + 1}
                            >
                              {error.list.body &&
                                getEntries(error.list.body).map(
                                  ([id, item], index) => {
                                    if (index < LIMITLIST) {
                                      return;
                                    }
                                    return getErrorsList(error, id, item);
                                  }
                                )}
                            </ol>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                    </div>
                    {!errors.head &&
                    getEntries(error.list.body).length > LIMITLIST ? (
                      <IconExpand
                        className={classNames(styles.bodyExpandIcon, {
                          [styles.bodyExpandIconOpened]: isOpenedList,
                          [styles.errorsBodyIcon]: !errors.head
                        })}
                        onClick={() => setIsOpenedList(!isOpenedList)}
                      />
                    ) : null}
                  </div>
                );
              })
            : null
          : null}
      </div>
    </div>
  );
};

// делаем именнованный экспорт для компонентов обернутых в компоненты высшего порядка
// необходимо для документации storybook, так как он не работает с оберткой компонента
export const Message = Alert;

export default observer(Alert);
