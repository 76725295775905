import styles from "./staffOneStatusesGraphHeader.module.scss";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";

import { useStores } from "stores/index";
import StaffOneStatusesGraphTodaybtn from "../StaffOneStatusesGraphTodaybtn";

import Tooltip from "shared/ui/Tooltip";
import StatusIcon from "shared/ui/StatusIcon";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import { classNames } from "shared/utils/helpers/classNames";

const StaffOneStatusesGraphHeader = () => {
  const { staffOneStatusesStore } = useStores();

  return (
    <thead className={styles.thead}>
      <StaffOneStatusesGraphTodaybtn />
      <tr className={styles.row}>
        <th
          className={`${styles.reverseIconCell}`}
          onClick={() => {
            staffOneStatusesStore.getReverseTable();
          }}
          id="StaffOneStatusesGraphHeader_reverseTable"
        >
          <Tooltip text="Реверс данных">
            <StatusIcon
              icon="iconarrowdouble"
              color={
                staffOneStatusesStore.isReversedTable
                  ? "blue-lazure"
                  : "bw-gray6"
              }
              className={styles.reverseIcon}
            />
          </Tooltip>
        </th>
        {staffOneStatusesStore.monthList.map((date, idx) => {
          return (
            <th
              key={date}
              colSpan={staffOneStatusesStore.monthStatusesCount[date]}
              className={classNames("", {
                [styles.monthCellWithButton]: idx === 0
              })}
            >
              <p className={styles.monthTitle}>
                {format(getParsedDate(date), "LLLL yyyy", {
                  locale: ru
                })[0].toUpperCase() +
                  format(getParsedDate(date), "LLLL yyyy", {
                    locale: ru
                  }).substring(1)}
              </p>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default observer(StaffOneStatusesGraphHeader);
