import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { browserName } from "react-device-detect";
import { useEffect } from "react";
import { useStores } from "stores";
import styles from "./termsControlTable.module.scss";
import TermsControlTableHeader from "./TermsControlTableHeader";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { Badge } from "shared/ui/Badge";
import StatusIcon from "shared/ui/StatusIcon";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { getValues } from "shared/utils/helpers/getValues";
import { ReactComponent as IconMessage } from "shared/assets/images/mainIcons/iconMessage.svg";
import Tooltip from "shared/ui/Tooltip";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import Alert from "shared/ui/Alert";
import Modal from "shared/ui/Modal";

type TermsControlTableProps = {
  filterList: () => void;
};

const TermsControlTable = ({ filterList }: TermsControlTableProps) => {
  const { safetyWorkTermsControlStore, menuStore, filterStore } = useStores();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      safetyWorkTermsControlStore.isScrolling &&
      safetyWorkTermsControlStore.page === safetyWorkTermsControlStore.prevPage
    ) {
      safetyWorkTermsControlStore.setPage(safetyWorkTermsControlStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      {getKeys(safetyWorkTermsControlStore.oneOfStaff).length ? (
        <Table
          className={styles.table}
          style={{
            marginTop: getTableMarginTop(
              filterStore.savedFiltersHeight,
              Boolean(
                getValues(safetyWorkTermsControlStore.filterParams).length
              ),
              filterStore.isOpenAdvancedSearch ||
                filterStore.isOpenedSavedFilterOptions ||
                filterStore.isOpenedColumnsOptions,
              browserName === "Firefox"
            ),
            transition: "0.2s"
          }}
        >
          <TermsControlTableHeader filterList={filterList} />
          <tbody className={styles.tableBody}>
            {getEntries(safetyWorkTermsControlStore.oneOfStaff).map(
              ([id, staff], index) =>
                getValues(staff.building).map((building, idx) =>
                  getEntries(building.position).map(([key, position]) => {
                    const positionsCount = getKeys(staff.building).length;
                    return (
                      <tr
                        key={key}
                        className={classNames(
                          styles.greyRow,
                          {
                            [styles.whiteRow]: index % 2
                          },
                          [styles.body]
                        )}
                      >
                        {idx === 0 && (
                          <td
                            rowSpan={positionsCount}
                            className={styles.firstStickyCol}
                          >
                            {index + 1}
                          </td>
                        )}
                        {idx === 0 && (
                          <td
                            rowSpan={positionsCount}
                            className={styles.secondStickyCol}
                          >
                            <Link
                              onClick={(event) => event.stopPropagation()}
                              to={`/staff/id=${id}`}
                              id={`TermsControlTable_goToStaff_${id}`}
                              target="_blank"
                              className={styles.linkToStaff}
                            >
                              <div>{`ТН${staff.uid.tn}`}</div>
                              <div className={styles.marginTop}>
                                {staff.uid.name}
                              </div>
                            </Link>
                            <div className={styles.marginTop}>
                              <span className={styles.dateText}>
                                Дата рождения:
                              </span>{" "}
                              {getFormattedDate(staff.uid.birthday)}
                            </div>
                          </td>
                        )}
                        <td className={styles.thirdStickyCol}>
                          <div className="d-flex">
                            <Badge
                              text={building.company.title}
                              colorName={building.company.color}
                            />
                            <StatusIcon
                              icon={building.event.icon}
                              color={building.event.color}
                              className={styles.statusIcon}
                            />
                            {building.event.title}
                          </div>
                          <Link
                            onClick={(event) => event.stopPropagation()}
                            id={`TermsControlTable_goToBuilding_${building.id}`}
                            to={`../../building/id=${building.id}`}
                            target="_blank"
                            className={styles.linkToBuilding}
                          >
                            <div className={styles.marginTop}>
                              {building.title}
                            </div>
                          </Link>
                        </td>
                        <td
                          className={classNames(styles.fourthStickyCol, {
                            [styles.stickyCellShadow]:
                              menuStore.scroll.scrollLeft
                          })}
                        >
                          <div>{position.title}</div>
                          <div className={styles.marginTop}>
                            <span className={styles.dateText}>
                              Дата трудоустройства:
                            </span>{" "}
                            {getFormattedDate(position.date_employment)}
                          </div>
                        </td>
                        {safetyWorkTermsControlStore.termsControlTypes.map(
                          (item) =>
                            getValues(item.types).map((type) => {
                              return (
                                <td key={type} className={styles.certCell}>
                                  {getValues(
                                    position.safetywork?.[item.kind] || {}
                                  )
                                    .filter((cert) => cert.type === type)
                                    .map((certificate) => {
                                      return (
                                        <div
                                          key={certificate.id}
                                          className={classNames(
                                            styles.cert,
                                            {},
                                            [
                                              styles[
                                                `cert_${certificate.color}`
                                              ]
                                            ]
                                          )}
                                        >
                                          <div className={styles.certStatus}>
                                            <StatusIcon
                                              icon={certificate.icon}
                                              color={certificate.color}
                                              className={styles.statusIcon}
                                            />
                                            {certificate.icon === "attention"
                                              ? "Не действителен"
                                              : certificate.icon === "hourglass"
                                              ? "Скоро закончится"
                                              : certificate.icon === "bigcheck"
                                              ? "Действителен"
                                              : certificate.icon === "infinity"
                                              ? "Нет срока"
                                              : ""}
                                          </div>
                                          <div className={styles.certStatus}>
                                            {certificate.endless
                                              ? `c ${getFormattedDate(
                                                  certificate.date_start
                                                )}`
                                              : `${getFormattedDate(
                                                  certificate.date_start
                                                )} - ${getFormattedDate(
                                                  certificate.date_end
                                                )}`}
                                          </div>
                                          {certificate.comment ? (
                                            <Tooltip
                                              text={`Комментарий: ${certificate.comment}`}
                                            >
                                              <IconMessage />
                                            </Tooltip>
                                          ) : null}
                                        </div>
                                      );
                                    })}
                                </td>
                              );
                            })
                        )}
                      </tr>
                    );
                  })
                )
            )}
          </tbody>
        </Table>
      ) : (
        <Alert
          errors={
            safetyWorkTermsControlStore.errorsMessage.head
              ? safetyWorkTermsControlStore.errorsMessage
              : { head: "Ничего не найдено", color: "empty" }
          }
          className={{ container: styles.alert }}
        />
      )}
      {safetyWorkTermsControlStore.success ? (
        <Modal
          type="success"
          show={safetyWorkTermsControlStore.success}
          onHide={() => {
            safetyWorkTermsControlStore.setSuccess(false);
          }}
          title="ТН скопированы"
          successIcon
          direction="row"
          autoCloseTime={2000}
        />
      ) : (
        ""
      )}
      {safetyWorkTermsControlStore.page !== 1 && (
        <div className={styles.firstCol}>
          {safetyWorkTermsControlStore.isLoading ? (
            <p className={styles.loading}>Пожалуйста, подождите</p>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default observer(TermsControlTable);
