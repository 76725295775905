import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useStores } from "stores/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import AwardsHeader from "./AwardsHeader";
import AwardsSearch from "./AwardsSearch";
import AwardsFields from "./AwardsFields";
import { useEffect } from "react";

const StaffOneAwards = () => {
  const { menuStore, staffOneAwardsStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    menuStore.sendTabId(id);
    staffOneAwardsStore.setSelectedOneForAllAwards(id);
    staffOneAwardsStore.setIsFocusSearch(false);
    menuStore.setOpenedModule("oneOfStaff");
    menuStore.setOpenedSubmodule("oneOfStaffAwards");
    menuStore.updateWindow({
      mainPath: `/staff/id=${id}`,
      path: `/staff/id=${id}/awards`
    });
  }, [id]);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsHeader />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsSearch id={id} />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AwardsFields />
      </ErrorBoundary>
    </>
  );
};

export default observer(StaffOneAwards);
