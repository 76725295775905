import styles from "./buildingOneStaff.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import LoadedComponent from "widgets/LoadedComponent";
import { useParams } from "react-router-dom";
import { useStores } from "stores";

import { Formik } from "formik";
import BuildingOneStaffTabs from "./BuildingOneStaffTabs";
import BuildingOneStaffHeader from "./BuildingOneStaffHeader";
import BuildingOneStaffTable from "./BuildingOneStaffTable";
import BuildingOneStaffWindow from "./BuildingOneStaffWindow";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import SearchStaffInput from "./SearchStaffInput";

const BuildingOneStaff = () => {
  const { id } = useParams();
  const { menuStore, buildingOneStore, buildingOneStaffStore } = useStores();
  const [isScaleChanged, setIsScaleChanged] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<Staff>(null);

  const [marginLeft, setMarginLeft] = useState(0);

  useEffect(() => {
    buildingOneStaffStore.setSelectedBuilding(id);

    menuStore.setOpenedModule("buildingOne");
    menuStore.setOpenedSubmodule("buildingOneStaff");
    menuStore.sendTabId(id);
    menuStore.updateWindow({
      mainPath: `/building/id=${id}`,
      path: `/building/id=${id}/staff`
    });

    // Очистка dismissHint при размонтировании
    return () => {
      buildingOneStaffStore.clearDismissHint();
    };
  }, [id]);

  useEffect(() => {
    setIsScaleChanged(true);
    setTimeout(() => {
      setIsScaleChanged(false);
    }, 500);

    menuStore.setMarginLeft(0);
  }, [window.innerWidth]);

  useEffect(() => {
    setMarginLeft(
      menuStore.scroll?.scrollLeft ? menuStore.scroll?.scrollLeft : 0
    );
  }, [menuStore.scroll]);

  return (
    <LoadedComponent isLoading={buildingOneStaffStore.isLoading[id]}>
      <>
        <Formik
          initialValues={
            buildingOneStaffStore.activeTab[id] &&
            buildingOneStaffStore.staffList[id]?.[
              buildingOneStaffStore.activeTab[id]
            ]
              ? buildingOneStaffStore.staffList[id][
                  buildingOneStaffStore.activeTab[id]
                ]
              : {}
          }
          enableReinitialize
          onSubmit={() => undefined}
        >
          {() => (
            <>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <BuildingOneStaffWindow staff_data={selectedStaff} />
              </ErrorBoundary>
              <div
                style={{
                  marginLeft: marginLeft,
                  transition:
                    !buildingOneStaffStore.isLoading && !isScaleChanged
                      ? "margin 0.8s ease"
                      : ""
                }}
                className={`w-100`}
              >
                <div className={styles.header}>
                  <div>
                    {buildingOneStore.selectedOne.title} <SearchStaffInput />
                  </div>

                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <BuildingOneStaffHeader />
                  </ErrorBoundary>
                </div>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <BuildingOneStaffTabs />
                </ErrorBoundary>
              </div>

              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                resetKeys={[buildingOneStaffStore.activeTab[id]]}
              >
                <BuildingOneStaffTable setSelectedStaff={setSelectedStaff} />
              </ErrorBoundary>
            </>
          )}
        </Formik>
      </>
    </LoadedComponent>
  );
};

export default observer(BuildingOneStaff);
