import { observer } from "mobx-react-lite";
import { browserName } from "react-device-detect";
import { useStores } from "stores";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import TermsControlTable from "./TermControlTable";
import { getKeys } from "shared/utils/helpers/getKeys";
import TermsControlCustomFilter from "./TermsControlCustomFilter";
import { useEffect } from "react";

type TermsControlTableWithFiltersProps = {
  filterList: () => void;
};

const TermsControlTableWithFilters = ({
  filterList
}: TermsControlTableWithFiltersProps) => {
  const { safetyWorkTermsControlStore, filterStore } = useStores();

  useEffect(() => {
    if (
      safetyWorkTermsControlStore.page !== 1 &&
      safetyWorkTermsControlStore.page !==
        safetyWorkTermsControlStore.prevPage &&
      safetyWorkTermsControlStore.isScrolling
    ) {
      safetyWorkTermsControlStore.getMoreCertsTermsControl();
    }
  }, [
    safetyWorkTermsControlStore.page,
    safetyWorkTermsControlStore.isScrolling
  ]);

  return (
    <TableWithStickyFilter
      isLoading={safetyWorkTermsControlStore.isLoading}
      isLoadingForFilters={safetyWorkTermsControlStore.isLoadingForFilters}
      getData={safetyWorkTermsControlStore.getData}
      cols={safetyWorkTermsControlStore.termsControlCols}
      params={safetyWorkTermsControlStore.termsControlColsParam}
      allCols={safetyWorkTermsControlStore.termsControlCols}
      currentCols={safetyWorkTermsControlStore.currentTitles}
      getDataWithFilter={
        safetyWorkTermsControlStore.getSafetyWorkListWithFilter
      }
      filterParams={safetyWorkTermsControlStore.filterParams}
      setQueryParams={safetyWorkTermsControlStore.setQueryParams}
      queryParams={safetyWorkTermsControlStore.queryParams}
      foundCounter={safetyWorkTermsControlStore.foundedStaff}
      itemName="сотрудников"
      theadTop={getTHeadTop(
        filterStore.savedFiltersHeight ?? -2,
        Boolean(getKeys(safetyWorkTermsControlStore.filterParams).length),
        filterStore.isOpenedSavedFilters && browserName === "Firefox"
      )}
      withoutSavedFilter
      withoutColsSetting
      withoutValuesSaving
      withoutAllParamsButton
      searchValue={safetyWorkTermsControlStore.searchValue}
      setSearchValue={safetyWorkTermsControlStore.setSearchValue}
      isSearchable
      searchInputTooltip="В этом поле можно искать несколько сотрудников, если вбить ТН номера через пробел"
      searchInputPlaceholder="Поиск в найденном"
      fixedWidthSearchInput
      selectMulti={["uid", "kind"]}
      defaultFields={{
        building: "",
        kind: [""],
        type: [[]] as string[][],
        uid: []
      }}
      selectsWithLoading={["uid"]}
      pageSelectsInfo={{
        uid: {
          page: safetyWorkTermsControlStore.pageStaff,
          prevPage: safetyWorkTermsControlStore.prevPageStaff,
          maxPage: safetyWorkTermsControlStore.maxPageStaff,
          searchValue: safetyWorkTermsControlStore.searchValueStaff,
          isLoading: safetyWorkTermsControlStore.isLoadingForStaffList,
          setSearchValue: safetyWorkTermsControlStore.setSearchValueStaff,
          setPage: safetyWorkTermsControlStore.setPageStaff,
          getList: safetyWorkTermsControlStore.getStaffList
        }
      }}
      customFilterMain={<TermsControlCustomFilter />}
    >
      <TermsControlTable filterList={filterList} />
    </TableWithStickyFilter>
  );
};

export default observer(TermsControlTableWithFilters);
