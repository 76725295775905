import styles from "./friendInviteOneInviteList.module.scss";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import List from "shared/ui/List";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { classNames } from "shared/utils/helpers/classNames";

type FriendInviteOneListProps = {
  id: string;
  title: string;
  value: string;
  referenseRef: React.MutableRefObject<HTMLTableDataCellElement>;
};

const FriendInviteOneList = ({
  id,
  title,
  value,
  referenseRef
}: FriendInviteOneListProps) => {
  const { friendInviteOneStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);

  const isStaff = title === "candidate_tn";
  const getList = () => friendInviteOneStore.getStaffList(title, false, true);
  const isOpenedList = friendInviteOneStore.openedList === `${id}_${title}`;

  useEffect(
    () => setIsLoading(friendInviteOneStore.isLoadingForStaffList),
    [friendInviteOneStore.isLoadingForStaffList]
  );

  useEffect(() => {
    if (
      friendInviteOneStore.pageStaff !== 1 &&
      friendInviteOneStore.pageStaff <= friendInviteOneStore.maxPageStaff &&
      friendInviteOneStore.pageStaff !== friendInviteOneStore.prevPageStaff
    ) {
      friendInviteOneStore.getStaffList(title, true, true);
    }
  }, [friendInviteOneStore.pageStaff, friendInviteOneStore.maxPageStaff]);

  const handleOpenedList = () => {
    requestAnimationFrame(() => {
      friendInviteOneStore.setOpenedList(`${id}_${title}`);
    });

    isStaff && getList();
  };

  const handleClickOnCell = () => {
    if (
      friendInviteOneStore.openedList !== `${id}_${title}` &&
      !["list", "true", "list-search"].includes(
        event.target["dataset"]["type"] || event.target["dataset"]["list"]
      )
    ) {
      handleOpenedList();
      isStaff && friendInviteOneStore.getStaffList(id, false, true);
    }
  };

  return (
    <>
      <IconExpand
        className={classNames(styles.icon, {
          [styles.iconOpenedSelect]: isOpenedList,
          [styles.iconCLosedSelect]: !isOpenedList
        })}
        onClick={handleClickOnCell}
      />
      {isOpenedList && (
        <List
          options={
            isStaff
              ? friendInviteOneStore.staffList || []
              : friendInviteOneStore.selects[title] || {}
          }
          value={value}
          setIsOpenedMenu={handleOpenedList}
          isOpenedMenu={isOpenedList}
          referenceRef={referenseRef}
          setSelectedItem={(item) => {
            friendInviteOneStore.editCol(id, {
              [`update[${title}]`]:
                (isStaff ? (item?.tn as string) : item?.id) || ""
            });
          }}
          valueName={isStaff ? "newname" : "id"}
          isLoading={isLoading}
          isSearchable
          isSearchWithPagination={isStaff}
          page={isStaff && friendInviteOneStore.pageStaff}
          prevPage={isStaff && friendInviteOneStore.prevPageStaff}
          maxPage={isStaff && friendInviteOneStore.maxPageStaff}
          setPage={isStaff && friendInviteOneStore.setPageStaff}
          getList={isStaff && getList}
          setSearchValue={isStaff && friendInviteOneStore.setSearchValueStaff}
          searchValue={isStaff && friendInviteOneStore.searchValueStaff}
        />
      )}
    </>
  );
};

export default observer(FriendInviteOneList);
