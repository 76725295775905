import { observer } from "mobx-react-lite";
import { Button, ButtonTheme } from "shared/ui/Button";
import StatusIcon from "shared/ui/StatusIcon";
import { useStores } from "stores";

const StaffImportUploadFileDownload = () => {
  const { staffImportStore } = useStores();
  const handleDownloadStatic = async () => {
    await staffImportStore.handleDownLoad();
  };

  return (
    <Button
      id="StaffImportUpload_download_xlsx"
      onClick={handleDownloadStatic}
      theme={ButtonTheme.SECONDARY}
    >
      <StatusIcon icon="iconexcel" /> Cкачать шаблон Excel
    </Button>
  );
};

export default observer(StaffImportUploadFileDownload);
