import { array, object, string } from "yup";

const stringSchema = string().trim().lowercase().nullable();
const text = /^[\p{L} ]+$/giu;

const shortSchema = stringSchema
  .matches(text, { message: "Только буквы" })
  .min(2, "Минимум 2 знака")
  .max(5, "Максимум 5 знаков")
  .required("Поле обязательно к заполнению");

const longSchema = stringSchema
  .matches(text, { message: "Только буквы" })
  .min(2, "Минимум 2 знака")
  .max(100, "Максимум 100 знаков")
  .required("Поле обязательно к заполнению");

export const validationSettingsFields = object({
  index: stringSchema
    .matches(/^[0-9]+$/gi, "Должно быть числом")
    .required("Поле обязательно к заполнению"),

  title: stringSchema
    .matches(text, { message: "Только буквы" })
    .min(2, "Минимум 2 знака")
    .max(100, "Не более 100 знаков")
    .required("Поле обязательно к заполнению"),

  comment: stringSchema,

  custom: object({
    short_name: stringSchema
      .matches(text, "Только буквы")
      .max(3, "Не более 3-х знаков"),

    buidling_condition: object({
      list: array().of(
        object({
          short: shortSchema,
          long: longSchema
        })
      ),
      i: object({
        short: shortSchema,
        long: longSchema
      })
    })
  }),

  statesList: array().of(
    object({
      short: shortSchema,
      long: longSchema
    })
  )
});
