import { useState } from "react";
import styles from "./friendInviteHistory.module.scss";
import StatusIcon from "shared/ui/StatusIcon";
import { classNames } from "shared/utils/helpers/classNames";
import { useStores } from "stores";
import { ErrorBoundary } from "react-error-boundary";
import FriendInviteHistoryOne from "./FriendInviteHistoryOne";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

const FriendInviteHistory = () => {
  const { friendInviteOneStore } = useStores();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.historyContainer}>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.header}>
        <StatusIcon
          icon="iconcollapsestroke"
          color="bw-gray6"
          className={classNames(styles.iconCollapse, {
            [styles.iconCollapseOpened]: isOpen,
            [styles.iconCollapseClosed]: !isOpen
          })}
        />
        <p className={styles.title}>История изменений</p>
      </div>
      {isOpen && (
        <div className={styles.content}>
          {getEntries(friendInviteOneStore.selectedOne?.history).map(
            ([time, group]) => {
              const historyItems = getValues(getValues(group)[0]);
              const user_name = getKeys(group)[0];

              return (
                <ErrorBoundary FallbackComponent={ErrorFallback} key={time}>
                  <FriendInviteHistoryOne
                    time={time}
                    user_name={user_name}
                    historyItems={historyItems}
                  />
                </ErrorBoundary>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default FriendInviteHistory;
