import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStores } from "stores";

import FilterMain from "./FilterMain";
import FilterHeader from "./FilterHeader";
import { Formik } from "formik";
import { Persist } from "formik-persist";

import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";

import { cloneDeep } from "lodash";
import { validationFilterFields } from "./FilterMain/validation";
import { useSearchParams } from "react-router-dom";

export type FilterProps = {
  filters?: {
    [key: string]: FilterType;
  };
  selectedFilter?: string;
  downloadedFilter?: string;
  setSelectedFilter?: (value: string) => void;
  setDownloadedFilter?: (value: string) => void;
  setFilters?: (
    action: string,
    id: string,
    values: FilterType
  ) => Promise<void>;
  setQueryParams?: (value: { [key: string]: string }) => void;

  isLoading: boolean;
  isLoadingForFilters: boolean;
  isSearchable?: boolean;
  searchValue?: string;
  foundCounter?: number;

  setSearchValue?: (value: string) => void;

  getData: (value?: string) => Promise<void>;

  cols: {
    [key: string]: Col;
  };
  params: {
    [key: string]: Col;
  };
  allCols: {
    [key: string]: Col;
  };
  currentCols: string[];
  getDataWithFilter?: (filter: FilterType) => void;
  selectMulti?: string[];

  isExport?: boolean;
  dataFileName?: string;
  listOrdered?: string;
  queryParams?: { [key: string]: string };
  createDataFile?: (extension: "csv" | "xls") => (
    | {
        [key: string]: string | number | string[];
      }
    | (string | number | string[])[]
  )[];
  setFiltersChanged?: (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => void;
  isScaleChanged?: boolean;
  searchInputTooltip?: string;
  withoutAdvancedSearch?: boolean;
  withoutSavedFilter?: boolean;
  withoutColsSetting?: boolean;
  withoutQueryParams?: boolean;
  withoutAllParamsButton?: boolean;
  defaultFields?: Record<string, string | string[] | string[][]>;
  dateRangeFields?: Record<string, string[]>;
  customFilterMain?: JSX.Element;
  selectsWithLoading?: string[];
  pageSelectsInfo?: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
  fixedWidthSearchInput?: boolean;
  withoutValuesSaving?: boolean;
  searchInputPlaceholder?: string;
};

const Filter = ({
  filters,
  selectedFilter,
  downloadedFilter,
  setSelectedFilter,
  setDownloadedFilter,
  setFilters,
  setQueryParams,

  isLoading,
  isLoadingForFilters,
  searchValue,
  setSearchValue,

  getData,

  cols,
  params,
  allCols,
  currentCols,
  getDataWithFilter,
  selectMulti,
  isSearchable,
  setFiltersChanged,
  listOrdered,
  queryParams,

  isExport,
  createDataFile,
  dataFileName,
  isScaleChanged,
  searchInputTooltip,
  withoutAdvancedSearch,
  withoutSavedFilter,
  withoutColsSetting,
  withoutQueryParams,
  withoutAllParamsButton,
  defaultFields,
  dateRangeFields,
  customFilterMain,
  selectsWithLoading,
  pageSelectsInfo,
  fixedWidthSearchInput,
  withoutValuesSaving,
  searchInputPlaceholder
}: FilterProps) => {
  const { filterStore, menuStore } = useStores();

  const [padding, setPadding] = useState(0);
  const [openedListName, setOpenedListName] = useState("");
  const [searchParams] = useSearchParams();

  // функция для записи текущего расширения окна браузера
  const handleResize = () => {
    filterStore.setDimensions({
      width: window.outerWidth,
      height: window.outerHeight
    });
  };

  // функция возврата списка в начальное положение при загрузке данных
  const setListToInitialPosition = () => {
    menuStore.scroll?.top < 0.01
      ? menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          top: 1,
          behavior: "smooth"
        })
      : menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          behavior: "smooth"
        });
  };

  useEffect(() => {
    // если в url присутствуют параметры фильтра, то раскрываем блок расширенного поиска
    if (searchParams.toString() && searchParams.toString().includes("filter")) {
      filterStore.setIsOpenAdvancedSearch(true);
    }
    window.addEventListener("resize", handleResize, false);
    filterStore.setSearchInputValue(searchValue);
    filterStore.setCurrentFilter(
      filterStore.creatingNewFilter(
        currentCols,
        filterStore.getDefaultArray(allCols, cols),
        defaultFields as Record<string, string | string[]>
      )
    );
    filterStore.setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, []);

  useEffect(() => {
    setListToInitialPosition();
  }, [isLoading, filterStore.dimensions, menuStore.isMenuShown]);

  // создание initialValues для создания фильтра
  useEffect(() => {
    if (!filterStore.isDiff) {
      filterStore.setCurrentFilter(
        cloneDeep(
          filterStore.creatingNewFilter(
            currentCols,
            filterStore.getDefaultArray(allCols, cols),
            defaultFields as Record<string, string | string[]>
          )
        )
      );
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (filterStore.isOpenAdvancedSearch) {
      setSelectedFilter && setSelectedFilter("new");
    } else {
      setSelectedFilter && setSelectedFilter(downloadedFilter);
    }
  }, [filterStore.isOpenAdvancedSearch, isLoading]);

  useEffect(() => {
    filters?.[downloadedFilter]?.general &&
      filterStore.setIsOpenedSavedFilterOptions(false);
  }, [downloadedFilter]);

  useEffect(() => {
    setPadding(
      (filterStore.savedFiltersHeight
        ? filterStore.savedFiltersHeight + 10
        : 0) + 35
    );
  }, [filterStore.isOpenedSavedFilters]);

  // функция отдает начальные значения для переключателя Switch, чтобы он отрисовался, если присутствует интервальная передача данных
  const getInitialPositionForDateRangeFields = () => {
    const initialPositionForDateRangeFields: Record<string, number> = {};
    if (dateRangeFields) {
      Object.keys(dateRangeFields).forEach((field) => {
        initialPositionForDateRangeFields[`isIntervalField_${field}`] = 0;
      });
    }
    return initialPositionForDateRangeFields;
  };

  const handleSubmit = (values: FilterType) => {
    setFilters(values.id === "new" ? "add" : "edit", values.id, values);
    filterStore.setIsDiff(false);
    setListToInitialPosition();
  };

  return (
    <>
      <FilterHeader
        isLoading={isLoading}
        filters={filters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        getData={getData}
        downloadedFilter={downloadedFilter}
        setDownloadedFilter={setDownloadedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        setQueryParams={setQueryParams}
        getDataWithFilter={getDataWithFilter}
        isSearchable={isSearchable}
        isExport={isExport}
        createDataFile={createDataFile}
        dataFileName={dataFileName}
        listOrdered={listOrdered}
        queryParams={queryParams}
        setListToInitialPosition={setListToInitialPosition}
        searchInputTooltip={searchInputTooltip}
        withoutAdvancedSearch={withoutAdvancedSearch}
        withoutSavedFilter={withoutSavedFilter}
        withoutColsSetting={withoutColsSetting}
        withoutQueryParams={withoutQueryParams}
        fixedWidthSearchInput={fixedWidthSearchInput}
        searchInputPlaceholder={searchInputPlaceholder}
      />

      {/* Formik для расширенного поиска */}
      {!withoutAdvancedSearch && filterStore.isOpenAdvancedSearch ? (
        <div
          style={{
            paddingTop: padding,
            marginLeft:
              isScaleChanged !== undefined && menuStore.scroll?.scrollLeft
                ? menuStore.scroll?.scrollLeft
                : "0px",
            marginTop:
              filterStore.savedFiltersHeight > 55
                ? `${filterStore.savedFiltersHeight / 2}px`
                : "0px",
            transition: !isScaleChanged ? "margin 0.4s ease" : ""
          }}
        >
          <Formik
            initialValues={{
              ...cloneDeep(
                filterStore.creatingNewFilter(
                  currentCols,
                  filterStore.getDefaultArray(allCols, cols),
                  defaultFields as Record<string, string | string[]>
                )
              ),
              ...getInitialPositionForDateRangeFields()
            }}
            validationSchema={validationFilterFields}
            enableReinitialize
            validateOnBlur
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              const changeValue = (
                key: string,
                value: string | number | string[] | FilterType["filter"]
              ) => {
                setFieldValue(key, value);
                const object = cloneDeep(values);
                setFiltersChanged &&
                  setFiltersChanged(selectedFilter, key, value);
                object[key] = value;
              };
              // компонент настройки колонок/параметров
              return (
                <>
                  <FilterMain
                    filters={filters}
                    selectedFilter={selectedFilter}
                    downloadedFilter={downloadedFilter}
                    setSelectedFilter={setSelectedFilter}
                    setFilters={setFilters}
                    currentCols={currentCols}
                    cols={cols}
                    params={params}
                    allCols={allCols}
                    getDataWithFilter={getDataWithFilter}
                    isLoading={isLoading}
                    isLoadingForFilters={isLoadingForFilters}
                    selectMulti={selectMulti}
                    setSearchValue={setSearchValue}
                    setQueryParams={setQueryParams}
                    openedListName={openedListName}
                    changeValue={changeValue}
                    setOpenedListName={setOpenedListName}
                    setListToInitialPosition={setListToInitialPosition}
                    defaultFields={
                      defaultFields as Record<string, string | string[]>
                    }
                    dateRangeFields={dateRangeFields}
                    customFilterMain={customFilterMain}
                    selectsWithLoading={selectsWithLoading}
                    pageSelectsInfo={pageSelectsInfo}
                    withoutSavedFilter={withoutSavedFilter}
                    withoutAllParamsButton={withoutAllParamsButton}
                  />
                  {/* Компонент Persist позволяет сохранаять стейт values formika */}
                  {/* для этого необходимо передать пропс name с уникальным названием */}
                  {/* location.pathname передан так как фильтр используется в нескольких модулях и тем самым для каждого модуля будет уникальный name фильтра */}
                  {Object.values(cols).length && !withoutValuesSaving ? (
                    <Persist name={`{filter-form-${location.pathname}}`} />
                  ) : (
                    ""
                  )}
                </>
              );
            }}
          </Formik>
        </div>
      ) : (
        ""
      )}

      {/* Formik для редактирования сохраненных фильтров */}
      {(!withoutColsSetting && filterStore.isOpenedColumnsOptions) ||
      (!withoutSavedFilter &&
        filterStore.isOpenedSavedFilterOptions &&
        !filters[downloadedFilter]?.general) ? (
        <div
          style={{
            paddingTop: padding,
            marginLeft:
              isScaleChanged !== undefined && menuStore.scroll?.scrollLeft
                ? menuStore.scroll?.scrollLeft
                : "0px",
            marginTop:
              filterStore.savedFiltersHeight > 55
                ? `${filterStore.savedFiltersHeight / 2}px`
                : "0px",
            transition: !isScaleChanged ? "margin 0.4s ease" : ""
          }}
        >
          <Formik
            initialValues={{
              ...cloneDeep(
                filters[selectedFilter] ||
                  filterStore.creatingNewFilter(
                    currentCols,
                    filterStore.getDefaultArray(allCols, cols),
                    defaultFields as Record<string, string | string[]>
                  )
              ),
              ...getInitialPositionForDateRangeFields()
            }}
            validationSchema={validationFilterFields}
            enableReinitialize
            validateOnBlur
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              const changeValue = (
                key: string,
                value: string | number | string[] | FilterType["filter"]
              ) => {
                setFieldValue(key, value);
                const object = cloneDeep(values);
                setFiltersChanged &&
                  setFiltersChanged(selectedFilter, key, value);
                object[key] = value;
              };
              // компонент настройки колонок/параметров
              return (
                <>
                  <FilterMain
                    filters={filters}
                    selectedFilter={selectedFilter}
                    downloadedFilter={downloadedFilter}
                    setSelectedFilter={setSelectedFilter}
                    setFilters={setFilters}
                    currentCols={currentCols}
                    cols={cols}
                    params={params}
                    allCols={allCols}
                    getDataWithFilter={getDataWithFilter}
                    isLoading={isLoading}
                    isLoadingForFilters={isLoadingForFilters}
                    selectMulti={selectMulti}
                    setSearchValue={setSearchValue}
                    setQueryParams={setQueryParams}
                    openedListName={openedListName}
                    changeValue={changeValue}
                    setOpenedListName={setOpenedListName}
                    setListToInitialPosition={setListToInitialPosition}
                    defaultFields={
                      defaultFields as Record<string, string | string[]>
                    }
                    dateRangeFields={dateRangeFields}
                    customFilterMain={customFilterMain}
                    selectsWithLoading={selectsWithLoading}
                    pageSelectsInfo={pageSelectsInfo}
                    withoutSavedFilter={withoutSavedFilter}
                  />
                </>
              );
            }}
          </Formik>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default observer(Filter);
