import styles from "./staffTableFooter.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

import { getEntries } from "shared/utils/helpers/getEntries";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";

const StaffTableFooter = () => {
  const { aregisterOneStore, menuStore } = useStores();
  const { id } = useParams();

  return (
    <tfoot
      className={classNames(styles.tfoot, {
        [styles.shadow]:
          aregisterOneStore.hasVerticalScroll() && menuStore.scroll?.top < 0.9
      })}
    >
      <tr>
        {getEntries(aregisterOneStore.presavesCols).map(([title, fields]) => {
          if (title === "main") {
            return (
              <Fragment key={title}>
                <td>
                  <div>
                    {`${
                      getEntries(aregisterOneStore.presavesStaffList[id]).length
                    }.`}
                  </div>
                </td>
                <td />
              </Fragment>
            );
          } else if (title === "group_by_presave")
            return getKeys(aregisterOneStore.presaves[id]).map((presave) =>
              getKeys(fields).map((field) => (
                <td key={field}>
                  {field !== "workshift" &&
                    numberWithSpaces(
                      aregisterOneStore.presavesTotal[id]?.[presave]?.[field],
                      false,
                      1
                    )}
                </td>
              ))
            );
          else if (title === "total")
            return getKeys(fields).map((field) => (
              <td key={`tfoot_${title}_${field}`} />
            ));
        })}
      </tr>
    </tfoot>
  );
};

export default observer(StaffTableFooter);
