import styles from "./staffOneSafetyWork.module.scss";

import { Certificate } from "stores/StaffModule/types/SafetyWork";
import { Company } from "stores/StaffModule/types/Company";

import { diff } from "deep-object-diff";
import StatusIcon from "shared/ui/StatusIcon";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import { Selects } from "stores/StaffModule/types/Selects";

type TableCol = {
  color: string;
  date_end: string | JSX.Element;
  date_start: string | JSX.Element;
  id: string;
  kind_title: string;
  type_title: string | JSX.Element;
};

export const getPositionsForOneOfStaff = (
  certificates: {
    [key: string]: { [key: string]: { [key: string]: Certificate } };
  },
  companies: { [key: string]: Company },
  last_position?: (arg: boolean) => void
): { [key: string]: { [key: string]: string } } => {
  const positions: {
    [company_id: string]: { [position_value: string]: string };
  } = {};
  const allPositionsFromEmployments: {
    [company_id: string]: { [position_value: string]: string };
  } = {};

  certificates &&
    getKeys(certificates).forEach((company) => {
      positions[company] = {};
      getKeys(certificates[company]).forEach((position) => {
        positions[company][position] = null;
      });
    });

  getEntries(companies).forEach(([company_id, company_data]) => {
    company_data?.employments &&
      getValues(company_data.employments).length &&
      getValues(company_data.employments).forEach((position) => {
        if (
          position.position?.value &&
          positions[company_id] &&
          !positions[company_id][position.position.value]
        ) {
          positions[company_id][position.position.value] =
            position.position_title || "Должность не указана";
        }

        if (last_position) {
          if (!allPositionsFromEmployments[company_id]) {
            allPositionsFromEmployments[company_id] = {};
          }

          if (position.position?.value) {
            allPositionsFromEmployments[company_id][position.position.value] =
              position.position_title || "Должность не указана";
          }
        }
      });
  });

  if (last_position) {
    last_position(
      getValues(diff(positions, allPositionsFromEmployments)).length
        ? true
        : false
    );
  }

  return positions;
};

export const getTableArray = (
  position: string,
  activePosition: string,
  certificatesList: { [key: string]: Certificate },
  certsTypeList: Selects["sw_type_list"]
): TableCol[] => {
  const table: TableCol[] = [];
  if (position === activePosition && getValues(certificatesList).length) {
    getValues(certificatesList).forEach((certificate) => {
      const isCertEndless = certsTypeList[certificate.type]?.custom?.endless;
      let color = "",
        icon: JSX.Element | null = null,
        tooltipStyles: {
          text: string;
          color: "warning" | "danger" | "";
        } = {
          text: "",
          color: ""
        };
      if (
        !isCertEndless &&
        (!certificate.date_start || !certificate.date_end)
      ) {
        color = "red";
        icon = <StatusIcon icon="attention" color="danger" />;
        tooltipStyles = {
          color: "danger",
          text: "Нет данных по документу."
        };
      } else {
        switch (certificate.tr) {
          case "tr-red":
            color = "red";
            icon = <StatusIcon icon="attention" color="danger" />;
            tooltipStyles = {
              color: "danger",
              text: "Истёк срок действия документа."
            };
            break;
          case "tr-warning":
            color = "warning";
            icon = <StatusIcon icon="hourglass" color="warning" />;
            tooltipStyles = {
              color: "warning",
              text: `Через ${calculateDays(new Date(), certificate.date_end)}
            ${getUnits(
              calculateDays(new Date(), certificate.date_end),
              "день",
              "дня",
              "дней"
            )} истекает срок действия документа`
            };
            break;
          default:
            break;
        }
      }

      table.push({
        id: certificate.id,
        color: color,
        kind_title: certificate.kind_title,
        type_title:
          icon && certificate.date_start && certificate.date_end ? (
            <div className={styles.typeWithIcon}>
              <div>{certificate.type_title}</div>
              <div
                className={classNames(styles.tooltip_forTypeWithIcon, {
                  [styles.tooltipWarning]: tooltipStyles.color === "warning",
                  [styles.tooltipDanger]: tooltipStyles.color === "danger"
                })}
                data-tooltip={tooltipStyles?.text}
              >
                <span>{icon}</span>
              </div>
            </div>
          ) : (
            certificate.type_title
          ),
        date_start: icon ? (
          <div
            className={classNames("", {
              [styles.tooltip]: certificate.date_start,
              [styles.tooltip_forIcon]: !certificate.date_start,
              [styles.tooltipWarning]: tooltipStyles.color === "warning",
              [styles.tooltipDanger]: tooltipStyles.color === "danger"
            })}
            data-tooltip={tooltipStyles?.text}
          >
            <>
              {certificate.date_start
                ? getFormattedDate(certificate.date_start)
                : icon}
            </>
          </div>
        ) : (
          getFormattedDate(certificate.date_start)
        ),
        date_end: icon ? (
          <div
            className={classNames("", {
              [styles.tooltip]: certificate.date_start,
              [styles.tooltip_forIcon]: !certificate.date_start,
              [styles.tooltipWarning]: tooltipStyles.color === "warning",
              [styles.tooltipDanger]: tooltipStyles.color === "danger"
            })}
            data-tooltip={tooltipStyles?.text}
          >
            <span>
              {certificate.date_end
                ? getFormattedDate(certificate.date_end)
                : icon}
            </span>
          </div>
        ) : isCertEndless ? (
          "Нет срока"
        ) : (
          getFormattedDate(certificate.date_end)
        )
      });
    });
  }

  return table;
};
