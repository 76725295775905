import styles from "./staffOneSafetyWork.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";

import StaffOneInfoSafetyWorkPositionsList from "./StaffOneInfoSafetyWorkPositionsList";
import StaffOneInfoSafetyWorkCertificateTable from "./StaffOneInfoSafetyWorkCertificateTable";
import StaffOneInfoSafetyWorkCommentsTable from "./StaffOneInfoSafetyWorkCommentsTable";
import LoadedComponent from "widgets/LoadedComponent";
import Alert from "shared/ui/Alert";
import { classNames } from "shared/utils/helpers/classNames";

const StaffOneInfoSafetyWork = () => {
  const { staffOneStore } = useStores();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("");
  const [noCertificateData, setNoCertificateData] = useState(false);
  const [activePosition, setActivePosition] = useState("");
  const [positions, setPositions] = useState<{
    [key: string]: { [key: string]: { [key: string]: string } };
  }>({});

  useEffect(() => {
    !(id in staffOneStore.safety_work) && staffOneStore.getSafetyWorkWidget(id);
    setActiveTab(staffOneStore.selectedCompany);
  }, [id, staffOneStore.selectedCompany]);

  return (
    <LoadedComponent
      // лоадер staffOneStore.isLoadingForWidgetMetrics[id] нужен для того, чтобы корректно отобразились табы по компаниям
      // применять во всех виджетах, где используются табы по компаниям
      isLoading={
        staffOneStore.isLoadingForWidget_safety_work[id] ||
        staffOneStore.isLoadingForWidgetMetrics[id]
      }
      withoutText
    >
      <div className={styles.container}>
        {noCertificateData && (
          <Alert
            errors={{
              head: "Должности сотрудника не соотносятся с трудоустройством",
              color: "warning"
            }}
            className={{ container: styles.emptyAlert }}
          />
        )}
        <div className={styles.header}>
          <SwitchedTabs
            tabs={staffOneStore.companiesTabs}
            changeActiveTab={setActiveTab}
            selectedTab={activeTab}
          />
          <div
            onClick={() => setActiveTab("comments")}
            className={classNames(styles.comments, {
              [styles.commentsActive]: activeTab === "comments"
            })}
          >
            Комментарии
          </div>
        </div>

        <StaffOneInfoSafetyWorkPositionsList
          activePosition={activePosition}
          setActivePosition={setActivePosition}
          positions={positions}
          setPositions={setPositions}
          id={id}
          activeTab={activeTab}
          setNoCertificateData={(boolean: boolean) =>
            setNoCertificateData(boolean)
          }
        />
        {positions?.[id] &&
          (activeTab !== "comments" ? (
            <StaffOneInfoSafetyWorkCertificateTable
              positions={positions[id]}
              activeTab={activeTab}
              activePosition={activePosition}
            />
          ) : (
            <StaffOneInfoSafetyWorkCommentsTable />
          ))}
      </div>
    </LoadedComponent>
  );
};

export default observer(StaffOneInfoSafetyWork);
