import { classNames } from "shared/utils/helpers/classNames";
import "./index.scss";
import { Tab, Tabs } from "react-bootstrap";

type SwitchedTabsProps = {
  /**
   * Массив табов
   */
  tabs: Array<{
    id: string;
    title: string;
    isBlockedTab?: boolean;
  }>;
  /**
   * Функция смены активного таба
   */
  changeActiveTab: (id: string) => void;
  /**
   * id активного таба
   */
  selectedTab: string | null;
  className?: Partial<{ container: string; tab: string }>;
};

const SwitchedTabs = ({
  tabs,
  changeActiveTab,
  selectedTab,
  className
}: SwitchedTabsProps) => {
  return (
    <Tabs
      activeKey={selectedTab}
      onSelect={(id: string) => {
        changeActiveTab(id);
      }}
      className={classNames("", {}, [className?.container || ""])}
    >
      {tabs.map((tab) =>
        tab.id !== undefined ? (
          <Tab
            key={`tab-${tab.title}-${tab.id}`}
            id={`tab-${tab.title}-${tab.id}`}
            eventKey={tab.id}
            title={tab.title}
            disabled={tab.isBlockedTab}
            className={classNames("", {}, [className?.tab || ""])}
          />
        ) : null
      )}
    </Tabs>
  );
};

export default SwitchedTabs;
