import { observer } from "mobx-react-lite";
import styles from "./windowTabs.module.scss";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";

const WindowTabs = () => {
  const { staffOneAwardsStore } = useStores();

  return (
    <div className={styles.tabs}>
      {getValues(staffOneAwardsStore.awardGroups).map((group) => {
        return (
          <button
            key={group.id}
            className={classNames(styles.button, {
              [styles.selectedButton]:
                group.id === staffOneAwardsStore.isActiveButton.id
            })}
            id={`WindowTabs_${group.id}`}
            onClick={() => {
              staffOneAwardsStore.setIsActiveButton(group);
              staffOneAwardsStore.setAwardFields(group);
            }}
          >
            {group.title}
          </button>
        );
      })}
    </div>
  );
};

export default observer(WindowTabs);
