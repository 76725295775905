import styles from "./friendInviteSelectsForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { classNames } from "shared/utils/helpers/classNames";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";

type FriendInviteSelectsFormProps = {
  id: string;
  title: string;
};
const FriendInviteSelectsForm = ({
  id,
  title
}: FriendInviteSelectsFormProps) => {
  const { friendInviteStore } = useStores();

  const isOpenedList = friendInviteStore.openedList === `${id}_${title}`;
  const invite =
    friendInviteStore.updatedInvites[id] ||
    friendInviteStore.invitesList[id] ||
    {};

  return (
    <div data-type="list" className={styles.field}>
      <div data-type="list">
        {friendInviteStore.selects[title]?.[invite?.[title]]?.title || ""}
      </div>
      <IconExpand
        data-type="list"
        className={classNames("", {
          [styles.iconOpenedSelect]: isOpenedList,
          [styles.iconCLosedSelect]: !isOpenedList
        })}
      />
    </div>
  );
};

export default observer(FriendInviteSelectsForm);
