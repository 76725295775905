import styles from "./staffOneSafetyWorkTabs.module.scss";

import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";

import SwitchedTabs from "shared/ui/Tabs/SwitchedTabs";
import { fileUrl } from "stores/utils/consts";
import { useParams } from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";

const StaffOneSafetyWorkTabs = () => {
  const { staffOneSafetyWorkStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    Object.values(staffOneSafetyWorkStore.companiesTabs).length &&
      staffOneSafetyWorkStore.openedAllMainCompanies[id] &&
      staffOneSafetyWorkStore.setSelectedCompany(
        staffOneSafetyWorkStore.openedAllMainCompanies[id]
      );
  }, [staffOneSafetyWorkStore.companiesTabs]);

  return Object.values(staffOneSafetyWorkStore.companiesTabs).length ? (
    <div className={styles.tabsContainer}>
      <SwitchedTabs
        tabs={Object.values(staffOneSafetyWorkStore.companiesTabs)}
        changeActiveTab={(tab) => {
          staffOneSafetyWorkStore.setSelectedCompany(tab);
        }}
        selectedTab={staffOneSafetyWorkStore.selectedCompany}
        className={{ container: styles.tabs }}
      />
      {/* удалить ссылку на текущее ПО после того, как в новом интерфейсе будет готов раздел с историей по ОТ */}
      <a
        className={styles.link}
        rel="noreferrer"
        target="_blank"
        href={`${fileUrl}/crm/safetywork/?action=showuidbyfilter&uid=${id}`}
      >
        <div
          className={styles.historyBtn}
          // раскомментировать, когда в новом интерфейсе будет готов раздел с историей по ОТ
          // onClick={() => setActiveTab("history")}
          // id="StaffOneSafetyWorkContent_btn_history"
        >
          <p>История</p>
          <StatusIcon icon="iconhistory" color="blue-lazure" />
        </div>
      </a>
    </div>
  ) : (
    <>{""}</>
  );
};

export default observer(StaffOneSafetyWorkTabs);
