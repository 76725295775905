import styles from "./awardsSearch.module.scss";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { useStores } from "stores";
import { classNames } from "shared/utils/helpers/classNames";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import SearchInput from "shared/ui/Inputs/SearchInput";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import { getValues } from "shared/utils/helpers/getValues";
import { Formik } from "formik";
import AwardsWindow from "../AwardsWindow";
import CollapsibleButton from "shared/ui/CollapsibleButton";

type AwardsSearchProps = {
  id: string;
};

const AwardsSearch = ({ id }: AwardsSearchProps) => {
  const { staffOneAwardsStore, userStore } = useStores();
  const [foundAwardsCounter, setFoundAwardsCounter] = useState(0);

  const inputRef = useRef<HTMLInputElement>();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    ref,
    handler: () => staffOneAwardsStore.setIsFocusSearch(false)
  });

  // При первом клике активируем режим поиска, сворачиваем виджеты,
  // при последующих кликах очищаем поле поиска
  // и сбрасываем счетчик найденных наград
  const setSearchModeOn = () => {
    staffOneAwardsStore.setFoundAwards([]);
    staffOneAwardsStore.setAwardsFieldsFoundDuringSearch([]);
    staffOneAwardsStore.changeOpenedAwards("delete", []);
    setFoundAwardsCounter(0);
    staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.setIsFocusSearch(true);
    staffOneAwardsStore.changeOpenedAwards(
      "add",
      staffOneAwardsStore.fieldsTitlеsArray
    );
  };

  const toggleFieldsShowing = () => {
    staffOneAwardsStore.searchValue && staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.changeOpenedAwards(
      staffOneAwardsStore.awardsArray?.length ? "delete" : "add",
      staffOneAwardsStore.fieldsTitlеsArray
    );
  };

  const clearSearch = () => {
    staffOneAwardsStore.setSearchValue("");
    staffOneAwardsStore.setIsFocusSearch(false);
  };

  // Открываем только те виджеты, в которых есть награды
  // название которых содержит подстроку заданную в поле поиска
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFoundAwardsCounter(0);
    const foundAwards: string[] = [];
    const awardsFieldsFoundDuringSearch: string[] = [];

    staffOneAwardsStore.setSearchValue(e.target.value.toLowerCase());

    getValues(staffOneAwardsStore.awards).map((award) => {
      if (
        award.type_title.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        !awardsFieldsFoundDuringSearch.includes(
          staffOneAwardsStore.awardGroups[award.custom.group].title
        ) &&
          awardsFieldsFoundDuringSearch.push(
            staffOneAwardsStore.awardGroups[award.custom.group].title
          );

        !foundAwards.includes(award.type_title) &&
          foundAwards.push(award.type_title);
      }
    });
    setFoundAwardsCounter(foundAwards.length);
    staffOneAwardsStore.setFoundAwards(foundAwards);
    staffOneAwardsStore.setAwardsFieldsFoundDuringSearch(
      awardsFieldsFoundDuringSearch
    );
    staffOneAwardsStore.changeOpenedAwards(
      "delete",
      awardsFieldsFoundDuringSearch
    );
  };

  return (
    <>
      {staffOneAwardsStore.openedWindowType === "add" && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AwardsWindow />
        </ErrorBoundary>
      )}
      <div className={styles.container}>
        <div className={styles.row}>
          <div
            onClick={setSearchModeOn}
            id="AwardsSearch_searchAwardsOn"
            ref={ref}
          >
            <Formik
              initialValues={{ awardsSearchInput: "" }}
              onSubmit={() => {
                return;
              }}
            >
              {({ setFieldValue }) => (
                <SearchInput
                  name="awardsSearchInput"
                  inputRef={inputRef}
                  placeholder="Поиск по наградам сотрудника"
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(
                      "awardsSearchInput",
                      e.target.value.toLowerCase()
                    );
                  }}
                  clearSearch={clearSearch}
                  withoutBtn
                />
              )}
            </Formik>
          </div>
          {userStore.userGroupsWithAddAward.includes(
            userStore.user?.groupid
          ) && (
            <div className={styles.addButton}>
              <ButtonsGroupForFormEdit
                blueBtnTitle="Добавить награду"
                blueBtnOnClick={() =>
                  staffOneAwardsStore.setOpenedWindowType("add")
                }
              />
            </div>
          )}
        </div>
        {!staffOneAwardsStore.isFocusSearch ? (
          <CollapsibleButton
            onClick={toggleFieldsShowing}
            openedAllWidgets={Number(!staffOneAwardsStore.awardsArray?.length)}
            id={id}
            className={{
              container: classNames(styles.openAllFieldsButton, {
                [styles.openAllFieldsButtonActive]:
                  !staffOneAwardsStore.awardsArray?.length
              })
            }}
          />
        ) : (
          <div
            className={classNames(styles.openAllFieldsButton, {
              [styles.openAllFieldsButtonActive]:
                !staffOneAwardsStore.awardsArray?.length
            })}
          >{`Найдено наград: ${foundAwardsCounter}`}</div>
        )}
      </div>
    </>
  );
};
export default observer(AwardsSearch);
