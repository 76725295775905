import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useStores } from "stores/index";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import FormWindow from "shared/ui/FormWindow";
import styles from "./staffOneEditPhotoAltGallery.module.scss";
import Modal from "shared/ui/Modal";
import PhotoAltGalleryUploadSection from "./PhotoAltGalleryUploadSection";
import PhotoAltGalleryViewSection from "./PhotoAltGalleryViewSection";
import PhotoAltGalleryManageBox from "./PhotoAltGalleryManageBox";
import PhotoAltGalleryThumbnails from "./PhotoAltGalleryThumbnails";
import StaffOneEditPhotoAltCropper from "../StaffOneEditPhotoAltCropper";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

type StaffOneEditPhotoAltGalleryProps = {
  loadedImages: { hash: string; isMain: number; src: string }[];
  imageIndex: number;
  setImageIndex: (imageIndex: number) => void;
  galleryState: string;
  setGalleryState: (galleryState: string) => void;
  userId: string;
};

export interface IFile extends File {
  preview?: string;
  path?: string;
}

const StaffOneEditPhotoAltGallery = ({
  loadedImages,
  imageIndex,
  setImageIndex,
  galleryState,
  setGalleryState,
  userId
}: StaffOneEditPhotoAltGalleryProps) => {
  const cropperRef = useRef(null);
  const { staffOneEditStore } = useStores();
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [editingFromViewing, setEditingFromViewing] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState<string | null>(null);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);

  const isLoading =
    staffOneEditStore.isLoadingPhotoAsMain || staffOneEditStore.isLoadingPhoto;

  // Функция для сохранения фотографии
  const handleSaveData = async () => {
    const imgData = cropperRef.current?.getCropImage();

    const file = imgData
      ? await staffOneEditStore.getDataURLtoFile(
          imgData,
          files[0]?.path || "newImage.jpg"
        )
      : files[0];
    staffOneEditStore.setUploadPhoto(
      loadedImages.length === 0 || loadedImages[imageIndex]?.isMain
        ? userId
        : "",
      file
    );

    setShowModal(false);
  };

  // Функция для закрытия окна редактирования без сохранения
  const handleCloseEditing = () => {
    setGalleryState("viewing");
    setEditingFromViewing(false);
  };

  // Функция для удаления фотографии
  const handleDeleteClick = () => {
    setPhotoToDelete(loadedImages[imageIndex]["hash"]);
    setShowModal(true);
  };

  // Получение названия изображения
  const imageName =
    galleryState === "editing"
      ? editingFromViewing
        ? loadedImages[imageIndex]?.src.split("/").pop() || "Без имени"
        : files[0]?.name || "Без имени"
      : loadedImages[imageIndex]?.src.split("/").pop()?.split("?")[0] ||
        "Без имени";

  // Очистка URL- объектов при размонтировании компонента
  useEffect(() => {
    return () => {
      files.forEach((file) => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [files]);

  return (
    <>
      <Formik
        initialValues={{
          loadedImages,
          photoChanged: false
        }}
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={handleSaveData}
      >
        {({ setFieldValue, handleSubmit }) => {
          const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
          // Используем хук useDropzone для обработки загрузки файлов
          const { getRootProps, getInputProps, open } = useDropzone({
            accept: {
              "image/jpeg": [],
              "image/png": [],
              "image/jpg": []
            },
            maxSize: MAX_FILE_SIZE,
            noClick: true,
            onDrop: (acceptedFiles, fileRejections) => {
              if (acceptedFiles.length > 0) {
                setFiles(
                  acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file)
                    })
                  )
                );
                setFieldValue("photoChanged", true);
                setGalleryState("editing");
                setUploadErrors([]);
              }

              if (fileRejections.length > 0) {
                // Обрабатываем ошибки
                const errors = fileRejections.map(
                  ({ file, errors: fileErrors }) => {
                    const e = fileErrors[0];

                    if (e.code === "file-invalid-type") {
                      return `Недопустимый тип файла ".${file.name
                        .split(".")
                        .pop()
                        .toLowerCase()}". Пожалуйста, загрузите изображение в формате .jpg, .jpeg, .png.`;
                    }

                    if (e.code === "file-too-large") {
                      return `Файл "${file.name}" слишком большой. 
                         Максимальный размер — 20 МБ.`;
                    }

                    return `Ошибка с файлом "${file.name}": ${e.message}`;
                  }
                );
                setUploadErrors(errors);
              }
            }
          });

          // Функция для закрытия окна с проверкой на несохранённые изменения
          const closeWindow = () => {
            setGalleryState("closed");
          };

          const handleMakeMainPhoto = async () => {
            await staffOneEditStore.makeAltPhotoTheMain(
              loadedImages[imageIndex]?.hash
            );
            setImageIndex(0);
          };

          return (
            <FormWindow
              title={
                galleryState === "uploading"
                  ? "Загрузка фото"
                  : galleryState === "editing"
                  ? "Редактирование фото"
                  : "Просмотр фото"
              }
              cancelBtnTitle={
                galleryState === "editing"
                  ? editingFromViewing
                    ? "Выйти без сохранения"
                    : "Отмена"
                  : null
              }
              cancelBtnOnClick={
                galleryState === "editing"
                  ? editingFromViewing
                    ? handleCloseEditing
                    : closeWindow
                  : null
              }
              cancelBtnImg={<IconClose />}
              setOpenWindow={() => setGalleryState("closed")}
              optionalCloseFunc={closeWindow}
              saveBtnTitle={
                galleryState === "editing"
                  ? editingFromViewing
                    ? "Сохранить"
                    : "Сохранить и добавить"
                  : null
              }
              saveBtnOnClickFunc={
                galleryState === "editing" ? handleSubmit : null
              }
              saveSuccessModal={staffOneEditStore.success}
              successMessageTitle={{ save: "Фотография успешно добавлена" }}
              buttonsOrder={["cancel", "save"]}
              isLoadingForModal={isLoading}
              onSuccessModalClose={() => {
                staffOneEditStore.setSuccess(false);
              }}
              isScroll
              isNotHaveButtons={galleryState !== "editing"}
            >
              <div className={styles.fullSize}>
                <div className={styles.grayBackgroundContainer}>
                  {galleryState === "uploading" && (
                    <PhotoAltGalleryUploadSection
                      getRootProps={getRootProps}
                      getInputProps={getInputProps}
                      open={open}
                      uploadErrors={uploadErrors}
                    />
                  )}
                  {galleryState === "editing" && (
                    <StaffOneEditPhotoAltCropper
                      file={
                        imageIndex !== null
                          ? loadedImages[imageIndex]["src"]
                          : files[0]?.preview
                      }
                      ref={cropperRef}
                    />
                  )}
                  {galleryState === "viewing" && (
                    <PhotoAltGalleryViewSection
                      imageIndex={imageIndex}
                      setImageIndex={setImageIndex}
                      loadedImages={loadedImages}
                      galleryState={galleryState}
                      cropperRef={cropperRef}
                    />
                  )}
                  {galleryState === "editing" && (
                    <div className={styles.imageNameBox}>
                      <span className={styles.imageName}>
                        Название изображения:
                      </span>{" "}
                      {imageName}
                    </div>
                  )}
                </div>
                {galleryState === "viewing" && (
                  <PhotoAltGalleryManageBox
                    imageName={imageName}
                    imageIndex={imageIndex}
                    makeMainPhoto={handleMakeMainPhoto}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
                {galleryState === "viewing" && (
                  <PhotoAltGalleryThumbnails
                    loadedImages={loadedImages}
                    imageIndex={imageIndex}
                    setImageIndex={setImageIndex}
                  />
                )}
              </div>
            </FormWindow>
          );
        }}
      </Formik>
      {/* Модальное окно для подтверждения удаления фото. */}
      {showModal && (
        <Modal
          type="clarification"
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          title="Вы уверены, что хотите удалить фото?"
          btnWithCrossTitle="Удалить"
          btnWithCrossOnClick={() => {
            if (photoToDelete) {
              staffOneEditStore.setDeletePhoto(photoToDelete);
            }
            setShowModal(false);
            setGalleryState("closed");
          }}
          blueBtnTitle="Отмена"
          blueBtnOnClick={() => {
            setShowModal(false);
          }}
          btnWithCrossImg
        />
      )}
      {staffOneEditStore.successDelete && (
        <Modal
          type="success"
          show={staffOneEditStore.successDelete}
          onHide={() => {
            staffOneEditStore.setSuccessDelete(false);
          }}
          title={"Фотография успешно удалена"}
          direction="row"
          autoCloseTime={2000}
          successIcon
        />
      )}
    </>
  );
};

export default observer(StaffOneEditPhotoAltGallery);
