import React from "react";
import styles from "./awardBlock.module.scss";
import Select from "shared/ui/Inputs/Select";
import { ViewField } from "shared/ui/ViewField";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import awardDefault from "shared/assets/images/mainIcons/awardDefault.png";
import { fileUrl } from "stores/utils/consts";
import { observer } from "mobx-react-lite";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

type StaffAwardBlockProps = {
  isDateOpen: boolean;
  setIsDateOpen: (val: boolean) => void;
  awardType: string;
  onSelectAward: (option: OptionWithTitle) => void;
  allAwards: Record<string, OptionWithTitle>;
  clearErrorsMessage: () => void;
  building: string;
};

const AwardBlock = ({
  isDateOpen,
  setIsDateOpen,
  awardType,
  onSelectAward,
  allAwards,
  clearErrorsMessage,
  building
}: StaffAwardBlockProps) => {
  return (
    <div className={styles.topBlock}>
      <div className={styles.inputGroup}>
        <Select
          options={allAwards}
          name="award_type"
          title="Награда"
          label={allAwards["id"]?.title}
          valueName="id"
          onClick={onSelectAward}
          required
        />
        <ViewField title="Группа" value={building} disabled />
        <DatePickerField
          name="award_date_start"
          title="Дата выдачи"
          isCalendarOpened={isDateOpen}
          setIsCalendarOpened={setIsDateOpen}
          onChange={clearErrorsMessage}
          required
        />
      </div>

      <div className={styles.imgBlock}>
        <img
          className={styles.img}
          src={
            awardType
              ? allAwards[awardType]?.img
                ? fileUrl + allAwards[awardType]?.img
                : awardDefault
              : awardDefault
          }
          alt={awardType ? allAwards[awardType]?.title : "Default"}
        />
        <p className={styles.imgSubtitle}>
          {awardType ? allAwards[awardType]?.title : "Заполните поля"}
        </p>
      </div>
    </div>
  );
};

export default observer(AwardBlock);
