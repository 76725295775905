import styles from "./salaryAddOperation.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { runInAction } from "mobx";

import { Formik } from "formik";

import LoadedComponent from "widgets/LoadedComponent";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import SalaryOperationsForm from "features/SalaryOperationsForm";
import Alert from "shared/ui/Alert";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "./validation";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

const SalaryAddOperation = () => {
  const { staff_id } = useParams();
  const { menuStore, salaryOperationsFormStore } = useStores();
  const { errorsMessage } = salaryOperationsFormStore;
  const [openedListName, setOpenedListName] = useState("");
  const [initialValues, setInitialValues] = useState(
    salaryOperationsFormStore.initialValues
  );
  const [requiredField, setRequiredField] = useState<string[]>([]);

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    menuStore.setOpenedModule("salary");
    menuStore.setOpenedSubmodule("add_operation");
    menuStore.updateWindow({
      mainPath: "/salary",
      path: "/salary/add_operation",
      title: "Финансы"
    });

    (async () => {
      await salaryOperationsFormStore.getDataForForm();

      if (staff_id) {
        await salaryOperationsFormStore.getStaffById(staff_id);
        runInAction(() => {
          salaryOperationsFormStore.initialValues.uid = staff_id;
        });
      } else {
        runInAction(() => {
          salaryOperationsFormStore.initialValues.uid = null;
        });
      }

      setInitialValues({ ...salaryOperationsFormStore.initialValues });
      setRequiredField(
        salaryOperationsFormStore.getRequiredFields(
          salaryOperationsFormStore.getNewInitialValues(staff_id || null)
        )
      );
    })();
  }, [staff_id]);

  const changeOpenedListName = (name: string) => {
    setOpenedListName(openedListName === name ? "" : name);
  };

  const employeeInfo =
    staff_id && salaryOperationsFormStore.staffInfo[staff_id]
      ? {
          tn: salaryOperationsFormStore.staffInfo[staff_id].tn,
          fio: salaryOperationsFormStore.staffInfo[staff_id].fio
        }
      : undefined;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent isLoading={salaryOperationsFormStore.isLoading}>
        <div className={styles.form}>
          {initialValues ? (
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={getValidationSchema(
                Object.keys(initialValues),
                requiredField
              )}
              onSubmit={(values) => {
                salaryOperationsFormStore.addOperation(
                  values.uid,
                  values.time_spending,
                  values.type,
                  values.money,
                  values.object,
                  values.comment,
                  values.initiator
                );
              }}
            >
              {({ values, isValid, dirty, handleSubmit, handleReset }) => {
                return (
                  <>
                    <div className={styles.form__fields}>
                      <SalaryOperationsForm
                        values={values}
                        openedListName={openedListName}
                        changeOpenedListName={changeOpenedListName}
                        employeeInfo={employeeInfo}
                      />
                    </div>

                    {errorsMessage ? (
                      <div className={styles.errors}>
                        <Alert errors={errorsMessage} />
                      </div>
                    ) : null}

                    <ButtonsGroupForSettings
                      saveBtnTitle="Создать"
                      saveBtnDisabled={!isValid || !dirty}
                      saveBtnOnClick={handleSubmit}
                      addBtnTitle="Очистить форму"
                      addBtnOnClick={handleReset}
                      addBtnDisabled={!dirty}
                      addBtnImg={<IconClose />}
                    />
                  </>
                );
              }}
            </Formik>
          ) : null}
        </div>
      </LoadedComponent>
    </ErrorBoundary>
  );
};

export default observer(SalaryAddOperation);
