import styles from "./textFieldGroup.module.scss";

import { getFieldsOfGroup } from "shared/utils/helpers/getFieldsCardRow";

import { Col } from "stores/utils/types/Col";

import { Fragment } from "react";
import { TextField, TextFieldAttrs, TextFieldTheme } from "shared/ui/TextField";
import { classNames } from "shared/utils/helpers/classNames";

export type TextFieldGroupProps = {
  /**
   * Имя основного поля для отображения.
   */
  name: string;
  /**
   * Массив имен полей, которые должны быть отрисованы в строке
   */
  fields: string[];
  /**
   * Массив всех имен полей, доступных для отрисовки в карточке (showFields)
   * Нужен для проверки доступности отображения полей из массива fields.
   */
  titles: string[];
  /**
   * Колонки полей с title
   */
  cols: Record<string, string | Col>;
  /**
   * Функция получения значения поля по его имени
   */
  getValue: (name: string, i: number) => TextFieldAttrs["value"];
  /**
   * Объект, содержащий стили, заданные в родителе
   * {
      row: optional, класс для контейнера строки,
      field: optional, класс для контейнера всех полей строки,
      title: optional, класс для всех заголовков,
      value: optional, класс для всех значений
    }
   */
  className?: Partial<{
    row: string;
  }> &
    TextFieldAttrs["className"];
  children?: (name: string, i: number) => JSX.Element;
};

export const TextFieldGroup = ({
  name,
  titles,
  fields,
  cols,
  getValue,
  children,
  className
}: TextFieldGroupProps) => {
  const group = getFieldsOfGroup(name, titles, fields);

  const getTitle = (name: string): string => {
    if (typeof cols[name] === "string") return cols[name] as string;
    if (typeof cols[name] === "object") return (cols[name] as Col).title;
  };

  return (
    group?.length > 0 && (
      <div className={classNames(styles.row, {}, [className?.row])}>
        {group.map((field_name, i) => (
          <Fragment key={field_name}>
            <TextField
              title={getTitle(field_name)}
              className={{
                field: `${i > 0 ? styles.borderLeft : ""} ${
                  className?.field || ""
                }`,
                ...(className ? className : {})
              }}
              value={getValue(field_name, i)}
              // Для корректного отображения ссылок
              theme={TextFieldTheme.LINK}
            />
            {children && children(field_name, i)}
          </Fragment>
        ))}
      </div>
    )
  );
};
