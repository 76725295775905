import { getEntries } from "./getEntries";

type SelectItem = {
  id?: string;
  newname?: string;
  title: string;
  [key: string]: unknown;
};

type Selects = Record<string, Record<string, SelectItem>>;

export const transformSelectsToDirectory = (selects: Selects) => {
  const directory: Record<
    string,
    Record<string, { newname: string; title: string }>
  > = {};

  getEntries(selects).forEach(([key, value]) => {
    directory[key] = {};

    getEntries(value).forEach(([id, item]) => {
      directory[key][id] = {
        newname: item.newname || id,
        title: item.title
      };
    });
  });

  return directory;
};
