import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import styles from "./termsControlTableHeader.module.scss";
import { ReactComponent as IconCopy } from "shared/assets/images/mainIcons/iconCopy.svg";
import { ReactComponent as IconArrowDouble } from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import Tooltip from "shared/ui/Tooltip";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";

type TermsControlTableHeaderProps = {
  filterList: () => void;
};

const TermsControlTableHeader = ({
  filterList
}: TermsControlTableHeaderProps) => {
  const { safetyWorkTermsControlStore, menuStore } = useStores();

  return (
    safetyWorkTermsControlStore.termsControlTypes.length && (
      <thead>
        <tr className={classNames(styles.whiteRow, {}, [styles.header])}>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.firstStickyCol,
              styles.foundedNumbers
            ])}
            rowSpan={2}
          >
            {safetyWorkTermsControlStore.foundedStaff}
          </th>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.secondStickyCol
            ])}
            rowSpan={2}
          ></th>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.thirdStickyCol
            ])}
            rowSpan={2}
          ></th>
          <th
            rowSpan={2}
            className={classNames(
              styles.titleCell,
              {
                [styles.stickyCellShadow]: menuStore.scroll.scrollLeft
              },
              [styles.withBorderBottom, styles.fourthStickyCol]
            )}
          >
            <div>
              {
                safetyWorkTermsControlStore.termsControlColsParam["kind"][
                  "title"
                ]
              }
            </div>
            <div>
              {
                safetyWorkTermsControlStore.termsControlColsParam["type"][
                  "title"
                ]
              }
            </div>
          </th>
          {safetyWorkTermsControlStore.termsControlTypes.map((item, ind) => {
            return (
              <th
                key={`${item.kind}_${ind}`}
                className={styles.certTitle}
                colSpan={getKeys(item.types).length}
              >
                {
                  safetyWorkTermsControlStore.termsControlSelects["kind"][
                    item.kind
                  ]?.title
                }
              </th>
            );
          })}
        </tr>
        <tr className={classNames(styles.whiteRow, {}, [styles.header])}>
          {safetyWorkTermsControlStore.termsControlTypes.map((item) =>
            getValues(item.types).map((value) => {
              return (
                <th className={styles.certTitle} key={value} rowSpan={2}>
                  {
                    safetyWorkTermsControlStore.termsControlSelects["type"][
                      value
                    ]?.title
                  }
                </th>
              );
            })
          )}
        </tr>
        <tr className={classNames(styles.whiteRow, {}, [styles.header])}>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.firstStickyCol
            ])}
          >
            №
          </th>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.secondStickyCol
            ])}
          >
            {safetyWorkTermsControlStore.termsControlColsParam["uid"]["title"]}
            <IconArrowDouble
              className={classNames(styles.sortIcon, {
                [styles.sortIconActive]:
                  "ASC" === safetyWorkTermsControlStore.ordered
              })}
              onClick={() => filterList()}
              id={`termsControlTableHeader_sortBy_${"uid"}`}
            />
            <Tooltip
              color="blue-lazure"
              placement="right-end"
              text="Копировать все ТН"
            >
              <IconCopy
                className={styles.copyIcon}
                onClick={() => safetyWorkTermsControlStore.massCopyTn()}
                id="termsControlTableHeader_copyAllTn"
              />
            </Tooltip>
          </th>
          <th
            className={classNames(styles.titleCell, {}, [
              styles.thirdStickyCol
            ])}
          >
            <div className={styles.title}>
              {
                safetyWorkTermsControlStore.termsControlColsParam["building"][
                  "title"
                ]
              }
            </div>
          </th>
          <th
            className={classNames(
              styles.titleCell,
              {
                [styles.stickyCellShadow]: menuStore.scroll.scrollLeft
              },
              [styles.fourthStickyCol]
            )}
          >
            <div className={styles.title}>Должность</div>
          </th>
        </tr>
      </thead>
    )
  );
};

export default observer(TermsControlTableHeader);
