import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { useStores } from "stores";
import SafetyWorkListTable from "./SafetyWorkListTable";
import { browserName } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import SafetyWorkListCustomFilter from "./SafetyWorkListCustomFilter";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const SafetyWorkList = () => {
  const { menuStore, safetyWorkListStore, filterStore } = useStores();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams: { [key: string]: string | string[] } = {};

  useEffect(() => {
    // условие, которое проверяет наличие query запроса в поисковой строке
    // если есть, то в filterParams стора записываются параметры запроса из query запроса
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      safetyWorkListStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      Object.entries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "order":
            safetyWorkListStore.setSafetyWorkListOrder(value);
            break;
          case "ordered":
            safetyWorkListStore.setSafetyWorkListOrdered(value);
            break;

          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      safetyWorkListStore.setFilterParamsFromQuery(queryParams);
    }

    if (!safetyWorkListStore.safetyWorkList.length) {
      safetyWorkListStore.getData();
    }
    menuStore.setOpenedModule("safetywork");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/safetywork", "Охрана труда");
  }, []);

  // обновляет параметры query запроса в поисковой строке
  // при изменении переменных из массива зависимостей
  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    Object.entries(safetyWorkListStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );
    if (safetyWorkListStore.safetyWorkListOrder) {
      filterParams["order"] = safetyWorkListStore.safetyWorkListOrder;
    }
    if (safetyWorkListStore.safetyWorkListOrdered) {
      filterParams["ordered"] = safetyWorkListStore.safetyWorkListOrdered;
    }

    safetyWorkListStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [
    safetyWorkListStore.filterParams,
    safetyWorkListStore.safetyWorkListOrder,
    safetyWorkListStore.safetyWorkListOrdered
  ]);

  // обновляет параметры query запроса в поисковой строке при переходе между вкладками приложения
  useEffect(() => {
    if (Object.keys(safetyWorkListStore.queryParams).length) {
      setSearchParams(safetyWorkListStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      safetyWorkListStore.page !== 1 &&
      safetyWorkListStore.page <= safetyWorkListStore.maxPage &&
      safetyWorkListStore.page !== safetyWorkListStore.prevPage
    ) {
      safetyWorkListStore.getMoreSafetyWorkList();
    }
  }, [safetyWorkListStore.page, safetyWorkListStore.maxPage]);

  const filterList = (order: string) => {
    safetyWorkListStore.setSafetyWorkListOrder(order);
    if (order === safetyWorkListStore.safetyWorkListOrder) {
      safetyWorkListStore.setSafetyWorkListOrdered(
        safetyWorkListStore.safetyWorkListOrdered === "DESC" ? "ASC" : "DESC"
      );
    } else {
      safetyWorkListStore.setSafetyWorkListOrdered("DESC");
    }
    safetyWorkListStore.setPage(1);

    //обновляет параметры query запроса в поисковой строке при применении сортировки
    safetyWorkListStore.setQueryParams({
      ...safetyWorkListStore.queryParams,
      order,
      ordered: safetyWorkListStore.safetyWorkListOrdered
    });
    setSearchParams({
      ...safetyWorkListStore.queryParams,
      order,
      ordered: safetyWorkListStore.safetyWorkListOrdered
    });
    safetyWorkListStore.getData();
  };

  useEffect(() => {
    if (
      safetyWorkListStore.pageStaff !== 1 &&
      safetyWorkListStore.pageStaff <= safetyWorkListStore.maxPageStaff &&
      safetyWorkListStore.pageStaff !== safetyWorkListStore.prevPageStaff
    ) {
      safetyWorkListStore.getMoreStaff();
    }
  }, [safetyWorkListStore.pageStaff, safetyWorkListStore.maxPageStaff]);

  const createDataFile = (format: "csv" | "xls") => {
    return safetyWorkListStore.safetyWorkList.map((item) => {
      const newItem: { [key: string]: string | number | string[] } = {};
      safetyWorkListStore.currentTitles.forEach((title) => {
        switch (title) {
          case "kind":
          case "type":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] =
              safetyWorkListStore.safetyWorkSelects[title]?.[item[title]][
                "title"
              ]);
          case "uid":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] = `${item["surname"] || ""} ${item["name"] || ""} ${
              item["patronymic"] || ""
            }`);
          case "date_start":
          case "date_end":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] = getFormattedDate(item[title]));
          case "company":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] = item["title_company"]);
          case "status_certificate":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] = item[title] === "Ok" ? "Действует" : "Не действителен");
          case "building":
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] =
              item["events_type"] &&
              Object.values(item["events_type"])
                .map(
                  (building) =>
                    `(${building["title_company"]}) ${
                      building["title_building"]
                    } ${
                      safetyWorkListStore.buildingEventsType[
                        building["type_event"]
                      ]?.title
                    }`
                )
                .join("\n"));
          default:
            return (newItem[
              safetyWorkListStore.safetyWorkCols[title]["title"]
            ] = item[title]);
        }
      });
      return format === "csv" ? Object.values(newItem) : newItem;
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <TableWithStickyFilter
        isLoading={safetyWorkListStore.isLoading}
        isLoadingForFilters={safetyWorkListStore.isLoadingForFilters}
        getData={safetyWorkListStore.getSafetyWorkList}
        selectedFilter={safetyWorkListStore.selectedFilter}
        setSelectedFilter={safetyWorkListStore.setSelectedFilter}
        downloadedFilter={safetyWorkListStore.downloadedFilter}
        setDownloadedFilter={safetyWorkListStore.setDownloadedFilter}
        cols={safetyWorkListStore.safetyWorkCols}
        params={safetyWorkListStore.safetyWorkColsParam}
        allCols={safetyWorkListStore.safetyWorkCols}
        currentCols={safetyWorkListStore.currentTitles}
        filters={safetyWorkListStore.filters}
        getDataWithFilter={safetyWorkListStore.getSafetyWorkListWithFilter}
        filterParams={safetyWorkListStore.filterParams}
        setFilters={safetyWorkListStore.setFilters}
        setQueryParams={safetyWorkListStore.setQueryParams}
        queryParams={safetyWorkListStore.queryParams}
        foundCounter={safetyWorkListStore.foundCounter}
        itemName="удостоверений"
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight,
          Boolean(Object.keys(safetyWorkListStore.filterParams).length),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        withoutColsSetting
        selectMulti={[
          "type",
          "kind",
          "company",
          "uid",
          "building",
          "events_type"
        ]}
        defaultFields={{
          building: [],
          events_type: [],
          kind: [],
          type: [],
          uid: [],
          company: [],
          date_start: "",
          date_end: ""
        }}
        dateRangeFields={safetyWorkListStore.dateRangeFields}
        selectsWithLoading={["uid"]}
        pageSelectsInfo={{
          uid: {
            page: safetyWorkListStore.pageStaff,
            prevPage: safetyWorkListStore.prevPageStaff,
            maxPage: safetyWorkListStore.maxPageStaff,
            searchValue: safetyWorkListStore.searchValueStaff,
            isLoading: safetyWorkListStore.isLoadingForStaffList,
            setSearchValue: safetyWorkListStore.setSearchValueStaff,
            setPage: safetyWorkListStore.setPageStaff,
            getList: safetyWorkListStore.getStaffList
          }
        }}
        customFilterMain={<SafetyWorkListCustomFilter />}
        isExport
        createDataFile={createDataFile}
        dataFileName="Охрана труда"
        setFiltersChanged={safetyWorkListStore.setFiltersChanged}
      >
        <SafetyWorkListTable filterList={filterList} />
      </TableWithStickyFilter>
    </ErrorBoundary>
  );
};

export default observer(SafetyWorkList);
