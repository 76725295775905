import { makeAutoObservable, runInAction } from "mobx";
import { isEmpty } from "lodash";
import RootStore from "stores";
import { Widgets } from "../types/Widgets";
import { Col } from "../types/Col";
import { Conviction } from "../types/Conviction";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Errors } from "stores/utils/types/ErrorsType";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";
import { TableParams } from "../types/TableParams";
import { PromisesInOrder } from "shared/utils/helpers/promisesInOrder";

export default class StaffOneConvictionStore {
  error = false;
  isLoading = false;
  success = false;
  isLoadingForForm = false;
  errorsMessage: Record<string, Errors["message"]> = {};

  convictions: { [key: string]: Conviction } = {};
  cols: { [key: string]: Col } = {};
  params: { [key: string]: TableParams } = {};
  showFields: { [key: string]: { [key: string]: string } } = {};
  requiredFields: string[] = [];
  staffData: Partial<Widgets["staff"]> = {};

  selectedOneForConvictions: Partial<Widgets> = {};
  openedAllStaffForConvictions: { [key: string]: Partial<Widgets> } = {};

  setSuccess(value: boolean) {
    this.success = value;
  }

  setUpdateConvictions = (data?: Partial<Widgets>) => {
    if (data) {
      runInAction(() => {
        this.convictions = data["widgets"]["conviction"];
        this.staffData = data["staff"];
      });
    } else {
      runInAction(() => {
        this.convictions = {};
        this.staffData = {};
      });
    }
  };

  setSelectedOneForConviction = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
      this.error = false;
      this.errorsMessage = {};
    });
    this.setUpdateConvictions();
    if (isEmpty(this.rootStore.menuStore.allWindows)) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForConvictions[id];
    }

    if (
      !isEmpty(this.openedAllStaffForConvictions) &&
      this.openedAllStaffForConvictions[id]
    ) {
      if (!isEmpty(this.openedAllStaffForConvictions[id])) {
        this.selectedOneForConvictions = this.openedAllStaffForConvictions[id];
        this.setUpdateConvictions(this.selectedOneForConvictions);
      } else {
        this.error = true;
      }
    } else {
      PromisesInOrder([
        () => isEmpty(this.cols) && this.getTableCols(),
        () => isEmpty(this.params) && this.getTableParams(),
        () => this.getConvictionsForOneOfStaff(id)
      ]);
    }
  };

  getConvictionsForOneOfStaff = async (id: string) => {
    runInAction(() => {
      this.isLoadingForForm = true;
      this.error = false;
      this.errorsMessage = {};
    });

    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields: "surname, name, patronymic, id, company, uid",
          widgets: ["conviction"],
          cols: "conviction",
          selects: "conviction"
        }
      });

      if (!isEmpty(data["staff"])) {
        runInAction(() => {
          this.openedAllStaffForConvictions[id] = data;
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/staff/id=${id}`,
            title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
          });

          if (this.rootStore.menuStore.tabId === id) {
            this.selectedOneForConvictions =
              this.openedAllStaffForConvictions[id];
            this.setUpdateConvictions(this.selectedOneForConvictions);
          }

          if (!isEmpty(data["selects"])) {
            getEntries(data["selects"]).forEach(([key, value]) => {
              const newDirectory = {};
              getValues(value).forEach((item) => {
                newDirectory[item["id"] ? item["id"] : item.title] = {
                  ...item,
                  newname: item["id"] ? item["id"] : item.title,
                  title:
                    key === "article" && item?.["number"]
                      ? `ст.${item["number"]} ${
                          item["part"] ? `ч.${item["part"]}` : ""
                        } ${item["title"]}`
                      : item["title"]
                };
              });

              this.cols[key] = {
                ...this.cols[key],
                directory: newDirectory
              };
            });
          }
        });
      } else {
        runInAction(() => {
          this.openedAllStaffForConvictions[id] = {};
          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/staff/id=${id}`,
            title: "Ничего не найдено"
          });
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingForForm = false;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Col }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "conviction",
          currentClass: "conviction",
          method: "getTableCols"
        });

      if (data.code === 200) {
        this.cols = data.result;
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<{ [key: string]: TableParams }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "conviction",
          currentClass: "conviction",
          method: "getTableParams"
        });

      if (data.code === 200) {
        this.params = data["result"];
        const fields = {
          records: {},
          add: {},
          edit: {},
          detail: {}
        };
        const required = [];
        getEntries(this.cols).forEach(([key, value], i) => {
          if (value?.hidden !== "on") {
            fields.detail[i] = key;
            if (this.params[key].editable) {
              fields.edit[i] = key;
            }
            if (this.params[key].show_default === "on") {
              fields.records[i] = key;
            }
            if (this.params[key].required) {
              required.push(key);
            }
            if (this.params[key].add_show === "on") {
              fields.add[i] = key;
            }
          }
        });
        this.showFields = fields;
        this.requiredFields = required;
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  addConviction = async (
    uid: string,
    article: string,
    date_start: string,
    punishment: string,
    term_type: string,
    comment: string
  ): Promise<void> => {
    const formData = {
      uid,
      article,
      date_start,
      punishment,
      term_type,
      comment
    };
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "conviction",
        method: "addRecord",
        body: formData
      });

      if (data.result) {
        await this.getConvictionsForOneOfStaff(uid);
        runInAction(() => {
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage.add && delete this.errorsMessage.add;
          this.success = true;
        });
      } else {
        runInAction(() => {
          this.success = false;
          this.errorsMessage.add = data.message;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  editConviction = async (
    uid: string,
    id: string,
    article: string,
    date_start: string,
    punishment: string,
    term_type: string,
    comment: string
  ): Promise<void> => {
    const formData = {
      id,
      "update[article]": article,
      "update[uid]": uid,
      "update[date_start]": date_start,
      "update[punishment]": punishment,
      "update[term_type]": term_type,
      "update[comment]": comment
    };
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });

    try {
      const data: ApiResponse<boolean | 0> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "conviction",
          method: "updateRecord",
          body: formData
        });

      if (data.result) {
        await this.getConvictionsForOneOfStaff(uid);
        runInAction(() => {
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage.update && delete this.errorsMessage.update;
          this.success = true;
        });
      } else {
        runInAction(() => {
          this.errorsMessage.update = data.message;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  deleteConviction = async (
    uid: string,
    id: string | number
  ): Promise<void> => {
    runInAction(() => {
      this.isLoadingForForm = true;
      this.success = false;
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "conviction",
        method: "deleteRecord",
        body: { id }
      });

      if (data.result) {
        await this.getConvictionsForOneOfStaff(uid);
        runInAction(() => {
          this.errorsMessage.delete && delete this.errorsMessage.delete;
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.success = true;
        });
      } else {
        runInAction(() => {
          this.errorsMessage.delete = data.message;
          this.success = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForForm = false;
      });
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
