import { makeAutoObservable } from "mobx";
import RootStore from "stores";

import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";

export default class FilterStore {
  // Высота сохр.фильтров и строки параметров фильтров
  savedFiltersHeight = 0;

  isOpenedSavedFilters = false;
  isOpenAdvancedSearch = false;
  isOpenedExport = false;
  isOpenedSavedFilterOptions = false;
  isOpenedColumnsOptions = false;
  isDiff = false;
  isGeneralFilterChanged = false;
  isDownloadedFilterSelected = false;
  showSaveModal = false;
  showDeleteModal = false;

  // справочник для хранения title у выбранных значений  из списка, пагинация которых осуществляется с помощью бэка (поле сотрудник, объекты и др)
  dictForArrayValuesFilter: Record<string, string> = {};

  searchInputValue = "";

  currentFilter = {} as FilterType;

  dimensions: Partial<{ width: number; height: number }> = {};

  resetAll = () => {
    this.savedFiltersHeight = 0;

    this.isOpenedExport = false;
    this.isOpenedSavedFilterOptions = false;
    this.isOpenedColumnsOptions = false;
    this.isDiff = false;
    this.isGeneralFilterChanged = false;
    this.isDownloadedFilterSelected = false;
    this.showSaveModal = false;
    this.showDeleteModal = false;

    this.searchInputValue = "";

    this.currentFilter = {} as FilterType;

    this.dimensions = {};
  };

  setDictForArrayValuesFilter = (value: Record<string, string>) => {
    this.dictForArrayValuesFilter = value;
  };

  // функция сохранения высоты блока сохранённых фильтров
  setSavedFiltersHeight = (value: number) => {
    this.savedFiltersHeight = value;
  };

  // функция изменения состояния сохранённых фильтров (открыты/закрыты)
  setIsOpenedSavedFilter = (value: boolean) => {
    this.isOpenedSavedFilters = value;
  };

  // функция изменения состояния расширенных настроек фильтров (открыты/закрыты)
  setIsOpenAdvancedSearch = (value: boolean) => {
    this.isOpenAdvancedSearch = value;
  };

  // функция изменения состояния меню экспорта (открыто/закрыто)
  setIsOpenedExport = (value: boolean) => {
    this.isOpenedExport = value;
  };

  // функция изменения состояния настроек сохранённого фильтра (открыты/закрыты)
  setIsOpenedSavedFilterOptions = (value: boolean) => {
    this.isOpenedSavedFilterOptions = value;
  };

  // функция изменения состояния настроек таблицы (открыты/закрыты)
  setIsOpenedColumnsOptions = (value: boolean) => {
    this.isOpenedColumnsOptions = value;
  };

  // функция изменения значения поля быстрого поиска
  setSearchInputValue = (value: string) => {
    this.searchInputValue = value;
  };

  // функция изменения значения изменений в настройках фильтра
  setIsDiff = (value: boolean) => {
    this.isDiff = value;
  };

  // функция для записи текущего расширения окна браузера
  setDimensions = (sizes: { [key: string]: number }) => {
    this.dimensions = sizes;
  };

  // функция для записи текущего фильтра
  setCurrentFilter = (value: FilterType) => {
    this.currentFilter = value;
  };

  // функция для записи наличия изменений в расширенных настройках основного фильтра
  setIsGeneralFilterChanged = (value: boolean) => {
    this.isGeneralFilterChanged = value;
  };

  // функция изменения состояния окна подтверждения сохранения фильтра (открыто/закрыто)
  setShowSaveModal = (value: boolean) => {
    this.showSaveModal = value;
  };

  // функция изменения состояния окна подтверждения удаления фильтра (открыто/закрыто)
  setShowDeleteModal = (value: boolean) => {
    this.showDeleteModal = value;
  };

  // функция для сброса данных в полях расширенного поиска при выборе любого сохраненного фильтра
  setIsDownloadedFilterSelected = (value: boolean) => {
    this.isDownloadedFilterSelected = value;
  };

  // функция создания нового фильтра (возвращает объект нового фильтра)
  creatingNewFilter = (
    currentCols: string[],
    defaultArray: string[],
    defaultFields: Record<string, string | string[] | string[][]>
  ) => {
    return {
      id: "new",
      title: null,
      default: 0,
      general: 0,
      filter: defaultFields,
      cols: currentCols.filter((col) => !defaultArray.includes(col)),
      order: "time_create",
      ordered: "DESC"
    };
  };

  // функция получения массива неудаляемых столбцов
  getDefaultArray = (
    allCols: { [key: string]: Col },
    cols: { [key: string]: Col }
  ) => {
    return Object.keys(allCols).filter(
      (col) => !Object.keys(cols).includes(col)
    );
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
