import styles from "./list.module.scss";

import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  flip,
  hide,
  autoUpdate,
  FloatingPortal
} from "@floating-ui/react";

import type { Placement, ReferenceType } from "@floating-ui/react";

import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { isArray } from "lodash";
import { MutableRefObject, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { getValues } from "shared/utils/helpers/getValues";

const useVisibility = (
  ref: MutableRefObject<ReferenceType>,
  root: HTMLElement
) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root,
        threshold: 0.1
      }
    );

    if (ref.current) observer.observe(ref.current as Element);

    return () => observer.disconnect();
  }, [ref, root]);

  return isVisible;
};

type ListProps = {
  options: { [key: string]: OptionWithTitle } | OptionWithTitle[];
  setSelectedItem: (option: OptionWithTitle) => void;
  placement?: Placement;
  required?: boolean;
  valueName: "id" | "newname";
  isSearchable?: boolean;
  notSortable?: boolean;
  isOpenedMenu?: boolean;
  setIsOpenedMenu: (value: boolean) => void;
  referenceRef: React.Ref<HTMLElement>;
  value: string;

  // props для списка с поиском
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  searchValue?: string;
  isLoading?: boolean;
};

const List = ({
  options,
  setSelectedItem,
  placement,
  valueName,
  isSearchable,

  notSortable,
  isOpenedMenu,
  setIsOpenedMenu,
  referenceRef,
  value,

  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  searchValue,
  isLoading
}: ListProps) => {
  // Настройки выпадающего списка в строгом порядке, т.к. выполняются по порядку
  const { refs, floatingStyles, context } = useFloating({
    open: isOpenedMenu, // Открыт ли список
    onOpenChange: (open) => {
      setIsOpenedMenu(open);
    }, // Функция, изменяющая состояние списка
    placement: placement || "bottom-start", // Положение списка
    // whileElementsMounted - функция, которая вызывается при монтировании ссылочных и плавающих элементов
    // возвращает функцию очистки, вызываемую при их размонтировании.
    // autoUpdate - функция, которая гарантирует, что плавающий элемент остается привязанным к ссылочному элементу
    whileElementsMounted: autoUpdate,
    middleware: [
      // Оптимизатор видимости, который меняет расположение плавающего элемента
      // чтобы он оставался в поле зрения с возможностью перевернуть его в любое место.
      flip({ fallbackAxisSideDirection: "end" }),
      hide()
    ]
  });

  // Слушатели событий для изменения состояния списка
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    // Закрытие списка при клике на ESC
    escapeKey: true
  });
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  const isReferenceVisible = useVisibility(
    refs.reference,
    document.getElementById("root")
  );

  useLayoutEffect(() => {
    if (!isReferenceVisible) {
      requestAnimationFrame(() => {
        setIsOpenedMenu(false);
      });
    }
  }, [isReferenceVisible]);

  useLayoutEffect(() => {
    if (referenceRef && "current" in referenceRef) {
      refs.setReference(referenceRef.current);
    }
  }, [referenceRef, refs]);

  return (
    <FloatingPortal data-list="true">
      {isOpenedMenu && refs.reference.current && (
        <div
          className={styles.list}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          data-list="true"
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ItemsScrollBoard
              options={options}
              values={value}
              valueName={valueName}
              notSearchable={getValues(options).length <= 10 || !isSearchable}
              isSearchWithPagination={isSearchWithPagination}
              page={page}
              prevPage={prevPage}
              maxPage={maxPage}
              setPage={setPage}
              getList={getList}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              isLoading={isLoading}
              addItem={(option) => {
                setSelectedItem(option);
                setIsOpenedMenu(false);
              }}
              notSortable={notSortable}
              selectedItem={
                value
                  ? isArray(options)
                    ? options.find((option) => option[valueName] === value)
                    : options?.[value]?.title
                    ? options?.[value]
                    : { title: value }
                  : null
              }
              onDelete={() => {
                setSelectedItem(null);
                setIsOpenedMenu(false);
              }}
            />
          </ErrorBoundary>
        </div>
      )}
    </FloatingPortal>
  );
};

export default observer(List);
