import styles from "./friendInviteAllTable.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

import LoadedComponent from "widgets/LoadedComponent";
import ScrollButton from "shared/ui/Buttons/ScrollButton";

import { ReactComponent as IconArrow } from "shared/assets/images/mainIcons/iconArrowDouble.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { isEmpty } from "lodash";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { browserName } from "react-device-detect";
import { getTableMarginTop } from "shared/utils/helpers/getTableMarginTop";
import { classNames } from "shared/utils/helpers/classNames";
import FriendInviteSelects from "./FriendInviteSelects";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import FriendInviteStaff from "./FriendInviteStaff";
import { calculateYears } from "shared/utils/helpers/calculateYears";
import { getParsedDate } from "shared/utils/helpers/getParsedDate";
import Alert from "shared/ui/Alert";
import Loading from "widgets/LoadedComponent/Loading";

type FriendInviteAllTableProps = {
  filterInvites: (arg: string) => void;
};

const FriendInviteAllTable = ({ filterInvites }: FriendInviteAllTableProps) => {
  const { friendInviteStore, filterStore, menuStore } = useStores();

  const navigate = useNavigate();
  const navigateUserProfile = ({ id }: { id: string }) => {
    navigate(`./id=${id}`);
    menuStore.setScrollPosition(menuStore.scroll);
  };

  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (
      menuStore.isScrollBottom &&
      friendInviteStore.maxPage >= friendInviteStore.page &&
      friendInviteStore.page === friendInviteStore.prevPage
    ) {
      friendInviteStore.setPage(friendInviteStore.page + 1);
    }
  }, [menuStore.isScrollBottom]);

  return (
    <>
      <ScrollButton tableRef={tableRef} />
      <table
        className={styles.table}
        ref={tableRef}
        style={{
          marginTop: getTableMarginTop(
            filterStore.savedFiltersHeight,
            Boolean(friendInviteStore.searchValue),
            false,
            browserName === "Firefox"
          ),
          transition: "0.2s"
        }}
      >
        <thead
          className={styles.thead}
          style={{
            top: getTHeadTop(
              filterStore.savedFiltersHeight,
              Boolean(friendInviteStore.searchValue),
              filterStore.isOpenedSavedFilters && browserName === "Firefox",
              Boolean(
                (friendInviteStore.filters &&
                  !friendInviteStore.filters[
                    friendInviteStore.downloadedFilter
                  ]?.["default"]) ||
                  friendInviteStore.searchValue ||
                  (!friendInviteStore.filters &&
                    friendInviteStore.filterParams &&
                    !isEmpty(friendInviteStore.filterParams))
              ),
              true
            )
          }}
        >
          <tr>
            {getEntries(friendInviteStore.cols).map(([title, col]) =>
              friendInviteStore.tableParams[title]?.show_default ? (
                <th
                  id={`FriendInviteAllTable_sortInvites_${title}`}
                  key={title}
                  className={classNames(styles.th, {
                    [styles.thDisabled]: title === "candidate_days"
                  })}
                  onClick={() => {
                    title !== "candidate_days" && filterInvites(title);
                  }}
                >
                  {col?.title || title}
                  {title !== "candidate_days" && (
                    <IconArrow
                      className={classNames(styles.iconSort, {
                        [styles.iconSortActive]:
                          friendInviteStore.order === title
                      })}
                    />
                  )}
                </th>
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {!isEmpty(friendInviteStore.invitesList) ? (
            getEntries(friendInviteStore.invitesList).map(([id, item], i) => {
              return (
                <tr
                  id={`FriendInviteAllTable_showOneInvite_${id}`}
                  key={id}
                  onClick={(event) => {
                    if (
                      event.target["dataset"]["type"] !== "list" &&
                      event.target["dataset"]["list"] !== "true" &&
                      event.target["dataset"]["type"] !== "list-search"
                    ) {
                      navigateUserProfile({
                        id: id
                      });
                    }
                  }}
                  className={classNames(styles.tableRow, {
                    [styles.greyRow]: i % 2
                  })}
                >
                  {getKeys(friendInviteStore.cols).map((title) => {
                    const param = friendInviteStore.tableParams[title];
                    if (!param?.show_default) return;
                    if (
                      param?.editable === "on" &&
                      ["status", "pay"].includes(title)
                    ) {
                      return (
                        <FriendInviteSelects
                          key={`${id}_${title}`}
                          id={id}
                          title={title}
                        />
                      );
                    }
                    switch (title) {
                      case "uid":
                      case "candidate_tn":
                        return (
                          <FriendInviteStaff
                            key={`${id}_${title}`}
                            id={id}
                            title={title}
                          />
                        );
                      case "alt_uid":
                        return (
                          <td key={`${id}_${title}`} className={styles.td}>
                            {item.alt_uid !== null ? (
                              <>
                                <div>{item.alt_uid}</div>
                                <div className={styles["notStaff_bw-gray5"]}>
                                  Не сотрудник
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        );
                      case "candidate_phone":
                        return (
                          <td
                            key={`${id}_${title}`}
                            style={{ whiteSpace: "nowrap" }}
                            className={styles.td}
                          >
                            {item.candidate_phone ? formatPhoneNumber(item.candidate_phone) : ""}
                          </td>
                        );

                      case "candidate_birthday":
                        return (
                          <td key={`${id}_${title}`} className={styles.td}>
                            <div className={styles.candidateBirthday}>
                              <span>
                                {item[title]
                                  ? getFormattedDate(item[title])
                                  : ""}
                              </span>
                              <span className={styles.birthday}>
                                (
                                {calculateYears(
                                  getParsedDate(item[title]),
                                  new Date()
                                )}
                                )
                              </span>
                            </div>
                          </td>
                        );
                      default:
                        return (
                          <td key={`${id}_${title}`} className={styles.td}>
                            {item[title] && item[title] !== -1
                              ? title === "time_create"
                                ? getFormattedDate(item[title]).slice(0, 10)
                                : getFormattedDate(
                                    friendInviteStore.selects[title]?.[
                                      item[title]
                                    ]?.title || item[title]
                                  )
                              : ""}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })
          ) : (
            <tr className={styles.error}>
              <td
                colSpan={
                  getKeys(friendInviteStore.cols).filter(
                    (title) =>
                      friendInviteStore.tableParams[title]?.show_default
                  ).length
                }
              >
                <Alert
                  errors={{
                    head: friendInviteStore.errorMessage || "",
                    color: "empty"
                  }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {friendInviteStore.page !== 1 ? (
        <div className={styles.loading}>
          {friendInviteStore.isLoading ? <Loading withoutText /> : ""}
        </div>
      ) : (
        <LoadedComponent isLoading={friendInviteStore.isLoading} />
      )}
    </>
  );
};

export default observer(FriendInviteAllTable);
