import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { SelectModule } from "stores/StaffModule/types/Selects";
import { Col } from "stores/utils/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { Param } from "stores/utils/types/Param";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { isEmpty } from "lodash";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

type StaffListItem = {
  uid: number;
  surname: string;
  name: string;
  patronymic: string;
  id: string;
};

export default class SafetyWorkAddNewStore {
  isLoading = false;
  error = false;
  errorsMessage: Partial<Errors["message"]> = {};

  safetyWorkCols: Record<string, Col> = {};
  safetyWorkParams: Record<string, Param> = {};
  currentTitles: string[] = [];
  newCertObject: Record<string, string | number> = {};
  requiredFields: string[] = [];

  selects: Record<string, SelectModule> = {};
  companiesLoaded = false;

  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  resetUidDirectory() {
    if (this.safetyWorkCols["uid"]) {
      this.safetyWorkCols["uid"]["directory"] = [];
    }
  }

  resetSearchValueStaff() {
    this.searchValueStaff = "";
    this.pageStaff = 1;
    this.prevPageStaff = 0;
    this.maxPageStaff = 0;
    this.isLoadingForStaffList = false;
  }

  getData = async () => {
    this.isLoading = true;

    try {
      if (!getKeys(this.safetyWorkParams).length) {
        await this.getTableParams();
      }

      if (!getKeys(this.safetyWorkCols).length) {
        await this.getTableCols();
      }

      await Promise.all([this.getSelects(), this.getStaffList()]);
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, Param>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkParams = data["result"];
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getTableCols = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Col }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "safetywork",
          currentClass: "safety_certs",
          method: "getTableCols"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.safetyWorkCols = data["result"];
          Object.keys(this.safetyWorkCols).forEach((key) => {
            if (this.safetyWorkParams[key]?.["add_show"] === "on") {
              this.currentTitles.push(key);
            }
            if (this.safetyWorkParams[key]?.["required"]) {
              this.requiredFields.push(key);
            }
          });
          this.currentTitles.forEach((title) => {
            switch (this.safetyWorkCols[title]["type"]) {
              case "bool":
                this.newCertObject[title] = 0;
                break;
              default:
                this.newCertObject[title] = "";
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getCompaniesList = async (uid: string) => {
    this.isLoadingForStaffList = true;
    this.companiesLoaded = false;
    try {
      const data: ApiResponse<SelectModule | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getStaffCompanyList",
          params: {
            uid,
            actual: "true"
          }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          const companies: SelectModule = {};
          getValues(data.result).forEach((company) => {
            companies[company.id] = company;
          });
          this.selects["company"] = companies;
          this.companiesLoaded = true;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: {
        select: Record<string, SelectModule>;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "safetywork",
        method: "getSelects"
      });

      runInAction(() => {
        if (!isEmpty(data.select)) {
          getEntries(data.select).forEach(
            ([field, directory]) => (this.selects[field] = directory)
          );
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      tempOneOfStaffObj["newname"] = oneOfStaff.id.toString();
      const fullNameParts = [
        oneOfStaff.surname,
        oneOfStaff.name,
        oneOfStaff.patronymic
      ];
      const fullName = fullNameParts.filter(Boolean).join(" ");
      tempOneOfStaffObj["title"] = `TH${oneOfStaff.uid} ${fullName}`;
      return tempOneOfStaffObj;
    });
  };
  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<Record<string, StaffListItem>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getPage",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (getValues(data["records"]).length) {
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            this.safetyWorkCols["uid"] = {
              ...this.safetyWorkCols["uid"],
              directory: this.dataPreparation(getValues(data["records"]))
            };
          }
        } else {
          this.safetyWorkCols["uid"]["directory"] = [];
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getPage",
          on_page: this.onPageStaff,
          page: this.pageStaff,

          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (getValues(data["records"]).length) {
          if (
            "uid" in this.safetyWorkParams &&
            this.safetyWorkParams["uid"]["isvariable"] === "on"
          ) {
            const directory = (
              this.safetyWorkCols["uid"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(getValues(data["records"])));
            this.safetyWorkCols["uid"] = {
              ...this.safetyWorkCols["uid"],
              directory: directory
            };
          }
        } else {
          this.safetyWorkCols["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  addCertificate = async (values: Record<string, string | number>) => {
    this.errorsMessage = {};
    const formData = { ...values };
    Object.entries(formData).forEach(([key, value]) => {
      if (!value) delete formData[key];
    });
    delete formData["withoutDateEnd"];
    delete formData["add_more"];
    try {
      const data: ApiResponse<string | boolean> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "safetywork",
          method: "addСertificate",
          body: formData
        });

      if (data.code === 200 && data.result) {
        this.rootStore.safetyWorkListStore.getData();
        if (!values["add_more"]) {
          return;
        }
      } else {
        runInAction(() => {
          this.errorsMessage = data["message"] || {
            head: "Ошибка добавления сертификата",
            color: "danger"
          };
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.errorsMessage = {
          head: "Ошибка добавления сертификата",
          color: "danger"
        };
      });
    }
  };

  setErrorMessage = (value: Partial<Errors["message"]>) => {
    this.errorsMessage = value;
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
