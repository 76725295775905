import ErrorsSelected from "shared/assets/images/menuIcons/iconSelected/Errors.svg";

import Certificates from "shared/assets/images/menuIcons/iconDefault/AllCertificates.svg";
import CertificatesSelected from "shared/assets/images/menuIcons/iconSelected/AllCertificates.svg";

import TermsControl from "shared/assets/images/menuIcons/iconDefault/Identity.svg";
import TermsControlSelected from "shared/assets/images/menuIcons/iconSelected/Identities.svg";

import AddOneIdentity from "shared/assets/images/menuIcons/iconDefault/AddOneIdentity.svg";
import AddOneIdentitySelected from "shared/assets/images/menuIcons/iconSelected/AddOneIdentity.svg";

import MassAddIdentity from "shared/assets/images/menuIcons/iconDefault/MassAddIdentity.svg";

import MenuButton from "shared/ui/Buttons/MenuButton";
import MenuSection from "shared/ui/MenuSection";
import { fileUrl } from "stores/utils/consts";

const SafetyWorkSection = () => {
  const overview = [
    {
      text: "Все удостоверения",
      to: "/safetywork/",
      moduleName: "safetywork",
      submoduleName: "main",
      defaultIcon: Certificates,
      selectedIcon: CertificatesSelected
    },
    {
      text: "Контроль сроков",
      to: "/safetywork/termsControl",
      moduleName: "safetywork",
      submoduleName: "termsControl",
      defaultIcon: TermsControl,
      selectedIcon: TermsControlSelected
    }
  ];

  const actions = [
    {
      text: "Создать удостоверение",
      to: "/safetywork/add",
      moduleName: "safetywork",
      submoduleName: "add",
      defaultIcon: AddOneIdentity,
      selectedIcon: AddOneIdentitySelected
    },
    {
      text: "Массовое создание удостоверений",
      to: "/safetywork/addmass",
      fullstack: `${fileUrl}/crm/safetywork/?action=massCreation`,
      moduleName: "safetywork",
      submoduleName: "addMass",
      defaultIcon: MassAddIdentity,
      selectedIcon: ErrorsSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((item) => (
          <MenuButton
            key={item["to"]}
            to={item["to"]}
            fullstack={item["fullstack"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};
export default SafetyWorkSection;
