import styles from "./friendInviteComment.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import CommentInputField from "features/Comments/CommentInputField";
import Alert from "shared/ui/Alert";
import Message from "shared/ui/Message";
import { getValues } from "shared/utils/helpers/getValues";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import LoadedComponent from "widgets/LoadedComponent";

const FriendInviteComment = () => {
  const { commentsStore } = useStores();

  const { id } = useParams();

  useEffect(() => {
    !commentsStore.comments[id] && commentsStore.getComments(id);
  }, [id]);

  return (
    <LoadedComponent
      isLoading={commentsStore.isLoading[id]}
      error={commentsStore.error[id]}
    >
      <div className={styles.container}>
        <CommentInputField
          addComment={commentsStore.addComment}
          commentCol={{}}
          className={{ container: styles.fieldContainer }}
        />
        <div className={styles.comments}>
          {!isEmpty(commentsStore.comments[id]) ? (
            <div>
              {getValues(commentsStore.comments[id]).map((comment) => (
                <Message
                  message={comment}
                  key={comment.id}
                  deleteMessage={commentsStore.deleteComment}
                  withIcon
                />
              ))}
            </div>
          ) : (
            <div>
              <Alert
                errors={{
                  head: "У сотрудника ещё нет комментариев",
                  color: "empty"
                }}
              />
            </div>
          )}
        </div>
      </div>
    </LoadedComponent>
  );
};

export default observer(FriendInviteComment);
