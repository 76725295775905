import React from "react";
import Modal from "shared/ui/Modal";
import { observer } from "mobx-react-lite";

interface ModalsBlockProps {
  modalAction: "confirmAdd" | "confirmReset" | null;
  handleCloseModal: () => void;
  handleConfirmModal: () => Promise<void>;
  showSuccess?: boolean;
  handleCloseSuccess: () => void;
  successTitle?: string;
  confirmAddTitle?: string;
  confirmResetTitle?: string;
}

const ModalsBlock = ({
  modalAction,
  handleCloseModal,
  handleConfirmModal,
  showSuccess,
  handleCloseSuccess,
  successTitle,
  confirmAddTitle,
  confirmResetTitle
}: ModalsBlockProps) => {
  return (
    <>
      {modalAction && (
        <Modal
          type="clarification"
          show
          onHide={handleCloseModal}
          title={
            modalAction === "confirmAdd" ? confirmAddTitle : confirmResetTitle
          }
          btnWithCrossTitle={
            modalAction === "confirmAdd" ? "Отмена" : "Очистить"
          }
          btnWithCrossOnClick={
            modalAction === "confirmAdd" ? handleCloseModal : handleConfirmModal
          }
          blueBtnTitle={modalAction === "confirmAdd" ? "Добавить" : "Отмена"}
          blueBtnOnClick={
            modalAction === "confirmAdd" ? handleConfirmModal : handleCloseModal
          }
          btnWithCrossImg
        />
      )}

      {showSuccess && (
        <Modal
          type="success"
          show
          onHide={handleCloseSuccess}
          title={successTitle}
          successIcon
          autoCloseTime={2000}
          direction="row"
        />
      )}
    </>
  );
};

export default observer(ModalsBlock);
