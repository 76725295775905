import { observer } from "mobx-react-lite";
import styles from "./termsControlKindsTypesForm.module.scss";
import { useStores } from "stores";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import Select from "shared/ui/Inputs/Select";
import { ViewField } from "shared/ui/ViewField";
import { useFormikContext } from "formik";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { Button, ButtonTheme } from "shared/ui/Button";
import { InitialValues } from "stores/SafetyWorkModule/safetyWorkTermsControl";
import { Fragment } from "react";
import { classNames } from "shared/utils/helpers/classNames";
import { getKeys } from "shared/utils/helpers/getKeys";
import { FilterType } from "stores/utils/types/FilterType";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

type TermsControlKindsTypesFormProps = {
  values: InitialValues | { filter: InitialValues };
  valueField?: string;
  prefix?: string;
  onChangeValue?: (
    key: string,
    value:
      | string
      | number
      | string[]
      | {
          [key: string]: string | string[];
        }[]
      | FilterType["filter"]
  ) => void;
};

const TermsControlKindsTypesForm = ({
  values,
  valueField = "newname",
  prefix = "",
  onChangeValue
}: TermsControlKindsTypesFormProps) => {
  const { safetyWorkTermsControlStore } = useStores();
  const { setFieldValue } = useFormikContext();

  const actualValues = "filter" in values ? values.filter : values;
  const prefixedField = (field: string) =>
    prefix ? `${prefix}.${field}` : field;

  const getFilteredKindOptions = (
    currentIndex: number,
    dict:
      | {
          [key: string]: {
            [key: string]: string;
            newname: string;
            title: string;
          };
        }
      | Array<{ [key: string]: string; newname: string; title: string }>
  ) => {
    const selectedKinds = actualValues.kind.filter(
      (kind, idx) => idx !== currentIndex && kind
    );

    if (Array.isArray(dict)) {
      return dict.filter((item) => !selectedKinds.includes(item.newname));
    }

    return Object.entries(dict).reduce((acc, [key, value]) => {
      if (!selectedKinds.includes(value.newname)) {
        acc[key] = value;
      }
      return acc;
    }, {} as typeof dict);
  };

  const handleKindChange = (index: number, option: OptionWithTitle) => {
    const fieldName = prefixedField(`kind.${index}`);
    if (onChangeValue) {
      onChangeValue(fieldName, option[valueField] as string);
      onChangeValue(prefixedField(`type.${index}`), [] as string[]);
    } else {
      setFieldValue(fieldName, option[valueField]);
      setFieldValue(prefixedField(`type.${index}`), []);
    }
    safetyWorkTermsControlStore.getTypesForKind(option.newname);
  };

  const handleDelete = (index: number) => {
    const newKind = [...actualValues.kind];
    const newType = [...(actualValues.type || [])] as string[][];
    newKind.splice(index, 1);
    newType.splice(index, 1);
    if (newKind.length === 0) {
      newKind.push("");
      newType.push([]);
    }
    if (onChangeValue) {
      onChangeValue(prefixedField("kind"), newKind);
      onChangeValue(prefixedField("type"), newType as unknown as string[]);
    } else {
      setFieldValue(prefixedField("kind"), newKind);
      setFieldValue(prefixedField("type"), newType);
    }
  };

  const handleAdd = () => {
    if (onChangeValue) {
      onChangeValue(prefixedField("kind"), [...actualValues.kind, ""]);
      onChangeValue(prefixedField("type"), [
        ...(actualValues.type || []),
        []
      ] as unknown as string[]);
    } else {
      setFieldValue(prefixedField("kind"), [...actualValues.kind, ""]);
      setFieldValue(prefixedField("type"), [...(actualValues.type || []), []]);
    }
  };

  return (
    <>
      {actualValues.kind.map((item, index) => (
        <Fragment key={`TermsControlKindsTypesForm_${index}`}>
          <Select
            key={`kind.${index}`}
            name={prefixedField(`kind.${index}`)}
            options={getFilteredKindOptions(
              index,
              safetyWorkTermsControlStore.termsControlColsParam?.["kind"]
                ?.directory
            )}
            title={
              safetyWorkTermsControlStore.termsControlColsParam["kind"]?.title
            }
            isFloating
            withClearBtn
            required={safetyWorkTermsControlStore.requiredFields.includes(
              "kind"
            )}
            valueName="newname"
            onCloseList={() => {
              safetyWorkTermsControlStore.setSearchValueBuilding("");
              safetyWorkTermsControlStore.getBuildingsList();
            }}
            onClick={(option) =>
              handleKindChange(index, option as OptionWithTitle)
            }
          />
          <>
            {!actualValues.kind[index] ? (
              <div
                key={`type_${index}`}
                className={styles.tooltipRight}
                data-tooltip="Выбор типа сертификата доступен после указания вида сертификата"
              >
                <ViewField
                  title={
                    safetyWorkTermsControlStore.termsControlColsParam["type"]
                      ?.title
                  }
                  value={undefined}
                  disabled
                />
              </div>
            ) : (
              <SelectMulti
                key={`type_${index}`}
                name={prefixedField(`type.${index}`)}
                title={
                  safetyWorkTermsControlStore.termsControlColsParam["type"]
                    ?.title
                }
                label={
                  safetyWorkTermsControlStore.termsControlColsParam["type"]
                    ?.title
                }
                options={safetyWorkTermsControlStore.listOfTypes[item]}
                required={safetyWorkTermsControlStore.requiredFields.includes(
                  "type"
                )}
                valueName="newname"
              />
            )}
          </>
          {index > 0 && (
            <div className={styles.deleteBtn}>
              <Button
                type="button"
                theme={ButtonTheme.CLEAR}
                onClick={() => handleDelete(index)}
                className={styles.deleteBtnText}
                id="TermsControlKindsTypesForm_deleteCertKind"
              >
                Удалить
                <IconClose className={styles.deleteBtnIcon} />
              </Button>
            </div>
          )}
        </Fragment>
      ))}
      <div className={styles.addBtn}>
        <Button
          type="button"
          theme={ButtonTheme.CLEAR}
          onClick={handleAdd}
          disabled={
            actualValues.kind.length ===
            getKeys(safetyWorkTermsControlStore.termsControlSelects.kind).length
          }
          className={styles.addBtnText}
          id="TermsControlKindsTypesForm_addCertKind"
        >
          Добавить Вид
          <IconAdd
            className={classNames("", {
              [styles.addBtnIconDisabled]:
                actualValues.kind.length ===
                getKeys(safetyWorkTermsControlStore.termsControlSelects.kind)
                  .length
            })}
          />
        </Button>
      </div>
    </>
  );
};

export default observer(TermsControlKindsTypesForm);
