import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import Handshake from "shared/assets/images/menuIcons/iconDefault/Handshake.svg";
import HandshakeSelected from "shared/assets/images/menuIcons/iconSelected/Handshake.svg";

const MenuFriendInviteSection = () => {
  const overview = [
    {
      text: "Все приглашения",
      to: "/friendinvite/",
      moduleName: "friendInvite",
      submoduleName: "main",
      defaultIcon: Handshake,
      selectedIcon: HandshakeSelected
    }
  ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection>
    </>
  );
};

export default MenuFriendInviteSection;
