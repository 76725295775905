export const specialCharsWithoutPunc = [
  "@",
  "#",
  "$",
  "%",
  "&",
  "*",
  "(",
  ")",
  "+",
  "=",
  "{",
  "}",
  "[",
  "]",
  "<",
  ">",
  "~",
  "^",
  "|",
  "\\",
  "`"
];
