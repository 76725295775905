import MenuSection from "shared/ui/MenuSection";
import MenuButton from "shared/ui/Buttons/MenuButton";
import AllEmployees from "shared/assets/images/menuIcons/iconDefault/AllEmployees.svg";
import AllEmployeesSelected from "shared/assets/images/menuIcons/iconSelected/AllEmployees.svg";

import AddEmployee from "shared/assets/images/menuIcons/iconDefault/AddEmployee.svg";
import AddEmployeeSelected from "shared/assets/images/menuIcons/iconSelected/AddEmployee.svg";

import Vacation from "shared/assets/images/menuIcons/iconDefault/Vacation.svg";
import VacationSelected from "shared/assets/images/menuIcons/iconSelected/Vacation.svg";

import ImportEmployee from "shared/assets/images/menuIcons/iconDefault/ImportEmployee.svg";
import ImportEmployeeSelected from "shared/assets/images/menuIcons/iconSelected/ImportEmployee.svg";
import AddAward from "shared/assets/images/menuIcons/iconDefault/AddAward.svg";
import AddAwardSelected from "shared/assets/images/menuIcons/iconSelected/AddAward.svg";
import Rating from "shared/assets/images/menuIcons/iconDefault/Rating.svg";
import RatingSelected from "shared/assets/images/menuIcons/iconSelected/Rating.svg";

import Tooltip from "shared/ui/Tooltip";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";

// import Export from "shared/assets/images/menuIcons/iconDefault/Export.svg";
// import ExportSelected from "shared/assets/images/menuIcons/iconSelected/Export.svg";

// import Import from "shared/assets/images/menuIcons/iconDefault/Import.svg";
// import ImportSelected from "shared/assets/images/menuIcons/iconSelected/Import.svg";

// import Status from "shared/assets/images/menuIcons/iconDefault/Status.svg";
// import StatusSelected from "shared/assets/images/menuIcons/iconSelected/Statuses.svg";

// import Buildings from "shared/assets/images/menuIcons/iconDefault/Buildings.svg";
// import BuildingsSelected from "shared/assets/images/menuIcons/iconSelected/Buildings.svg";

// import EmployeeCard from "shared/assets/images/menuIcons/iconDefault/EmployeeCard.svg";
// import EmployeeCardSelected from "shared/assets/images/menuIcons/iconSelected/EmployeeCard.svg";

// import AddressTypes from "shared/assets/images/menuIcons/iconDefault/AddressTypes.svg";
// import AddressTypesSelected from "shared/assets/images/menuIcons/iconSelected/TypesOfAddresses.svg";

const StaffSection = () => {
  const { staffListStore, userStore } = useStores();

  // Если нет данных пользователя, не рендерим меню
  if (!userStore.user) {
    return null;
  }

  const overview = [
    {
      text: "Все сотрудники",
      to: "/staff/",
      moduleName: "staff",
      submoduleName: "main",
      defaultIcon: AllEmployees,
      selectedIcon: AllEmployeesSelected
    },
    {
      text: "Отпуск",
      to: "/staff/staffVacation",
      moduleName: "staff",
      submoduleName: "staffVacation",
      defaultIcon: Vacation,
      selectedIcon: VacationSelected
    },
    {
      text: "Рейтинги",
      to: "/staff/staffRating",
      moduleName: "staff",
      submoduleName: "staffRating",
      defaultIcon: Rating,
      selectedIcon: RatingSelected
    }
  ];

  const actions = [
    {
      text: "Добавить",
      to: "/staff/add",
      moduleName: "staff",
      submoduleName: "add",
      defaultIcon: AddEmployee,
      selectedIcon: AddEmployeeSelected
    },
    {
      text: "Добавить награду",
      to: "/staff/addAward",
      moduleName: "staff",
      submoduleName: "addAward",
      defaultIcon: AddAward,
      selectedIcon: AddAwardSelected
    },
    {
      text: "Импортировать",
      to: "/staff/import",
      moduleName: "staff",
      submoduleName: "import",
      defaultIcon: ImportEmployee,
      selectedIcon: ImportEmployeeSelected
    }
    // {
    //   text: "Экспортировать",
    //   to: "/staff/export",
    //   submoduleName: "export",
    //   defaultIcon: Export,
    //   selectedIcon: ExportSelected,
    // }
  ];

  // const settings = [
  //   {
  //     text: "Статусы",
  //     to: "/staff/statusSettings",
  //     submoduleName: "statusSettings",
  //     defaultIcon: Status,
  //     selectedIcon: StatusSelected
  //   },
  //   {
  //     text: "Карточка сотрудника",
  //     to: "/staff/cardSettings",
  //     submoduleName: "cardSettings",
  //     defaultIcon: EmployeeCard,
  //     selectedIcon: EmployeeCardSelected
  //   },
  //   {
  //     text: "Города",
  //     to: "/staff/Buildings",
  //     submoduleName: "Buildings",
  //     defaultIcon: Buildings,
  //     selectedIcon: BuildingsSelected
  //   },
  //   {
  //     text: "Типы адресов",
  //     to: "/staff/typeAddress",
  //     submoduleName: "typeAddress",
  //     defaultIcon: AddressTypes,
  //     selectedIcon: AddressTypesSelected
  //   }
  // ];

  return (
    <>
      <MenuSection sectionTitle="Обзор">
        {overview.map((item) =>
          item["to"] === "/staff/staffVacation" ? (
            <Tooltip
              placement="right-start"
              color="blue-lazure"
              text="Раздел Отпуск находится в разработке"
              key={item["text"]}
            >
              <div>
                <MenuButton
                  to={item["to"]}
                  text={item["text"]}
                  defaultIcon={item["defaultIcon"]}
                  selectedIcon={item["selectedIcon"]}
                  moduleName={"staff"}
                  submoduleName={item["submoduleName"]}
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <MenuButton
              key={item["text"]}
              to={item["to"]}
              text={item["text"]}
              defaultIcon={item["defaultIcon"]}
              fullstack={item["fullstack"]}
              selectedIcon={item["selectedIcon"]}
              moduleName={item["moduleName"]}
              submoduleName={item["submoduleName"]}
              onClick={
                item["to"] === "/staff"
                  ? () =>
                      Object.values(staffListStore.filters).forEach(
                        (filter) => {
                          if (filter["default"])
                            staffListStore.getStaffListWithFilter(filter);
                        }
                      )
                  : null
              }
            />
          )
        )}
      </MenuSection>
      <MenuSection sectionTitle="Действия">
        {actions.map((action) => {
          // hardcode, ограничиваем доступ к добавлению наград
          if (
            action.submoduleName === "addAward" &&
            !userStore.userGroupsWithAddAward.includes(userStore.user?.groupid)
          ) {
            return null;
          }

          return (
            <MenuButton
              key={action["text"]}
              to={action["to"]}
              text={action["text"]}
              defaultIcon={action["defaultIcon"]}
              selectedIcon={action["selectedIcon"]}
              moduleName={action["moduleName"]}
              submoduleName={action["submoduleName"]}
            />
          );
        })}
      </MenuSection>
      {/* <MenuSection sectionTitle="Настроить">
        {settings.map((item) => (
          <MenuButton
            key={item["text"]}
            to={item["to"]}
            text={item["text"]}
            defaultIcon={item["defaultIcon"]}
            selectedIcon={item["selectedIcon"]}
            moduleName={item["moduleName"]}
            submoduleName={item["submoduleName"]}
          />
        ))}
      </MenuSection> */}
    </>
  );
};

export default observer(StaffSection);
