import styles from "./staffAddAwardsFormContent.module.scss";
import { Form, useFormikContext } from "formik";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Alert from "shared/ui/Alert";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { getKeys } from "shared/utils/helpers/getKeys";
import { useStores } from "stores/index";
import ModalsBlock from "./ModalsBlock";
import EmployeesBlock from "./EmployeesBlock";
import AwardBlock from "./AwardBlock";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { isEqual } from "lodash";

type FormValues = {
  award_type: string;
  building: string;
  award_date_start: string;
  staff_id: string[];
};

type LastPayload = Omit<FormValues, "building"> | null;

type StaffAddAwardFormContentProps = {
  setSelectedSnapshot: (items: Array<{ id: string; title: string }>) => void;
  lastPayload: LastPayload;
};

const StaffAddAwardFormContent = ({
  setSelectedSnapshot,
  lastPayload
}: StaffAddAwardFormContentProps) => {
  const { staffAddAwardStore } = useStores();
  const { values, handleReset, dirty, isValid, submitForm, setFieldValue } =
    useFormikContext<FormValues>();
  const [isDateOpen, setIsDateOpen] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<
    "confirmAdd" | "confirmReset" | null
  >(null);
  const totalCount =
    staffAddAwardStore.awardResult &&
    staffAddAwardStore.awardResult.successIds.length +
      staffAddAwardStore.awardResult.errorIds.length;

  const successMessage =
    staffAddAwardStore.awardResult &&
    staffAddAwardStore.awardResult.errorIds.length > 0
      ? `Награда “${
          staffAddAwardStore.allAwards[values.award_type]?.title
        }” добавлена ${
          staffAddAwardStore.awardResult.successIds.length
        } из ${totalCount} человек`
      : `Награда “${
          staffAddAwardStore.allAwards[values.award_type]?.title
        }” добавлена`;

  const disableAddButton =
    lastPayload &&
    isEqual(lastPayload, {
      award_type: values.award_type,
      award_date_start: values.award_date_start,
      staff_id: values.staff_id
    });

  const handleSelectAward = (option: OptionWithTitle) => {
    if (option) {
      const groupTitle =
        staffAddAwardStore.awardGroups[option.group as string]?.title || "";
      setFieldValue("building", groupTitle);
    } else {
      setFieldValue("building", "");
    }
  };

  const resetFormAndErrors = () => {
    handleReset();
    staffAddAwardStore.clearErrorsMessage();
    staffAddAwardStore.resetStaffListState();
    staffAddAwardStore.getStaffList();
  };

  const handleCloseSuccess = () => {
    if (staffAddAwardStore.awardResult.errorIds.length === 0) {
      staffAddAwardStore.setSuccess(false);
      resetFormAndErrors();
    } else {
      staffAddAwardStore.setSuccess(false);
    }
  };

  const handleClearForm = () => {
    setModalAction("confirmReset");
  };

  const handleAddAward = () => {
    setModalAction("confirmAdd");
  };

  const handleConfirmModal = async () => {
    if (modalAction === "confirmAdd") {
      setModalAction(null);
      await submitForm();
    } else if (modalAction === "confirmReset") {
      setModalAction(null);
      resetFormAndErrors();
    }
  };

  const handleCloseModal = () => {
    setModalAction(null);
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form}>
        <AwardBlock
          isDateOpen={isDateOpen}
          setIsDateOpen={setIsDateOpen}
          awardType={values.award_type}
          onSelectAward={handleSelectAward}
          building={values.building}
          allAwards={staffAddAwardStore.allAwards}
          clearErrorsMessage={() => staffAddAwardStore.clearErrorsMessage()}
        />
        <EmployeesBlock
          staffIdCount={values.staff_id.length}
          setSelectedSnapshot={setSelectedSnapshot}
        />
        {staffAddAwardStore.errorsMessage &&
          getKeys(staffAddAwardStore.errorsMessage).length > 0 &&
          staffAddAwardStore.errorsMessage.color !== "success" && (
            <Alert
              errors={staffAddAwardStore.errorsMessage}
              className={{ container: styles.errorBlock }}
              isLinkInNewTab
            />
          )}

        <ButtonsGroupForSettings
          saveBtnTitle="Добавить"
          saveBtnDisabled={!dirty || !isValid || disableAddButton}
          saveBtnOnClick={handleAddAward}
          addBtnTitle="Очистить форму"
          addBtnOnClick={handleClearForm}
          addBtnDisabled={!dirty}
          addBtnImg={<IconClose />}
        />
      </Form>

      <ModalsBlock
        modalAction={modalAction}
        handleCloseModal={handleCloseModal}
        handleConfirmModal={handleConfirmModal}
        showSuccess={staffAddAwardStore.success}
        handleCloseSuccess={handleCloseSuccess}
        successTitle={successMessage}
        confirmAddTitle={`Вы уверены, что хотите добавить награду “${
          staffAddAwardStore.allAwards[values.award_type]?.title
        }”?`}
        confirmResetTitle="Вы уверены, что хотите очистить данные?"
      />
    </div>
  );
};

export default observer(StaffAddAwardFormContent);
