import styles from "./typeAndStatusFilterBtns.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";

const allBtnFilterTitles = { type: "Все типы", status: "Все статусы" };

/**
 * Компонент фильтрации по типам и статусам
 *
 * Логика работы:
 * 1. Есть 2 группы фильтров: типы и статусы
 * 2. В каждой группе можно:
 *    - Выбрать "Все" = применятся все фильтры группы
 *    - Выбрать конкретный = применится только он
 *    - Выбрать несколько = применятся выбранные
 *
 * 3. При перезагрузке страницы:
 *    - Для статусов = выбираются все
 *    - Для типов = выбирается только "объектный" тип
 *
 * Это сделано чтобы пользователь всегда видел данные после перезагрузки страницы.
 */
const TypeAndStatusFilterBtns = () => {
  const { menuStore, salaryPaymentlistAllStore } = useStores();

  const getTabs = (
    mode: typeof salaryPaymentlistAllStore.buttonFilterMode[number]
  ): {
    id: string;
    title: string;
  }[] => {
    return [
      ...Object.values(salaryPaymentlistAllStore.selects[mode] || {}),
      { id: "all", title: allBtnFilterTitles[mode] }
    ];
  };

  return (
    <div
      className={styles.savedFilters}
      style={{
        marginLeft: menuStore.scroll?.scrollLeft
          ? menuStore.scroll?.scrollLeft
          : 0,
        transition: !salaryPaymentlistAllStore.isLoading
          ? "margin 0.8s ease"
          : ""
      }}
    >
      {salaryPaymentlistAllStore.buttonFilterMode.map((mode) => {
        const handleChangeTab = (id: string) => {
          if (
            (salaryPaymentlistAllStore.buttonFilter[mode]?.length <= 1 &&
              !salaryPaymentlistAllStore.buttonFilter[mode].includes(id)) ||
            salaryPaymentlistAllStore.buttonFilter[mode].length > 1
          ) {
            salaryPaymentlistAllStore.setSelectedButtonFilter(
              mode,
              id === "all"
                ? Object.keys(salaryPaymentlistAllStore.selects[mode] || {})
                : [id]
            );
          }
        };

        const getSelectedTab = (tab_id: string) =>
          salaryPaymentlistAllStore.searchValue ||
          salaryPaymentlistAllStore.buttonFilter[mode].includes(tab_id) ||
          (tab_id === "all" &&
            salaryPaymentlistAllStore.buttonFilter[mode].length ===
              Object.values(salaryPaymentlistAllStore.selects[mode] || {})
                .length)
            ? tab_id
            : "";

        return (
          salaryPaymentlistAllStore.buttonFilter[mode] !== undefined && (
            <div key={mode} className={styles.savedFilters__group}>
              <ButtonsTabs
                tabs={getTabs(mode)}
                customSelectedTab={getSelectedTab}
                changeActiveTab={handleChangeTab}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default observer(TypeAndStatusFilterBtns);
