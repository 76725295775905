import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStores } from "stores";
import { Formik } from "formik";
import { getValidationSchema } from "./validation";
import StaffImportTable from "./StaffImportTable";
import StaffImportButtons from "./StaffImportButtons";
import StaffImportUpload from "./StaffImportUpload";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Persist } from "formik-persist";
import Modal from "shared/ui/Modal";
import { getUnits } from "shared/utils/helpers/getUnits";
import LoadedComponent from "widgets/LoadedComponent";
import { getValues } from "shared/utils/helpers/getValues";

const StaffImport = () => {
  const { menuStore, staffImportStore } = useStores();

  useEffect(() => {
    staffImportStore.getDataForExcel();
    menuStore.setOpenedModule("staff");
    menuStore.setOpenedSubmodule("import");
    menuStore.updateWindow({
      mainPath: "/staff",
      path: "/staff/import",
      title: "Сотрудники"
    });
  }, []);

  useEffect(() => {
    if (staffImportStore.countAddedStaff > 0) {
      const timer = setTimeout(() => {
        staffImportStore.resetCountAddedStaff();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [staffImportStore.countAddedStaff]);

  return (
    <LoadedComponent
      isLoading={staffImportStore.isLoading}
      error={staffImportStore.error}
    >
      <>
        {!staffImportStore.staffList.length ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffImportUpload />
          </ErrorBoundary>
        ) : staffImportStore.staffList.length ? (
          <Formik
            initialValues={{
              staff: staffImportStore.staffList,
              selectAll: getValues(staffImportStore.verificationResponse).some(
                (value) => value.approve
              )
                ? 1
                : 0,
              companyAll: [] as string[]
            }}
            enableReinitialize
            validationSchema={() =>
              getValidationSchema(
                staffImportStore.titles,
                staffImportStore.requiredFields
              )
            }
            validateOnMount
            validateOnBlur
            validateOnChange
            onSubmit={() => null}
          >
            {() => {
              return (
                <div style={{ width: "fit-content" }}>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <StaffImportButtons />
                  </ErrorBoundary>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <StaffImportTable />
                  </ErrorBoundary>
                  <Persist name={"import_staff"} />
                </div>
              );
            }}
          </Formik>
        ) : (
          ""
        )}
        {staffImportStore.countAddedStaff ? (
          <Modal
            type="success"
            title={`${getUnits(
              staffImportStore.countAddedStaff,
              "Добавлена",
              "Добавлено",
              "Добавлены"
            )} ${staffImportStore.countAddedStaff} ${getUnits(
              staffImportStore.countAddedStaff,
              "запись",
              "записи",
              "записей"
            )}`}
            show={true}
            onHide={staffImportStore.resetCountAddedStaff}
            successIcon
            direction="row"
          />
        ) : (
          ""
        )}
        {staffImportStore.loadingFileError?.length > 0 ? (
          <Modal
            type="clarification"
            title="Ошибка загрузки файла"
            subtitle={staffImportStore.loadingFileError}
            show={staffImportStore.loadingFileError?.length > 0}
            greyBtnOnClick={() => staffImportStore.setLoadingFileError("")}
            greyBtnTitle="OK"
            errorIcon
          />
        ) : (
          ""
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffImport);
