import styles from "./friendInviteStaffForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Link } from "react-router-dom";
import { Badge } from "shared/ui/Badge";
import { getValues } from "shared/utils/helpers/getValues";
import { fileUrl } from "stores/utils/consts";
import { classNames } from "shared/utils/helpers/classNames";
import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import LoaderSpinner from "shared/ui/LoaderSpinner";

type FriendInviteStaffFormProps = {
  id: string;
  title: string;
};

const FriendInviteStaffForm = ({ id, title }: FriendInviteStaffFormProps) => {
  const { friendInviteStore } = useStores();

  const invite =
    friendInviteStore.updatedInvites[id] ||
    friendInviteStore.invitesList[id] ||
    {};
  const value = invite[title];

  const isOpenedList = friendInviteStore.openedList === `${id}_${title}`;
  const isEditableCandidate =
    title === "candidate_tn" &&
    friendInviteStore.tableParams[title]?.access_edit;

  return (
    <div className={styles.staff} data-type="list">
      <div
        className={classNames("", {
          [styles.candidate]: isEditableCandidate
        })}
        data-type="list"
      >
        {friendInviteStore.isLoadingCol === `${title}_${id}` ? (
          <LoaderSpinner color="bw-gray5" size="small" />
        ) : (
          value && (
            <Link
              to={`/staff/id=${invite?.[title]?.id}`}
              id={`FriendInviteStaffForm_${title}_${value}`}
              className={styles.link}
              data-type="list"
              target="_blank"
            >{`ТН ${invite?.[title]?.tn || ""}${
              " " + invite?.[title]?.name || ""
            }`}</Link>
          )
        )}
        {isEditableCandidate && (
          <IconExpand
            data-type="list"
            className={classNames(styles.icon, {
              [styles.iconOpenedSelect]: isOpenedList,
              [styles.iconCLosedSelect]: !isOpenedList,
              [styles.fixedIcon]: !invite[title]
            })}
          />
        )}
      </div>

      {value &&
        getValues(invite?.[title].events).map((event, idx) => {
          return (
            <div
              className={styles.event}
              key={`event_${id}_${idx}`}
              data-type="list"
            >
              <div className={styles.company} data-type="list">
                <Badge
                  text={
                    friendInviteStore.companiesList?.[event.company]?.title ||
                    event.company
                  }
                  colorName={
                    friendInviteStore.companiesList?.[event.company]?.color ||
                    "primary"
                  }
                  data-type="list"
                />
                <Badge
                  text={event.type.title}
                  textColorName="bw-gray7"
                  icon={event.type.icon}
                  iconColor={event.type.color}
                  colorName="none"
                  data-type="list"
                />
              </div>
              <a
                href={`${fileUrl}/crm/building/?id=${event.building?.id}`}
                id={`FriendInviteStaffForm_${title}_${event.building?.id}`}
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                data-type="list"
              >
                {event.building?.title}
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default observer(FriendInviteStaffForm);
