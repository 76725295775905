import styles from "./commentInputField.module.scss";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import commentValidation from "./validation";
import { ReactComponent as Attachment } from "shared/assets/images/mainIcons/iconClip/iconClipWithoutStroke.svg";
import { ReactComponent as SendButton } from "shared/assets/images/mainIcons/iconsSend/iconSendWithoutStroke.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconClose.svg";
import Textarea from "shared/ui/Inputs/Textarea";
import { Col } from "stores/StaffModule/types/Col";
import { classNames } from "shared/utils/helpers/classNames";

type Values = {
  id: string;
  comment: string;
  file: File | null;
};

type CommentInputFieldProps = {
  addComment: (id: string, comment: string, file: File | null) => void;
  commentCol: Partial<Col>;
  className?: Partial<{ container: string; input: string }>;
};

const CommentInputField = ({
  addComment,
  commentCol,
  className
}: CommentInputFieldProps) => {
  const { id } = useParams();
  const initialValues: Values = { comment: "", file: null, id: id };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={commentValidation}
      onSubmit={(values) => {
        addComment(id, values.comment, values.file);
      }}
    >
      {({
        values,
        errors,
        handleBlur,
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        isValid,
        dirty
      }) => {
        const handleFileDelete = () => {
          setFieldValue("file", null);
        };

        return (
          <div
            className={classNames(styles.container, {}, [
              className?.container || ""
            ])}
          >
            <Form onSubmit={handleSubmit}>
              <div
                className={classNames(styles.commentInputField, {}, [
                  className?.input || ""
                ])}
              >
                <Textarea
                  value={values.comment}
                  placeholder="Ваш комментарий..."
                  name="comment"
                  forCommentInputField
                  commentCol={commentCol}
                />
                <div className={styles.buttonsContainer}>
                  <div className={styles.buttonsWrapper}>
                    <div className={styles.addFileButton}>
                      <input
                        type="file"
                        id="file"
                        accept=".jpg, .jpeg, .png, .gif, .ico, .csv, .xls, .xlsx, .txt, .doc, .docx, .pdf, .cdr, .zip"
                        name="file"
                        onChange={(event) => {
                          setFieldTouched("file");
                          if (event.currentTarget.files[0]) {
                            setFieldValue("file", event.currentTarget.files[0]);
                          }
                        }}
                        onBlur={handleBlur}
                        hidden
                      />
                      <label htmlFor="file">
                        <div
                          data-tooltip="Вы можете прикрепить 1 файл, размером до 20Мб. Допустимый формат: .jpg, .jpeg, .png, .gif, .ico, .csv, .xls, .xlsx,
        .txt, .doc, .docx, .pdf, .cdr, .zip"
                          className={styles.tooltip}
                        >
                          <Attachment className={styles.attachment} />
                        </div>
                      </label>
                      {values.file && (
                        <div className={styles.displayFileName}>
                          {values.file.name.replace(/^.*\\/, "")}
                          <span
                            className={styles.displayFileDelete}
                            onClick={handleFileDelete}
                          >
                            <IconClose id="delete_file" />
                          </span>
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className={styles.sendButton}
                      disabled={!isValid || !dirty}
                    >
                      <SendButton />
                    </button>
                  </div>
                </div>
              </div>
              {errors.comment || errors.file ? (
                <div className={styles.errors}>
                  <div>{errors.comment}</div>
                  {errors.file ? <div>{`${errors.file}`}</div> : null}
                </div>
              ) : null}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default CommentInputField;
