import styles from "./buildingOneWorkshiftStafftableSelectEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import Select from "shared/ui/Inputs/Select";
import { WorkshiftStaffFieldProps } from "stores/BuildingModule/types/WorkshiftStaffFieldProps";
import { useStores } from "stores";
import { useFormikContext } from "formik";
import { StaffType } from "stores/BuildingModule/types/StaffType";
import { classNames } from "shared/utils/helpers/classNames";

interface IBuildingOneWorkshiftStafftableSelectEditProps
  extends WorkshiftStaffFieldProps {
  notSortable: boolean;
  required: boolean;
  isMenuOpened: boolean;
  setIsTouched: (value: string) => void;
}

const BuildingOneWorkshiftStafftableSelectEdit = ({
  name,
  staffIndex,
  notSortable,
  required,
  isMenuOpened,
  setIsTouched
}: IBuildingOneWorkshiftStafftableSelectEditProps) => {
  const { buildingOneWorkshiftStore, buildingOneStore } = useStores();
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<StaffType[]>();
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside({
    ref,
    handler: (event) => {
      // условие ниже позволяет не закрывать список при клике на сам список
      if (
        event.target?.["dataset"]["list"] === "true" ||
        event.target?.["dataset"]["type"] === "list-search" ||
        (typeof event.target?.["childNodes"]?.[0] === "string" &&
          event.target?.["childNodes"]?.[0]?.includes("itemsScrollBoard")) ||
        event.target?.["parentNode"]?.["dataset"]["list"] === "true"
      )
        return;
      else setIsTouched("");
    }
  });

  // функция получения списков (для Типа оплат и Состояния в смене)
  const getOptions = () => {
    const selects = buildingOneWorkshiftStore.selects;

    let options: { [key: string]: { title: string } } = {};
    switch (name) {
      case "payment_type":
        // Для списка ячейки массового редактирования Типа оплат
        // Нужно собрать все доступые типы оплат у всех сотрудников в группе
        if (values[staffIndex].isMassEdit) {
          const available_payment_types = [];
          values.forEach((staff) => {
            if (!staff.isMassEdit) {
              Object.values(staff.available_payment_types).forEach((type) => {
                !available_payment_types.includes(type) &&
                  available_payment_types.push(type);
              });
            }
          });

          available_payment_types.forEach((type) => {
            options[type] = selects[name][type];
          });
        }
        // для 1 сотрудника смотрим список доступных типов в available_payment_types
        else
          Object.values(values[staffIndex].available_payment_types).forEach(
            (type) => {
              options[type] = selects[name][type];
            }
          );
        break;
      case "objectstatus":
        // для Состояния в смене мы проходимся по всему справочнику Состояний
        Object.entries(selects[name]).forEach(
          ([objectstatus_name, objectstatus_value]) => {
            // и если в настройках Объекта (в сторе buildingOne)
            // состояние из списка не указано как скрытое
            if (
              buildingOneStore.selectedOne?.hide_objectstatus &&
              Object.values(
                buildingOneStore.selectedOne.hide_objectstatus
              ).includes(objectstatus_value.id)
            ) {
              return;
            }

            // добавляем состояние в список
            options[objectstatus_name] = selects[name][objectstatus_name];
          }
        );
        break;
      default:
        options = selects[name];
    }

    return options;
  };

  const handleClick = (option: {
    [key: string]: string | boolean | number;
    title: string;
  }) => {
    buildingOneWorkshiftStore.sendData(
      name === "objectstatus"
        ? (option["title_api"] as string)
        : (option["id"] as string),
      name,
      values[staffIndex].ws_staff_id,
      values[staffIndex].uid,
      values[staffIndex].isMassEdit,
      name === "payment_type"
    );
    setFieldValue(
      `${staffIndex}.${name}`,
      name === "objectstatus" ? option.title_api : option.id
    );

    if (
      name === "objectstatus" &&
      (!option || option?.custom?.["block_hours"] === "on")
    ) {
      setFieldValue(`${staffIndex}.work_hours`, 0);
      setFieldValue(`${staffIndex}.night`, 0);

      setFieldTouched(`${staffIndex}.work_hours`);
      setFieldTouched(`${staffIndex}.night`);
    }
  };

  return (
    <div
      className={classNames(styles.select, {
        [styles.select_required]: name.includes("payment_type")
      })}
      ref={ref}
    >
      <Select
        name={`${staffIndex}.${name}`}
        options={getOptions()}
        onClick={handleClick}
        valueName="id"
        notSortable={notSortable}
        required={required}
        withOpenedMenu={isMenuOpened}
      />
    </div>
  );
};

export default observer(BuildingOneWorkshiftStafftableSelectEdit);
