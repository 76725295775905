import styles from "./staffImportTableBodyDuplicateError.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Tooltip from "shared/ui/Tooltip";
import { ReactComponent as Attention } from "shared/assets/images/iconStatus/Attention.svg";
import { classNames } from "shared/utils/helpers/classNames";
import { isEmpty } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";

type StaffImportTableBodyDuplicateErrorProps = {
  index: number;
  title?: string;
  innerDoubles?: boolean;
};

const StaffImportTableBodyDuplicateError = ({
  index,
  title,
  innerDoubles
}: StaffImportTableBodyDuplicateErrorProps) => {
  const { staffImportStore } = useStores();
  const navigate = useNavigate();

  let uid = null,
    full_name = null,
    staff_id = null;

  if (
    staffImportStore.verificationResponse[index] &&
    !isEmpty(staffImportStore.verificationResponse[index]["doubles"])
  ) {
    const doubleData = title
      ? staffImportStore.verificationResponse[index]["doubles"][title]
      : getValues(staffImportStore.verificationResponse[index]["doubles"])[0];

    if (doubleData) {
      uid = doubleData.uid || null;
      full_name = doubleData.full_name || null;
      staff_id = doubleData.staff_id || null;
    }
  }

  const hasDbDuplicate =
    staffImportStore.dbDuplicateColsResponse[index]?.includes(title);
  const hasInnerDuplicate =
    staffImportStore.innerDuplicateColsResponse[index]?.includes(title);

  // Если данных о дубле нет, не отображаем ошибку
  if (!hasDbDuplicate && !hasInnerDuplicate) {
    return null;
  }

  return (
    <div
      className={classNames(styles.duplicateError, {
        [styles.duplicateErrorGrey]: !hasDbDuplicate && !hasInnerDuplicate
      })}
    >
      <Attention />
      {uid && (
        <Tooltip
          text={
            <>
              <div>Нажмите чтобы перейти в профиль сотрудника:</div>{" "}
              <div>{`ТН${uid} ${full_name}`} </div>
            </>
          }
          borderColor="bw-gray6"
          color="bw-gray6"
          placement="top"
        >
          <p
            className={styles.duplicateErrorText}
            onClick={() => navigate(`../staff/id=${staff_id}`)}
          >{`ТН${uid}`}</p>
        </Tooltip>
      )}
      {innerDoubles && (
        <p className={styles.innerDuplicateErrorText}>Совпадения в данных</p>
      )}
    </div>
  );
};

export default observer(StaffImportTableBodyDuplicateError);
