import UserStore from "./user";
import ApiStore from "./api";
import MenuStore from "./menu";
import MapStore from "./map";
import FilterStore from "./filter";
import ModalWindowsStore from "./modalWindows";
import {
  FriendInviteStore,
  FriendInviteOneStore
} from "./FriendInviteModule/index";

import { createContext, useContext } from "react";

import { StaffApiStore } from "./API/index";

import {
  StaffListStore,
  StaffVacationStore,
  StaffDaDataStore,
  StaffCitiesStore,
  StaffStatusStore,
  StaffTypeAddressStore,
  StaffAddNewStore,
  StaffAddAwardStore,
  OperationFormStore,
  StaffOneStore,
  StaffOneEditStore,
  StaffOneStatusesStore,
  StaffOneAllSectionsStore,
  StaffOneSafetyWorkStore,
  StaffOneConvictionStore,
  StaffCardSettingsStore,
  StaffOneSalaryStore,
  StaffOnePremiumLevelStore,
  StaffRatingStore,
  StaffImportStore,
  StaffOneAwardsStore
} from "stores/StaffModule";

import {
  BuildingOneStore,
  BuildingListStore,
  BuildingOneTimesheetStore,
  BuildingOneWorkshiftStore,
  BuildingOneStaffStore,
  BuildingOneStaffMovementsStore,
  BuildingOneAddWorkshiftFormStore
} from "stores/BuildingModule";
import DateFormatsStore from "./DateFormats";
import PolatiAppStore from "./PolatiAppModule/polatiApp";
import {
  AregisterListStore,
  AregisterOneStore,
  AregisterAppcreateStore,
  AregisterOneEditStore,
  AregisterReportStore,
  AregisterOneMasscopyStore
} from "./AregisterModule";
import {
  SalaryOperationsListStore,
  SalaryOperationsFormStore,
  SalaryOperationOneStore,
  SalaryPremiumsListStore,
  SalaryPremiumOneStore,
  SalaryPaymentlistAllStore,
  SalaryPaymentlistOneStore
} from "./SalaryModule";

import { ProjectsOneStore, ProjectsListStore } from "./ProjectsModule";

import { SafetyWorkListStore, SafetyWorkTermsControlStore, SafetyWorkAddNewStore } from "./SafetyWorkModule";
import CommentsStore from "./Comments/commentsStore";

export default class RootStore {
  apiStore: ApiStore;
  filterStore: FilterStore;
  menuStore: MenuStore;
  staffApiStore: StaffApiStore;
  staffVacationStore: StaffVacationStore;
  staffListStore: StaffListStore;
  operationFormStore: OperationFormStore;
  staffRatingStore: StaffRatingStore;
  staffImportStore: StaffImportStore;
  buildingOneStore: BuildingOneStore;
  buildingOneTimesheetStore: BuildingOneTimesheetStore;
  buildingOneStaffStore: BuildingOneStaffStore;
  buildingOneStaffMovementsStore: BuildingOneStaffMovementsStore;
  buildingOneAddWorkshiftFormStore: BuildingOneAddWorkshiftFormStore;
  projectsOneStore: ProjectsOneStore;
  projectsListStore: ProjectsListStore;
  staffStatusStore: StaffStatusStore;
  staffOneStore: StaffOneStore;
  staffOneEditStore: StaffOneEditStore;
  staffOneStatusesStore: StaffOneStatusesStore;
  staffOneAllSectionsStore: StaffOneAllSectionsStore;
  staffOneSafetyWorkStore: StaffOneSafetyWorkStore;
  staffOneSalaryStore: StaffOneSalaryStore;
  staffOneConvictionStore: StaffOneConvictionStore;
  staffOneAwardsStore: StaffOneAwardsStore;
  staffOnePremiumLevelStore: StaffOnePremiumLevelStore;
  friendInviteStore: FriendInviteStore;
  friendInviteOneStore: FriendInviteOneStore;
  buildingOneWorkshiftStore: BuildingOneWorkshiftStore;
  staffCitiesStore: StaffCitiesStore;
  aregisterListStore: AregisterListStore;
  aregisterOneStore: AregisterOneStore;
  aregisterOneEditStore: AregisterOneEditStore;
  aregisterAppcreateStore: AregisterAppcreateStore;
  aregisterReportStore: AregisterReportStore;
  aregisterOneMasscopyStore: AregisterOneMasscopyStore;
  salaryOperationsListStore: SalaryOperationsListStore;
  salaryOperationsFormStore: SalaryOperationsFormStore;
  salaryPremiumsListStore: SalaryPremiumsListStore;
  salaryOperationOneStore: SalaryOperationOneStore;
  salaryPremiumOneStore: SalaryPremiumOneStore;
  salaryPaymentlistAllStore: SalaryPaymentlistAllStore;
  salaryPaymentlistOneStore: SalaryPaymentlistOneStore;
  safetyWorkListStore: SafetyWorkListStore;
  safetyWorkTermsControlStore: SafetyWorkTermsControlStore;
  safetyWorkAddNewStore: SafetyWorkAddNewStore;
  polatiAppStore: PolatiAppStore;
  buildingListStore: BuildingListStore;
  staffCardSettingsStore: StaffCardSettingsStore;
  staffAddNewStore: StaffAddNewStore;
  staffAddAwardStore: StaffAddAwardStore;
  modalWindowsStore: ModalWindowsStore;
  mapStore: MapStore;
  userStore: UserStore;
  staffDaDataStore: StaffDaDataStore;
  staffTypeAddressStore: StaffTypeAddressStore;
  dateFormatsStore: DateFormatsStore;
  commentsStore: CommentsStore;

  constructor() {
    this.apiStore = new ApiStore(this);
    this.menuStore = new MenuStore(this);
    this.filterStore = new FilterStore(this);
    this.staffApiStore = new StaffApiStore(this);
    this.staffVacationStore = new StaffVacationStore(this);
    this.staffListStore = new StaffListStore(this);
    this.operationFormStore = new OperationFormStore(this);
    this.staffRatingStore = new StaffRatingStore(this);
    this.staffImportStore = new StaffImportStore(this);
    this.buildingOneStore = new BuildingOneStore(this);
    this.buildingOneTimesheetStore = new BuildingOneTimesheetStore(this);
    this.staffStatusStore = new StaffStatusStore(this);
    this.staffOneStore = new StaffOneStore(this);
    this.staffOneEditStore = new StaffOneEditStore(this);
    this.staffOneStatusesStore = new StaffOneStatusesStore(this);
    this.staffOneAllSectionsStore = new StaffOneAllSectionsStore(this);
    this.staffOneSafetyWorkStore = new StaffOneSafetyWorkStore(this);
    this.staffOneSalaryStore = new StaffOneSalaryStore(this);
    this.staffOneConvictionStore = new StaffOneConvictionStore(this);
    this.staffOnePremiumLevelStore = new StaffOnePremiumLevelStore(this);
    this.staffOneAwardsStore = new StaffOneAwardsStore(this);
    this.friendInviteStore = new FriendInviteStore(this);
    this.friendInviteOneStore = new FriendInviteOneStore(this);
    this.buildingOneWorkshiftStore = new BuildingOneWorkshiftStore(this);
    this.staffCitiesStore = new StaffCitiesStore(this);
    this.aregisterOneStore = new AregisterOneStore(this);
    this.aregisterReportStore = new AregisterReportStore(this);
    this.aregisterOneMasscopyStore = new AregisterOneMasscopyStore(this);
    this.buildingOneStaffStore = new BuildingOneStaffStore(this);
    this.buildingOneStaffMovementsStore = new BuildingOneStaffMovementsStore(
      this
    );
    this.buildingOneAddWorkshiftFormStore =
      new BuildingOneAddWorkshiftFormStore(this);
    this.aregisterListStore = new AregisterListStore(this);
    this.aregisterOneEditStore = new AregisterOneEditStore(this);
    this.aregisterAppcreateStore = new AregisterAppcreateStore(this);
    this.salaryOperationsListStore = new SalaryOperationsListStore(this);
    this.salaryOperationsFormStore = new SalaryOperationsFormStore(this);
    this.salaryOperationOneStore = new SalaryOperationOneStore(this);
    this.salaryPremiumsListStore = new SalaryPremiumsListStore(this);
    this.salaryPremiumOneStore = new SalaryPremiumOneStore(this);
    this.salaryPaymentlistAllStore = new SalaryPaymentlistAllStore(this);
    this.salaryPaymentlistOneStore = new SalaryPaymentlistOneStore(this);
    this.projectsListStore = new ProjectsListStore(this);
    this.projectsOneStore = new ProjectsOneStore(this);
    this.safetyWorkListStore = new SafetyWorkListStore(this);
    this.safetyWorkTermsControlStore = new SafetyWorkTermsControlStore(this);
    this.safetyWorkAddNewStore = new SafetyWorkAddNewStore(this);
    this.polatiAppStore = new PolatiAppStore(this);
    this.buildingListStore = new BuildingListStore(this);
    this.staffCardSettingsStore = new StaffCardSettingsStore(this);
    this.staffAddNewStore = new StaffAddNewStore(this);
    this.staffAddAwardStore = new StaffAddAwardStore(this);
    this.modalWindowsStore = new ModalWindowsStore(this);
    this.mapStore = new MapStore(this);
    this.userStore = new UserStore(this);
    this.staffDaDataStore = new StaffDaDataStore(this);
    this.staffTypeAddressStore = new StaffTypeAddressStore(this);
    this.dateFormatsStore = new DateFormatsStore(this);
    this.commentsStore = new CommentsStore(this);
  }
}

export const rootStoreContext = createContext(new RootStore());

export const useStores = () => useContext(rootStoreContext);
