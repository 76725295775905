import styles from "./staffOneStatusesOnObject.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";

import StaffOneStatusesTable from "./StaffOneStatusesTable";
import StaffOneStatusesWindow from "./StaffOneStatusesWindow";
import StaffOneStatusesGraph from "./StaffOneStatusesGraph";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { isEmpty } from "lodash";
import Alert from "shared/ui/Alert";

type StaffOneStatusesOnObjectProps = {
  selectedTab: string;
};

const StaffOneStatusesOnObject = ({
  selectedTab
}: StaffOneStatusesOnObjectProps) => {
  const { staffOneStatusesStore } = useStores();

  return !isEmpty(staffOneStatusesStore.statusesCols) ? (
    <div className={styles.wrapper}>
      {staffOneStatusesStore.selectedCompany ? (
        staffOneStatusesStore.openedWindow === "add" ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOneStatusesWindow />
          </ErrorBoundary>
        ) : null
      ) : null}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[selectedTab]}
      >
        <>
          {selectedTab === "table" ? (
            <StaffOneStatusesTable />
          ) : (
            <StaffOneStatusesGraph />
          )}
        </>
      </ErrorBoundary>
    </div>
  ) : (
    <Alert
      errors={{
        head: "Сотрудника не было ни на одном объекте",
        color: "empty"
      }}
    />
  );
};

export default observer(StaffOneStatusesOnObject);
