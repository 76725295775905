import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Award } from "../types/Award";
import { Widgets } from "../types/Widgets";
import { cloneDeep, without } from "lodash";
import { Select } from "stores/utils/types/Select";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Errors } from "stores/utils/types/ErrorsType";
import { Col } from "stores/StaffModule/types/Col";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { getEntries } from "shared/utils/helpers/getEntries";

export default class StaffOneAwardsStore {
  error = false;
  success = false;
  isLoading = false;
  isLoadingForModal = false;
  errorsMessage: Partial<Errors["message"]> = {};
  deletedAward = "";

  awards: Record<string, Award> = {};
  // массив полей наград, количество полей изменяется в зависимости от открытых/закрытых виджетов наград
  awardsArray: string[] = [];
  // справочник "award_groups" чтобы достать заголовки для виджетов
  awardGroups: { [key: string]: Partial<Select> } = {};
  // массив заголовков для виджетов
  fieldsTitlеsArray: string[] = [];
  cols: { [key: string]: Partial<Col> } = {};
  // доступ на редактирование
  access = "";
  allAwards: Record<string, OptionWithTitle> = {};
  staffInfo: Partial<Widgets["staff"]> = {};
  isActiveButton: Partial<Select> = {};
  searchValue = "";
  isFocusSearch = false;
  openedAward = "";
  actualPositionTitle = "";
  openedWindowType = "";
  withoutAwards = false;

  rootStore: RootStore;

  openedAllStaffForAllAwards: { [key: string]: Partial<Widgets> } = {};

  // счетчик найденных наград
  foundAwards: string[] = [];
  // массив полей в которых найдено совпадение по поиску
  awardsFieldsFoundDuringSearch: string[] = [];

  setSuccess(value: boolean) {
    this.success = value;
  }

  updateSections = (data?: Partial<Widgets>) => {
    if (data) {
      this.awardGroups = data["selects"]["award_groups"];
      this.staffInfo = data["staff"];
      this.cols = data["cols"]["staff_awards"];
      this.access = data["widgets"]["access_awards_edit"];
      getValues(this.awardGroups).forEach((group) => {
        !this.fieldsTitlеsArray.includes(group.title) &&
          this.fieldsTitlеsArray.push(group.title);
      });
      const awards: Record<string, Award> = {};
      getValues(this.awardGroups).forEach((group) => {
        getValues(data["widgets"]["awards"])
          .filter((award) => award.custom?.group === group.id)
          .forEach((value) => {
            awards[value.id] = value;
          });
      });
      this.awards = awards;
      const allAwards = {};
      getEntries(data["selects"]["award_type"]).forEach(([id, award]) => {
        allAwards[id] = {
          newname: id,
          group: award.custom?.group,
          autoset: award.custom?.autoset,
          ...award
        };
      });
      this.allAwards = allAwards;
    } else {
      this.awardGroups = {};
      this.allAwards = {};
      this.staffInfo = {};
      this.awards = {};
      this.errorsMessage = {};
    }
  };

  setSelectedOneForAllAwards = (id: string) => {
    this.isLoading = true;
    this.error = false;
    this.errorsMessage = {};

    this.updateSections();
    if (!getValues(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForAllAwards[id];
    }

    if (this.openedAllStaffForAllAwards[id]) {
      if (getValues(this.openedAllStaffForAllAwards[id]).length) {
        this.updateSections(this.openedAllStaffForAllAwards[id]);
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else this.getAwardsForOneOfStaff(id);
  };

  getAwardsForOneOfStaff = async (id: string) => {
    this.isLoading = true;
    this.errorsMessage = {};
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields:
            "surname, name, patronymic, id, company, uid, photo, position",
          widgets: ["awards"],
          selects: "award_groups, position, award_types",
          cols: "staff_awards"
        }
      });

      if (!data["errors"]) {
        this.openedAllStaffForAllAwards[id] = data;
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data.staff.surname} ${data.staff.name}`
        });
        if (this.rootStore.menuStore.tabId === id) {
          runInAction(() => (this.isLoading = false));
          this.updateSections(this.openedAllStaffForAllAwards[id]);
        }
        if (getValues(data.selects.position).length) {
          getValues(data.selects.position).forEach((item) => {
            getValues(item).forEach((positionElement) => {
              if (positionElement.id === this.staffInfo.position) {
                this.actualPositionTitle = positionElement.title;
              }
            });
          });
        }
      } else {
        this.openedAllStaffForAllAwards[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });

        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setIsFocusSearch = (value: boolean) => {
    this.isFocusSearch = value;
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setAwardsArray = (value: string[]) => {
    this.awardsArray = value;
  };

  setFoundAwards = (foundAwards: string[]) => {
    this.foundAwards = foundAwards;
  };

  setAwardsFieldsFoundDuringSearch = (fields: string[]) => {
    this.awardsFieldsFoundDuringSearch = fields;
  };

  setOpenedAward = (value: string) => {
    this.openedAward = value;
  };

  setIsActiveButton = (value: Partial<Select>) => {
    this.isActiveButton = value;
  };

  clearErrorsMessage = () => {
    getKeys(this.errorsMessage).forEach(
      (key) => delete this.errorsMessage[key]
    );
  };

  changeOpenedAwards = (action: "add" | "delete", fields: string[]) => {
    let newAwardsArray = cloneDeep(this.awardsArray);
    if (action === "add") {
      fields.forEach((field) => {
        !newAwardsArray.includes(field) && newAwardsArray.push(field);
      });
    } else {
      fields.forEach((field) => {
        newAwardsArray = without(newAwardsArray, field);
      });
    }
    this.setAwardsArray(newAwardsArray);
  };

  addAward = async (
    staff_id: string,
    award_type: string,
    award_date_start: string
  ) => {
    this.errorsMessage = {};
    this.isLoadingForModal = true;
    this.success = false;

    try {
      const data: ApiResponse<{ message?: Omit<Errors["message"], "body"> }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          currentClass: "staff_award",
          method: "addAward",
          body: { staff_id, award_type, award_date_start }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.success = true;
          this.getAwardsForOneOfStaff(staff_id);
          this.rootStore.staffOneStore.setRebootStaff(staff_id, true);
        } else {
          this.errorsMessage = data.message;
          this.isLoadingForModal = false;
          this.success = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForModal = false;
      });
    }
  };

  updateAward = async (
    staff_id: string,
    award_id: string,
    award_date_start: string
  ) => {
    this.errorsMessage = {};
    this.isLoadingForModal = true;
    this.success = false;

    try {
      const data: ApiResponse<{ message?: Omit<Errors["message"], "body"> }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          currentClass: "staff_award",
          method: "updateAward",
          body: { staff_id, award_id, award_date_start }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.success = true;
          this.getAwardsForOneOfStaff(staff_id);
          this.rootStore.staffOneStore.setRebootStaff(staff_id, true);
        } else {
          this.errorsMessage = data.message;
          this.success = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForModal = false;
      });
    }
  };

  deleteAward = async (
    staff_id: string,
    award_id: string,
    award_name: string
  ) => {
    this.errorsMessage = {};
    this.deletedAward = award_name;
    this.success = false;
    this.isLoadingForModal = true;

    try {
      const data: ApiResponse<{ message?: Omit<Errors["message"], "body"> }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          currentClass: "staff_award",
          method: "deleteAward",
          body: {
            award_id
          }
        });
      runInAction(() => {
        if (data.code === 200) {
          this.getAwardsForOneOfStaff(staff_id);
          this.rootStore.staffOneStore.setRebootStaff(staff_id, true);
          this.success = true;
        } else {
          this.errorsMessage = data.message;
          this.success = false;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.success = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForModal = false;
      });
    }
  };

  clearDeletedAward = () => {
    this.deletedAward = "";
  };

  setOpenedWindowType = (value: string) => {
    this.openedWindowType = value;
    this.errorsMessage = {};
  };

  setWithoutAwards = (value: boolean) => {
    this.withoutAwards = value;
  };

  setAwardFields = (group: Partial<Select>) => {
    const filteredAwardsObj = getValues(this.awards).filter(
      (award) => award.custom.group === group.id
    );
    if (filteredAwardsObj.length) {
      this.setWithoutAwards(false);
      this.setOpenedAward(filteredAwardsObj?.[0]?.id);
    }
    if (!filteredAwardsObj.length) {
      this.setWithoutAwards(true);
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
