import styles from "./friendInviteOneEdit.module.scss";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import Select from "shared/ui/Inputs/Select";
import { getEntries } from "shared/utils/helpers/getEntries";
import { useStores } from "stores/index";
import LoadedComponent from "widgets/LoadedComponent";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import FriendInviteOneEditStaff from "./FriendInviteOneEditStaff";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";

const FriendInviteOneEdit = () => {
  const { menuStore, friendInviteOneStore } = useStores();
  const [openedCalendars, setOpenedCalendars] = useState<Record<string, boolean>>({});

  const { id } = useParams();

  useEffect(() => {
    friendInviteOneStore.setSelectedOneInvite(id);
    menuStore.sendTabId(id);
    menuStore.setOpenedModule("friendInviteOne");
    menuStore.setOpenedSubmodule("friendInviteOneEdit");
    menuStore.updateWindow({
      mainPath: `/friendinvite/id=${id}`,
      path: `/friendinvite/id=${id}/edit`
    });
  }, [id]);

  return (
    <LoadedComponent
      isLoading={friendInviteOneStore.isLoading}
      error={friendInviteOneStore.error}
    >
      <Formik
        initialValues={friendInviteOneStore.formikValues[id]}
        onSubmit={friendInviteOneStore.handleSubmitForm}
        enableReinitialize
      >
        {({ values, dirty, handleSubmit, handleReset }) => {
          return (
            <div className={styles.form}>
              {getEntries(friendInviteOneStore.cols).map(([title, col]) => {
                if (
                  col.hidden !== "on" &&
                  friendInviteOneStore.tableParams[title]?.editable
                ) {
                  if (friendInviteOneStore.selects[title]) {
                    return (
                      <ErrorBoundary
                        key={`field_${title}`}
                        FallbackComponent={ErrorFallback}
                      >
                        <Select
                          name={title}
                          options={friendInviteOneStore.selects[title]}
                          label={
                            friendInviteOneStore.selects[title]?.[values[title]]
                              ?.title || values[title]
                          }
                          title={col?.title || "Title"}
                          required={
                            friendInviteOneStore.tableParams[title]
                              ?.required === "on"
                          }
                          valueName="id"
                          isFloating
                        />
                      </ErrorBoundary>
                    );
                  }

                  if (["candidate_tn", "uid"].includes(title)) {
                    return (
                      <ErrorBoundary
                        key={`field_${title}`}
                        FallbackComponent={ErrorFallback}
                      >
                        <FriendInviteOneEditStaff
                          name={title}
                          title={col?.title}
                        />
                      </ErrorBoundary>
                    );
                  }

                  if (col.type === "date" || col.type === "timestamp") {
                    return (
                      <ErrorBoundary
                        key={`field_${title}`}
                        FallbackComponent={ErrorFallback}
                      >
                        <DatePickerField
                          name={title}
                          isCalendarOpened={openedCalendars[title] || false}
                          setIsCalendarOpened={(isOpened: boolean) => 
                            setOpenedCalendars(prev => ({
                              ...prev,
                              [title]: isOpened
                            }))
                          }
                          title={col?.title}
                          required={
                            friendInviteOneStore.tableParams[title]
                              ?.required === "on"
                          }
                        />
                      </ErrorBoundary>
                    );
                  }

                  switch (title) {
                    case "candidate_tn":
                    case "uid":
                      return <div key={title}>{title}</div>;
                    default:
                      return (
                        <ErrorBoundary
                          key={`field_${title}`}
                          FallbackComponent={ErrorFallback}
                        >
                          <Input
                            name={title}
                            label={col?.title}
                            required={
                              friendInviteOneStore.tableParams[title]
                                ?.required === "on"
                            }
                          />
                        </ErrorBoundary>
                      );
                  }
                }
              })}
              <ButtonsGroupForFormEdit
                blueBtnTitle="Сохранить"
                blueBtnDisabled={!dirty}
                blueBtnOnClick={handleSubmit}
                grayBtnTitle="Отмена"
                grayBtnDisabled={!dirty}
                grayBtnOnClick={() => {
                  handleReset();
                }}
              />
            </div>
          );
        }}
      </Formik>
    </LoadedComponent>
  );
};

export default observer(FriendInviteOneEdit);
