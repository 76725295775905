import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";
import { Col } from "stores/utils/types/Col";
import { SelectModule } from "stores/StaffModule/types/Selects";
import { FilterType } from "stores/utils/types/FilterType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { cloneDeep, difference, without } from "lodash";

type SalaryOperationsListItem = {
  uid: {
    id: string;
    tn: number;
    surname: string;
    patronymic: string;
    name: string;
  };

  salary_list: {
    [key: string]: {
      id: string;
      time_spending: string;
      money: number;
      type: string;
      author: {
        id: string;
        title: string;
      };
      object: {
        id: string;
        title: string;
      };
      paymentlist: string;
    };
  };
};

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

export default class SalaryOperationsListStore {
  error = false;
  isLoading = false;
  isLoadingForFilters = false;
  errorMessage = "";

  page = 1;
  onPage = 100;
  maxPage = 0;
  prevPage = 1;

  openedFilter = false;

  orderCurrentTitles: string[] = [
    "uid",
    "object",
    "author",
    "time_spending",
    "money",
    "type"
  ];

  currentTitles: string[] = [];

  salaryOperationsTableCols: { [key: string]: Col } = {};
  salaryOperationsTableParams: { [key: string]: Col } = {};
  salaryOperationsTypes: SelectModule = {};
  dateRangeFields: { [key: string]: string[] } = {};

  filters: { [key: string]: FilterType } = {};
  // объект, в котором хранится не сохраненный фильтр
  filtersChanged: { [key: string]: FilterType } = {};

  // предустановленные фильтры хардкодим на фронте
  generalFilters: { [key: string]: FilterType } = {
    filter_default: {
      cols: [],
      default: 1,
      filter: {},
      general: 1,
      id: "filter_default",
      order: "time_create",
      ordered: "DESC",
      title: "Все операции"
    },
    cancelled: {
      cols: [],
      default: 0,
      filter: { cancelled: 1 },
      general: 1,
      id: "cancelled",
      order: "time_create",
      ordered: "DESC",
      title: "Легитимные"
    }
  };

  salaryOperationsList: SalaryOperationsListItem[] = [];

  searchValue = "";
  foundCounter = 0;
  selectedFilter = "";
  downloadedFilter = "";
  filterParams: { [key: string]: string[] | string | number } = {};
  queryParams: { [key: string]: string } = {};

  // поля для работы со выпадающим списком Сотрудник в фильтре
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";
  isLoadingForStaffList = false;

  // поля для работы со выпадающим списком Объект в фильтре
  pageBuilding = 1;
  onPageBuilding = 30;
  maxPageBuilding = 0;
  prevPageBuilding = 1;
  searchValueBuilding = "";
  isLoadingForBuildingList = false;

  // поля для работы со выпадающим списком Автор в фильтре
  pageAuthor = 1;
  onPageAuthor = 30;
  maxPageAuthor = 0;
  prevPageAuthor = 1;
  searchValueAuthor = "";
  isLoadingForAuthorList = false;

  // список в виде пары id - title
  // нужен для вывода title в компоненте SelectMulti и FilterMainWindow
  dictForFilter: Record<string, string> = {};

  rootStore: RootStore;

  getNewFilterParams = (filter: FilterType) => {
    this.filterParams = {};
    Object.entries(filter.filter).forEach(([key, value]) => {
      if (
        (typeof value === "string" && value) ||
        (Array.isArray(value) && value.length) ||
        typeof value === "number"
      ) {
        this.filterParams[`filter[${key}]`] = value as string;
      } else if (typeof value === "object" && !Array.isArray(value)) {
        this.filterParams[`filter[${key}]`] = Object.values(value);
      }
    });
  };

  getOperationsListWithFilter = (filter: FilterType) => {
    this.getNewFilterParams(filter);

    this.setPage(1);
    this.setDownloadedFilter(filter["id"]);
    this.getSalaryOperationsList();
  };

  setFiltersChanged = (
    currentFilter: string,
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => {
    if (
      this.filtersChanged[currentFilter] &&
      Object.keys(this.filtersChanged[currentFilter]).length
    ) {
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
      this.filtersChanged[currentFilter][key] = value;
    } else {
      this.filtersChanged[currentFilter] = {
        id: "new",
        title: "",
        default: 0,
        general: 0,
        filter: {},
        cols: [],
        order: "time_create",
        ordered: "DESC"
      };
      if (key.includes("filter") && key !== "filter") {
        this.filtersChanged[currentFilter]["filter"][key.slice(7)] =
          value as string;
      } else {
        this.filtersChanged[currentFilter][key] = value;
      }
    }
    this.filtersChanged[currentFilter][key] = value;
  };

  getSalaryOperationsList = async () => {
    this.isLoading = true;
    this.page = 1;
    this.salaryOperationsList = [];
    this.error = false;
    this.isLoading = true;

    try {
      const data: ApiResponse<{ [key: string]: SalaryOperationsListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getSalary",
          on_page: this.onPage,
          page: this.page,
          params: {
            fast_search: this.searchValue,
            ...this.filterParams
          }
        });

      runInAction(() => {
        this.filters = {};
        this.filters = cloneDeep(this.generalFilters);

        Object.values(this.filters).forEach((filter) => {
          if (
            !this.downloadedFilter &&
            filter.default &&
            !Object.keys(this.filterParams).length
          ) {
            this.setDownloadedFilter(filter.id);
          }
        });

        if (data["code"] === 200 && Object.values(data["result"]).length) {
          this.salaryOperationsList = Object.values(data.result);
        } else {
          this.salaryOperationsList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        if (data["code"] === 200 && Object.values(data["cols"]).length) {
          this.salaryOperationsTableCols = data.cols;
          Object.entries(data.cols).forEach(([key, value]) => {
            if (value.useAsFilter === "on") {
              this.salaryOperationsTableParams[key] = value;
            }
            if ("range" in value) {
              this.dateRangeFields[key] = Object.keys(value["range"]);
            }
          });
          this.getSalaryOperationsTypes();
          this.getStaffList();
          this.getBuildingList();
          this.getAuthorList();
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitles = without(
            this.orderCurrentTitles,
            ...difference(
              this.orderCurrentTitles,
              Object.values(data["show_fields"])
            )
          );
          this.currentTitles = this.orderCurrentTitles.concat(
            difference(
              Object.values(data["show_fields"]),
              this.orderCurrentTitles
            )
          );
        } else {
          this.currentTitles = [];
        }
        this.maxPage = data["nav"]["max_page"];
        this.foundCounter = data["nav"]["count"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
        this.isLoadingForFilters = false;
      });
    }
  };

  getMoreSalaryOperationsList = async () => {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const data: ApiResponse<{ [key: string]: SalaryOperationsListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getSalary",
          on_page: this.onPage,
          page: this.page,
          params: {
            fast_search: this.searchValue,
            ...this.filterParams
          }
        });

      runInAction(() => {
        if (data["code"] === 200 && Object.values(data["result"]).length) {
          this.salaryOperationsList.push(...Object.values(data.result));
          this.rootStore.menuStore.isScrollBottom = false;
        } else {
          this.salaryOperationsList = [];
          this.isLoading = false;
          this.errorMessage = "Ничего не найдено";
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  getSalaryOperationsTypes = async () => {
    try {
      const data: ApiResponse<{ [key: string]: SelectModule }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getSelects"
        });
      runInAction(() => {
        if (Object.values(data.result).length) {
          this.salaryOperationsTypes = data["result"]["operation_type"];
          Object.entries(data["result"]).forEach(([key, value]) => {
            const directory: {
              [key: string]: { newname: string; title: string };
            } = {};
            if (key === "operation_type") {
              Object.values(value).forEach((item) => {
                directory[item.id ? item.id : item.title] = {
                  newname: item.id ? item.id : item.title,
                  title: item.title
                };
              });

              this.salaryOperationsTableParams["type"] = {
                ...this.salaryOperationsTableParams["type"],
                directory: directory
              };
            }
          });
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<Record<string, StaffListItem>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.salaryOperationsTableParams["uid"] = {
            ...this.salaryOperationsTableParams["uid"],
            directory: this.dataPreparationStaff(Object.values(data["result"]))
          };
        } else {
          this.error = true;
          this.salaryOperationsTableParams["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          const directory = (
            this.salaryOperationsTableParams["uid"]["directory"] as {
              newname: string;
              title: string;
            }[]
          ).concat(this.dataPreparationStaff(Object.values(data["result"])));
          this.salaryOperationsTableParams["uid"] = {
            ...this.salaryOperationsTableParams["uid"],
            directory: directory
          };
        } else {
          this.salaryOperationsTableParams["uid"]["directory"] = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  dataPreparationStaff = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj["newname"] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  // метод для получения объектов в выпадающем окне фильтра
  getBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryOperationsTableParams) {
            this.salaryOperationsTableParams["object"] = {
              ...this.salaryOperationsTableParams["object"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.salaryOperationsTableParams["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.maxPageBuilding = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.salaryOperationsTableParams["object"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForBuildingList = false;
      });
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreBuildingList = async () => {
    this.isLoadingForBuildingList = true;

    const formData: Record<string, string> = {
      "filter[where][title][value]": this.searchValueBuilding,
      "filter[where][title][comparison]": "LIKE",
      "filter[where][title][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formData,
        select: ["id", "title"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("object" in this.salaryOperationsTableParams) {
            const directory = (
              this.salaryOperationsTableParams["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (object) => !(object["id"] in this.dictForFilter)
                )
              )
            );

            this.salaryOperationsTableParams["object"] = {
              ...this.salaryOperationsTableParams["object"],
              directory: directory
            };
          }
        } else {
          this.salaryOperationsTableParams["object"]["directory"] = [];
          this.isLoadingForBuildingList = false;
        }
        this.prevPageBuilding = this.pageBuilding;
      });
    } catch (error) {
      runInAction(() => {
        this.salaryOperationsTableParams["object"]["directory"] = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForBuildingList = false));
    }
  };

  // метод для получения объектов по их id из сохраненных фильтров и последующее их добавление в справочник
  getBuildingDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        currentClass: "building",
        method: "getList",
        on_page: this.onPageBuilding,
        page: this.pageBuilding,
        params: formDataFromSavedFilter,
        select: ["id", "title"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["title"];
          });
          if ("object" in this.salaryOperationsTableParams) {
            const directory = (
              this.salaryOperationsTableParams["object"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));

            this.salaryOperationsTableParams["object"] = {
              ...this.salaryOperationsTableParams["object"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  // метод для создания списков в выпадающем окне фильтров
  dataPreparation = (data: { id: string; title: string; name?: string }[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; newname: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        switch (key) {
          case "id":
            tempOneOfStaffObj["newname"] = value;
            break;
          case "name":
            tempOneOfStaffObj["title"] = value;
            break;
          default:
            tempOneOfStaffObj[key] = value;
        }
      });
      return tempOneOfStaffObj;
    });
  };

  // метод для получения авторов в выпадающем окне фильтра
  getAuthorList = async () => {
    this.isLoadingForAuthorList = true;

    const formData: Record<string, string> = {
      "filter[where][name][value]": this.searchValueAuthor,
      "filter[where][name][comparison]": "LIKE",
      "filter[where][name][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formData,
        select: ["id", "name"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("author" in this.salaryOperationsTableParams) {
            this.salaryOperationsTableParams["author"] = {
              ...this.salaryOperationsTableParams["author"],
              directory: this.dataPreparation(Object.values(data["result"]))
            };
          }
        } else {
          this.salaryOperationsTableParams["author"]["directory"] = [];
          this.isLoadingForAuthorList = false;
        }
        this.maxPageAuthor = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.salaryOperationsTableParams["author"]["directory"] = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForAuthorList = false;
      });
    }
  };

  // метод для получения объектов в выпадающем окне фильтра при скролле списка (пагинация)
  getMoreAuthorList = async () => {
    this.isLoadingForAuthorList = true;

    const formData: Record<string, string> = {
      "filter[where][name][value]": this.searchValueAuthor,
      "filter[where][name][comparison]": "LIKE",
      "filter[where][name][logic]": "AND"
    };

    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formData,
        select: ["id", "name"]
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if ("author" in this.salaryOperationsTableParams) {
            const directory = (
              this.salaryOperationsTableParams["author"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(
              this.dataPreparation(
                Object.values(data["result"]).filter(
                  (author) => !(author["id"] in this.dictForFilter)
                )
              )
            );

            this.salaryOperationsTableParams["author"] = {
              ...this.salaryOperationsTableParams["author"],
              directory: directory
            };
          }
        } else {
          this.salaryOperationsTableParams["author"]["directory"] = [];
          this.isLoadingForAuthorList = false;
        }
        this.prevPageAuthor = this.pageAuthor;
      });
    } catch (error) {
      runInAction(() => {
        this.salaryOperationsTableParams["author"]["directory"] = [];
      });
    } finally {
      runInAction(() => (this.isLoadingForAuthorList = false));
    }
  };

  // метод для получения авторов по их id из сохраненных фильтров и последующее их добавление в справочник
  getAuthorDictForSavedFilter = async (valueFromSavedFilter?: string[]) => {
    const formDataFromSavedFilter = {
      "filter[where][id][value]": valueFromSavedFilter,
      "filter[where][id][logic]": "OR",
      "filter[where][id][comparison]": "IN"
    };
    try {
      const data: ApiResponse<
        Record<string, { id: string; title: string }> | -1
      > = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "core",
        currentClass: "user",
        method: "getList",
        on_page: this.onPageAuthor,
        page: this.pageAuthor,
        params: formDataFromSavedFilter,
        select: ["id", "name"]
      });
      runInAction(() => {
        if (data["result"] !== -1) {
          Object.values(data["result"]).forEach((value) => {
            this.dictForFilter[value["id"]] = value["name"];
          });
          if ("author" in this.salaryOperationsTableParams) {
            const directory = (
              this.salaryOperationsTableParams["author"]["directory"] as {
                newname: string;
                title: string;
              }[]
            ).concat(this.dataPreparation(Object.values(data["result"])));

            this.salaryOperationsTableParams["author"] = {
              ...this.salaryOperationsTableParams["author"],
              directory: directory
            };
          }
        } else {
          this.dictForFilter = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dictForFilter = {};
      });
    }
  };

  setSearchValue = (value: string) => {
    this.searchValue = value;
  };

  setPage = (value: number) => {
    if (!this.isLoading) {
      this.page = value;
    }
  };

  setFilterParamsFromQuery = (value: { [key: string]: string[] | string }) => {
    this.filterParams = value;
  };

  setQueryParams = (value: { [key: string]: string }) => {
    this.queryParams = value;
  };

  setSelectedFilter = (value: string) => {
    this.selectedFilter = value;
  };

  setDownloadedFilter = (value: string) => {
    this.downloadedFilter = value;
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  setSearchValueBuilding = (value: string) => {
    this.setPageBuilding(1);
    this.prevPageBuilding = 1;
    this.searchValueBuilding = value;
  };

  setPageBuilding = (value: number) => {
    if (!this.isLoadingForBuildingList) {
      this.pageBuilding = value;
    }
  };

  setSearchValueAuthor = (value: string) => {
    this.setPageAuthor(1);
    this.prevPageAuthor = 1;
    this.searchValueAuthor = value;
  };

  setPageAuthor = (value: number) => {
    if (!this.isLoadingForAuthorList) {
      this.pageAuthor = value;
    }
  };

  resetAll = () => {
    this.salaryOperationsList = [];
    this.salaryOperationsTableCols = {};
    this.salaryOperationsTypes = {};
    this.error = false;
    this.isLoading = false;
    this.searchValue = "";
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
