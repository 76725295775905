import styles from "./aregisterHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";

import iconLockOpen from "shared/assets/images/mainIcons/iconLockOpen.svg";
import iconLock from "shared/assets/images/mainIcons/iconLock.svg";
import iconCancel from "shared/assets/images/mainIcons/iconCancel.svg";

import { classNames } from "shared/utils/helpers/classNames";

const status_params = {
  cancelled: {
    icon: iconCancel,
    bg_color: "accent-pastel-red",
    color: "danger"
  },
  done: { icon: iconLock, bg_color: "bw-gray2", color: "bw-gray5" },
  open: { icon: iconLockOpen, bg_color: "accent-light-green", color: "success" }
};

const AregisterHeader = () => {
  const { aregisterOneStore, menuStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);

  // отслеживание скролла для закрепления хэдера
  useEffect(() => {
    if (menuStore.scroll?.scrollTop >= 30 && !isScroll) {
      setTimeout(() => {
        setIsScroll(true);
      }, 100);
    } else if (menuStore.scroll?.scrollTop < 28 && isScroll) {
      setTimeout(() => {
        setIsScroll(false);
      }, 100);
    }
  }, [menuStore.scroll?.scrollTop]);

  const params =
    status_params[
      aregisterOneStore.selectedOne.status.cancelled
        ? "cancelled"
        : aregisterOneStore.selectedOne.status.done
        ? "done"
        : "open"
    ];

  return (
    <div
      className={classNames(
        styles.title,
        {
          [styles.sticky]: isScroll,
          [styles.normal]: !isScroll
        },
        [styles[`title_${params.bg_color}`]]
      )}
    >
      Заявка {aregisterOneStore.selectedOne["internal_num"]}
      <div
        className={classNames(styles.state, {}, [
          styles[`state_${params.color}`]
        ])}
      >
        <img src={params.icon} />
        {aregisterOneStore.selectedOne.status.title}
      </div>
    </div>
  );
};

export default observer(AregisterHeader);
