import styles from "./staffListCustomFilter.module.scss";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import SelectMulti from "shared/ui/Inputs/SelectMulti";
import Select from "shared/ui/Inputs/Select";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import { Input } from "shared/ui/Inputs/Input";
import { useFormikContext } from "formik";
import { useStores } from "stores";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import StatusIcon from "shared/ui/StatusIcon";
import Switch from "shared/ui/Inputs/Switch";
import { format } from "date-fns";
import NumberFormat from "react-number-format";
import { ViewField } from "shared/ui/ViewField";
import { getValues } from "shared/utils/helpers/getValues";

type SafetyWorkCustomFilterProps = Partial<{
  openedListName: string;
  valueField: string;
  values: FilterType;
  params: { [key: string]: Col };
  selectMulti: string[];
  dateRangeFields: Record<string, string[]>;
  changeValue: (
    key: string,
    value: string | number | string[] | FilterType["filter"]
  ) => void;
  changeOpenedWindows: (arg: string) => void;
  dictForArray: Record<string, string>;
  setDictForArray: (value: Record<string, string>) => void;
  selectsWithLoading: string[];
  pageSelectsInfo: Record<
    string,
    {
      page: number;
      prevPage: number;
      maxPage: number;
      searchValue: string;
      isLoading: boolean;
      setSearchValue: (value: string) => void;
      setPage: (value: number) => void;
      getList: () => void;
    }
  >;
}>;

const SafetyWorkCustomFilter = ({
  openedListName,
  valueField,
  values,
  params,
  selectMulti,
  dateRangeFields,
  changeValue,
  changeOpenedWindows,
  dictForArray,
  setDictForArray,
  selectsWithLoading,
  pageSelectsInfo
}: SafetyWorkCustomFilterProps) => {
  const { safetyWorkListStore, filterStore } = useStores();
  const { handleChange, setFieldValue } = useFormikContext();

  // Временный костыль: приводит строку к массиву через split,
  // иначе используем getValues, пока Formik не гарантирует единый тип данных
  const toArray = (value: unknown): string[] => {
    if (typeof value === "string") {
      return value
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }
    return getValues(value as object) as string[];
  };

  // в данном useEffect заполняем словарь для расширенного поиска, если в url присутствуют параметры фильтрации, переходя по ссылке или обновляя страницу
  // применяем для полей, пагинация которых происходит с бэка
  useEffect(() => {
    if (
      Array.isArray(values.filter["building"]) &&
      values.filter["building"].length
    ) {
      // согласно значению поля Объект записываем в словарь эти объекты
      safetyWorkListStore.getBuildingDictForSavedFilter(
        values.filter["building"] as string[]
      );
      setDictForArray(safetyWorkListStore.dictForFilter);
    }

    // Проверяем наличие ID сотрудников в фильтре
    if (Array.isArray(values.filter["uid"]) && values.filter["uid"].length) {
      // согласно значению поля Сотрудник записываем в словарь этих сотрудников
      safetyWorkListStore.getStaffDictForSavedFilter(
        values.filter["uid"] as string[]
      );
      setDictForArray(safetyWorkListStore.dictForFilter);
    }
  }, [values.filter["building"], values.filter["uid"]]);

  // в данном useEffect заполняем словарь для сохраненного фильтра для тех полей, пагинация которых происходит с бэка
  useEffect(() => {
    if (
      Array.isArray(safetyWorkListStore.filterParams["filter[building]"]) &&
      safetyWorkListStore.filterParams["filter[building]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Объект в сохраненном фильтре записываем в словарь эти объекты
      safetyWorkListStore.getBuildingDictForSavedFilter(
        safetyWorkListStore.filterParams["filter[building]"]
      );
      setDictForArray(safetyWorkListStore.dictForFilter);
    }

    // Аналогично проверяем наличие ID сотрудников в сохраненном фильтре
    if (
      Array.isArray(safetyWorkListStore.filterParams["filter[uid]"]) &&
      safetyWorkListStore.filterParams["filter[uid]"].length &&
      filterStore.isOpenedSavedFilterOptions
    ) {
      // согласно значению поля Сотрудник в сохраненном фильтре записываем в словарь этих сотрудников
      safetyWorkListStore.getStaffDictForSavedFilter(
        safetyWorkListStore.filterParams["filter[uid]"]
      );
      setDictForArray(safetyWorkListStore.dictForFilter);
    }
  }, [
    filterStore.isOpenedSavedFilterOptions,
    safetyWorkListStore.downloadedFilter
  ]);

  // пагинация для выпадающего списка объектов
  useEffect(() => {
    if (
      safetyWorkListStore.pageBuilding !== 1 &&
      safetyWorkListStore.pageBuilding <= safetyWorkListStore.maxPageBuilding &&
      safetyWorkListStore.pageBuilding !== safetyWorkListStore.prevPageBuilding
    ) {
      safetyWorkListStore.getMoreBuildingList();
    }
  }, [safetyWorkListStore.pageBuilding, safetyWorkListStore.maxPageBuilding]);

  return (
    <>
      {Object.keys(values.filter).map((key) => {
        const field = params[key];

        if (field?.directory) {
          const dict = field.directory;

          if (selectMulti && selectMulti.includes(key)) {
            switch (key) {
              case "building":
                return (
                  <div key={key} className={styles.flexBox}>
                    <SelectMulti
                      key={key}
                      name={`filter.${key}`}
                      label={field.title}
                      title={field.title}
                      options={dict}
                      valueName="newname"
                      isSearchWithPagination
                      page={safetyWorkListStore.pageBuilding}
                      prevPage={safetyWorkListStore.prevPageBuilding}
                      maxPage={safetyWorkListStore.maxPageBuilding}
                      setPage={safetyWorkListStore.setPageBuilding}
                      getList={safetyWorkListStore.getBuildingList}
                      setSearchValue={
                        safetyWorkListStore.setSearchValueBuilding
                      }
                      searchValue={safetyWorkListStore.searchValueBuilding}
                      isLoading={safetyWorkListStore.isLoadingForBuildingList}
                      onSelectedItemsChange={(items) => {
                        const newArray = items.map((item) => item.id);
                        changeValue(`filter.${key}`, newArray);
                        if (!newArray.length) {
                          changeValue("filter.events_type", []);
                        }
                      }}
                      onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                      onMenuClose={() => changeOpenedWindows("")}
                      dictForArray={dictForArray}
                      setDictForArray={setDictForArray}
                      className={styles.container}
                    />
                    {toArray(values.filter["building"]).length >= 2 ? (
                      <div
                        className={styles.tooltipBottom}
                        data-tooltip="При построении списка по нескольким объектам ожидание ответа будет увеличено"
                      >
                        <StatusIcon icon="attention" color="accent-orange" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );

              case "events_type":
                if (
                  !values.filter["building"] ||
                  (Array.isArray(values.filter["building"]) &&
                    !values.filter["building"].length)
                ) {
                  return (
                    <div
                      key={key}
                      className={styles.tooltipRight}
                      data-tooltip="Выбор статуса сотрудников на объекте доступен после указания объекта"
                    >
                      <ViewField
                        title={field?.title}
                        value={undefined}
                        disabled
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={key} className={styles.flexBox}>
                      <SelectMulti
                        key={key}
                        name={`filter.${key}`}
                        label={field.title}
                        title={field.title}
                        options={dict}
                        valueName="newname"
                        onSelectedItemsChange={(items) => {
                          const newArray = items.map((item) => item.id);
                          changeValue(`filter.${key}`, newArray);
                        }}
                        onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                        onMenuClose={() => changeOpenedWindows("")}
                        dictForArray={dictForArray}
                        setDictForArray={setDictForArray}
                        className={styles.container}
                      />
                      {toArray(values.filter["events_type"]).length >= 2 ? (
                        <div
                          className={styles.tooltipBottom}
                          data-tooltip="При построении списка по нескольким статусам ожидание ответа будет увеличено"
                        >
                          <StatusIcon icon="attention" color="accent-orange" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                }

              default:
                return (
                  <SelectMulti
                    key={key}
                    name={`filter.${key}`}
                    label={field.title}
                    title={field.title}
                    options={dict}
                    valueName="newname"
                    isSearchWithPagination={selectsWithLoading?.includes(key)}
                    page={pageSelectsInfo?.[key]?.page}
                    prevPage={pageSelectsInfo?.[key]?.prevPage}
                    maxPage={pageSelectsInfo?.[key]?.maxPage}
                    setPage={pageSelectsInfo?.[key]?.setPage}
                    getList={pageSelectsInfo?.[key]?.getList}
                    setSearchValue={pageSelectsInfo?.[key]?.setSearchValue}
                    searchValue={pageSelectsInfo?.[key]?.searchValue}
                    isLoading={pageSelectsInfo?.[key]?.isLoading}
                    onSelectedItemsChange={(items) => {
                      const newArray = items.map((item) => item.id);
                      changeValue(`filter.${key}`, newArray);
                    }}
                    onMenuOpen={() => changeOpenedWindows(`filter.${key}`)}
                    onMenuClose={() => changeOpenedWindows("")}
                    dictForArray={dictForArray}
                    setDictForArray={setDictForArray}
                    className={styles.container}
                  />
                );
            }
          } else {
            return (
              <Select
                key={key}
                name={`filter.${key}`}
                title={field.title}
                label={
                  values.filter[key] && values.filter[key] !== -1
                    ? dict[values.filter[key] as string]
                      ? dict[values.filter[key] as string].title
                      : (values.filter[key] as string)
                    : null
                }
                options={dict}
                onClick={(value) => {
                  changeValue(`filter.${key}`, value[valueField] as string);
                }}
                isFloating
                valueName={valueField}
              />
            );
          }
        }

        if (field?.type === "date") {
          if (dateRangeFields && key in dateRangeFields) {
            return (
              <div key={key} className={styles.dateRangeContainer}>
                <div>
                  {!values[`isIntervalField_${key}`] ? (
                    <DatePickerField
                      name={`filter.${dateRangeFields[key][0]}`}
                      title={field.title}
                      isCalendarOpened={openedListName === key}
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(key);
                      }}
                      onBlur={() => {
                        changeOpenedWindows(null);
                        if (
                          new Date(
                            values["filter"][
                              `${dateRangeFields[key][0]}`
                            ] as string
                          ).valueOf() > new Date().valueOf()
                        ) {
                          setFieldValue(
                            `filter.${dateRangeFields[key][1]}`,
                            values["filter"][`${dateRangeFields[key][0]}`]
                          );
                        } else {
                          setFieldValue(
                            `filter.${dateRangeFields[key][1]}`,
                            format(new Date(), "yyyy-MM-dd")
                          );
                        }
                      }}
                    />
                  ) : (
                    <DateRangePicker
                      monthsShown={2}
                      name={`filter.${dateRangeFields[key][0]}`}
                      title={field.title}
                      isCalendarOpened={
                        openedListName === `filter.${dateRangeFields[key][0]}`
                      }
                      setIsCalendarOpened={() => {
                        changeOpenedWindows(
                          `filter.${dateRangeFields[key][0]}`
                        );
                      }}
                      placeholderVisible
                      siblingDateName={`filter.${dateRangeFields[key][1]}`}
                      isForFilter
                      valueStartDate={
                        values.filter[dateRangeFields[key][0]] as string
                      }
                      valueEndDate={
                        values.filter[dateRangeFields[key][1]] as string
                      }
                    />
                  )}
                </div>
                <label className={styles.switch}>
                  <Switch
                    name={`isIntervalField_${key}`}
                    onChange={(e) => {
                      if (
                        !e.target.checked &&
                        values.filter[dateRangeFields[key][1]]
                      ) {
                        setFieldValue(
                          `filter.${dateRangeFields[key][1]}`,
                          format(new Date(), "yyyy-MM-dd")
                        );
                      }
                    }}
                  />
                  Указать интервал
                </label>
              </div>
            );
          } else {
            return (
              <DatePickerField
                key={key}
                name={`filter.${key}`}
                title={field.title}
                isCalendarOpened={openedListName === key}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(key);
                }}
                onBlur={() => {
                  changeOpenedWindows(null);
                }}
              />
            );
          }
        }

        if (
          dateRangeFields &&
          getValues(dateRangeFields).flat().includes(key)
        ) {
          return;
        }

        if (field?.type === "bool") {
          return (
            <div className={styles.input} key={key}>
              <Select
                name={`filter.${key}`}
                title={field.title}
                isFloating
                options={{
                  1: { newname: "1", title: "Да" },
                  0: { newname: "0", title: "Нет" }
                }}
                onClick={(option) => {
                  changeValue(`filter.${key}`, option.newname as string);
                }}
              />
            </div>
          );
        }

        if (key === "cert_expires") {
          return (
            <div className={styles.input} key={key}>
              <NumberFormat
                name={`filter.${key}`}
                label={field?.title}
                allowNegative={false}
                value={values.filter[key] as string}
                customInput={Input}
              />
            </div>
          );
        }

        return (
          <div className={styles.input} key={key}>
            <Input
              name={`filter.${key}`}
              onChange={(e) => {
                handleChange(e);
                changeValue(`filter.${key}`, e.target.value);
              }}
              label={field?.title}
            />
          </div>
        );
      })}
    </>
  );
};

export default observer(SafetyWorkCustomFilter);
