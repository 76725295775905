import styles from "./salaryOperationsOneInfo.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import PageTitle from "shared/ui/PageTitle";

import { classNames } from "shared/utils/helpers/classNames";
import { fileUrl } from "stores/utils/consts";
import StatusIcon from "shared/ui/StatusIcon";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

const SalaryOperationsOneInfo = () => {
  const { id } = useParams();
  const { menuStore, salaryOperationOneStore } = useStores();

  useEffect(() => {
    Object.entries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    salaryOperationOneStore.setSelectedOneOfSalaryOperation(id);

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("salaryOne");
    menuStore.setOpenedSubmodule("salaryOne");
  }, [id]);

  return (
    <LoadedComponent isLoading={salaryOperationOneStore.isLoading}>
      <>
        <PageTitle title="Описание" leftCol />
        {Object.values(salaryOperationOneStore.selectedOneSalaryOperation)
          .length ? (
          <div className={styles.wrapper}>
            {Object.keys(
              salaryOperationOneStore.selectedOneSalaryOperation
            ).map((title) => {
              const operation =
                salaryOperationOneStore.selectedOneSalaryOperation;
              const col =
                salaryOperationOneStore.salaryOperationsOneCols?.[title];

              if (col.hidden === "on") {
                return null;
              }

              if (col) {
                switch (title) {
                  case "initiator":
                  case "uid":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <div className={styles.field__values}>
                          {operation[title] &&
                          salaryOperationOneStore.selectedOneSalaryOperation[
                            title
                          ].tn !== null ? (
                            <>
                              <p className={styles.field__values__color}>
                                {`ТН${salaryOperationOneStore.selectedOneSalaryOperation[title].tn}`}
                              </p>
                              <Link
                                to={`/staff/id=${operation[title].id}`}
                                id={`SalaryOperationsOne_${title}_${operation[title].id}`}
                                className={styles.field__values__link}
                                target="_blank"
                              >
                                {`${operation[title].surname} ${
                                  operation[title].name
                                } ${operation[title].patronymic || ""}`}
                              </Link>
                            </>
                          ) : (
                            <br />
                          )}
                        </div>
                      </div>
                    );
                  case "type":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <p
                          className={`${styles.field__values} ${
                            operation[title] &&
                            salaryOperationOneStore.salaryOperationsTypes[
                              operation[title]
                            ]?.title &&
                            salaryOperationOneStore.salaryOperationsTypes[
                              operation[title]
                            ]["custom"]["color"] !== "default"
                              ? styles[
                                  `field__values__color_${
                                    salaryOperationOneStore
                                      .salaryOperationsTypes[operation[title]][
                                      "custom"
                                    ]["color"]
                                  }`
                                ]
                              : ""
                          }`}
                        >
                          {salaryOperationOneStore.salaryOperationsTypes[
                            operation[title]
                          ]?.title || <br />}
                        </p>
                      </div>
                    );

                  case "money":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <p className={styles.field__values}>
                          {numberWithSpaces(operation[title], true)}
                        </p>
                      </div>
                    );
                  case "connect":
                    return (
                      operation[title]?.link && (
                        <div key={title} className={styles.field}>
                          <p className={styles.field__title}>
                            {col?.["title"]}
                          </p>
                          <a
                            href={`${fileUrl}${operation[title].link}`}
                            id={`SalaryOperationsOne_${title}_${operation[title]}`}
                            className={classNames(styles.field__values, {}, [
                              styles.field__values__link
                            ])}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <StatusIcon
                              icon="iconfinance"
                              color="blue-lazure"
                            />
                            <p>Финансы</p>
                          </a>
                        </div>
                      )
                    );
                  case "object":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <Link
                          to={`/building/id=${operation[title].building}`}
                          id={`SalaryOperationsOne_${title}_${operation[title].building}`}
                          className={styles.field__values__link}
                          target="_blank"
                        >
                          {operation[title].title}
                        </Link>
                      </div>
                    );
                  case "paymentlist":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <div className={styles.field__values}>
                          <p
                            className={
                              operation[title] &&
                              salaryOperationOneStore.paymentlistStatuses[
                                operation[title].status_title
                              ]?.title
                                ? styles[
                                    `field__values__color_${
                                      salaryOperationOneStore
                                        .paymentlistStatuses[
                                        operation[title].status_title
                                      ]["custom"]["color"]
                                    }`
                                  ]
                                : ""
                            }
                          >
                            {salaryOperationOneStore.paymentlistStatuses[
                              operation[title].status_title
                            ]?.title || ""}
                          </p>
                          {operation[title] &&
                          Object.values(operation[title]).length ? (
                            <a
                              href={`${fileUrl}/crm/salary/?ps_id=${operation[title].id}`}
                              id={`SalaryOperationsOne_${title}_${operation[title].id}`}
                              className={styles.field__values__link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {operation[title].status_title}
                            </a>
                          ) : (
                            <br />
                          )}
                        </div>
                      </div>
                    );
                  case "cancelled":
                    return (
                      <div key={title} className={styles.field}>
                        <p className={styles.field__title}>{col?.["title"]}</p>
                        <div className={styles.field__values}>
                          {operation[title] &&
                          Object.values(operation[title]).length ? (
                            <a
                              href={`${fileUrl}/crm/salary/?sd=${operation[title]}`}
                              id={`SalaryOperationsOne_${title}_${operation[title]}`}
                              className={styles.field__values__link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {operation[title]}
                            </a>
                          ) : (
                            <StatusIcon icon="dash" color="accent-coral" />
                          )}
                        </div>
                      </div>
                    );
                  default:
                    if (col.type === "date" || col.type === "timestamp") {
                      return (
                        <div key={title as string} className={styles.field}>
                          <p className={styles.field__title}>
                            {col?.["title"]}
                          </p>
                          <p className={styles.field__values}>
                            {getFormattedDate(operation[title as string])}
                          </p>
                        </div>
                      );
                    }

                    return typeof operation[title as string] !== "object" ? (
                      <div key={title as string} className={styles.field}>
                        <p className={styles.field__title}>
                          {col?.["title"] || ""}
                        </p>
                        <div className={styles.field__values}>
                          {operation[title as string] || <br />}
                        </div>
                      </div>
                    ) : (
                      ""
                    );
                }
              }
            })}
          </div>
        ) : null}
      </>
    </LoadedComponent>
  );
};

export default observer(SalaryOperationsOneInfo);
