import styles from "./customHeader.module.scss";
import { DateFormatType } from "..";
import { getMonth } from "shared/utils/helpers/getMonth";
import { MouseEvent, ReactNode } from "react";
import CustomHeaderButton from "./CustomHeaderButton";
import { classNames } from "shared/utils/helpers/classNames";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  dateFormat: DateFormatType;
}

const CustomHeader = ({
  dateFormat,
  date,
  nextMonthButtonDisabled,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth
}: CustomHeaderProps) => {
  const handleChangeDate =
    (callback: () => void) =>
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      event.preventDefault();
      callback();
    };

  const dateFormatHeader: Record<DateFormatType, ReactNode> = {
    ["MMMM yyyy"]: (
      <CustomHeaderButton
        onClickDecrease={handleChangeDate(decreaseMonth)}
        onClickIncrease={handleChangeDate(increaseMonth)}
      >
        {date &&
          `${
            getMonth(date)[0].toUpperCase() + getMonth(date).substring(1)
          } ${date.getFullYear()}`}
      </CustomHeaderButton>
    ),

    ["yyyy"]: (
      <CustomHeaderButton
        onClickDecrease={handleChangeDate(decreaseYear)}
        onClickIncrease={handleChangeDate(increaseYear)}
      >
        {date ? date.getFullYear() : new Date().getFullYear()}
      </CustomHeaderButton>
    ),

    ["MM.yyyy"]: (
      <CustomHeaderButton
        onClickDecrease={handleChangeDate(decreaseYear)}
        onClickIncrease={handleChangeDate(increaseYear)}
      >
        {date ? date.getFullYear() : new Date().getFullYear()}
      </CustomHeaderButton>
    )
  };

  return (
    <div
      className={classNames(styles.header, {
        ["justify-content-center"]:
          dateFormat === "yyyy" || dateFormat === "MM.yyyy"
      })}
      data-type="datepicker"
    >
      {dateFormat ? (
        dateFormatHeader[dateFormat]
      ) : (
        <>
          <CustomHeaderButton
            onClickDecrease={handleChangeDate(decreaseMonth)}
            onClickIncrease={handleChangeDate(increaseMonth)}
            disabled={nextMonthButtonDisabled}
          >
            {getMonth(date)[0].toUpperCase() + getMonth(date).substring(1)}
          </CustomHeaderButton>
          <CustomHeaderButton
            onClickDecrease={handleChangeDate(decreaseYear)}
            onClickIncrease={handleChangeDate(increaseYear)}
          >
            {date ? date.getFullYear() : new Date().getFullYear()}
          </CustomHeaderButton>
        </>
      )}
    </div>
  );
};

export default CustomHeader;
