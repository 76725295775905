import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import React, { useEffect, useState } from "react";
import styles from "./staffOneEditFormPhotoAlt.module.scss";
import { fileUrl } from "stores/utils/consts";
import StaffOneEditPhotoAltGallery from "./StaffOneEditPhotoAltGallery";
import iconStar from "shared/assets/images/mainIcons/iconIndicatorStar.svg";
import iconClose from "shared/assets/images/mainIcons/iconClose.svg";
import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconAddWithoutFill } from "shared/assets/images/mainIcons/iconAdd/iconAddWithoutFill.svg";
import { getValues } from "shared/utils/helpers/getValues";
import { classNames } from "shared/utils/helpers/classNames";
import Modal from "shared/ui/Modal";

type StaffOneEditFormPhotoAltProps = {
  value: { [key: string]: { src: string | number; hash: string } };
  mainPhoto: { src: string | number; hash: string };
  disabled?: boolean;
  userId: string;
};

type GalleryImage = {
  hash: string;
  src: string;
  isMain: number;
};

const StaffOneEditFormPhotoAlt = ({
  value,
  mainPhoto,
  userId
}: StaffOneEditFormPhotoAltProps) => {
  const { staffOneEditStore } = useStores();
  const [galleryState, setGalleryState] = useState<string>("closed");
  const [galleryImageIndex, setGalleryImageIndex] = useState<number | null>(
    null
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState<string>(null);
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([]);

  useEffect(() => {
    const altPhotos = getValues(value).map(({ hash, src }) => ({
      hash,
      src: `${fileUrl}${src}?${Math.floor(Math.random() * 100000)}`,
      isMain: 0
    }));
    if (mainPhoto?.src) {
      setGalleryImages([
        {
          hash: mainPhoto.hash,
          src: `${fileUrl}${mainPhoto.src}?${Math.floor(
            Math.random() * 100000
          )}`,
          isMain: 1
        },
        ...altPhotos
      ]);
    } else {
      setGalleryImages(altPhotos);
    }
  }, [value, mainPhoto, staffOneEditStore.isLoadingPhotoAsMain]);

  const handleImageClick = (idx: number) => {
    setGalleryImageIndex(idx);
    setGalleryState("viewing");
  };

  const handleDelete = (hash: string) => {
    setPhotoToDelete(hash);
    setShowDeleteModal(true);
  };

  const handleUploadClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    setGalleryState("uploading");
    setGalleryImageIndex(null);
  };

  return (
    <div className={styles.photosGalleryWrapper}>
      {galleryImages.map((photo, idx) =>
        photo.src !== null ? (
          <div
            className={classNames(styles.photoWrapper, {
              [styles.photoWrapperDisabled]:
                galleryState === "viewing" && idx === galleryImageIndex
            })}
            key={photo.hash}
          >
            <img
              className={styles.photo}
              src={photo.src as string}
              alt={`Photo ${idx + 1}`}
              onClick={() => handleImageClick(idx)}
            />
            <img
              src={iconClose}
              className={styles.iconClose}
              alt="Delete"
              id={`StaffOneEditPhotoAlt_delete_${photo.hash}`}
              onClick={() => handleDelete(photo.hash)}
            />
            {photo.isMain > 0 && (
              <img src={iconStar} className={styles.iconStar} alt="Main" />
            )}
          </div>
        ) : null
      )}
      <input
        type="file"
        id="StaffOneEditPhotoAlt_uploadPhoto"
        accept=".jpg, .jpeg, .png"
        onClick={handleUploadClick}
        name="uploadImg"
        hidden
      />
      <label
        htmlFor="StaffOneEditPhotoAlt_uploadPhoto"
        className={styles.placeForAdd}
      >
        <div className={styles.positionRelative}>
          <IconImage className={styles.iconAddFotoDefault} />
          <IconAddWithoutFill className={styles.iconAddWithoutFill} />
        </div>
      </label>
      {galleryState !== "closed" && (
        <StaffOneEditPhotoAltGallery
          loadedImages={galleryImages}
          imageIndex={galleryImageIndex}
          setImageIndex={setGalleryImageIndex}
          galleryState={galleryState}
          setGalleryState={setGalleryState}
          userId={userId}
        />
      )}
      {showDeleteModal && (
        <Modal
          type="clarification"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          title="Вы уверены, что хотите удалить фото?"
          btnWithCrossTitle="Удалить"
          btnWithCrossOnClick={() => {
            if (photoToDelete) {
              staffOneEditStore.setDeletePhoto(photoToDelete);
            }
            setShowDeleteModal(false);
          }}
          blueBtnOnClick={() => setShowDeleteModal(false)}
          blueBtnTitle="Отмена"
          btnWithCrossImg
        />
      )}
      {staffOneEditStore.successDelete && (
        <Modal
          type="success"
          show={staffOneEditStore.successDelete}
          onHide={() => {
            staffOneEditStore.setSuccessDelete(false);
          }}
          title={"Фотография успешно удалена"}
          direction="row"
          autoCloseTime={2000}
          successIcon
        />
      )}
    </div>
  );
};

export default observer(StaffOneEditFormPhotoAlt);
