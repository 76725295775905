import {
  stringWithPuncAndLimit,
  stringWithNumAndPuncAndLimit,
  stringSchema,
  commentSchema
} from "shared/utils/validation/validation";
import * as yup from "yup";
import { isEmpty } from "lodash";
import {
  phoneSchema,
  pastDate,
  validationStaffForm
} from "shared/utils/validation/validationStaffForm";
import { object, array, string } from "yup";
import { getValidationConvictionSchema } from "shared/utils/validation/validationStaffConvictions";

const validationAddNewStaffSchema = {
  ...validationStaffForm,
  company: array().test("isEmpty", "Поле обязательно для заполнения", (val) => {
    return !isEmpty(val);
  }),
  surname: stringWithPuncAndLimit,
  name: stringWithPuncAndLimit,
  patronymic: stringWithPuncAndLimit,
  autoFIO: stringWithPuncAndLimit,
  phone: phoneSchema,
  comment: commentSchema,
  pasp_place: stringWithNumAndPuncAndLimit,
  birth_place: stringWithPuncAndLimit,
  propisk: stringWithNumAndPuncAndLimit,
  living_adress: stringWithNumAndPuncAndLimit,
  pasp_date: pastDate("pasp_date"),
  education: stringWithPuncAndLimit,
  conviction: array().of(
    object({
      article: stringSchema,
      date_start: yup.date(),
      punishment: stringSchema,
      term_type: stringSchema,
      comment: stringSchema
    })
  )
};

export const getValidationSchema = (
  cols: string[],
  requiredCols: string[],
  isConvictionField: boolean,
  requiredConvictionFields: string[],
  convictionCols: string[]
) => {
  const validationSchema = {};

  cols.map((col) => {
    validationSchema[col] = validationAddNewStaffSchema[col]
      ? validationAddNewStaffSchema[col]
      : string().nullable();

    if (requiredCols.includes(col) && col !== "uid") {
      validationSchema[col] = validationAddNewStaffSchema[col]
        ? validationAddNewStaffSchema[col]?.required(
            "Поле обязательно для заполнения"
          )
        : string().nullable()?.required("Поле обязательно для заполнения");
    }

    if (isConvictionField) {
      validationSchema["conviction"] = array().of(
        getValidationConvictionSchema(convictionCols, requiredConvictionFields)
      );
    }
  });

  return object(validationSchema);
};
