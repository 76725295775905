import styles from "./selectMulti.module.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import ItemsScrollBoard from "shared/ui/ItemsScrollBoard";
import { ClearFieldButton } from "shared/ui/ClearFieldButton";
import { ReactComponent as IconAdd } from "shared/assets/images/mainIcons/iconAdd/iconAddDefault.svg";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";
import { Option } from "shared/ui/Option";
import FormWindow from "shared/ui/FormWindow";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconCloseRed } from "shared/assets/images/mainIcons/iconsClose/iconCloseRed.svg";
import { ReactComponent as IconCloseGrey } from "shared/assets/images/mainIcons/iconsClose/iconCloseGray.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { classNames } from "shared/utils/helpers/classNames";
import { getValues } from "shared/utils/helpers/getValues";
import { observer } from "mobx-react-lite";
import { Button, ButtonTheme } from "shared/ui/Button";
import Scrollbars from "react-custom-scrollbars-2";
import Tooltip from "shared/ui/Tooltip";

type ActionButtonProps = {
  theme?: Exclude<ButtonTheme, ButtonTheme.ICON>;
  text: string;
  icon: React.ReactNode;
};

type SelectMultiProps = {
  name: string;
  required?: boolean;
  label?: string;
  isFocused?: boolean;
  title: string;
  subtitle?: string;
  options: Record<string, OptionWithTitle> | OptionWithTitle[];
  defaultArray?: string[];
  valueName?: string;
  isSearchWithPagination?: boolean;
  page?: number;
  prevPage?: number;
  maxPage?: number;
  setPage?: (value: number) => void;
  getList?: () => void;
  setSearchValue?: (value: string) => void;
  isLoading?: boolean;
  searchPlaceholder?: string;
  onClick?: (option: OptionWithTitle) => void;
  searchValue?: string;
  // пропс передается в редактировании сотрудника,
  //так как после очистки initialValues не приходит meta.error
  isError?: boolean;
  // Пропс для передачи наружу выбранных элементов
  onSelectedItemsChange?: (items: Array<{ id: string; title: string }>) => void;
  saveBtnTitle?: string;
  addBtnTitle?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  actionButton?: ActionButtonProps;
  className?: string;
  dictForArray?: Record<string, string>;
  setDictForArray?: (value: Record<string, string>) => void;
};

const SelectMulti = ({
  name,
  required,
  label,
  isFocused,
  title,
  subtitle,
  options,
  defaultArray,
  valueName = "newname",
  isSearchWithPagination,
  page,
  prevPage,
  maxPage,
  setPage,
  getList,
  setSearchValue,
  isLoading,
  searchPlaceholder,
  onClick,
  searchValue,
  isError,
  onSelectedItemsChange,
  saveBtnTitle = "Готово",
  addBtnTitle = "Отмена",
  onMenuOpen,
  onMenuClose,
  actionButton,
  className,
  dictForArray,
  setDictForArray
}: SelectMultiProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempSelectedItems, setTempSelectedItems] = useState<
    Array<{ id: string; title: string }>
  >([]);
  const { setFieldTouched } = useFormikContext();
  const [field, meta, { setValue }] = useField({
    name,
    required
  });
  const ref = useRef<HTMLDivElement>();
  const prevOpen = useRef(isModalOpen);
  const prevValueRef = useRef<string[]>([]);
  const values: string[] = useMemo(() => {
    if (!field.value) {
      return [];
    }

    if (typeof field.value === "string") {
      return field.value
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const rawValues = getValues(field.value) ?? [];
    return rawValues
      .map((val) => (val || "").toString().trim())
      .filter(Boolean);
  }, [field.value]);
  const selectedCount = isModalOpen ? tempSelectedItems.length : values.length;
  
  /**
   * Функция для получения отображаемого заголовка по значению
   * @param value - значение для поиска (обычно id или newname элемента)
   * @returns строка с заголовком элемента
   */
  const getTitle = (value: string) => {
    // 1. Проверяем кэш
    // Если у нас есть словарь с закэшированными значениями и в нем есть искомый элемент
    // возвращаем его title сразу, это самый быстрый путь
    if (dictForArray && dictForArray[value]) {
      return dictForArray[value];
    }

    // 2. Проверяем временное состояние
    // Если открыто модальное окно ИЛИ включена постраничная загрузка
    // пытаемся найти элемент во временном состоянии (там хранятся выбранные элементы)
    if (isModalOpen || isSearchWithPagination) {
      const selectedItem = tempSelectedItems.find((item) => item.id === value);
      if (selectedItem) {
        return selectedItem.title;
      }
      // Важно: если элемент не найден, мы НЕ делаем return
      // а идем дальше искать в options
    }

    // 3. Ищем в основном списке опций
    let titleStr = "";
    if (Array.isArray(options)) {
      // Если options - это массив, ищем напрямую
      const found = options.find((option) => option[valueName] === value);
      titleStr = found ? found.title : value;
    } else {
      // Если options - это объект, сначала пробуем прямой доступ
      // если не получилось - ищем через getValues
      const found = options[value]
        ? options[value]
        : getValues(options).find(
            (option) => String(option[valueName] ?? "") === String(value ?? "")
          );
      titleStr = found ? found.title : value;
    }

    // Если ничего не нашли - вернется само значение value
    return titleStr;
  };

  // При открытии модального окна инициализируем tempSelectedItems из Formik
  const handleOpenModal = () => {
    const initialTemp = values.map((val: string) => ({
      id: val,
      title: getTitle(val)
    }));
    prevValueRef.current = values;
    setTempSelectedItems(initialTemp);
    setIsModalOpen(true);
    if (required) {
      setFieldTouched(field.name);
    }
  };

  // Если окно открыто – очищаем tempSelectedItems, иначе очищаем значение в Formik
  const handleClearField = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();
    if (isModalOpen) {
      setTempSelectedItems([]);
    } else {
      setValue([]);
      onSelectedItemsChange && onSelectedItemsChange([]);
    }
  };

  // Добавление одного элемента во временное состояние
  const handleAddItem = (item: OptionWithTitle) => {
    const itemId = String(item[valueName]);
    if (!tempSelectedItems.find((x) => x.id === itemId)) {
      setTempSelectedItems([
        ...tempSelectedItems,
        { id: itemId, title: item.title }
      ]);
      onClick && onClick(item);
    }
  };

  // Добавление всех выбранных элементов
  const handleAddAllItem = (items: OptionWithTitle[]) => {
    const newItems = items.map((item) => ({
      id: String(item[valueName]),
      title: item.title
    }));
    setTempSelectedItems([...tempSelectedItems, ...newItems]);
  };

  // Если окно открыто, удаляем из tempSelectedItems,
  // если закрыто – сразу обновляем значение в Formik.
  const handleDeleteSelectedItem = (id: string) => {
    if (isModalOpen) {
      setTempSelectedItems(tempSelectedItems.filter((item) => item.id !== id));
    } else {
      const updatedValues = values.filter((val) => val !== id);
      setValue(updatedValues);
      if (onSelectedItemsChange) {
        const updatedItems = updatedValues.map((val) => ({
          id: val,
          title: getTitle(val)
        }));
        onSelectedItemsChange(updatedItems);
      }
    }
  };

  // Отображение выбранных элементов
  const renderSelectedItems = () => {
    const itemsToRender = isModalOpen
      ? tempSelectedItems
      : values.map((val: string) => ({
          id: val,
          title: getTitle(val)
        }));
    return (
      <div className={styles.fieldItemsBox}>
        {itemsToRender.map((item, index) => (
          <Option
            key={item.id + index}
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteSelectedItem(item.id);
            }}
            id={`selectmulti_chooseOption_${item.id}`}
            title={item.title}
          />
        ))}
        {defaultArray && defaultArray.length > 0 && (
          <div className={styles.fieldItemsBox}>
            {defaultArray.map((key, index) => (
              <Tooltip
                key={key}
                text="Обязательное поле"
                color="blue-lazure"
                placement="bottom"
                withoutFlip
              >
                <Option key={key + index} title={getTitle(key)} required />
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Функция для сброса поискового запроса и обновления списка
  const handleClearSearchInput = () => {
    if (isSearchWithPagination && searchValue) {
      setSearchValue("");
      getList();
    }
  };

  // По кнопке "Готово" коммитим изменения в Formik и уведомляем родителя
  const handleSave = () => {
    const newValues = tempSelectedItems.map((item) => item.id);
    setValue(newValues);

    if (setDictForArray) {
      const newDict = { ...dictForArray };
      tempSelectedItems.forEach(({ id, title }) => {
        newDict[id] = title;
      });
      setDictForArray(newDict);
    }

    onSelectedItemsChange && onSelectedItemsChange(tempSelectedItems);
    handleClearSearchInput();
    setIsModalOpen(false);
  };

  // Проверка наличия изменений в текущей сессии
  const checkIsDirtyThisSession = () => {
    const currentTemp = tempSelectedItems.map((item) => item.id);
    return JSON.stringify(prevValueRef.current) !== JSON.stringify(currentTemp);
  };

  useEffect(() => {
    if (isModalOpen) {
      ref.current?.focus();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (prevOpen.current && !isModalOpen) {
      onMenuClose?.();
    } else if (!prevOpen.current && isModalOpen) {
      onMenuOpen?.();
    }
    prevOpen.current = isModalOpen;
  }, [isModalOpen]);

  // Вычисляем полный список опций из пропсов
  const computedOptions = useMemo(() => {
    let baseOptions = Array.isArray(options) ? options : getValues(options);

    if (defaultArray?.length) {
      return baseOptions.filter(
        (opt) => !defaultArray.includes(String(opt[valueName]))
      );
    }

    baseOptions = getValues(
      baseOptions.reduce((acc, opt) => {
        acc[String(opt[valueName])] = opt;
        return acc;
      }, {})
    );

    return baseOptions;
  }, [options, defaultArray]);

  // Вычисляем выбранные значения: если окно открыто – берем из tempSelectedItems, иначе из Formik
  const computedSelectedValues = useMemo(() => {
    return isModalOpen ? tempSelectedItems.map((item) => item.id) : values;
  }, [isModalOpen, tempSelectedItems, values]);

  // Функция для закрытия модального окна
  const handleCloseModal = () => {
    handleClearSearchInput();
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={classNames("", {
          [styles.field]: !isModalOpen,
          [styles.fieldFocused]: isModalOpen,
          [styles.fieldError]: (meta.error && meta.touched) || isError
        })}
        ref={ref}
        aria-labelledby="select-label"
      >
        {actionButton ? (
          <Button
            theme={actionButton.theme || ButtonTheme.CLEAR}
            id={`selectmulti_actionButton_${name}`}
            onClick={handleOpenModal}
          >
            {actionButton.text}
            {actionButton.icon}
          </Button>
        ) : (
          <div
            className={classNames(
              styles.selectContainer,
              {
                [styles.containerModalOpen]:
                  values.length || defaultArray?.length || isModalOpen
              },
              [className]
            )}
            id={`selectmulti_showMenuWithoutBtn_${name}`}
            tabIndex={-1}
            onClick={handleOpenModal}
          >
            <p
              className={classNames("", {
                [styles.label]: !(values.length || defaultArray?.length),
                [styles.labelRequired]:
                  !(values.length || defaultArray?.length) && required,
                [styles.labelRequiredError]:
                  (!(values.length || defaultArray?.length) &&
                    required &&
                    meta.touched &&
                    meta.error) ||
                  isError,
                [styles.labelSmall]:
                  values.length || defaultArray?.length || isModalOpen,
                [styles.labelSmallRequired]:
                  (values.length || defaultArray?.length) && required,
                [styles.blueLabel]: isModalOpen
              })}
            >
              {label}
            </p>

            <div
              className={classNames(styles.fieldItems, {
                [styles.fieldItemsWithLabel]: label
              })}
            >
              {renderSelectedItems()}
            </div>

            <div className={styles.buttons}>
              <IconAdd
                name={name}
                className={classNames(styles.iconAdd, {
                  [styles.iconAddActive]: isModalOpen
                })}
                onClick={handleOpenModal}
                id={`selectmulti_showMenu_${name}`}
                tabIndex={-1}
              />
              <ClearFieldButton
                name={name}
                disabled={false}
                onClick={handleClearField}
                isFocused={isFocused}
              />
            </div>
          </div>
        )}
        {!actionButton &&
          (meta.error && meta.touched ? (
            <div className={styles.errorText}>{meta.error}</div>
          ) : isError ? (
            <div className={styles.errorText}>
              {"Поле обязательно для заполнения"}
            </div>
          ) : null)}
      </div>
      {isModalOpen && (
        <FormWindow
          title={title}
          subtitle={subtitle}
          setOpenWindow={handleCloseModal}
          saveBtnTitle={saveBtnTitle}
          saveBtnOnClickFunc={handleSave}
          saveBtnDisabledValue={!checkIsDirtyThisSession()}
          addBtnTitle={addBtnTitle}
          addBtnImg={<IconClose />}
          buttonsOrder={["add", "save"]}
          fromOverlayWindow
          isScroll
          isFromSelectMulti
          onDiscardSelectMulti={() => {
            setValue(prevValueRef.current);
          }}
          onCheckSelectMultiDirty={checkIsDirtyThisSession}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={styles.container}>
              <div className={styles.moveItemBlock}>
                <div className={styles.multiSelectHeader}>
                  <p className={styles.selectedCount}>
                    Добавлено: {selectedCount}
                  </p>
                  <Button
                    className={selectedCount && styles.clearBtn}
                    theme={ButtonTheme.CLEAR}
                    disabled={!selectedCount}
                    id="selectmulti_clearValueBtn"
                    onClick={handleClearField}
                  >
                    Очистить
                    {selectedCount ? <IconCloseRed /> : <IconCloseGrey />}
                  </Button>
                </div>
                <Scrollbars
                  width="100%"
                  autoHeight
                  autoHeightMax="250px"
                  autoHideTimeout={1000}
                  autoHideDuration={200}
                >
                  {renderSelectedItems()}
                </Scrollbars>
              </div>
              <p className={styles.optionsListBlockTitle}>
                Выберите одно или несколько значений
              </p>
              <ItemsScrollBoard
                options={computedOptions}
                values={computedSelectedValues}
                valueName={valueName}
                isSearchWithPagination={isSearchWithPagination}
                page={page}
                isItemBtnMode
                prevPage={prevPage}
                maxPage={maxPage}
                setPage={setPage}
                getList={getList}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                isLoading={isLoading}
                addItem={handleAddItem}
                addAllItem={handleAddAllItem}
                searchPlaceholder={searchPlaceholder}
                className={styles.optionList}
              />
            </div>
          </ErrorBoundary>
        </FormWindow>
      )}
    </>
  );
};

export default observer(SelectMulti);
