import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import FriendInviteAllTable from "./FrienInviteAllTable/index";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import TableWithStickyFilter from "features/TableWithStickyFilter";
import { getTHeadTop } from "shared/utils/helpers/getTHeadTop";
import { browserName } from "react-device-detect";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { getEntries } from "shared/utils/helpers/getEntries";

const FriendInviteAll = () => {
  const { menuStore, friendInviteStore, filterStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialLoading, setInitialLoading] = useState(true);

  const queryParams: Record<string, string | string[]> = {};

  useEffect(() => {
    if (searchParams.toString()) {
      const filterParams: { [key: string]: string } = {};
      for (const [key, value] of searchParams.entries()) {
        filterParams[key] = value;
      }

      friendInviteStore.setQueryParams(filterParams);
      setSearchParams(filterParams);

      getEntries(filterParams).forEach(([key, value]) => {
        switch (key) {
          case "fast_search":
            friendInviteStore.setSearchValue(value);
            break;
          case "order":
            friendInviteStore.setOrder(value);
            break;
          case "ordered":
            friendInviteStore.setOrdered(value as "ASC" | "DESC");
            break;
          default:
            if (key.match(/[0-9.,:]/)) {
              const valideName = key.split("[").slice(0, -1).join("[");
              if ((queryParams[valideName] as string)?.length) {
                queryParams[valideName] = [
                  ...(queryParams[valideName] as string[]),
                  value
                ];
              } else {
                queryParams[valideName] = [value];
              }
            } else {
              queryParams[key] = value;
            }
        }
      });

      friendInviteStore.setFilterParamsFromQuery(queryParams);
    }
    if (isEmpty(friendInviteStore.invitesList)) {
      setInitialLoading(true);
      friendInviteStore.getAllInviteFriendData().then(() => {
        setInitialLoading(false);
      });
    } else {
      setInitialLoading(false);
    }
    menuStore.setSavedScroll();
    menuStore.setOpenedModule("friendInvite");
    menuStore.setOpenedSubmodule("main");
    menuStore.addWindow("/friendinvite", "Приведи друга");
  }, []);

  useEffect(() => {
    const filterParams: {
      [key: string]: string;
    } = {};

    getEntries(friendInviteStore.filterParams)?.forEach(
      ([key, value]: [string, string | number | string[]]) => {
        switch (typeof value) {
          case "string":
          case "number":
            filterParams[key] = String(value);
            break;
          case "object":
            value?.forEach((objectValue, index) => {
              filterParams[`${key}[${index}]`] = objectValue;
            });
            break;
        }
      }
    );
    if (friendInviteStore.ordered) {
      filterParams["ordered"] = friendInviteStore.ordered;
    }
    if (friendInviteStore.order) {
      filterParams["order"] = friendInviteStore.order;
    }
    if (friendInviteStore.searchValue) {
      filterParams["fast_search"] = friendInviteStore.searchValue;
    }

    friendInviteStore.setQueryParams(filterParams);
    setSearchParams(filterParams);
  }, [
    friendInviteStore.filterParams,
    friendInviteStore.searchValue,
    friendInviteStore.ordered,
    friendInviteStore.order
  ]);

  useEffect(() => {
    if (!isEmpty(friendInviteStore.queryParams)) {
      setSearchParams(friendInviteStore.queryParams);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      friendInviteStore.page !== 1 &&
      friendInviteStore.page <= friendInviteStore.maxPage &&
      friendInviteStore.page !== friendInviteStore.prevPage
    ) {
      friendInviteStore.getMorePage();
    }
  }, [friendInviteStore.page, friendInviteStore.maxPage]);

  // Добавляем эффекты для подгрузки данных при скролле в фильтрах
  useEffect(() => {
    if (
      friendInviteStore.pageStaffFilter !== 1 &&
      friendInviteStore.pageStaffFilter <=
        friendInviteStore.maxPageStaffFilter &&
      friendInviteStore.pageStaffFilter !==
        friendInviteStore.prevPageStaffFilter
    ) {
      friendInviteStore.getMoreStaffForFilter();
    }
  }, [friendInviteStore.pageStaffFilter, friendInviteStore.maxPageStaffFilter]);

  useEffect(() => {
    if (
      friendInviteStore.pageAuthorFilter !== 1 &&
      friendInviteStore.pageAuthorFilter <=
        friendInviteStore.maxPageAuthorFilter &&
      friendInviteStore.pageAuthorFilter !==
        friendInviteStore.prevPageAuthorFilter
    ) {
      friendInviteStore.getMoreAuthorForFilter();
    }
  }, [
    friendInviteStore.pageAuthorFilter,
    friendInviteStore.maxPageAuthorFilter
  ]);

  // в данном useEffect заполняем словарь для сохраненного фильтра для тех полей, пагинация которых происходит с бэка
  useEffect(() => {
    if (filterStore.isOpenedSavedFilterOptions) {
      // Проверяем наличие ID сотрудников в поле uid
      if (
        Array.isArray(friendInviteStore.filterParams["filter[uid]"]) &&
        friendInviteStore.filterParams["filter[uid]"].length
      ) {
        friendInviteStore.getStaffDictForSavedFilter(
          friendInviteStore.filterParams["filter[uid]"],
          "uid"
        );
      }

      // Проверяем наличие ID сотрудников в поле candidate_tn
      if (
        Array.isArray(friendInviteStore.filterParams["filter[candidate_tn]"]) &&
        friendInviteStore.filterParams["filter[candidate_tn]"].length
      ) {
        friendInviteStore.getStaffDictForSavedFilter(
          friendInviteStore.filterParams["filter[candidate_tn]"],
          "candidate_tn"
        );
      }

      // Проверяем наличие ID авторов
      if (
        Array.isArray(friendInviteStore.filterParams["filter[author]"]) &&
        friendInviteStore.filterParams["filter[author]"].length
      ) {
        friendInviteStore.getAuthorDictForSavedFilter(
          friendInviteStore.filterParams["filter[author]"]
        );
      }
    }
  }, [
    filterStore.isOpenedSavedFilterOptions,
    friendInviteStore.downloadedFilter
  ]);

  const filterInvites = (order: string) => {
    if (friendInviteStore.selectedFilter !== "new") {
      friendInviteStore.getNewFilterParams(
        friendInviteStore.filters[friendInviteStore.selectedFilter]
      );
    }

    const ordered =
      order === friendInviteStore.order
        ? friendInviteStore.ordered === "DESC"
          ? "ASC"
          : "DESC"
        : "DESC";

    friendInviteStore.setOrder(order);
    friendInviteStore.setOrdered(ordered);
    friendInviteStore.setPage(1);

    const queryParams = {
      ...friendInviteStore.queryParams,
      order,
      ordered,
      selectedFilter: friendInviteStore.downloadedFilter
    };

    friendInviteStore.setQueryParams(queryParams);
    setSearchParams(queryParams);

    friendInviteStore.getPage();
  };

  return (
    <>
      <TableWithStickyFilter
        isLoading={initialLoading || friendInviteStore.isLoading}
        isLoadingForFilters={friendInviteStore.isLoadingForFilters}
        filters={friendInviteStore.filters}
        selectedFilter={friendInviteStore.selectedFilter}
        setSelectedFilter={friendInviteStore.setSelectedFilter}
        getDataWithFilter={friendInviteStore.getPageListWithFilter}
        filterParams={friendInviteStore.filterParams}
        setFilters={friendInviteStore.setFilters}
        setFiltersChanged={friendInviteStore.setFiltersChanged}
        downloadedFilter={friendInviteStore.downloadedFilter}
        setDownloadedFilter={friendInviteStore.setDownloadedFilter}
        setQueryParams={friendInviteStore.setQueryParams}
        queryParams={friendInviteStore.queryParams}
        foundCounter={friendInviteStore.foundCounter}
        itemName="приглашений"
        searchValue={friendInviteStore.searchValue}
        setSearchValue={friendInviteStore.setSearchValue}
        getData={friendInviteStore.getPage}
        cols={friendInviteStore.cols}
        params={friendInviteStore.filterCols}
        allCols={friendInviteStore.cols}
        defaultFields={{
          uid: "",
          candidate_tn: "",
          candidate_surname: "",
          candidate_name: ""
        }}
        currentCols={friendInviteStore.currentTitles}
        isSearchable
        withoutColsSetting
        theadTop={getTHeadTop(
          filterStore.savedFiltersHeight ?? -2,
          Boolean(friendInviteStore.searchValue),
          filterStore.isOpenedSavedFilters && browserName === "Firefox"
        )}
        searchInputTooltip="В этом поле можно искать несколько рекомендателей, если вбить ТН номера через пробел"
        fixedWidthSearchInput
        selectMulti={[
          "uid",
          "uc",
          "candidate_tn",
          "status",
          "pay",
          "author",
          "position"
        ]}
        selectsWithLoading={["uid", "candidate_tn", "author"]}
        pageSelectsInfo={{
          uid: {
            page: friendInviteStore.pageStaffFilter,
            prevPage: friendInviteStore.prevPageStaffFilter,
            maxPage: friendInviteStore.maxPageStaffFilter,
            searchValue: friendInviteStore.searchValueStaffFilter,
            isLoading: friendInviteStore.isLoadingForStaffListFilter,
            setSearchValue: friendInviteStore.setSearchValueStaffFilter,
            setPage: friendInviteStore.setPageStaffFilter,
            getList: friendInviteStore.getStaffListForFilter
          },
          candidate_tn: {
            page: friendInviteStore.pageStaffFilter,
            prevPage: friendInviteStore.prevPageStaffFilter,
            maxPage: friendInviteStore.maxPageStaffFilter,
            searchValue: friendInviteStore.searchValueStaffFilter,
            isLoading: friendInviteStore.isLoadingForStaffListFilter,
            setSearchValue: friendInviteStore.setSearchValueStaffFilter,
            setPage: friendInviteStore.setPageStaffFilter,
            getList: friendInviteStore.getStaffListForFilter
          },
          author: {
            page: friendInviteStore.pageAuthorFilter,
            prevPage: friendInviteStore.prevPageAuthorFilter,
            maxPage: friendInviteStore.maxPageAuthorFilter,
            searchValue: friendInviteStore.searchValueAuthorFilter,
            isLoading: friendInviteStore.isLoadingForAuthorListFilter,
            setSearchValue: friendInviteStore.setSearchValueAuthorFilter,
            setPage: friendInviteStore.setPageAuthorFilter,
            getList: friendInviteStore.getAuthorListForFilter
          }
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <FriendInviteAllTable filterInvites={filterInvites} />
        </ErrorBoundary>
      </TableWithStickyFilter>
    </>
  );
};

export default observer(FriendInviteAll);
