import styles from "./statusIcon.module.scss";
import { ReactSVG } from "react-svg";
import { classNames } from "shared/utils/helpers/classNames";

// Функция формирует объект с парами ключ-значение, где ключ - название картинки, например, worker, а значение - src для использования в img
// Используется webpack require.context

const importAll = (r: __WebpackModuleApi.RequireContext) => {
  const images = {};
  r.keys().map((item) => {
    images[item.replace("./", "").replace(".svg", "").toLowerCase()] = r(item);
  });
  return images;
};

export const iconStatus = importAll(
  require.context("shared/assets/images/iconStatus", false)
);

export const mainIcons = importAll(
  require.context("shared/assets/images/mainIcons", false)
);

type StatusIconProps = {
  icon: string;
  color?: string;
  negative?: boolean;
  small?: boolean;
  onClick?: {
    handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    id: string;
  };
  className?: string;
};

const StatusIcon = ({
  icon,
  color,
  negative,
  small,
  onClick,
  className
}: StatusIconProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick && onClick.handleClick(event);
  };

  return icon ? (
    <div
      className={classNames(
        "",
        {
          [styles[`icon_active_${color}`]]: negative,
          [styles[`icon_${color}`]]: !negative,
          [styles.small]: small
        },
        [className || ""]
      )}
      onClick={handleClick}
      id={onClick?.id || null}
    >
      {mainIcons[icon] || iconStatus[icon] ? (
        <ReactSVG
          src={mainIcons[icon] || iconStatus[icon]}
          data-svg={icon?.toLowerCase()}
        />
      ) : (
        ""
      )}
    </div>
  ) : null;
};

export default StatusIcon;
