import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type StaffListItem = {
  tn: number;
  fio: string;
  id: string;
};

type StatusList = Record<
  string,
  {
    comment: null;
    custom: null | {
      closed: number;
    };
    id: string;
    idmodule: number;
    ordered: null;
    title: string;
    ukey: string;
  }
>;

export default class OperationFormStore {
  error = false;
  isLoadingForStaffList = false;

  statusList: StatusList = {};

  // Данные сотрудников
  page = 1;
  onPage = 100;
  maxPage = 0;
  prevPage = 1;

  staffList: { title: string; id: string }[] = [];
  pageStaff = 1;
  onPageStaff = 30;
  maxPageStaff = 0;
  prevPageStaff = 1;
  searchValueStaff = "";

  getStatusList = async () => {
    try {
      const data: {
        records: StatusList;
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduStatusList"
      });
      runInAction(() => {
        if (Object.values(data.records).length) {
          this.statusList = data.records;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj = {} as { title: string; id: string };
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj[key] = value as string;
              break;
            case "tn":
              break;
            case "fio":
              tempOneOfStaffObj["title"] = `ТН${oneOfStaff["tn"]} ${value}`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.staffList = this.dataPreparation(Object.values(data["result"]));
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPageStaff = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;

    try {
      const data: ApiResponse<{ [key: string]: StaffListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "fastSearch",
          on_page: this.onPageStaff,
          page: this.pageStaff,
          params: {
            fast_search: this.searchValueStaff,
            only_records: 1
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.staffList.push(
            ...this.dataPreparation(Object.values(data["result"]))
          );
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPageStaff = this.pageStaff;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  setSearchValueStaff = (value: string) => {
    this.setPageStaff(1);
    this.prevPageStaff = 1;
    this.searchValueStaff = value;
  };

  setPageStaff = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.pageStaff = value;
    }
  };

  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
