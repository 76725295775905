import { cloneDeep, isEmpty, isEqual } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { StaffImportTableType, StaffStatus } from "./types/StaffImportTable";
import { Errors } from "stores/utils/types/ErrorsType";
import { TableParams } from "stores/utils/types/TableParams";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getKeys } from "shared/utils/helpers/getKeys";
import { getValues } from "shared/utils/helpers/getValues";
import { formatPhoneNumber } from "shared/utils/helpers/formatPhoneNumber";
import { Col } from "stores/utils/types/Col";
import { Selects } from "./types/Selects";
import { format, isValid } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

type ColsForDuplicateCheck = {
  name: string;
  default: boolean;
  selectable: boolean;
  title: string;
};

type VerificationResponse = {
  [key: string]: Partial<{
    approve: boolean;
    incorrect_cols: Record<string, string>;
    doubles?: {
      [key: string]: { uid: number; staff_id: string; full_name: string };
    };
    inner_doubles?: Partial<{
      inn: number;
      pasp_n: number;
      snils: number;
      phone_1: string;
      phone_2: string;
    }>;
  }>;
};

type SimpleSelects = Partial<
  Record<keyof Selects, Record<string, { id: string; title: string }>>
>;

export type ErrorDictTitle =
  | "requiredFields"
  | "duplicateRow"
  | "validationError"
  | "invalidResponse";

export default class StaffImportStore {
  isLoading = false; // лоадер при импорте таблицы с экселя
  isLoadingDuplicateCheck = false; // лоадер при вызове проверки на дубликат
  error = false;
  errorMessage: Partial<Errors["message"]> = {}; // ошибки с бэка
  staffList: StaffImportTableType[] = [];
  duplicate: Record<string, StaffImportTableType> = {}; // дубликаты из импортируемой таблицы
  errorsList: Errors["message"][] = []; // список текущих сообщений при импорте таблицы
  requiredFields: string[] = [];
  colsForDuplicateCheck: Record<string, ColsForDuplicateCheck> = {}; // список колонок, по которым можно осуществить проверку на дубли
  selectedDuplicateCols: string[] = []; // массив колонок, которые выбрали для проверки на дубли
  verificationResponse: VerificationResponse = {}; // ответ с бэка, который содержит данные после проверки на дубли и корректность заполнения полей
  staffStatus: Record<string, StaffStatus> = {}; // поле с указанием статуса для каждой строки таблицы. При изменении данных сотрудника после проверки также всегда меняем статус сотрудника в этом поле
  initialStaffStatus: Record<string, StaffStatus> = {}; // поле с указанием статуса для каждой строки таблицы. Это поле перезаписывается лишь после проверки с бэка. Изменения в таблице это поле не меняют
  dbDuplicateColsResponse: Record<string, string[]> = {}; // дубли с БД
  innerDuplicateColsResponse: Record<string, string[]> = {}; // внутренние дубли
  changedPhoneFields: {
    rowIndex: number;
    phoneField: string;
    oldValue: string;
    newValue: string;
  }[] = []; // массив для хранения измененных телефонных полей
  countAddedStaff = 0; // количество добавленных записей

  // справочник возможных сообщений при импорте, которые выявляются на фронте
  errorsDict: Record<ErrorDictTitle, Errors["message"]> = {
    requiredFields: {
      head: "Заполните обязательные поля и запустите проверку на дубли",
      color: "danger",
      body: {}
    },
    duplicateRow: {
      head: "В загруженном файле Excel есть повторяющиеся записи:",
      color: "warning",
      body: {}
    },
    validationError: {
      head: "Некоторые данные заполнены некорректно",
      color: "danger",
      body: {}
    },
    invalidResponse: {
      head: "Найдены дублирующиеся поля",
      color: "danger",
      body: {}
    }
  };

  loadingFileError: Error["message"] = "";
  tableCols: Record<string, Col> = {};
  tableParams: Record<string, TableParams> = {};
  selects: SimpleSelects = {};
  titles: string[] = [];
  selectedStaffRows: string[] = [];

  rootStore: RootStore;

  /**
   * Получает данные для обработки Excel
   */
  getDataForExcel = async () => {
    this.isLoading = true;
    await Promise.all([
      isEmpty(this.tableCols) && this.getTableCols(),
      isEmpty(this.selects) && this.getSelects()
    ]).then(() => runInAction(() => (this.isLoading = false)));
  };
  /**
   * Получает колонки для таблицы
   */
  getTableCols = async () => {
    try {
      const data: ApiResponse<Record<string, Col>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableCols"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.tableCols = data.result;

          this.tableCols.select = { title: "Выбрать всё", type: "bool" };
          this.tableCols.status = { title: "Статус" };

          this.tableCols.phone_1 = data.result?.phone;
          this.tableCols.phone_2 = data.result?.phone;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };
  /**
   * Получает параметры для таблицы
   * Запрашивает параметры для таблицы и заполняет requiredFields
   */
  getTableParams = async () => {
    try {
      const data: ApiResponse<Record<string, TableParams>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          currentClass: "staff",
          method: "getTableParams"
        });

      runInAction(() => {
        if (data.code === 200) {
          getEntries(data.result).forEach(([key, value]) => {
            if (value.required && this.titles.includes(key)) {
              this.requiredFields.push(key);
            }
          });

          this.tableParams = data.result;
        } else {
          this.error = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };
  /**
   * Получает справочники для таблицы
   */
  getSelects = async () => {
    try {
      const data: ApiResponse<SimpleSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getSelects"
        });
      runInAction(() => {
        if (data.code == 200) {
          getEntries(data.result).forEach(([key, list]) => {
            if (key === "company") {
              this.selects[key] = list;
            } else {
              getValues(list).forEach((value) => {
                !this.selects[key] && (this.selects[key] = {});
                this.selects[key][value["id"] || value["title"]] = {
                  id: value["id"] || value["title"],
                  title: value["title"]
                };
              });
            }
          });
        } else {
          runInAction(() => {
            this.error = true;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  /**
   * Обрабатывает загруженный файл Excel и преобразует его данные в массив объектов.
   */
  handleDrop = (files: Array<File>) => {
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = async (event) => {
          try {
            const buffer = event.target?.result;
            if (!buffer) throw new Error("Файл не удалось прочитать");

            // Создаем экземпляр ExcelJS Workbook
            const workbook = new ExcelJS.Workbook();

            // Загружаем данные из буфера
            await workbook.xlsx.load(buffer as ArrayBuffer);

            // Получаем листы
            const dataSheet = workbook.getWorksheet(1); // Первый лист
            const referenceSheet = workbook.getWorksheet("Справочник"); // Лист справочников

            if (!dataSheet) throw new Error("Не найден первый лист данных");
            if (!referenceSheet) throw new Error("Не найден лист справочников");

            // Считываем справочники
            const referenceMap: Record<string, Record<string, string>> = {};
            referenceSheet.eachRow((row, rowIndex) => {
              if (rowIndex > 1) {
                const field = row.getCell(1).value as string;
                const id = row.getCell(2).value as string;
                const title = row.getCell(3).value as string;

                // Исключаем строки-заголовки
                if (!field.includes("Справочник")) {
                  if (!referenceMap[field]) {
                    referenceMap[field] = {};
                  }
                  referenceMap[field][title] = id;
                }
              }
            });

            // Считываем заголовки из первой строки
            const titles = (dataSheet.getRow(2).values as Array<string>)?.slice(
              1
            );
            if (!titles || titles.length === 0)
              throw new Error("Заголовки в файле не найдены");

            const rows = [];
            dataSheet.eachRow((row, rowIndex) => {
              if (rowIndex > 2) {
                const rowData: Record<string, unknown> = {};

                titles.forEach((header, colIndex) => {
                  const fieldName = header; // Название поля
                  const cellValue = row.getCell(colIndex + 1).value; // Значение ячейки
                  const colConfig = this.tableCols[fieldName]; // Конфигурация поля

                  if (colConfig) {
                    if (colConfig.type === "date") {
                      // Преобразуем дату
                      rowData[fieldName] = isValid(cellValue)
                        ? format(new Date(cellValue as string), "yyyy-MM-dd")
                        : "";
                    } else if (referenceMap[fieldName]) {
                      // Обработка списков
                      const id = referenceMap[fieldName]?.[cellValue as string];
                      if (id !== undefined) {
                        if (id && isNaN(Number(id))) {
                          if (fieldName === "company") {
                            rowData[fieldName] = [id]; // Компании храним в массиве, т.к. в таблице это мультиселект
                          } else rowData[fieldName] = id; // Если ID не число, сохраняем его
                        } else {
                          rowData[fieldName] = cellValue; // Иначе сохраняем значение
                        }
                      }
                    } else {
                      if (fieldName.startsWith("phone")) {
                        rowData[fieldName] = this.getValidPhone(
                          cellValue as string
                        );
                      }
                      // Прочие типы
                      rowData[fieldName] = cellValue;
                    }
                  }
                });

                rows.push(rowData);
              }
            });
            if (!rows.length)
              throw new Error("Файл пуст или содержит некорректные данные");
            resolve({
              rows,
              titles
            });
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    } else {
      return Promise.reject(new Error("Файлы не выбраны"));
    }
  };

  /**
   * Метод, который обновляет флаг, указывающий на то, что идет загрузка файла
   */
  setIsLoading = (
    value: boolean // - флаг, указывающий на то, что идет загрузка файла
  ) => {
    this.isLoading = value;
  };
  /**
   * Метод, который записывает ошибку, возникшую при загрузке файла
   */
  setLoadingFileError = (
    value: string // - текст ошибки
  ) => {
    this.loadingFileError = value;
  };
  /**
   * Обновляет массив dbDuplicateColsResponse, где хранятся поля, которые являются дубликатами в базе данных.
   */
  setDbDuplicateColsResponse = (
    index: number, // - индекс строки, для которой обновляется массив
    value: string[] // - обновленный массив полей, которые являются дубликатами в базе данных
  ) => {
    this.dbDuplicateColsResponse[index] = value;
  };

  /**
   * Обновляет массив innerDuplicateColsResponse, где хранятся поля, которые являются внутренними дубликатами.
   */
  setInnerDuplicateColsResponse = (
    index: number, // - индекс строки, для которой обновляется массив
    value: string[] // - обновленный массив полей, которые являются внутренними дубликатами
  ) => {
    this.innerDuplicateColsResponse[index] = value;
  };

  /**
   * Добавляет изменённое поле телефона в массив измененных полей.
   */
  addChangedPhoneField = (
    rowIndex: number, // - индекс строки, в которой изменилось поле
    phoneField: string, // - имя поля телефона, которое изменилось
    oldValue: string, // - старое значение поля телефона
    newValue: string // - новое значение поля телефона
  ) => {
    this.changedPhoneFields.push({ rowIndex, phoneField, oldValue, newValue });
  };

  /**
   * Очищает массив измененных полей телефонов.
   *
   */
  clearChangedPhoneFields = () => {
    this.changedPhoneFields = [];
  };

  /**
   * Преобразует телефонный номер в формат, пригодный для отображения.
   */
  getValidPhone = (value: Date | number | string | string[]): string => {
    if ((typeof value === "number" || typeof value === "string") && value) {
      return formatPhoneNumber(
        String(value).replace(/\+/g, ""),
        "withoutInvalidPhone"
      ).replace(/\D/g, "");
    } else {
      return "";
    }
  };

  /**
   * Получение списка полей для проверки на дублирование.
   */
  getDuplicateCheckCols = async () => {
    try {
      const data: ApiResponse<Record<string, ColsForDuplicateCheck>> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getImportCheckDoubleCols"
        });

      runInAction(() => {
        if (data["code"] === 200) {
          getValues(data.result).forEach((col) => {
            if (["pasp_n", "inn"].includes(col.name)) return;
            this.colsForDuplicateCheck[col.name] = col;
            if (col.default) {
              this.selectedDuplicateCols.push(col["name"]);
            }
          });
        } else {
          this.colsForDuplicateCheck = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.colsForDuplicateCheck = {};
      });
    }
  };

  /**
   * Подготовка данных для таблицы импорта.
   */
  setDataForTable = (
    rows: Record<string, string>[], // - массив строк из импортируемого файла
    titles: string[] // - массив заголовков столбцов
  ) => {
    if (rows?.length) {
      Promise.all([
        isEmpty(this.colsForDuplicateCheck) && this.getDuplicateCheckCols(),
        this.getCurrentTitles(titles)
      ])
        .then(() => runInAction(() => (this.isLoading = false)))
        .catch(() => runInAction(() => (this.error = true)));

      this.verificationResponse = {};
      this.staffStatus = {};
      this.initialStaffStatus = {};
      this.dbDuplicateColsResponse = {};
      this.innerDuplicateColsResponse = {};
      this.duplicate = {};

      const staffList: StaffImportTableType[] = [];

      rows.forEach((row) => {
        const staff: Partial<StaffImportTableType> = {};
        const snilsWithoutSpaces = String(row.snils).replace(/\D/g, "");

        // поле "проверки" строки на дубли, изначально присваиваем 0
        staff.select = 0;

        // добавляем поля из строки таблицы в объект staff
        titles.map((title) => {
          switch (title) {
            case "snils":
              if (row[title] && snilsWithoutSpaces?.length) {
                // добавляем символ "-" и пробелы в нужных местах согласно валидации
                staff[title] = `${snilsWithoutSpaces.substring(
                  0,
                  3
                )}-${snilsWithoutSpaces.substring(
                  3,
                  6
                )}-${snilsWithoutSpaces.substring(
                  6,
                  9
                )} ${snilsWithoutSpaces.substring(9)}`;
              } else {
                staff[title] = null;
              }

              break;
            case "phone_1":
            case "phone_2":
              staff[title] = this.getValidPhone(row[title]);
              break;

            default:
              if (!title.includes("phone_")) {
                staff[title] = row[title] || null;
              }
          }
        });

        // сотрудника добавляем в общий список
        staffList.push(staff);
      });

      // ищем дублирующиеся строки и если они присутствуют, то заносим их в объект дубликатов (this.duplicate)
      staffList.forEach((_element, ind, array) => {
        let iteration = 0;
        while (iteration <= array.length) {
          if (
            isEqual(array[ind], array[iteration]) &&
            ind !== iteration &&
            !(ind in this.duplicate)
          ) {
            this.duplicate[iteration] = { ...array[iteration], ind };
          }
          iteration++;
        }
      });
      // в this.staffList записываем отфильтрованный (без дубликатов) массив preparatedValue
      this.staffList = staffList.filter(
        (_element, index) => !(index in this.duplicate)
      );

      // если есть дублирующиеся строки, то добавляем их в список сообщений с указанием количества повторов
      if (!isEmpty(this.duplicate)) {
        const error = cloneDeep(this.errorsDict["duplicateRow"]);
        const subError: Record<string, string> = {};

        getValues(this.duplicate).forEach((element) => {
          if (!((element["ind"] as string) in subError)) {
            subError[element["ind"] as string] = `${element["surname"] || ""} ${
              element["name"] || ""
            } ${element["patronymic"] || ""} Удалено дублирующихся записей: ${
              getValues(this.duplicate).filter(
                (item) => item["ind"] === element["ind"]
              ).length
            }`;
          }
        });
        error["body"] = {
          value: { head: "", list: { type: "text", body: subError } }
        };

        this.errorsList.push(error);
      }

      // для каждой строки таблицы говорим, что требуется проверка
      this.staffList.forEach(
        (_row, ind) => (this.staffStatus[ind] = "verificationRequired")
      );
    } else {
      this.staffList = [];
      this.errorsList = [];
      this.duplicate = {};
      this.isLoading = false;
    }
  };

  /**
   * Метод создает список заголовков для таблицы importStaff из списка titles.
   */
  getCurrentTitles = (
    titles: string[] // - список заголовков
  ) => {
    if (!this.titles.length) {
      titles.map((title, index) => {
        if (index === 0) {
          this.titles.push("select");
          this.titles.push("status");
          this.titles.push(title);
        } else this.titles.push(title);
      });

      this.getTableParams();
    }
  };

  /**
   * Метод добавляет (action === "add") или удаляет (action === "delete") сообщение из списка
   * текущих сообщений errorsList. Новое сообщение добавляется в начало списка.
   */
  setErrorsList = (
    value: Errors["message"], // - сообщение, которое нужно добавить или удалить
    action: "add" | "delete" // - действие, которое нужно выполнить
  ) => {
    if (action === "add") {
      // новые сообщения добавляем в начало массива
      this.errorsList = [value].concat(this.errorsList);
    } else {
      this.errorsList = this.errorsList.filter(
        (error) => error?.head !== value?.head
      );
    }
  };

  /**
   * Метод обновляет статус проверки для строки с индексом {@param index} на {@param value}.
   */
  setStaffStatus = (
    value: StaffStatus, // - новый статус
    index: number // - индекс строки
  ) => {
    this.staffStatus[index] = value;
  };

  /**
   * Метод обновляет список выбранных дубликатов {@link selectedDuplicateCols} и
   * сбрасывает статус проверки для всех строк на "verificationRequired"
   */
  setSelectedDuplicateCols = (
    value: string[] // - новый список выбранных дубликатов
  ) => {
    this.selectedDuplicateCols = value;
    this.selectedStaffRows = [];
    for (const key in this.staffStatus) {
      this.staffStatus[key] = "verificationRequired";
      this.staffList[key]["select"] = 0;
    }
  };

  /**
   * Метод обновляет значение поля {@param title} для строки с индексом {@param index} на {@param value}.
   */
  updateStaffField = (
    index: number, // - индекс строки
    title: string, // - название поля
    value: string | null // - новое значение поля
  ) => {
    runInAction(() => {
      if (this.staffList[index]) {
        this.staffList[index][title] = value;
      }
    });
  };

  /**
   * Метод подготавливает данные для отправки на бэк. Если передан параметр isCheck, то
   * отдаем только те записи, у которых поле select равно 0 (не выбраны чекбоксом в интерфейсе таблицы).
   */
  getPreparedData = (
    values: StaffImportTableType[], //  - список записей, которые нужно отправить
    isCheck?: boolean // - флаг, указывающий, что нужно отправить только не выбранные записи
  ) => {
    const formData = cloneDeep(values).map((staff) => {
      delete staff.status;
      const arrayOfPhone = [];
      getKeys(staff).forEach((key) => {
        if (key.includes("phone_")) {
          staff[key] && arrayOfPhone.push(staff[key]);
          delete staff[key];
        }
      });

      arrayOfPhone.length ? (staff.phone = arrayOfPhone) : "";

      return staff;
    });

    const preparedFormData: Record<string, string> = {};
    formData.forEach((staff, index) => {
      if (!isCheck && !staff.select) {
        return;
      }
      // если передан параметр isCheck, то отдаем только те записи, у которых поле select равно 0 (не выбраны чекбоксом в интерфейсе таблицы)
      if (isCheck ? !staff.select : true) {
        getEntries(staff).forEach(([key, value]) => {
          if (key !== "select" && value) {
            if (Array.isArray(value)) {
              value.forEach(
                (element, ind) =>
                  (preparedFormData[`staff[${index}][${key}][${ind}]`] =
                    element)
              );
            } else {
              preparedFormData[`staff[${index}][${key}]`] = String(value);
            }
          }
        });
      }
    });

    const preparedCheckDoubleCols: Record<string, string> = {};
    if (this.selectedDuplicateCols.length) {
      this.selectedDuplicateCols.forEach((col, index) => {
        preparedCheckDoubleCols[`check_double[${index}]`] = col;
      });
    } else {
      preparedCheckDoubleCols["check_double[]"] = "";
    }

    return { ...preparedFormData, ...preparedCheckDoubleCols };
  };

  /**
   * Проверяет дубликаты в импортируемом списке сотрудников.
   */
  checkDuplicateStaff = async (values: StaffImportTableType[]) => {
    this.isLoadingDuplicateCheck = true;
    this.dbDuplicateColsResponse = {};
    this.innerDuplicateColsResponse = {};
    this.errorMessage = {};

    const previousStatus = cloneDeep(this.staffStatus);
    const previousSelects = values.map((s) => s.select);

    values.forEach((row, index) => {
      if (!row["select"]) {
        this.staffStatus[index] = "isLoadingDuplicateCheck";
      }
    });

    try {
      const data: ApiResponse<VerificationResponse> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "importStaff",
          body: {
            add: 0,
            ...this.getPreparedData(values, true)
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.staffList = values.slice();

          getEntries(data["result"]).forEach(([key, value]) => {
            this.verificationResponse[key] = value;

            if (value.approve) {
              this.dbDuplicateColsResponse[key] = [];
              this.innerDuplicateColsResponse[key] = [];
            }

            // Заполняем dbDuplicateColsResponse
            if (!isEmpty(this.verificationResponse[key]["doubles"])) {
              this.dbDuplicateColsResponse[key] = getKeys(
                this.verificationResponse[key]["doubles"]
              ) as string[];
            }

            // Заполняем innerDuplicateColsResponse
            if (!isEmpty(this.verificationResponse[key]["inner_doubles"])) {
              this.innerDuplicateColsResponse[key] = getKeys(
                this.verificationResponse[key]["inner_doubles"]
              );
            }

            // Расставляем статусы для каждой строки согласно полю approve
            if (value["approve"]) {
              this.staffList[key]["select"] = 1;
              this.staffStatus[key] = "correct";
              this.initialStaffStatus[key] = "correct";
              this.staffList.forEach((_staff, ind) => {
                !this.selectedStaffRows.includes(`staff.${ind}.select`) &&
                  this.selectedStaffRows.push(`staff.${ind}.select`);
              });
            } else {
              // Если у строки approve: false, то это либо неправильно заполненное поле, либо ошибка дубля
              if (getValues(value["incorrect_cols"])) {
                this.staffStatus[key] = "incorrectField";
                this.initialStaffStatus[key] = "incorrectField";
              }
              if (
                !isEmpty(value["doubles"]) ||
                !isEmpty(value["inner_doubles"])
              ) {
                this.staffStatus[key] = "incorrectDouble";
                this.initialStaffStatus[key] = "incorrectDouble";
              }
            }
          });

          // Восстанавливает `correct` для строк, которые не отправлялись
          values.forEach((row, index) => {
            if (!data.result[index] && previousStatus[index] === "correct") {
              this.staffStatus[index] = "correct";
              row.select = previousSelects[index];
            }
          });

          // Дополнительная проверка внутренних дублей
          this.staffList.forEach((row, index) => {
            // Извлекаем все поля с телефонами и валидируем номера
            const phoneEntries = this.titles
              .filter((title) => title.startsWith("phone"))
              .map((title) => ({
                title,
                phone: this.getValidPhone(row[title])
              }))
              .filter(
                (entry) =>
                  entry.phone !== undefined &&
                  entry.phone !== null &&
                  entry.phone !== ""
              );

            // Подсчитываем количество каждого номера телефона
            const phoneCounts: Record<string, number> = {};
            phoneEntries.forEach(({ phone }) => {
              phoneCounts[phone] = (phoneCounts[phone] || 0) + 1;
            });

            // Находим заголовки с дублирующимися номерами
            const newDuplicates = phoneEntries
              .filter(({ phone }) => phoneCounts[phone] > 1)
              .map(({ title }) => title);

            // Обновляем `inner_doubles` для текущей строки
            newDuplicates.forEach((dup) => {
              if (!this.verificationResponse[index].inner_doubles?.[dup]) {
                !this.verificationResponse[index].inner_doubles &&
                  (this.verificationResponse[index].inner_doubles = {});
                const phone = phoneEntries.find(
                  (entry) => entry.title === dup
                )?.phone;
                if (phone) {
                  this.verificationResponse[index].inner_doubles[dup] = phone;
                }
              }
            });

            // Обновляем `innerDuplicateColsResponse` для текущей строки
            if (!this.innerDuplicateColsResponse[index]) {
              this.innerDuplicateColsResponse[index] = [];
            }

            const existingDuplicates = new Set(
              this.innerDuplicateColsResponse[index]
            );
            newDuplicates.forEach((dup) => existingDuplicates.add(dup));
            this.innerDuplicateColsResponse[index] =
              Array.from(existingDuplicates);

            if (this.innerDuplicateColsResponse[index]?.length) {
              this.staffStatus[index] = "incorrectDouble";
              this.initialStaffStatus[index] = "incorrectDouble";
            }
          });
        } else {
          this.errorMessage = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    } finally {
      setTimeout(() => {
        runInAction(() => (this.isLoadingDuplicateCheck = false));
      }, 3000);
    }
  };

  /**
   * Добавляет новых сотрудников.
   */
  addNewStaff = async (
    values: StaffImportTableType[] // - Массив объектов с данными сотрудников для импорта.
  ) => {
    try {
      const data: ApiResponse<VerificationResponse> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "importStaff",
          body: {
            add: 1,
            ...this.getPreparedData(values)
          }
        });

      runInAction(() => {
        if (data.code === 200) {
          // индексы недобавленных сотрудников
          const notAdded: number[] = [];
          // количество добавленных сотрудников
          this.countAddedStaff = getEntries(data.result).filter(
            ([key, element]) => {
              // если сотрудник не добавлен
              if (!element.approve) {
                // сохраняем индекс строки этого сотрудника
                notAdded.push(+key);
              }
              // возвращаем только добавленных сотрудников
              return element.approve;
            }
          ).length;

          // проверяем дубликаты оставшихся сотрудников, включая сотрудников, которые не были добавлены
          if (notAdded.length || values.filter((item) => !item.select).length) {
            this.checkDuplicateStaff(
              values.filter((item, index) => {
                if (item.select && notAdded.includes(index)) {
                  item.select = 0;
                  return true;
                } else return !item.select;
              })
            );
          } else {
            this.staffList = [].slice();
          }
        } else {
          this.errorMessage = data.message;
        }
      });
    } catch (error) {
      runInAction(() => (this.error = true));
    } finally {
      runInAction(() => (this.isLoadingDuplicateCheck = false));
    }
  };

  /**
   * Сбрасывает количество добавленных сотрудников.
   */
  resetCountAddedStaff = () => {
    this.countAddedStaff = 0;
  };

  /**
   * Генерирует и скачивает Excel файл с данными импорта сотрудников.
   * Лист 1 содержит данные сотрудников с выпадающими списками на основе справочников.
   * Лист 2 содержит справочники для выпадающих списков.
   */
  handleDownLoad = async () => {
    const titlesForExcel = [
      "surname",
      "name",
      "patronymic",
      "birthday",
      "grazd",
      "company",
      "education",
      "snils",
      "size",
      "height",
      "shoe_size",
      "training_date_start",
      "training_date_finish",
      "place_of_study",
      "phone_1",
      "phone_2"
    ];

    const workbook = new ExcelJS.Workbook();

    // Лист 1: Импорт сотрудников
    const sheet1 = workbook.addWorksheet("Импорт сотрудников");

    // Заголовки столбцов
    // Генерация первой строки (наименования для пользователей)
    const userHeaders = titlesForExcel.map((col) => {
      if (col.includes("phone")) {
        return `${this.tableCols["phone"]?.title || col} ${col.split("_")[1]}`;
      }

      return this.tableCols[col]?.title || col;
    });
    sheet1.addRow(userHeaders);

    // Вторая строка (названия для разработчиков)
    sheet1.addRow(titlesForExcel);
    // Скрыть строку разработчиков
    sheet1.getRow(2).hidden = true;

    // Установка ширины столбцов
    const rows = sheet1.getRows(1, sheet1.rowCount) || [];
    sheet1.columns.forEach((column, colIndex) => {
      // Получаем максимальную длину данных в текущем столбце
      let maxWidth = 10; // Минимальная ширина
      rows.forEach((row) => {
        const value = row.getCell(colIndex + 1).value || ""; // Получаем значение ячейки
        const length = value.toString().length; // Длина значения
        if (length > maxWidth) {
          maxWidth = length;
        }
      });
      column.width = maxWidth + 2; // Увеличиваем немного для отступов
    });

    // Лист 2: Справочник
    const sheet2 = workbook.addWorksheet("Справочник");
    sheet2.addRow(["Поле", "ID", "Название"]); // Заголовки справочников

    // Добавление справочников на лист 2
    let currentRow = 2; // Начало справочников со второй строки
    getEntries(this.selects).forEach(([columnName, dictionary]) => {
      if (titlesForExcel.includes(columnName)) {
        // Указываем, какой столбец относится к справочнику
        sheet2.mergeCells(`A${currentRow}:C${currentRow}`);
        sheet2.getCell(
          `A${currentRow}`
        ).value = `Справочник для "${columnName}"`;
        sheet2.getCell(`A${currentRow}`).font = { bold: true };

        currentRow++;

        // Добавляем элементы справочника
        getValues(dictionary).forEach((item, index) => {
          const id =
            typeof item === "object"
              ? (item as { id: string; title: string }).id || index
              : index; // Определяем ID
          const title = typeof item === "object" ? item.title : item; // Определяем Название
          sheet2.addRow([columnName, id, title]);
          currentRow++;
        });

        currentRow++; // Пустая строка между справочниками
      }
    });

    // Установка выпадающих списков на листе 1
    titlesForExcel.forEach((col, index) => {
      // Правило ниже нужно для того, чтобы сработали настройки списков
      // Это правило для столбца "День рождения" всё равно не сработает
      index === 3 &&
        sheet1
          .getColumn(index + 1)
          .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 2) {
              // Устанавливаем минимальную и максимальную даты
              const minDate = "1900-01-01";
              const maxDate = "2999-12-31";
              cell.dataValidation = {
                type: "date",
                allowBlank: true,
                formulae: [minDate, maxDate]
              };
            }
          });
      const maxRows = 500;
      const lastCell = sheet1.getCell(`D:${maxRows}`);
      lastCell.dataValidation = {
        type: "date",
        allowBlank: true,
        formulae: [""]
      };
      if (this.selects[col]) {
        // Ссылки на данные справочника на листе 2
        const startRow = sheet2
          .getColumn(1)
          .values.findIndex((val) => val === col);
        const endRow = startRow + getValues(this.selects[col]).length - 1;

        // Формирование диапазона для формулы
        const formulaRange = `Справочник!$C$${startRow}:$C$${endRow}`;

        // Функция получения буквенного значения столбца
        const columnNumberToLetter = (columnNumber: number) => {
          let letter = "";
          while (columnNumber > 0) {
            const remainder = (columnNumber - 1) % 26;
            letter = String.fromCharCode(65 + remainder) + letter; // 65 — это ASCII-код для "A"
            columnNumber = Math.floor((columnNumber - 1) / 26);
          }
          return letter;
        };
        const columnLetter = columnNumberToLetter(index + 1); // Преобразование индекса в букву столбца

        // Очищаем содержимое ячеек, чтобы избежать конфликтов с правилом
        sheet1
          .getColumn(columnLetter)
          .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 2) {
              cell.value = null; // Очистка содержимого ячейки
            }
          });

        // Создаем диапазон для всего столбца
        sheet1
          .getColumn(columnLetter)
          .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            if (rowNumber > 2) {
              cell.dataValidation = {
                type: "list",
                allowBlank: true,
                formulae: [formulaRange] // Пользователь видит названия
              };
            }
          });

        // Добавляем правило для будущих ячеек
        // Создаем ячейку в конце столбца с правилом проверки данных
        const maxRows = 500; // Максимальное количество строк в Excel
        const lastCell = sheet1.getCell(`${columnLetter}:${maxRows}`);
        lastCell.dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [formulaRange]
        };
      }
    });

    // Защита второго листа от редактирования
    // Устанавливаем пароль
    sheet2.protect("developer", {
      selectLockedCells: false,
      selectUnlockedCells: false,
      formatCells: false,
      formatColumns: false,
      formatRows: false,
      insertColumns: false,
      insertRows: false,
      insertHyperlinks: false,
      deleteColumns: false,
      deleteRows: false,
      sort: false,
      autoFilter: false,
      pivotTables: false
    });

    // Сохранение файла
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "Импорт_людей_в_ПО.xlsx");
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
