import styles from "./filterInfoBlock.module.scss";
import { observer } from "mobx-react-lite";
import { FilterType } from "stores/utils/types/FilterType";
import { Col } from "stores/utils/types/Col";
import { useStores } from "stores";
import { cloneDeep } from "lodash";
import { getValues } from "shared/utils/helpers/getValues";
import { getKeys } from "shared/utils/helpers/getKeys";

export type FilterParams = {
  [key: string]: string | number | string[];
};

type FilterProps = {
  filters: {
    [key: string]: FilterType;
  };
  downloadedFilter: string;
  item: string;

  searchValue: string;
  foundCounter?: number;
  setSearchValue: (value: string) => void;
  getDataWithFilter?: (filter: FilterType) => void;
  filterParams?: FilterParams;
  getData?: (filters?: string) => Promise<void>;
  dateRangeFields?: Record<string, string[]>;
  setSelectedFilter?: (value: string) => void;
  setDownloadedFilter?: (value: string) => void;
  currentCols: string[];
  allCols: { [key: string]: Col };
  cols: { [key: string]: Col };
  defaultFields: Record<string, string | string[] | string[][]>;
  params: { [key: string]: Col };
  customConditionDisplayForInfoBlock?: (
    filter: string,
    value: string | number | string[]
  ) => string;
  resetForm?: () => void;
};

const FilterInfoBlock = ({
  filters,
  downloadedFilter,
  item,
  searchValue,
  setSearchValue,
  getDataWithFilter,
  foundCounter,
  filterParams,
  dateRangeFields,
  getData,
  setSelectedFilter,
  setDownloadedFilter,
  currentCols,
  allCols,
  cols,
  defaultFields,
  params,
  customConditionDisplayForInfoBlock,
  resetForm
}: FilterProps) => {
  const { filterStore, menuStore } = useStores();

  // функция возврата списка в начальное положение при загрузке данных
  const setListToInitialPosition = () => {
    menuStore.scroll?.top < 0.01
      ? menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          top: 1,
          behavior: "smooth"
        })
      : menuStore.scrollbarRef.current?.view?.scroll({
          left: 1,
          behavior: "smooth"
        });
  };

  // функция для получения названия параметров для их вывода при применении сохраненных фильтров

  const getParamsName = (values: FilterParams) => {
    if (getKeys(values).length) {
      const keys = getKeys(values).filter(
        (key): key is string => typeof key === "string"
      );
      return keys.filter((key) => key.includes("filter[")).length
        ? keys
            .filter((key) => key.includes("filter["))
            .map((key) => {
              const paramsName = key.split("[")[1].slice(0, -1);
              if (getValues(dateRangeFields).flat().includes(paramsName)) {
                const paramsForInfoBlock = cloneDeep(params);
                getKeys(dateRangeFields).forEach((key) => {
                  if (key in paramsForInfoBlock) {
                    paramsForInfoBlock[dateRangeFields[key][0]] =
                      paramsForInfoBlock[key];
                    delete paramsForInfoBlock[key];
                  }
                });
                return paramsForInfoBlock[paramsName]?.["title"];
              } else return params[paramsName]?.["title"];
            })
            .filter(Boolean)
            .join(", ")
        : getKeys(values)
            .filter((key) => key in params)
            .map((key) => params[key]?.["title"]);
    }
  };

  /**
   * Сбрасывает все настройки фильтрации до дефолтных значений:
   * - Очищает быстрый поиск
   * - Сбрасывает все поля формы фильтра
   * - Удаляет сохраненные значения из localStorage
   * - Применяет дефолтный фильтр, если он есть
   * - Если дефолтного фильтра нет - создает и применяет новый пустой
   *
   * @example
   * <p onClick={handleResetFilter}>Сбросить фильтр</p>
   */
  const handleResetFilter = () => {
    // Сбрасываем поиск
    if (searchValue) {
      setSearchValue("");
      filterStore.setSearchInputValue("");
    }

    // Сбрасываем состояние изменения фильтра
    filterStore.setIsDiff(false);

    // Создаем новый пустой фильтр с дефолтными значениями
    const newFilter = filterStore.creatingNewFilter(
      currentCols,
      filterStore.getDefaultArray(allCols, cols),
      defaultFields
    );

    // Очищаем сохраненные значения фильтра из localStorage
    localStorage.removeItem(`{filter-form-${location.pathname}}`);

    // Сбрасываем позицию списка
    setListToInitialPosition();

    // Сбрасываем значения в Formik
    resetForm?.();

    // Применяем дефолтный фильтр
    if (filters) {
      getValues(filters).forEach((filter) => {
        if (filter["default"]) {
          getDataWithFilter(filter);
          setSelectedFilter && setSelectedFilter(filter.id);
          setDownloadedFilter && setDownloadedFilter(filter.id);
        }
      });
    } else {
      getDataWithFilter ? getDataWithFilter(newFilter) : getData();
    }

    // Обновляем текущий фильтр
    filterStore.setCurrentFilter(newFilter);

    // Закрываем расширенный поиск
    filterStore.setIsOpenAdvancedSearch(false);
  };

  /**
   * Определяет, нужно ли отображать блок с информацией о фильтрации
   *
   * Возвращает true в следующих случаях:
   * - Если задана кастомная функция проверки условия отображения (customConditionDisplayForInfoBlock)
   * - Если выбран не дефолтный фильтр из сохраненных фильтров
   * - Если задано значение быстрого поиска
   * - Если нет сохраненных фильтров, но есть параметры фильтрации
   */
  const shouldDisplayFilter = customConditionDisplayForInfoBlock
    ? customConditionDisplayForInfoBlock("filter", "value")
    : (filters && !filters[downloadedFilter]?.["default"]) ||
      searchValue ||
      (!filters && getKeys(filterParams).length);

  return shouldDisplayFilter ? (
    <div className={styles.filterInfoBlock}>
      <p className={styles.filterInfoElement}>
        Найдено {item}: <span>{foundCounter}</span>
      </p>
      {(filters && !filters[downloadedFilter]?.["default"]) ||
      (!filters && getKeys(filterParams).length) ? (
        <p className={styles.filterInfoElement}>
          Параметры:{" "}
          <span>
            {getParamsName(filterParams)?.length
              ? getParamsName(filterParams)
              : "-"}
          </span>
        </p>
      ) : null}

      {searchValue ? (
        <p className={styles.filterInfoElement}>
          Быстрый поиск: <span>{searchValue}</span>
        </p>
      ) : null}

      {!filters?.[downloadedFilter]?.["default"] || searchValue ? (
        <p
          className={styles.resetFilter}
          onClick={handleResetFilter}
          id="FilterInfoBlock_resetFilter"
        >
          {!filters?.[downloadedFilter]?.["default"]
            ? "Сбросить фильтр"
            : "Сбросить поиск"}
        </p>
      ) : null}
    </div>
  ) : null;
};

export default observer(FilterInfoBlock);
