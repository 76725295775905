import styles from "./staffOneStatusesGraphVacSubStatus.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";
import { Status } from "stores/StaffModule/types/Status";

import { classNames } from "shared/utils/helpers/classNames";

import StaffOneStatusesGraphOneStatus from "../StaffOneStatusesGraphOneStatus";
import StatusIcon from "shared/ui/StatusIcon";
import { getUnits } from "shared/utils/helpers/getUnits";
import { calculateDays } from "shared/utils/helpers/calculateDays";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";

type StaffOneStatusesGraphVacSubStatusProps = {
  status: Status;
  isSubDate: boolean;
};

const StaffOneStatusesGraphVacSubStatus = ({
  status,
  isSubDate
}: StaffOneStatusesGraphVacSubStatusProps) => {
  const { staffOneStatusesStore } = useStores();

  return (
    <>
      <div
        className={classNames(styles.statusOneBlock, {}, [
          styles[
            `statusOneBlock_${
              staffOneStatusesStore.statusesParams[status.type].custom.color_api
            }`
          ]
        ])}
        id={`StaffOneStatusesGraphVacSubStatus_${status["id"]}`}
        onClick={staffOneStatusesStore.handleSetSelectedStatus(status)}
      >
        <div className={styles.mainInfo}>
          <div className={styles.statusBlock}>
            <StatusIcon
              icon={
                staffOneStatusesStore.statusesParams[status.type].custom.icon
              }
              color={
                staffOneStatusesStore.statusesParams[status.type].custom
                  .color_api
              }
            />
            <p className={styles.statusTitle}>
              {status["type_title"]}
              <span
                className={classNames(styles.vacationMark, {}, [
                  styles[
                    `vacationMark_${
                      staffOneStatusesStore.statusesParams[status.type].custom
                        .color_api
                    }`
                  ]
                ])}
              >
                Ое
              </span>
            </p>
            <p className={styles.dateValue}>
              {getFormattedDate(status["sub_start_date"])}
              {` - ${getFormattedDate(status["sub_end_date"])}`}
            </p>
          </div>

          <div>
            {calculateDays(status["event_start"], new Date()) > 0 ? (
              <p className={styles.inStatus}>
                Дней в статусе:{" "}
                <span className={styles.inStatusDate}>
                  {status.days_in_status}
                </span>
              </p>
            ) : (
              <p className={styles.inStatus}>
                Статус начнется через{" "}
                <span className={styles.inStatusDate}>
                  {status.days_in_status}
                </span>{" "}
                {getUnits(status.days_in_status, "день", "дня", "дней")}
              </p>
            )}
          </div>
        </div>
      </div>
      <StaffOneStatusesGraphOneStatus status={status} isSubDate={isSubDate} />
    </>
  );
};
export default observer(StaffOneStatusesGraphVacSubStatus);
