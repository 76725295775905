import styles from "./salaryOperationsOneEdit.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";

import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";
import Modal from "shared/ui/Modal";
import SalaryOperationsForm from "features/SalaryOperationsForm";

import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "./validation";
import { getEntries } from "shared/utils/helpers/getEntries";
import Alert from "shared/ui/Alert";

const SalaryOperationsOneEdit = () => {
  const { id } = useParams();
  const { menuStore, salaryOperationOneStore, salaryOperationsFormStore } =
    useStores();
  const { errorsMessage } = salaryOperationsFormStore;
  const [openedListName, setOpenedListName] = useState("");
  const [openedModal, setOpenedModal] = useState("");
  const [initialValues, setInitialValues] = useState(
    salaryOperationsFormStore.initialValues
  );
  const [requiredField, setRequiredField] = useState<string[]>([]);

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  useEffect(() => {
    salaryOperationOneStore.setSelectedOneOfSalaryOperation(id);

    menuStore.updateWindow({
      mainPath: `/salary/id=${id}`,
      path: `/salary/id=${id}/edit`
    });

    menuStore.sendTabId(id);
    menuStore.setOpenedModule("salaryOne");
    menuStore.setOpenedSubmodule("salaryOneEdit");
    salaryOperationsFormStore.getDataForForm();
  }, [id]);

  useEffect(() => {
    getEntries(menuStore.allWindows).forEach(([key, value]) => {
      if (value["parent_path"] && value["parent_path"] !== `/salary`) {
        menuStore.deleteWindow(key);
      }
    });

    setInitialValues(
      salaryOperationsFormStore.getInitialValuesForEdit(
        salaryOperationOneStore.selectedOneSalaryOperation
      )
    );

    initialValues
      ? setRequiredField(
          salaryOperationsFormStore.getRequiredFields(initialValues)
        )
      : "";
  }, [salaryOperationOneStore.selectedOneSalaryOperation]);

  return (
    <div className={styles.form}>
      {initialValues ? (
        <Formik
          key={id}
          initialValues={initialValues}
          validationSchema={getValidationSchema(
            Object.keys(initialValues),
            requiredField
          )}
          onSubmit={() => {
            return;
          }}
          enableReinitialize
        >
          {({ values, isValid, dirty, handleSubmit, handleReset }) => {
            return (
              <>
                <div className={styles.form__fields}>
                  <SalaryOperationsForm
                    values={values}
                    openedListName={openedListName}
                    changeOpenedListName={changeOpenedListName}
                    setOpenedModal={setOpenedModal}
                    operation={
                      salaryOperationOneStore.selectedOneSalaryOperation
                    }
                    isEdit
                  />
                </div>

                {errorsMessage ? (
                  <div className={styles.errors}>
                    <Alert errors={errorsMessage} />
                  </div>
                ) : null}

                <ButtonsGroupForSettings
                  saveBtnTitle="Сохранить изменения"
                  saveBtnDisabled={!isValid || !dirty}
                  saveBtnOnClick={handleSubmit}
                  addBtnTitle="Отменить изменения"
                  addBtnOnClick={handleReset}
                  addBtnDisabled={!dirty}
                  addBtnImg={<IconClose />}
                  cancelBtnTitle="Удалить операцию"
                  cancelBtnOnClick={() => {
                    setOpenedModal("delete_operation");
                    handleReset();
                  }}
                  cancelBtnImg={<IconBasket />}
                />
                {openedModal.length > 0 ? (
                  <Modal
                    type="clarification"
                    show={openedModal.length > 0}
                    onHide={() => {
                      setOpenedModal("");
                    }}
                    title={
                      openedModal === "paymentlist"
                        ? "Вы уверены, что хотите открепить операцию от Ведомости?"
                        : openedModal === "cancel_operation"
                        ? "Вы уверены, что хотите отменить операцию?"
                        : openedModal === "delete_operation"
                        ? "Вы уверены, что хотите удалить операцию?"
                        : ""
                    }
                    btnWithCrossTitle={
                      openedModal === "paymentlist"
                        ? "Открепить"
                        : openedModal === "cancel_operation"
                        ? "Да"
                        : openedModal === "delete_operation"
                        ? "Удалить"
                        : ""
                    }
                    btnWithCrossOnClick={() => {
                      setOpenedModal("");
                      openedModal === "cancel_operation" &&
                        salaryOperationOneStore.cancelOperation(id);
                      openedModal === "delete_operation" &&
                        salaryOperationOneStore.deleteOperation(id);
                    }}
                    blueBtnOnClick={() => {
                      setOpenedModal("");
                    }}
                    blueBtnTitle={
                      openedModal === "cancel_operation" ? "Нет" : "Отмена"
                    }
                    btnWithCrossImg={openedModal !== "cancel_operation"}
                  />
                ) : null}
              </>
            );
          }}
        </Formik>
      ) : null}
    </div>
  );
};

export default observer(SalaryOperationsOneEdit);
