import styles from "./buildingOneWorkshiftHeaderWorkshift.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import BuildingOneWorkshiftHeaderWorkshiftStatus from "./BuildingOneWorkshiftHeaderWorkshiftStatus";
import StatusIcon from "shared/ui/StatusIcon";
import ReactToPrint from "react-to-print";
import BuildingOneWorkshiftHeaderWorkshiftMessage from "./BuildingOneWorkshiftHeaderWorkshiftMessage";
import { classNames } from "shared/utils/helpers/classNames";
import { Button, ButtonTheme } from "shared/ui/Button";

const BuildingOneWorkshiftHeaderWorkshift = () => {
  const navigate = useNavigate();
  const { menuStore, buildingOneWorkshiftStore } = useStores();
  const [isScroll, setIsScroll] = useState(false);

  const { id } = useParams();

  const componentRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  // отслеживание скролла для закрепления хэдера
  useEffect(() => {
    if (menuStore.scroll?.scrollTop >= 30 && !isScroll) {
      setTimeout(() => {
        setIsScroll(true);
      }, 100);
    } else if (menuStore.scroll?.scrollTop < 28 && isScroll) {
      setTimeout(() => {
        setIsScroll(false);
      }, 100);
    }
  }, [menuStore.scroll?.scrollTop]);

  // отслеживание скролла для сохранения высоты хэдера
  useEffect(() => {
    buildingOneWorkshiftStore.setHeight(
      id,
      "header",
      headerRef?.current?.getBoundingClientRect()?.height || 60
    );
  }, [isScroll, window.innerWidth]);

  const getWorkshiftBtn = () => {
    return Object.values(buildingOneWorkshiftStore.selects.status).find(
      (type) =>
        buildingOneWorkshiftStore.selects.status?.[
          buildingOneWorkshiftStore.selectedOne.status
        ]?.custom.done
          ? type.custom.done !== "on"
          : type.custom.done === "on"
    );
  };

  // функция проверки блокировки кнопки изменения статуса смены
  const checkDisabledButton = () => {
    // если активный режим - редактирование, блокируем кнопку
    if (buildingOneWorkshiftStore.openedMode[id] === "edit") {
      return true;
    }
    // если режим - просмотр
    else if (
      // статус смены - Открыта
      getWorkshiftBtn()?.custom.done &&
      // и есть хотя бы 1 ошибка с бэка или фронта
      (!buildingOneWorkshiftStore.closeWorkshiftMsg[id]?.result ||
        buildingOneWorkshiftStore.closeWorkshiftStatusMsg[id]?.result === false)
    ) {
      // блокируем кнопку
      return true;
    }
    // иначе - разрешаем изменить статус
    else return false;
  };
  // функция редактирования статуса смены
  const handleClickOnStatusBtn = () =>
    buildingOneWorkshiftStore.editStatus(
      id,
      getWorkshiftBtn()?.id,
      buildingOneWorkshiftStore.selectedOne.comment
    );
  const handleClickOnBuildingLink = () =>
    navigate(`/building/id=${buildingOneWorkshiftStore.selectedOne.building}`);
  const handleClickOnEditBtn = () =>
    buildingOneWorkshiftStore.setOpenedMode(id, "edit");
  const handleClickOnPrintBtn = () =>
    buildingOneWorkshiftStore.setOpenedMode(id, "show");
  const getContentForReactToPrint = () => componentRef.current;
  const handleTriggerReactToPrint = () => (
    <StatusIcon
      icon="iconprinter"
      color="bw-gray6"
      onClick={{
        handleClick: handleClickOnPrintBtn,
        id: "BuildingOneWorkshiftStaffHeader_editToPrint_btn"
      }}
    />
  );

  return (
    <div
      className={classNames(
        styles.workshift,
        {
          [styles.workshift_sticky]: isScroll,
          [styles.workshift_normal]: !isScroll
        },
        [
          styles[
            `workshift_${
              buildingOneWorkshiftStore.selects.status[
                buildingOneWorkshiftStore.selectedOne.status
              ]?.custom?.color || ""
            }`
          ]
        ]
      )}
      ref={headerRef}
    >
      <div className={styles.workshift__flex}>
        <div
          className={styles.workshift__title}
        >{`Смена ${buildingOneWorkshiftStore.selectedOne.date_report}`}</div>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BuildingOneWorkshiftHeaderWorkshiftStatus />
        </ErrorBoundary>
        <Button
          theme={
            !buildingOneWorkshiftStore.selects.status?.[
              buildingOneWorkshiftStore.selectedOne.status
            ]?.custom.done
              ? ButtonTheme.CANCELLED
              : ButtonTheme.SECONDARY
          }
          id="BuildingOneWorkshiftHeaderWorkshift_statusBtn"
          onClick={handleClickOnStatusBtn}
          disabled={checkDisabledButton()}
        >
          {getWorkshiftBtn()?.custom?.button || ""}
        </Button>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BuildingOneWorkshiftHeaderWorkshiftMessage />
        </ErrorBoundary>
      </div>
      <div className={styles.workshift__flex}>
        {["prev", "next", "building"].map((name) => {
          switch (name) {
            case "prev":
            case "next":
              return (
                <div key={name} className="d-flex gap-1 align-items-center">
                  <span>{`${
                    name === "prev" ? "Предыдущая" : "Следующая"
                  } смена: `}</span>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <BuildingOneWorkshiftHeaderWorkshiftStatus title={name} />
                  </ErrorBoundary>
                </div>
              );
            case "building":
              return (
                <div key={name} className="d-flex gap-2 align-items-center">
                  <span>Объект: </span>
                  <a
                    className={styles.link}
                    id={`BuildingOneWorkshiftHeaderWorkshift_navigate_${buildingOneWorkshiftStore.selectedOne.building}`}
                    onClick={handleClickOnBuildingLink}
                  >
                    {buildingOneWorkshiftStore.buildingTitle}
                  </a>
                </div>
              );
          }
        })}
      </div>
      <div className={styles.workshift__absoluteButtons}>
        <StatusIcon
          icon="iconedit"
          color={
            buildingOneWorkshiftStore.openedMode[id] === "edit"
              ? "blue-lazure"
              : "bw-gray6"
          }
          onClick={{
            handleClick: handleClickOnEditBtn,
            id: "BuildingOneWorkshiftStaffHeader_changeForm_btn"
          }}
        />
        <ReactToPrint
          trigger={handleTriggerReactToPrint}
          content={getContentForReactToPrint}
        />
      </div>
    </div>
  );
};

export default observer(BuildingOneWorkshiftHeaderWorkshift);
