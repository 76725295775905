import { Formik } from "formik";
import FormWindow from "shared/ui/FormWindow";
import styles from "./filterMainWindow.module.scss";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { Col } from "stores/utils/types/Col";
import { Input } from "shared/ui/Inputs/Input";
import { FilterType } from "stores/utils/types/FilterType";
import { validation } from "./validation";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Option } from "shared/ui/Option";
import { getEntries } from "shared/utils/helpers/getEntries";
import { getValues } from "shared/utils/helpers/getValues";

type FilterMainWindowProps = {
  setIsMenuOpened: () => void;
  title: string;
  subtitle?: string;
  valuesSaveFilter?: FilterType;
  directory?: Col["directory"] | { [key: string]: Col };
  setNewFilter?: (arg: FilterType) => void;
  selectMulti?: string[];
  dictForArray?: Record<string, string>;
  dateRangeFields?: Record<string, string[]>;
};

// Функция для безопасного преобразования значения в массив строк
const safeFilter = (value: unknown): string[] => {
  if (Array.isArray(value)) {
    return value.filter(Boolean);
  }
  if (typeof value === "string" && value) {
    return value
      .split(",")
      .map((s) => s.trim())
      .filter(Boolean);
  }
  return [];
};

const FilterMainWindow = ({
  setIsMenuOpened,
  title,
  subtitle,
  valuesSaveFilter,
  directory,
  setNewFilter,
  selectMulti,
  dictForArray,
  dateRangeFields
}: FilterMainWindowProps) => {
  return (
    <Formik
      initialValues={valuesSaveFilter}
      enableReinitialize
      onSubmit={() => {
        return;
      }}
      validationSchema={validation}
    >
      {({
        dirty,
        values,
        isValid,
        handleBlur,
        handleChange,
        setFieldValue
      }) => {
        // Проверяем, что values.filter существуют и являются объектами
        const hasFilter = values?.filter && typeof values.filter === "object";

        return (
          <div className={styles.formWindowBlock}>
            <FormWindow
              title={title}
              subtitle={subtitle}
              setOpenWindow={() => setIsMenuOpened()}
              saveBtnTitle="Сохранить"
              saveBtnOnClickFunc={() => {
                setNewFilter(values);
                setIsMenuOpened();
              }}
              saveBtnDisabledValue={!dirty || !isValid}
              addBtnTitle="Отмена"
              addBtnImg={<IconClose />}
              buttonsOrder={["add", "save"]}
              isScroll
              fromOverlayWindow
            >
              <div className={styles.container}>
                {values ? (
                  <>
                    <div className={styles.titleBlock}>
                      <p>
                        Придумайте короткое название для фильтра (не более 100
                        символов).
                      </p>
                      <Input
                        name="title"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        label="Название"
                      />
                    </div>

                    <p className={styles.optionsListBlockTitle}>Параметры:</p>
                    <div className={styles.paramsBlock}>
                      <>
                        {hasFilter && getEntries(values.filter).map(([key, value]) => {
                          const hasValue =
                            typeof value === "string" || Array.isArray(value)
                              ? value.length > 0
                              : typeof value === "object" &&
                                getValues(value).length > 0;

                          const isNotDateRangeField = dateRangeFields
                            ? !getValues(dateRangeFields).flat().includes(key)
                            : true;

                          if (hasValue && isNotDateRangeField) {
                            return (
                              <div key={key}>
                                <p
                                  className={`${styles.helpingInfoText} ${styles.titleParam}`}
                                >
                                  {directory[key]?.["title"]}
                                </p>
                                {(() => {
                                  const field = directory[key];
                                  if (field?.directory) {
                                    if (selectMulti.includes(key)) {
                                      // Безопасно преобразуем value в массив строк
                                      const safeValues = safeFilter(value);

                                      return (
                                        <div className={styles.cols}>
                                          {safeValues.map((name) => (
                                            <Option
                                              key={name}
                                              title={
                                                dictForArray[name] ||
                                                field["directory"][name]?.[
                                                  "title"
                                                ] ||
                                                name
                                              }
                                              onClick={() => {
                                                const arrayFilter =
                                                  safeValues.filter(
                                                    (item) => item !== name
                                                  );
                                                setFieldValue(
                                                  `filter[${key}]`,
                                                  arrayFilter
                                                );
                                              }}
                                            />
                                          ))}
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <Option
                                          title={
                                            field["directory"][value]?.["title"]
                                          }
                                          onClick={() => {
                                            setFieldValue(
                                              `filter[${key}]`,
                                              null
                                            );
                                          }}
                                        />
                                      );
                                    }
                                  }
                                  if (field?.type === "bool") {
                                    return (
                                      <Option
                                        title={value === "1" ? "Да" : "Нет"}
                                        onClick={() => {
                                          setFieldValue(`filter[${key}]`, null);
                                        }}
                                      />
                                    );
                                  }

                                  return (
                                    <Option
                                      title={value as string}
                                      onClick={() => {
                                        setFieldValue(`filter[${key}]`, null);
                                      }}
                                    />
                                  );
                                })()}
                              </div>
                            );
                          }
                        })}
                        {dateRangeFields &&
                          hasFilter &&
                          Object.entries(dateRangeFields).map(
                            ([key, value]) => {
                              if (
                                value.every(
                                  (field) =>
                                    typeof values.filter?.[field] ===
                                      "string" &&
                                    (values.filter?.[field] as string).length
                                )
                              ) {
                                return (
                                  <div key={key}>
                                    <p
                                      className={`${styles.helpingInfoText} ${styles.titleParam}`}
                                    >
                                      {directory[key]?.["title"]}
                                    </p>
                                    <Option
                                      title={`${getFormattedDate(
                                        values.filter[value[0]] as string
                                      )} - ${getFormattedDate(
                                        values.filter[value[1]] as string
                                      )}`}
                                      onClick={() => {
                                        value.forEach((field) => {
                                          setFieldValue(
                                            `filter[${field}]`,
                                            null
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                      </>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </FormWindow>
          </div>
        );
      }}
    </Formik>
  );
};

export default FilterMainWindow;
