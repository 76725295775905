import { format } from "date-fns";
import { makeAutoObservable } from "mobx";
import RootStore from "stores";
import { StaffList } from "stores/StaffModule/types/StaffList";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { NavType } from "stores/utils/types/NavType";

export default class StaffApiStore {
  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }

  checkAmnesty = async (uid: string) => {
    try {
      const data: ApiResponse<never> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "checkAmnesty",
        params: { uid, date: format(new Date(), "yyyy-MM-dd") }
      });
      if (data.code == 200) {
        return data;
      } else {
        return undefined;
      }
    } catch (error) {
      throw new Error(error["message"] || "Ошибка при выполнении запрос");
    }
  };

  getPage = async (
    nav: NavType,
    params: Record<string, string | number | string[] | number[]>
  ): Promise<ApiResponse<never> & { records: StaffList["records"] }> => {
    try {
      const data: ApiResponse<never> & StaffList =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "staff",
          method: "getPage",
          on_page: nav.on_page,
          page: nav.now_page,
          params
        });

      return data;
    } catch (error) {
      throw new Error(error["message"] || "Ошибка при выполнении запрос");
    }
  };
}
